import React, {useEffect, useRef, useState} from "react";
import useAlert from "../../../../CustomHooks/useAlert";
import {useNavigate, useParams} from "react-router-dom";
import TableComponent from "../../../../Components/TableComponentFiles/TableComponent";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchContentForGroup,
    fetchItemGroups,
    fetchItemsOfGroup, fetchItemsOfGroupByItemId
} from "../../../../redux/actions/PagesDataActions";
import axios from "axios";
import DragDropHandler from "../../../../Components/DragDropHandler";
import {useItemTypes} from "../../../../CustomHooks/useItemTypes";
import {TypeSelect} from "../CustomPageEditor/Tabs/SettingsTab/ItemSettings/TypeSelect";

const ItemGroups = () => {
    const {id, itemId} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const itemGroupsData = useSelector((state) => state.itemGroupsReducer);
    const {itemGroups} = itemGroupsData
    const {itemsOfGroup, item_group_data, success} = useSelector((state) => state.itemsOfGroupReducer);
    const {types} = useItemTypes()
    const [formData, setFormData] = useState({
        name: "",
        type: "",
        type_id: -1,
    })
    const prevTypeRef = useRef(formData.type);
    const prevTypeIdRef = useRef(formData.type_id);
    useEffect(() => {
        if (id) {
            dispatch(fetchItemsOfGroup(id))
            dispatch(fetchContentForGroup(0))
        } else {
            dispatch(fetchItemGroups())
        }
    }, [id]);
    useEffect(() => {
        if (itemId && success) {
            const itemOfGroup = itemsOfGroup.find(x => x.id === Number(itemId))
            setFormData(itemOfGroup)
            if (itemOfGroup.type === "product") dispatch(fetchContentForGroup(itemOfGroup.type_id))
            else dispatch(fetchContentForGroup(0))
        } else {
            setFormData({
                name: "",
                type: "",
                type_id: -1,
            })
        }
    }, [itemId, success]);
    useEffect(() => {
        if (success && id) {
            setItemGroup(item_group_data)
        } else {
            setItemGroup({name:"", type: "row"})
        }
    }, [success, id]);
    useEffect(() => {
        if (prevTypeRef.current !== formData.type && prevTypeIdRef.current === formData.type_id) {
            setFormData((prevFormData) => ({...prevFormData, type_id: -1}));
        }
    }, [formData.type]);
    useEffect(() => {
        prevTypeRef.current = formData.type;
        prevTypeIdRef.current = formData.type_id;
    }, [formData.type, formData.type_id]);

    const [showAlert, AlertComponent] = useAlert();
    const [itemGroup, setItemGroup] = useState({name: "", type: "row"})
    const submitItemOfGroup = async (e) => {
        e.preventDefault()
        if (itemId) {
            try {
                const {data} = await axios.post('/admin/content/update/item-of-group/' + itemId, formData);
                showAlert("success", "Item Updated in Group Successfully")
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
            dispatch(fetchItemsOfGroupByItemId(itemId))
        } else {
            try {
                const {data} = await axios.post('/admin/content/add/item-of-group',
                    {...formData, item_group_id: id});
                showAlert("success", "Item Added in Group Successfully")
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
            dispatch(fetchItemsOfGroup(id))
        }
    }
    const submitItemGroup = async (e) => {
        e.preventDefault()
        if (id) {
            try {
                const {data} = await axios.post('/admin/content/update/item-group/' + id, itemGroup);
                showAlert("success", "Item Group Updated Successfully")
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
        } else {
            try {
                const {data} = await axios.post('/admin/content/add/item-group', itemGroup)
                showAlert("success", "Item Group Added Successfully")
                if (typeof data === "number") {
                    navigate('/item-groups/' + data)
                } else {
                    showAlert("error", data.detail)
                }
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
        }
        dispatch(fetchItemGroups())
    }
    const submitArrangement = async (reorderedArray) => {
        try {
            const response = await axios.post("/admin/content/update/order/items-of-group", reorderedArray)
            showAlert("success", "Group Items Rearranged Successfully!")
        } catch (e) {
            showAlert("error", e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message)
        }
    }

    return (
        <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
            {AlertComponent}
            {!itemId &&
                <form onSubmit={submitItemGroup} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Items Group</span>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="order">Name</label>
                        <input type="text" id="order" value={itemGroup.name} required={true}
                               onChange={(e) => setItemGroup({...itemGroup, name: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="type">Type</label>
                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                id="type" value={itemGroup.type} required={true}
                                onChange={(e) => setItemGroup({...itemGroup, type: e.target.value})}>
                            <option value="row">1 Item in 1 Column</option>
                            <option value="column">All Items in 1 Column</option>
                        </select>
                    </div>
                    <button
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit">{(id ? "Update" : "Add")} Item Group
                    </button>
                </form>}
            {itemGroupsData.success && !id && !itemId && <div className="rounded-lg bg-white mx-2 mb-4">
                <TableComponent TableData={itemGroups.map(({id, name, updated_at, type}) => ({
                    id, name, type: type === "row" ? "1 Item in 1 Column" : "All Items in 1 Column",
                    updated_at
                }))} tableName="Item Groups" showAlert={showAlert}/>
            </div>}
            {(id || itemId) && <>
                <form onSubmit={submitItemOfGroup} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">{itemId ? "Edit" : "Add"} Item in Group</span>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="order">Name</label>
                        <input type="text" id="order" value={formData.name} required={true}
                               onChange={(e) => setFormData((prevState) => ({...prevState, name: e.target.value}))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="type">Type</label>
                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                id="type" value={formData.type} required={true}
                                onChange={(e) => setFormData({...formData, type: e.target.value})}>
                            <option value={""} disabled selected>Select Type</option>
                            {types.map(type => (
                                <option key={type.value} value={type.value}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                    <TypeSelect formData={formData} setFormData={setFormData}/>
                    <button
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit">{(itemId ? "Update" : "Add")} Item in Group
                    </button>
                </form>
                {id && success &&
                    <DragDropHandler ItemComponent={ContentItemComponent} submitArrangement={submitArrangement}
                                     itemsArray={itemsOfGroup}/>}
                {id && success && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={itemsOfGroup.map(({id, name, type, updated_at}) => ({
                        id, name,
                        type,
                        updated_at
                    }))} tableName="Items of Group" showAlert={showAlert}/>
                </div>}
            </>}

        </div>
    )
}
const ContentItemComponent = ({item}) => {
    const {types} = useItemTypes()
    return (
        <div
            className="w-full flex flex-col justify-center gap-2 items-center">
            <div>{item.name}</div>
            <div>Type: {types.find(x => x.value === item.type).name}</div>
        </div>
    )
}
export default ItemGroups