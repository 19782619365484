import React, {useState} from "react";
import ImageUploadComponent from "../../../../Components/ImageUploadComponent";
import {getBaseUrl} from "../../../../App";
import ImagesUploadComponent from "../../../../Components/ImagesUploadComponent";
import {deleteProductImageVariant} from "../../../../redux/actions/ProductActions";
import {showAlert} from "../../../../utils/providers/AlertProvider";
import {useDispatch} from "react-redux";

const UploadProductImages = ({
                                 mainImage,
                                 setMainImage,
                                 images,
                                 setImages,
                                 product,
                                 setImageVariations,
                                 imageVariations
                             }) => {

    const dispatch = useDispatch();

    const deleteImage = (id) => {
        dispatch(deleteProductImageVariant(id))
        setImageVariations(imageVariations.filter(x => x.id !== id))
        showAlert("success", "Image Deleted Successfully!")
    }

    return (<>
        <ImageUploadComponent setImage={setMainImage} title={"Update Main Image of Product"}
                              required={product === undefined}/>
        {product && Object.keys(mainImage).length === 0 && <div className="relative w-full">
            <div
                className="flex justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                <img src={getBaseUrl() + product.image} alt="Preview"
                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
            </div>
        </div>}
        <ImagesUploadComponent images={images} setImages={setImages}
                               title={"Set Product Variant Images"}/>
        {imageVariations?.map((x, i) => (i !== 0 && <div className="relative w-full">
            <div
                className="flex justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                <img src={getBaseUrl() + x.image} alt="Preview"
                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                <span onClick={() => deleteImage(x.id)}
                      className="absolute cursor-pointer text-center top-4 text-red-500 right-2 rounded-full shadow-lg border-2 px-2 py-2 pt-2 material-symbols-rounded bg-black bg-opacity-10 font-light text-md ">delete</span>
            </div>
        </div>))}
        <span>Products with good and clear images are sold faster!</span>
    </>)
}
export default UploadProductImages