import {useDispatch, useSelector} from "react-redux";
import {setAllPageItems} from "../redux/actions/PageEditorActions";

export const usePageEditorItems = () => {
    const pageItems = useSelector((state) => state.allPageItems);
    const dispatch = useDispatch()
    const setPageItems = (data) => {
        if (typeof data === 'function') {
            const newData = data(pageItems);
            dispatch(setAllPageItems(newData));
        } else {
            dispatch(setAllPageItems(data));
        }
    }
    return [pageItems, setPageItems];
}