import React from "react";
import {Link} from "react-router-dom";
import {getBaseUrl} from "../../../App";

const ProductDetailsPage = ({productDetails, getDate}) => {
    const {image_variations, product_details, product, variations_with_types} = productDetails
    return (
        <React.Fragment>
            <div className="bg-gray-100 py-1">
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div className="flex border-b-2 justify-between items-center pb-2">
                        <span className="text-lg font-bold">Product</span>
                        <Link to={"/update/product/" + product.id}> <span
                            className="bg-green-600 text-white rounded px-3 py-1.5">Edit Product</span></Link>
                    </div>
                    <div>Product ID: {product.id}</div>
                    <hr/>
                    <div><b>Name:</b> {product.name}</div>
                    <div><b>Short Description:</b> {product.description}</div>
                    <div><b>SKU:</b> {product.SKU}</div>
                    <div><b>Slug:</b> {product.slug}</div>
                    <div><b>Category:</b> {product.category_id}</div>
                    <div><b>Price:</b> ${product.price}</div>
                    <div><b>Discount:</b> {product.discount_rate}%</div>
                    <div><b>Stock:</b> {product.stock}</div>
                    <div><b>Product Views:</b> {product.page_views}</div>
                    <div><b>Rating:</b> {product.rating}</div>
                    <div><b>Edited on:</b> {getDate(product.updated_at)}</div>
                    <div><b>Created on:</b> {getDate(product.created_at)}</div>
                </div>

                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <span className="text-lg font-bold">Product Images</span>
                    <hr/>
                    <div><b>Main Image:</b><br/><img className="h-56 w-auto border-2 rounded"
                                                     src={getBaseUrl() + product.image}/></div>
                    <div><b>Image Variations:</b></div>
                    {image_variations.map((x, i) => (
                        <div>
                            {i !== 0 && <img className="h-auto w-56 border-2 rounded"
                                             src={getBaseUrl() +x.image}/>}

                        </div>

                    ))}

                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <span className="text-lg font-bold">Product Details</span>
                    <hr/>
                    <div><b>Long Description:</b>
                        <div dangerouslySetInnerHTML={{__html: product_details.description}}></div>
                    </div>
                    <div><b>FAQs:</b>
                        <div dangerouslySetInnerHTML={{__html: product_details.faqs}}></div>
                    </div>
                    <div><b>SEO Title:</b> {product_details.seo_title}</div>
                    <div><b>SEO Description:</b> {product_details.seo_description}</div>
                    <div><b>SEO Keywords:</b> {product_details.seo_keywords}</div>
                </div>

                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <span className="text-lg font-bold">Product Variations</span>
                    <hr/>
                    {variations_with_types.map((y, index) => (
                        <>
                            {index !== 0 ? <hr/> : ""}
                            <div className="flex flex-col">
                                <div><b>Type:</b> {y.variation_type}</div>

                                {y.variations.map((x, index) => (
                                    <>
                                        <hr/>
                                        <div className="flex flex-row justify-between">
                                            <div><b>Name: </b> {x.variation}</div>
                                            <div><b>Price: </b>${x.price}</div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}
export default ProductDetailsPage
