import React from "react";
import {effects} from "../Screens/AdminPanel/Content/ItemsOrganizer";

const SliderOptions = ({formData, setFormData, dark=false}) => {
    return (<>
            <div className="checkbox-wrapper-14 flex justify-between my-2">
                <span>Slider</span>
                <input type="checkbox" className="switch" checked={formData.is_slider}
                       onChange={(e) => setFormData({...formData, is_slider: e.target.checked})}/>
            </div>
            {formData.is_slider && <>
                <div className="w-full">
                    <label htmlFor="speed">Slider Speed in Milliseconds</label>
                    <input type="number" id="speed" value={formData.slider_speed} required={true}
                           onChange={(e) => setFormData(prevState => ({
                               ...prevState, slider_speed: e.target.value
                           }))}
                           className={`py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500 ${dark && "bg-gray-800"}`}/>
                </div>
                <div className="w-full">
                    <label htmlFor="effect">Slider Effect/Animation</label>
                    <select className={`py-2 px-3 mt-1 rounded-md w-full border border-gray-500 ${dark && "bg-gray-800"}`}
                            id="effect" value={formData.slider_effect}
                            onChange={(e) => setFormData(prevState => ({
                                ...prevState, slider_effect: e.target.value
                            }))}>
                        <option disabled selected>Select Effect/Animation</option>
                        {effects.map(x => (<option key={x.name} value={x.name}>{x.title}</option>))}
                    </select>
                </div>
            </>}</>)
}
export default SliderOptions