import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../../Components/loading";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";

const Feedbacks = () => {
    const [feedbacks, setFeedbacks] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const getFeedbacks = async () => {
            try {
                const {data} = await axios.get('/admin/orders/feedbacks')
                setFeedbacks(data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        getFeedbacks()
    }, []);
    return (
        <React.Fragment>
            {loading ? <Loading/> : <TableComponent TableData={feedbacks.reverse()}
                                                    tableName={"Feedbacks"}/>}
        </React.Fragment>
    )
}
export default Feedbacks