import React from "react";
import SvgIcon from "../../../../utils/IconsSelector/SvgIcon"

const Feature = ({
                     title_text,
                     description_text,
                     iconPath,
                     title_text_color,
                     icon_color,
                     description_text_color,
                     icon_background_color,
                     background_gradient_direction,
                     background_start_color,
                     background_end_color,
                 }) => {
    return (
        <div
            style={{backgroundImage: `linear-gradient(${background_gradient_direction}, ${background_start_color}, ${background_end_color})`}}
            className="p-8 text-white rounded-lg shadow-lg animate__animated animate__fadeInUp">
            <div style={{backgroundColor: hexToRGBA(icon_background_color, 0.3)}}
                 className="mx-10 p-8 flex bg-opacity-20 justify-center rounded-full animate__animated animate__fadeInBottomRight">
                <div className="text-3xl"><SvgIcon d={iconPath} style={{color: icon_color}} width="100"
                                                   height="100"/></div>
            </div>
            <h2 style={{color: title_text_color}}
                className="text-2xl text-center font-bold mt-4 animate__animated animate__lightSpeedInRight">{title_text}</h2>
            <p style={{color: description_text_color}}
               className="mt-2 text-center animate__animated animate__fadeIn">{description_text}</p>
        </div>
    )
}
const hexToRGBA = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
};
export default Feature

// "gift":"<svg  xmlns=\"http://www.w3.org/2000/svg\" width=\"48\" height=\"48\" viewBox=\"0 0 128 128\" ><path d=\"m40.6 11c2.3 0 4.4 0.9 6.1 2.5 5.4 5.4 10.8 19.2 8.9 21.2 0 0-0.3 0.3-1.5 0.3-5.5 0-16.1-5.7-19.5-9.2-3.4-3.4-3.4-8.9 0-12.2 1.5-1.7 3.7-2.6 6-2.6m46.8 0.1c2.2 0 4.4 0.8 6.1 2.4 3.4 3.4 3.4 8.9 0 12.2-3.4 3.5-14 9.2-19.5 9.2-1.1 0-1.5-0.3-1.5-0.3-1.9-1.9 3.5-15.7 8.9-21.2 1.6-1.5 3.8-2.3 6-2.3m32.6 32.1v16h-52v-16h52m-60 0v16h-52v-16h52m52 23.9v47.9h-44v-47.9h44m-52 0v47.9h-44v-47.9h44m-19.4-64.1c-4.4 0-8.6 1.7-11.7 4.9-6.5 6.5-6.5 17 0 23.5 1.2 1.2 2.8 2.5 4.7 3.8h-25.6c-4.4 0-8 3.6-8 8v24h8v55.8h112v-55.9h8v-24c0-4.4-3.6-8-8-8h-25.6c1.9-1.3 3.6-2.6 4.7-3.8 6.5-6.5 6.5-17 0-23.5-3-3-7.3-4.8-11.7-4.8s-8.7 1.7-11.7 4.8c-4.6 4.6-7.8 11.7-8.9 14.5-2.2 5.4-3 9.5-2.5 12.8h-0.5c0.5-3.2-0.4-7.4-2.5-12.8-1.2-2.8-4.3-9.9-8.9-14.5-3.2-3.1-7.4-4.8-11.8-4.8z\"></path></svg>",
//     "medal_new":"<svg  xmlns=\"http://www.w3.org/2000/svg\" width=\"48\" height=\"48\" viewBox=\"0 0 128 128\" ><path d=\"m119.4 104.8-17.8-30.8c1.6-1.3 2.5-3.4 2.4-5.6l-1.3-10.7 7.4-7.6c2.3-2.3 2.4-6.1 0.2-8.8l-7.4-7.9 1.5-10.8c0.3-3.2-2-6.4-5.2-7l-10.7-2.1-5.2-9.4c-1.1-2.2-3.3-3.5-5.8-3.5-0.8 0-1.5 0.1-2.3 0.4l-10.4 4.4-9.6-4.6c-0.9-0.5-1.9-0.7-3-0.7-0.6 0-1.3 0.1-1.9 0.3-1.6 0.5-2.9 1.5-3.8 3l-5.3 9.4-10.7 1.9c-3.4 0.6-5.7 3.7-5.4 7.1l1.3 10.7-7.4 7.6c-2.3 2.3-2.4 6.1-0.2 8.8l7.4 7.9-1.5 10.8c-0.3 2.3 0.8 4.6 2.7 5.9l-17.9 31.3c-0.7 1.1-0.7 2.6-0.1 3.9v0.1l0.1 0.1c0.8 1.2 2 1.9 3.4 1.9h19.2l8.7 14.9c0.8 1.2 2 1.9 3.4 1.9s2.8-0.8 3.5-2l16.6-27.9 16.8 28.3c0.8 1.2 2 1.9 3.4 1.9s2.8-0.8 3.5-2l8.7-15.3h19.2c1.4 0 2.7-0.8 3.4-1.9 0.9-1.1 0.9-2.7 0.1-3.9zm-34.9 11.3-17.9-30.5 7.4 3.6c0.9 0.4 1.8 0.6 2.9 0.6 2.4 0 4.6-1.2 5.7-3.2l5.3-9.4 5.8-1 15.4 26.6h-14.7c-1.4 0-2.8 0.8-3.5 2l-6.4 11.3zm-40-0.3-6.5-11c-0.8-1.2-2-1.9-3.4-1.9h-14.8l15.5-27 5.4 0.9 5.2 9.3c1.1 2.2 3.3 3.5 5.8 3.5 0.8 0 1.5-0.1 2.3-0.4l0.5-0.1 3.9-1.8 1.4 2.4-15.3 26.1zm-11.9-78.1c1.4-1.4 2.1-3.4 1.8-5.3l-1.2-10 10.2-1.8c1.9-0.3 3.6-1.6 4.6-3.2l4.9-8.8 9 4.3c0.9 0.5 1.9 0.7 2.9 0.7s2-0.2 2.9-0.7l9.2-4.1 4.9 9.1c1.1 1.7 2.7 2.9 4.5 3.1l10 1.9-1.4 10c-0.3 1.9 0.3 4 1.7 5.3l6.9 7.3-7 7.1c-1.5 1.4-2.1 3.4-1.8 5.3l1.2 9.9-9.9 1.6c-2 0.3-3.7 1.6-4.6 3.2l-4.9 8.8-9-4.3c-0.9-0.5-1.9-0.7-2.9-0.7s-2 0.2-2.9 0.7l-9.2 4.2-4.9-9c-1.1-1.7-2.8-2.9-4.6-3.1l-10.1-1.8 1.4-10c0.3-1.9-0.3-4-1.7-5.3l-6.8-7.4 6.8-7z\"></path><path d=\"m64.5 71c14.7 0 26.6-11.7 26.6-26.2 0-14.4-11.9-26.2-26.6-26.2s-26.6 11.7-26.6 26.2 11.9 26.2 26.6 26.2zm0-44.4c10.2 0 18.6 8.2 18.6 18.3s-8.3 18.3-18.6 18.3c-10.2 0-18.6-8.2-18.6-18.3s8.4-18.3 18.6-18.3z\"></path></svg>",
