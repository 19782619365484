import React, {useEffect, useState} from "react"
import Chart from "react-apexcharts"
import DashboardCard from "../../../Components/DashboardCard";
import {useDispatch, useSelector} from "react-redux";
import {useViewport} from "../../../CustomHooks/useViewport";
import {fetchDashboardPage} from "../../../redux/actions/PagesDataActions";

const DashboardPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDashboardPage())
    }, []);
    const {isMobile} = useViewport()
    const {ordersList} = useSelector((state) => state.ordersList);
    const {wishlist} = useSelector((state) => state.wishlistItemsList);
    const {product_views, total_products} = wishlist
    const {users} = useSelector((state) => state.usersList);
    const {reviews} = useSelector((state) => state.reviewsList);
    const ordersDates = []
    let ordersData = []
    let salesData = []
    ordersList.map(x => {
        const date = new Date(x.created_at);
        const options = {day: 'numeric', month: 'long', year: 'numeric'};
        const formattedDate = date.toLocaleDateString('en-US', options);
        if (ordersDates.includes(formattedDate)) {
            ordersData[ordersData.length - 1] = parseInt(ordersData[ordersData.length - 1]) + 1
            salesData[salesData.length - 1] = (parseFloat(salesData[salesData.length - 1]) + x.total_price).toFixed(2)
        } else {
            ordersDates.push(formattedDate)
            ordersData.push(1)
            salesData.push(x.total_price)
        }
    })

    const reviewLen = (num) => {
        return reviews.filter(x => x.rating === num).length
    }
    const lineCharts = [
        {
            dateTimeArray: ordersDates,
            series: [
                {
                    name: "Orders",
                    data: ordersData
                }
            ]
        },
        {
            dateTimeArray: ordersDates,
            series: [
                {
                    name: "Sales",
                    data: salesData
                }
            ]
        }
    ]
    const deliveredOrders = ordersList.filter(x => x.status === "Delivered")
    const pendingOrders = ordersList.filter(x => x.status !== "Delivered" && x.status !== "Cancelled")
    const pendingSales = pendingOrders.reduce((accumulator, order) => accumulator + order.total_price, 0).toFixed(2)
    const completedSales = deliveredOrders.reduce((accumulator, order) => accumulator + order.total_price, 0).toFixed(2)
    const pieCharts = [
        {
            labels: ["Delivered Orders", "Pending Orders"],
            series: [deliveredOrders.length, pendingOrders.length]
        }
        , {
            labels: ["5 Stars", "4 Stars", "3 Stars", "2 Stars", "1 Star"],
            series: [reviewLen(5), reviewLen(4), reviewLen(3), reviewLen(2), reviewLen(1)]
        }, {
            labels: ["Completed Sales", "Pending Sales"],
            series: [(completedSales * 100 / 100), (pendingSales * 100 / 100)]
        }
    ]
    const dashboardCards = [
        {
            label: "Orders",
            icon: "shopping_cart",
            color: "rgba(25,135,84,0.8)",
            count: ordersList.filter(x => x.visibility === 1).length
        }
        , {
            label: "Products",
            icon: "inventory_2",
            color: "rgba(162, 0, 255, 0.8)",
            count: total_products
        }
        , {
            label: "Members",
            icon: "groups",
            color: "rgba(255,0,0,0.7)",
            count: users.length
        }
        , {
            label: "Product Views",
            icon: "visibility",
            color: "rgba(255,153,0,0.8)",
            count: product_views
        }
    ]
    return (
        !Array.isArray(wishlist) && typeof wishlist === "object" && <React.Fragment>
            <div className="flex flex-col gap-5 bg-gray-200 py-6">
                <div className={`grid ${isMobile ? "grid-cols-1" : "grid-cols-4"} place-items-center gap-3 mx-10`}>
                    {dashboardCards.map((card, index) => (
                        <DashboardCard card={card} index={index}/>
                    ))}
                </div>
                <div className={`grid ${isMobile ? "grid-cols-1" : "grid-cols-3"} place-items-center gap-3`}>
                    {pieCharts.map(chart => (
                        <div className="bg-white rounded-md p-3">
                            <Chart type="donut" series={chart.series}
                                   options={pieChartOptions(chart.labels)}/>
                        </div>
                    ))}
                </div>
                <div className={`grid ${isMobile ? "grid-cols-1" : "grid-cols-2"} place-items-center gap-3`}>
                    {
                        lineCharts.map(chart => (
                            <div className="bg-white rounded-md p-6">
                                <div className="mx-4 mb-2">{chart.series[0].name}
                                    <hr/>
                                </div>
                                <Chart series={chart.series} options={lineChartOptions(chart.dateTimeArray)}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
const pieChartOptions = (labels) => {
    return ({
        chart: {
            type: 'donut',
            height: 350,
        },
        dataLabels: {
            enabled: false,
        },
        labels: labels,
        legend: {
            position: 'bottom',
        },
    })
}
const lineChartOptions = (dateTimeArray) => {
    return (
        {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: dateTimeArray
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
        }
    )

}

export default DashboardPage