import React from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";
import PlaceholderItem from "./PlaceholderItem";
import {ITEM_OF_ITEM_OF_PAGE_ITEM, ITEM_OF_PAGE_ITEM} from "../../../../../redux/Constants/PageEditorConstants";
import ItemOfItemOfPageItem from "./ItemOfItemOfPageItem";
import {useDispatch, useSelector} from "react-redux";
import {updateIndexOfArray} from "../../../../../utils/UtilityFunctions";
import {usePageEditorItems} from "../../../../../CustomHooks/usePageEditorItems";
import {
    setSelectedItemOfItemOfPageItem,
    setSelectedItemOfPageItem,
    setSelectedPageItem,
    setSelectedTab
} from "../../../../../redux/actions/PageEditorActions";

const ItemOfPageItem = ({item, itemOfPageItemIndex, pageItemIndex}) => {
    const [pageItems, setPageItems] = usePageEditorItems()
    const selectedItemOfPageItem = useSelector((state) => state.selectedItemOfPageItem);
    const dispatch = useDispatch()

    const handleOnDragStart = (e) => {
        e.stopPropagation()
        item.pageItemIndex = pageItemIndex
        e.dataTransfer.setData('itemComponent', JSON.stringify(item))
    };
    const handleOnDragOver = (e) => {
        e.preventDefault()
    };
    const handleClick = (e) => {
        e.stopPropagation()
        dispatch(setSelectedPageItem({}))
        dispatch(setSelectedItemOfPageItem(item))
        dispatch(setSelectedItemOfItemOfPageItem({}))
    }
    return (<Draggable
        draggableId={"itemOfPageItemIndex " + itemOfPageItemIndex + ", pageItemIndex " + pageItemIndex}
        index={itemOfPageItemIndex}>
        {(provided) => (<div {...provided.dragHandleProps} {...provided.draggableProps}
                             ref={provided.innerRef} style={{gridTemplateColumns: "repeat(15, minmax(0, 1fr))"}}
                             className="grid w-full" key={item.index}>
            <PlaceholderItem itemIndex={itemOfPageItemIndex}
                             type={ITEM_OF_PAGE_ITEM}
                             vertical={false}
                             before={true}
                             pageIndex={pageItemIndex}/>
            <Droppable
                droppableId={"itemOfPageItemIndex " + itemOfPageItemIndex + ", pageItemIndex " + pageItemIndex}
                type={ITEM_OF_ITEM_OF_PAGE_ITEM}>
                {(provided) => (<div {...provided.droppableProps}
                                     ref={provided.innerRef} style={{ gridColumn: "span 13 / span 13"}}
                                     className="relative w-full">
                    <div
                        draggable={true}
                        className={`w-full cursor-grab flex flex-col border p-6 rounded-lg border-dashed ${selectedItemOfPageItem === item ? "border-blue-500" : "border-gray-300"}`}
                        onClick={(e) => handleClick(e)}
                        onDragOver={handleOnDragOver}
                        onDragStart={handleOnDragStart}
                    >
                        {item.items.length === 0 && <PlaceholderItem before={true}
                                                                     vertical={true}
                                                                     itemIndex={itemOfPageItemIndex}
                                                                     type={ITEM_OF_ITEM_OF_PAGE_ITEM}
                                                                     itemOfItemIndex={0}
                                                                     pageIndex={pageItemIndex}/>}
                        {item.items.map((itemOfItem, index) => (
                            <ItemOfItemOfPageItem key={index} item={itemOfItem} itemOfItemOfPageItemIndex={index}
                                                  itemOfPageItemIndex={itemOfPageItemIndex}
                                                  pageItemIndex={pageItemIndex}/>))}
                        {selectedItemOfPageItem === item && (<span
                            onClick={() => deleteItemOfPageItem(itemOfPageItemIndex, pageItemIndex, setPageItems, false)}
                            className="material-symbols-rounded absolute text-sm top-0 right-0 text-white bg-red-500 rounded-lg px-1 py-0.5 cursor-pointer">delete</span>)}
                    </div>
                    {provided.placeholder}
                </div>)}
            </Droppable>
            <PlaceholderItem itemIndex={itemOfPageItemIndex}
                             type={ITEM_OF_PAGE_ITEM}
                             vertical={false}
                             before={false}
                             pageIndex={pageItemIndex}/>
        </div>)}
    </Draggable>)
}
export default ItemOfPageItem

const deleteItemOfPageItem = (itemIndex, pageIndex, setPageItems) => {
    setPageItems((prevState) => {
        return prevState.map(pageItem => {
            if (pageItem.index === pageIndex) {
                const newItems = [...pageItem.items]
                newItems.splice(itemIndex, 1)
                return {
                    ...pageItem, items: updateIndexOfArray(newItems)
                };
            }
            return pageItem;
        });
    });
}