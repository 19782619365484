import {
    ADD_PRODUCT_ERROR,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS, PRODUCT_DETAILS_ERROR,
    PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_ERROR,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS, PRODUCT_UPDATE_ERROR, PRODUCT_UPDATE_REQUEST, PRODUCT_UPDATE_SUCCESS,
    DELETE_PRODUCT_VARIANT_IMAGE_SUCCESS, DELETE_PRODUCT_VARIANT_IMAGE_ERROR, DELETE_PRODUCT_VARIANT_IMAGE_REQUEST
} from "../Constants/ProductConstants";

export const productListReducer = (state = {products: []}, action) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return {loading: true, products: []}
        case PRODUCT_LIST_SUCCESS:
            return {loading: false,success:true, products: action.payload}
        case PRODUCT_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const productDetailsReducer = (state = {productDetails: []}, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return {loading: true, productDetails: []}
        case PRODUCT_DETAILS_SUCCESS:
            return {loading: false,success:true, productDetails: action.payload}
        case PRODUCT_DETAILS_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const addProductReducer = (state = {product: {}}, action) => {
    switch (action.type) {
        case ADD_PRODUCT_REQUEST:
            return {loading: true, message: {}}
        case ADD_PRODUCT_SUCCESS:
            return {loading: false,success:true, message: action.payload}
        case ADD_PRODUCT_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const updateProductReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case PRODUCT_UPDATE_SUCCESS:
            return {loading: false,success:true, message: action.payload}
        case PRODUCT_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const deleteProductVariantImageReducer = (state = {image: {}}, action) => {
    switch (action.type) {
        case DELETE_PRODUCT_VARIANT_IMAGE_REQUEST:
            return {loading: true, message: {}}
        case DELETE_PRODUCT_VARIANT_IMAGE_SUCCESS:
            return {loading: false,success:true, message: action.payload}
        case DELETE_PRODUCT_VARIANT_IMAGE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}