import {
    allPageItems,
    selectedItemOfItemOfPageItem,
    selectedItemOfPageItem,
    selectedPageId,
    selectedPageItem,
    selectedTab
} from "./PageEditorReducer";
import {combineReducers} from "redux";
import {currencyReducer, menuItemsId, search, selectedPage, sidebar} from "./AdminPanelReducer";
import {categoriesSidebar, menuSidebar, productSearch, selectedHomePage,} from "./HomeReducer"
import {addProductReducer, productDetailsReducer, productListReducer, updateProductReducer} from "./ProductReducer";
import {addCategoryReducer, categoryListReducer, collectionListReducer, updateCategoryReducer} from "./CategoryReducer";
import {
    addVariationReducer,
    addVariationTypeReducer,
    productVariationsWithTypesListReducer,
    variationsListReducer,
    variationsWithTypesListReducer,
    variationTypesListReducer
} from "./VariationReducer";
import {
    authReducer,
    loginUserReducer,
    logoutUserReducer,
    refreshUserReducer,
    registerUserReducer,
    userDataReducer,
    usersListReducer
} from "./UserReducer";
import {cartItemsListReducer} from "./CartReducer";
import {
    apiSettingsDataReducer,
    contactSettingsDataReducer,
    footerLinksReducer,
    generalSettingsDataReducer,
    paymentSettingsDataReducer,
    socialSettingsDataReducer,
    stripePaymentsDataReducer,
    tinymceApi,
    updateGeneralSettingsReducer
} from "./SettingsReducer";
import {
    deleteOrderReducer,
    orderedProductsListReducer,
    ordersListReducer,
    reviewsListReducer,
    saveOrderReducer,
    soldProductsListReducer,
    updateOrderReducer
} from "./OrderReducer";
import {
    bannersDataReducer,
    contentEditorsReducer,
    couponsDataReducer,
    customCodesReducer,
    featureItemsReducer,
    itemGroupsOfItemsOrganizerReducer,
    itemGroupsReducer,
    itemsOfGroupReducer,
    itemsOrganizersReducer,
    menuItemsReducer,
    pageItemsReducer,
    sliderBannersDataReducer,
    slidersDataReducer,
    teamMemberItemsReducer,
    topBarReducer,
    topBarsReducer,
    updateHomeItemsReducer,
    updateHomeOrderReducer,
    updateMenuItemsReducer,
    updateMenuOrderReducer,
    updateSliderReducer
} from "./GeneralItemsReducer";
import {wishlistItemsListReducer} from "./WishlistReducer";
import {editorPageDataReducer, mediaListReducer, pageDataReducer, pageListReducer} from "./PageReducer";

const rootReducer = combineReducers({
    selectedPageItem, selectedItemOfPageItem, selectedItemOfItemOfPageItem, allPageItems, selectedTab, selectedPageId,
    productList: productListReducer,
    productDetails: productDetailsReducer,
    addProduct: addProductReducer,
    updateProduct: updateProductReducer,
    categoryList: categoryListReducer,
    collectionList: collectionListReducer,
    addCategory: addCategoryReducer,
    updateCategory: updateCategoryReducer,
    variationsWithTypes: variationsWithTypesListReducer,
    productVariationsWithTypes: productVariationsWithTypesListReducer,
    addVariation: addVariationReducer,
    addVariationType: addVariationTypeReducer,
    variationsList: variationsListReducer,
    variationTypesList: variationTypesListReducer,
    loginUser: loginUserReducer,
    registerUser: registerUserReducer,
    userData: userDataReducer,
    usersList: usersListReducer,
    auth: authReducer,
    topBar: topBarReducer,
    topBars: topBarsReducer,
    refreshUser: refreshUserReducer,
    logoutUser: logoutUserReducer,
    cartItemsList: cartItemsListReducer,
    wishlistItemsList: wishlistItemsListReducer,
    orderedProductsList: orderedProductsListReducer,
    ordersList: ordersListReducer,
    saveOrder: saveOrderReducer,
    updateOrder: updateOrderReducer,
    deleteOrder: deleteOrderReducer,
    updateGeneralSettings: updateGeneralSettingsReducer,
    generalSettingsData: generalSettingsDataReducer,
    contactSettingsData: contactSettingsDataReducer,
    socialSettingsData: socialSettingsDataReducer,
    paymentSettingsData: paymentSettingsDataReducer,
    apiSettingsData: apiSettingsDataReducer,
    stripePaymentsDataReducer,
    bannersData: bannersDataReducer,
    slidersData: slidersDataReducer,
    sliderBannersData: sliderBannersDataReducer,
    updateSlider: updateSliderReducer,
    tinymceApi,
    menuItems: menuItemsReducer,
    couponsData: couponsDataReducer,
    updateMenuItems: updateMenuItemsReducer,
    updateMenuOrder: updateMenuOrderReducer,
    pageItemsReducer,
    updateHomeItems: updateHomeItemsReducer,
    updateHomeOrder: updateHomeOrderReducer,
    pageList: pageListReducer,
    pageData: pageDataReducer,
    editorPageData: editorPageDataReducer,
    soldProductsList: soldProductsListReducer,
    reviewsList: reviewsListReducer,
    currencyReducer: currencyReducer,
    mediaListReducer,
    footerLinksReducer,
    menuItemsId,
    sidebar,
    search,
    selectedPage,
    productSearch,
    categoriesSidebar,
    menuSidebar,
    selectedHomePage,
    featureItemsReducer,
    teamMemberItemsReducer, itemGroupsOfItemsOrganizerReducer,
    contentEditorsReducer, customCodesReducer, itemGroupsReducer, itemsOfGroupReducer, itemsOrganizersReducer
});

export default rootReducer;