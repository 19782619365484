import {
    ALL_PAGE_ITEMS,
    SELECTED_ITEM_OF_ITEM_OF_PAGE_ITEM,
    SELECTED_ITEM_OF_PAGE_ITEM,
    SELECTED_PAGE_ID,
    SELECTED_PAGE_ITEM,
    SET_TAB
} from "../Constants/PageEditorConstants";


export const setSelectedPageItem = (item) => (dispatch) => {
    dispatch({
        type: SELECTED_PAGE_ITEM, payload: item
    })
}
export const setSelectedItemOfPageItem = (item) => (dispatch) => {
    dispatch({
        type: SELECTED_ITEM_OF_PAGE_ITEM, payload: item
    })
}
export const setSelectedItemOfItemOfPageItem = (item) => (dispatch) => {
    dispatch({
        type: SELECTED_ITEM_OF_ITEM_OF_PAGE_ITEM, payload: item
    })
}

export const setAllPageItems = (pageItems) => {
    return {
        type: ALL_PAGE_ITEMS, payload: pageItems
    }
}
export const setSelectedTab = (tab) => {
    return {
        type: SET_TAB, payload: tab
    }
}
export const setSelectedPageId = (id) => {
    return {
        type: SELECTED_PAGE_ID, payload: id
    }
}