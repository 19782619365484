import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {ICON_MAP} from "../../../../../utils/IconsSelector/socialIcons";

const SocialLinks = ({title}: { title: string }) => {
    const {socialSettings} = useSelector((state) => state.socialSettingsData);
    return (<div>
            {title !== "" && title !== null &&
                <div className="mb-1 font-public-sans font-bold text-lg text-gray-800">{title}:</div>}
            <div className="flex gap-2 flex-wrap">
                {socialSettings.map(item => (<div>
                        <FontAwesomeIcon style={{color: item.color}}
                                         className="text-3xl hover:text-[36px] transition-all"
                                         icon={ICON_MAP[item.icon]}/></div>))}
            </div>
        </div>)
}
export default SocialLinks