import React from 'react';
import {getBaseUrl} from "../App";


function Image({src, alt, link, className, ...props}) {
    let baseSrc;
    try {
        baseSrc = getBaseUrl() + src.replace(/\.[^/.]+$/, "");
    } catch (e) {
        baseSrc = getBaseUrl();
    }

    const srcDesktop = `${baseSrc}_desktop.webp`;

    const content = (
        <img src={srcDesktop} alt={alt} loading="lazy"
             className={className} {...props} />
    );

    return link ? (
        <a target="_blank" href={link} rel="noopener noreferrer">
            {content}
        </a>
    ) : content;
}

export default Image;
