import axios from "axios";
import {fetchOrderList} from "../../redux/actions/OrderActions";
import {fetchProductList} from "../../redux/actions/ProductActions";
import {fetchCategoryList} from "../../redux/actions/CategoryActions";
import {fetchPageList} from "../../redux/actions/PagesActions";
import {fetchBannersData, fetchSliderBannersData} from "../../redux/actions/GeneralItemsActions";
import {fetchPaymentSettingsData} from "../../redux/actions/SettingsActions";
import React from "react";
import {useDispatch} from "react-redux";
import {fetchCouponsPage, fetchSliderPage, fetchUsersPage} from "../../redux/actions/PagesDataActions";
import {showAlert} from "../../utils/providers/AlertProvider";

const MultipleSelection = ({tableName, selectedFlatRows}) => {
    const dispatch = useDispatch()
    const deleteRows = async (ids) => {
        const deleteOperations = {
            'order': { url: '/admin/orders/delete/bulk', successMessage: 'Order Deleted Successfully', fetchFunction: fetchOrderList },
            'product': { url: '/admin/products/delete/bulk', successMessage: 'Products Deleted Successfully', fetchFunction: fetchProductList },
            'categories': { url: 'admin/categories/delete/bulk', successMessage: 'Categories Deleted Successfully', fetchFunction: fetchCategoryList },
            'pages': { url: '/admin/pages/delete/bulk', successMessage: 'Pages Deleted Successfully', fetchFunction: fetchPageList },
            'sliders': { url: '/admin/home/delete/slider/bulk', successMessage: 'Sliders Deleted Successfully', fetchFunction: fetchSliderPage },
            'slider': { url: '/admin/home/delete/slider-banner/bulk', successMessage: 'Slider Items Deleted Successfully', fetchFunction: fetchSliderPage },
            'banner': { url: '/admin/home/delete/banner/bulk', successMessage: 'Banners Deleted Successfully', fetchFunction: fetchBannersData },
            'coupon': { url: '/admin/home/delete/coupon/bulk', successMessage: 'Coupons Deleted Successfully', fetchFunction: fetchCouponsPage },
            'countries': { url: '/admin/settings/delete/country/bulk', successMessage: 'Countries Deleted Successfully', fetchFunction: fetchPaymentSettingsData },
            'states': { url: '/admin/settings/delete/state/bulk', successMessage: 'States Deleted Successfully', fetchFunction: fetchPaymentSettingsData },
            'cities': { url: '/admin/settings/delete/city/bulk', successMessage: 'Cities Deleted Successfully', fetchFunction: fetchPaymentSettingsData },
            'users': { url: '/admin/home/delete/user/bulk', successMessage: 'Users Deleted Successfully', fetchFunction: fetchUsersPage },
            'feedback': { url: '/admin/orders/delete/feedbacks/bulk', successMessage: 'Feedback Deleted Successfully', fetchFunction: fetchUsersPage }
        };

        const operation = Object.entries(deleteOperations).find(([key,]) => tableName.toLowerCase().includes(key));

        if (operation) {
            const [_, { url, successMessage, fetchFunction }] = operation;

            try {
                if(url.includes("pages")) {
                    await axios.post(url, { slugs: selectedFlatRows.map(row => row.original.slug.slice(1)) });
                } else {
                    await axios.post(url, { ids: ids });
                }
                showAlert("success", successMessage);
                await dispatch(fetchFunction());
            } catch (error) {
                showAlert("error", error.message);
            }
        }
    };

    return (
        <div className="flex justify-between items-center px-2 py-2 rounded bg-red-100 w-full my-3">
                        <span
                            className="text-orange-400">{selectedFlatRows.length} {selectedFlatRows.length === 1 ? "row" : "rows"} selected</span>
            <div onClick={() => deleteRows(selectedFlatRows.map(row => row.original.id))}
                 className="flex items-center text-sm border-[1px] px-3 py-2 cursor-pointer rounded text-white bg-red-500 font-light">
                <span className="material-symbols-rounded mr-0.5 font-thin">delete</span>
                <span>Delete</span>
            </div>
        </div>
    )
}
export default MultipleSelection