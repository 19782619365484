import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {fetchUsersPage} from "../../../redux/actions/PagesDataActions";

const UsersPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUsersPage())
    }, []);
    const {users, success} = useSelector((state) => state.usersList);
    return (
        (success && users.length !== 0) &&
        <div className="flex flex-col rounded-lg mb-3 mx-2 p-4 bg-white gap-3">
            <span className="text-lg font-public-sans font-bold">All Users</span>
            <hr/>
            <div className="rounded-lg bg-white mx-2 mb-4">
                <TableComponent TableData={users.filter(x => x.is_active)} tableName="Users Data"/>
            </div>

        </div>
    )
}
export default UsersPage