import React, {useState} from "react";
import useAlert from "../../../../../CustomHooks/useAlert";
import {
    ITEM_OF_ITEM_OF_PAGE_ITEM,
    ITEM_OF_PAGE_ITEM,
    PAGE_ITEM
} from "../../../../../redux/Constants/PageEditorConstants";
import {updateIndexOfArray} from "../../../../../utils/UtilityFunctions";
import {usePageEditorItems} from "../../../../../CustomHooks/usePageEditorItems";


const PlaceholderItem = ({before, vertical, itemIndex, itemOfItemIndex, type, pageIndex, last = false}) => {
    const [dragOverItem, setDragOverItem] = useState(null)
    const [showAlert, AlertComponent] = useAlert();
    const [pageItems, setPageItems] = usePageEditorItems()
    const pageItem = pageItems.find((pageItem) => pageItem.index === pageIndex)
    const handleOnDrop = (e) => {
        e.stopPropagation()
        let itemComponent = e.dataTransfer.getData('itemComponent')
        if (type === PAGE_ITEM) {
            setNewItemInPageItems(itemComponent)
        } else if (type === ITEM_OF_PAGE_ITEM) {
            setNewItemInItemsOfPageItems(itemComponent)
        } else if (type === ITEM_OF_ITEM_OF_PAGE_ITEM) {
            setNewItemInItemsOfItemsOfPageItems(itemComponent)
        }
        setDragOverItem(null);
    };

    const setNewItemInPageItems = (itemComponent) => {
        let newObject = {
            index: 0,
            lg_columns: 0,
            mb_columns: 0,
            is_slider: false,
            slider_speed: 500,
            slider_effect: 'simple',
            name: "",
            show_name: false,
            items: [{
                index: 0, items: [{
                    index: 0,
                    name: "",
                    type: itemComponent,
                    type_id: -1,
                    show_name: false,
                    is_slider: false,
                    slider_speed: 500,
                    slider_effect: 'simple',
                    max_rows: 1
                }]
            }]
        }
        setPageItems((prevState) => {
            const newArray = [...prevState]
            newArray.splice(pageIndex + (before ? 0 : 1), 0, newObject)
            return updateIndexOfArray(newArray)
        });
    }
    const setNewItemInItemsOfPageItems = (itemComponent) => {
        let newObject = {
            index: 0, items: [{
                index: 0,
                name: "",
                type: itemComponent,
                type_id: -1,
                show_name: false,
                is_slider: false,
                slider_speed: 500,
                slider_effect: 'simple',
                max_rows: 1
            }]
        }
        setPageItems((prevState) => {
            return prevState.map(pageItem => {
                if (pageItem.index === pageIndex) {
                    const newItems = [...pageItem.items]
                    newItems.splice(itemIndex + (before ? 0 : 1), 0, newObject)
                    return {
                        ...pageItem, items: updateIndexOfArray(newItems)
                    };
                }
                return pageItem;
            });
        });
    }
    const setNewItemInItemsOfItemsOfPageItems = (itemComponent) => {
        let newObject = {
            index: 0,
            name: "",
            type: itemComponent,
            type_id: -1,
            show_name: false,
            is_slider: false,
            slider_speed: 500,
            slider_effect: 'simple',
            max_rows: 1
        };
        setPageItems((prevState) => {
            return prevState.map(pageItem => {
                if (pageItem.index === pageIndex) {
                    return {
                        ...pageItem, items: pageItem.items.map(innerItem => {
                            if (innerItem.index === itemIndex) {
                                const newItems = [...innerItem.items];
                                newItems.splice(itemOfItemIndex + (before ? 0 : 1), 0, newObject);
                                return {
                                    ...innerItem, items: updateIndexOfArray(newItems)
                                };
                            }
                            return innerItem;
                        })
                    };
                }
                return pageItem;
            });
        });
    }

    const handleOnDragStart = (e, item) => {
        e.dataTransfer.setData('itemComponent', JSON.stringify(item))
    };
    const handleOnDragOver = (e) => {
        e.preventDefault()
    };
    const itemData = {
        itemIndex: itemIndex,
        itemOfItemIndex: itemOfItemIndex,
        pageIndex: pageIndex,
        type: type,
        before: before,
        vertical: vertical,
    }
    return (<>
        {AlertComponent}
        <div
             className={`${last ? "w-full min-w-5 min-h-[200px] h-full" : vertical ? "w-full h-5" : "h-full w-full"} p-3 text-center ${JSON.stringify(itemData) === JSON.stringify(dragOverItem) ? "bg-blue-300" : ""}`}
             onDragOver={handleOnDragOver}
             onDragStart={(e) => handleOnDragStart(e, itemData)}
             onDragEnter={() => setDragOverItem(itemData)}
             onDragLeave={() => setDragOverItem(null)}
             onDrop={(e) => handleOnDrop(e)}
        ></div>
    </>)
}
export default PlaceholderItem


