import {
    faFacebookSquare,
    faXTwitter,
    faInstagramSquare,
    faWhatsappSquare,
    faPinterestSquare,
    faThreads,
    faSnapchatSquare,
    faYoutubeSquare,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

export const ICON_MAP = {
    'facebook-square': faFacebookSquare,
    'square-x-twitter': faXTwitter,
    'instagram-square': faInstagramSquare,
    'square-threads': faThreads,
    'whatsapp-square': faWhatsappSquare,
    'pinterest-square': faPinterestSquare,
    'snapchat-square': faSnapchatSquare,
    'youtube-square': faYoutubeSquare,
    'tiktok': faTiktok
};
