import React, {useState} from "react";
import './SimpleLoader.css'
import Img from "../../../../../../utils/Image";

// Placeholder component with pulsing effect
const PulsePlaceholder = () => (
    <div className="flex justify-center items-center animate-pulse mb-8 w-full h-[20vw] bg-gray-400">
        <span className="loader"></span>
    </div>
);
const ImgWithLoading = ({src, alt, className}) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            {!loaded && <PulsePlaceholder/>}
            <Img
                className={`${className} ${loaded ? "opacity-100 w-full h-full" : "opacity-0 w-0 h-0"}`}
                src={src}
                alt={alt}
                onLoad={() => setLoaded(true)}// Same as above
                loading="lazy"
            />
        </>
    );
};

export default ImgWithLoading