export const SELECTED_PAGE_ITEM = "SELECTED_PAGE_ITEM"
export const SELECTED_ITEM_OF_PAGE_ITEM = "SELECTED_ITEM_OF_PAGE_ITEM"
export const SELECTED_ITEM_OF_ITEM_OF_PAGE_ITEM = "SELECTED_ITEM_OF_ITEM_OF_PAGE_ITEM"

export const SET_TAB = "SET_TAB"

export const ALL_PAGE_ITEMS = "ALL_PAGE_ITEMS"

export const PAGE_ITEM = "PAGE_ITEM"
export const ITEM_OF_PAGE_ITEM = "ITEM_OF_PAGE_ITEM"
export const ITEM_OF_ITEM_OF_PAGE_ITEM = "ITEM_OF_ITEM_OF_PAGE_ITEM"

export const SELECTED_PAGE_ID = "SELECTED_PAGE_ID"