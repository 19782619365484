import React, {useState} from "react";

const classifiedTypes = [{
    category: "Content Elements", items: [{name: "Rich Content Editor", value: "rich_content_editor", icon: "edit"}, {
        name: "Custom Code", value: "custom_code", icon: "code"
    }, {name: "Team Member", value: "team_member", icon: "group"}, {
        name: "Feature Card", value: "feature", icon: "star"
    }, {name: "Image Slider", value: "image_slider", icon: "slideshow"}, {
        name: "Banner", value: "banner", icon: "photo"
    },]
}, {
    category: "Contact Elements",
    items: [{name: "Contact Information", value: "contact_information", icon: "contacts"}, {
        name: "Contact Us Form", value: "contact_us_form", icon: "contact_mail"
    }, {name: "Social Links", value: "social_links", icon: "share"}]
}, {
    category: "Categories", items: [{
        name: "Category", value: "category", icon: "category"
    }, {
        name: "All Categories", value: "all_categories", icon: "apps"
    }, {
        name: "Selective Categories", value: "selective_categories", icon: "filter_list"
    }, {
        name: "Category Products", value: "category_products", icon: "shopping_basket"
    }]
}, {
    category: "Collections", items: [{
        name: "Collection", value: "collection", icon: "collections"
    }, {
        name: "All Collections", value: "all_collections", icon: "view_module"
    }, {
        name: "Selective Collections", value: "selective_collections", icon: "filter_list"
    }, {
        name: "Collection Products", value: "collection_products", icon: "shopping_basket"
    }]
}, {
    category: "Product Listings",
    items: [{name: "Single Product Card", value: "single_product_card", icon: "shopping_cart"}, {
        name: "Top Rated Products", value: "top_rated_products", icon: "star"
    }, {
        name: "Most Sold Products", value: "most_sold_products", icon: "trending_up"
    }, {
        name: "Free Delivery Products", value: "free_delivery_products", icon: "local_shipping"
    }, {name: "Discounted Products", value: "discounted_products", icon: "local_offer"}, {
        name: "Latest Products", value: "latest_products", icon: "new_releases"
    }]
}];


const ItemTypesTab = () => {
    const [selectedType, setSelectedType] = useState("")
    const [selectedItems, setSelectedItems] = useState([])
    const selectTypeCategory = (category) => {
        if (selectedType === category) {
            setSelectedType("")
            setSelectedItems([])
        } else {
            setSelectedType(category)
            setSelectedItems(classifiedTypes.find(x => x.category === category).items)
        }
    }
    const handleOnDragStart = (e, item) => {
        e.dataTransfer.setData('itemComponent', item.value)
    };

    return (classifiedTypes.map(type => (<>
        <div onClick={() => selectTypeCategory(type.category)}
             className={`flex justify-between w-full py-2 px-3 bg-gray-800 text-white cursor-pointer border-b-2 border-black ${selectedType === type.category ? "font-bold bg-gray-900" : ""} hover:font-bold  hover:bg-gray-900 transition-all`}>
            <span>{type.category}</span>
            <span className="material-symbols-rounded">arrow_drop_down</span>
        </div>
        {selectedType === type.category && <div className="grid grid-cols-2 gap-2 p-2">
            {selectedItems.map((item, index) => (<div draggable={true} onDragStart={(e) => handleOnDragStart(e, item)}
                                                      className="flex flex-col cursor-grab justify-center px-2 py-3 items-center bg-gray-700 border-[1px] rounded-lg border-black">
                                        <span
                                            className="material-symbols-rounded text-white text-4xl">{item.icon}</span>
                <div
                    className="text-center text-white text-sm">{item.name}</div>
            </div>))}
        </div>}
    </>)))
}
export default ItemTypesTab