import {
    SELECTED_PAGE_ITEM,
    SELECTED_ITEM_OF_ITEM_OF_PAGE_ITEM,
    SELECTED_ITEM_OF_PAGE_ITEM,
    ALL_PAGE_ITEMS, SET_TAB, SELECTED_PAGE_ID
} from "../Constants/PageEditorConstants";


export const selectedPageItem = (state = {}, action) => {
    switch (action.type) {
        case SELECTED_PAGE_ITEM:
            return action.payload;
        default:
            return state;
    }
}
export const selectedTab = (state = "Elements", action) => {
    switch (action.type) {
        case SET_TAB:
            return action.payload;
        default:
            return state;
    }
}
export const selectedPageId = (state = -1, action) => {
    switch (action.type) {
        case SELECTED_PAGE_ID:
            return action.payload;
        default:
            return state;
    }
}
export const selectedItemOfPageItem = (state = {}, action) => {
    switch (action.type) {
        case SELECTED_ITEM_OF_PAGE_ITEM:
            return action.payload;
        default:
            return state;
    }
}
export const selectedItemOfItemOfPageItem = (state = {}, action) => {
    switch (action.type) {
        case SELECTED_ITEM_OF_ITEM_OF_PAGE_ITEM:
            return action.payload;
        default:
            return state;
    }
}
export const allPageItems = (state = [], action) => {
    switch (action.type) {
        case ALL_PAGE_ITEMS:
            return action.payload;
        default:
            return state;
    }
}
