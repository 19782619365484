import {
    ADD_CATEGORY_ERROR,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    CATEGORY_LIST_ERROR,
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_UPDATE_ERROR,
    CATEGORY_UPDATE_REQUEST,
    CATEGORY_UPDATE_SUCCESS, COLLECTION_LIST_ERROR, COLLECTION_LIST_SUCCESS, COLLECTION_LIST_REQUEST
} from "../Constants/CategoryConstants";

export const categoryListReducer = (state = {categories: []}, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return {loading: true, categories: []}
        case CATEGORY_LIST_SUCCESS:
            return {loading: false, success: true, categories: action.payload}
        case CATEGORY_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const collectionListReducer = (state = {collections: []}, action) => {
    switch (action.type) {
        case COLLECTION_LIST_REQUEST:
            return {loading: true, collections: []}
        case COLLECTION_LIST_SUCCESS:
            return {loading: false, success: true, collections: action.payload}
        case COLLECTION_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const addCategoryReducer = (state = {category: {}}, action) => {
    switch (action.type) {
        case ADD_CATEGORY_REQUEST:
            return {loading: true}
        case ADD_CATEGORY_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case ADD_CATEGORY_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const updateCategoryReducer = (state = {category: {}}, action) => {
    switch (action.type) {
        case CATEGORY_UPDATE_REQUEST:
            return {loading: true}
        case CATEGORY_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case CATEGORY_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}