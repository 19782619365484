import {
    HIDE_PRODUCT_SEARCH,
    HIDE_MENU_SIDEBAR,
    HIDE_CATEGORIES_SIDEBAR,
    SHOW_PRODUCT_SEARCH,
    SHOW_MENU_SIDEBAR,
    SHOW_CATEGORIES_SIDEBAR, SET_SELECTED_HOME_PAGE
} from "../Constants/HomeConstants";

export const productSearch = (state = true, action) => {
    switch (action.type) {
        case SHOW_PRODUCT_SEARCH:
            return true;
        case HIDE_PRODUCT_SEARCH:
            return false;
        default:
            return state;
    }
}
export const menuSidebar = (state = false, action) => {
    switch (action.type) {
        case SHOW_MENU_SIDEBAR:
            return true;
        case HIDE_MENU_SIDEBAR:
            return false;
        default:
            return state;
    }
}
export const categoriesSidebar = (state = false, action) => {
    switch (action.type) {
        case SHOW_CATEGORIES_SIDEBAR:
            return true;
        case HIDE_CATEGORIES_SIDEBAR:
            return false;
        default:
            return state;
    }
}
export const selectedHomePage = (state = "Home", action) => {
    switch (action.type) {
        case SET_SELECTED_HOME_PAGE:
            return action.payload;
        default:
            return state;
    }
}