import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideSidebar, setMenuItemsId, setSelectedPage} from "../../redux/actions/AdminPanelActions";
import {Link, useLocation} from "react-router-dom";
import {getBaseUrl} from "../../App";

const PanelSidebarMenu = ({icons}) => {
    const menuItemsId = useSelector((state) => state.menuItemsId);
    const selectedPage = useSelector((state) => state.selectedPage);
    const sidebar = useSelector((state) => state.sidebar);
    const generalSettingsData = useSelector((state) => state.generalSettingsData);
    const {generalSettings} = generalSettingsData;
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        icons.map(icon => {
            icon.pageList.map(menuItem => {
                if (location.pathname.includes(menuItem.toString().toLowerCase().replaceAll(" ","-"))) {
                    dispatch(setSelectedPage(menuItem))
                    dispatch(setMenuItemsId(icon.id))
                }
            })
        })
    }, []);
    const handleMenuItemClick = (menuItem) =>  {
        dispatch(hideSidebar())
        dispatch(setSelectedPage(menuItem))
    }

    return (
        <React.Fragment>
            <div className="text-md flex flex-col z-20 h-screen w-[78%] bg-white border-gray-200 border-r-[1px]">
                <div
                    className="flex overflow-hidden w-full py-2 border-b-[1px] border-gray-200 justify-center items-center">
                    <img className="text-center max-h-12 max-w-none" src={getBaseUrl() + generalSettings.app_logo} alt=""/>
                </div>

                <div
                    className="flex flex-col p-5 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 ">
                    {icons.filter(icon => icon.id === menuItemsId).map(selectedIcon => (
                        <React.Fragment>
                            <div
                                className="uppercase font-medium text-blue-500 pb-3">{selectedIcon.label}</div>
                            {selectedIcon.pageList.map(menuItem => (
                                <Link
                                    to={`/${selectedIcon.label.toString().toLowerCase()}/${menuItem.toString().toLowerCase().replaceAll(" ", "-")}`}><div onClick={() =>  handleMenuItemClick(menuItem)}
                                     className={`text-gray-800 pb-3 mt-1 mb-1 pt-3 cursor-pointer 
                                                hover:text-blue-500 leading-[100%] transition-all ${menuItem === selectedPage ? "border-r-4 border-blue-700" : ""}`}>
                                    {menuItem}
                                </div>
                                </Link>
                            ))}

                        </React.Fragment>
                    ))}

                </div>
            </div>
        </React.Fragment>
    )
}
export default PanelSidebarMenu