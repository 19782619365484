import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {useLocation} from "react-router-dom";
import {fetchOrderList} from "../../../redux/actions/OrderActions";
import Loading from "../../../Components/loading";
import useAlert from "../../../CustomHooks/useAlert";
import {capitalizeFirstLetter, getDate} from "../../../utils/UtilityFunctions";

const OrdersPage = ({type}) => {
    const ordersData = useSelector((state) => state.ordersList);
    const orders = ordersData.ordersList.reverse()
    const success = ordersData.success
    const loading = ordersData.loading
    const [showAlert, AlertComponent] = useAlert();
    let ordersList = [];
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(fetchOrderList());
        ordersList = [];
    }, [location]);

    success && orders.map((x, i) => {
        const obj = {
            id: x.id,
            tracking_code: x.tracking_code,
            total_products: x.total_products,
            total_price: x.total_price.toFixed(2),
            status: x.status,
            currency: x.currency,
            visibility: x.visibility,
            updated_at: getDate(x.updated_at),
        }
        if (type === "deleted") {
            if (obj.visibility === 0) {
                ordersList.push(obj);
            }
        } else if (type === "pending") {
            if (obj.status !== "Delivered" && obj.status !== "Cancelled" && obj.visibility === 1) {
                ordersList.push(obj);
            }
        } else if (type === "delivered") {
            if (obj.status === "Delivered" && obj.visibility === 1) {
                ordersList.push(obj);
            }
        } else if (type === "cancelled") {
            if (obj.status === "Cancelled" && obj.visibility === 1) {
                ordersList.push(obj);
            }
        } else if (type === "all" || type === "invoices") {
            if (obj.visibility === 1) {
                ordersList.push(obj);
            }
        }
    });

    return (
        <React.Fragment>
            {AlertComponent}
            {(success && Array.isArray(orders) && orders.length > 0) && <TableComponent TableData={ordersList} showAlert={showAlert}
                                                                                        tableName={type === "invoices" ? "Invoices" : capitalizeFirstLetter(type) + " Orders"}/>}
            {loading && <Loading/>}
        </React.Fragment>
    )
}


export default OrdersPage;
