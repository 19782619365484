import React from "react";
import ImgWithLoading from "./ImgWithLoading";
import SwiperSlider from "../../../../../../utils/Slider";


const ImageSlider = ({slider}) => {
    return (
        <div className="relative overflow-hidden">
            <span className="absolute inset-0 bg-black bg-opacity-0 z-50"></span>
            <SwiperSlider effect={slider.effect} speed={slider.speed} loop={slider.loop}>
                {slider.banners.map((banner, index) => (
                    <React.Fragment key={index}>
                        {banner.link !== "" ? (
                            <a target="_blank" href={banner.link}>
                                <ImgWithLoading key={banner.id} className="mb-8 w-full" src={banner.image} alt=""/>
                            </a>
                        ) : (
                            <ImgWithLoading key={banner.id} className="mb-8 w-full" src={banner.image} alt=""/>
                        )}
                    </React.Fragment>
                ))}
            </SwiperSlider>
        </div>
    );
};

export default ImageSlider;
