import React, {useEffect, useState} from "react";
import Select from "react-select";
import CurrencyList from "currency-list";
import {useDispatch, useSelector} from "react-redux";
import {fetchPaymentSettingsData} from "../../../redux/actions/SettingsActions";
import useAlert from "../../../CustomHooks/useAlert";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import flags from '../../../utils/flags.json'

const CurrencySettingsPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPaymentSettingsData())
    }, []);
    const [showAlert, AlertComponent] = useAlert();
    const [formData, setFormData] = useState({
        name: "",
        symbol: "",
        code: "",
        enabled: false,
        exchange_rate: 1,
        region_price_shift: 1
    })
    const {id} = useParams()
    const {paymentSettings, success} = useSelector((state) => state.paymentSettingsData);
    const {currencies} = paymentSettings
    const {apiSettings} = useSelector((state) => state.apiSettingsData);
    const [suggestions, setSuggestions] = useState('')
    const [defaultCurrency, setDefault] = useState({})
    const [baseCurrency, setBase] = useState({})
    const [apiKey, setApiKey] = useState('')
    useEffect(() => {
        if (success) {
            const baseCurrency = currencies.find(x => x.is_base)
            const defaultCurrency = currencies.find(x => x.is_default)
            if (defaultCurrency) setDefault({
                value: defaultCurrency.id,
                code: defaultCurrency.code,
                label: `${defaultCurrency.name} (${defaultCurrency.code}, ${defaultCurrency.symbol})`
            })
            if (baseCurrency) setBase({
                value: baseCurrency.id,
                code: baseCurrency.code,
                label: `${baseCurrency.name} (${baseCurrency.code}, ${baseCurrency.symbol})`
            })
            const api = apiSettings.find(x => x.name === "currency")
            if (api) setApiKey(api.secret_key)
        }
    }, [success]);

    const handleChange = (item) => {
        const currency = item.value
        if (currency.symbol === currency.symbol_native) {
            setFormData(prevState => ({
                ...prevState,
                name: currency.name,
                symbol: currency.symbol,
                code: currency.code
            }))
            setSuggestions('')
        } else {
            setFormData(prevState => ({
                ...prevState,
                name: currency.name,
                symbol: "",
                code: currency.code
            }))
            setSuggestions(`Suggestions: ${currency.symbol}, ${currency.symbol_native}`)
        }
    }
    useEffect(() => {
        if (id && currencies) {
            const currency = currencies.find(x => x.id === Number(id))
            setFormData(currency)
        }
    }, [id, currencies]);
    const submitCurrency = async (e) => {
        e.preventDefault()
        const flag = flags.find(x => x.code === formData.code).flag
        try {
            if (id) {
                const response = await axios.post('/admin/settings/update/currency/' + id, {...formData, flag: flag});
                showAlert("success", formData.name + " Currency Updated")
            } else {
                const response = await axios.post('/admin/settings/update/currency', {...formData, flag: flag});
                showAlert("success", "Currency Added")
            }
            dispatch(fetchPaymentSettingsData())
        } catch (error) {
            showAlert("error", error.response.data.detail || error.message)
        }
    }
    const setDefaultCurrency = async () => {
        try {
            const response = await axios.post('/admin/settings/update/default/currency/' + defaultCurrency.value);
            showAlert("success", defaultCurrency.label + " is made default currency")
            dispatch(fetchPaymentSettingsData())
        } catch (error) {
            showAlert("error", error.message)
        }
    }

    const setBaseCurrency = async () => {
        try {
            const response = await axios.post('/admin/settings/update/base/currency/' + baseCurrency.value);
            showAlert("success", baseCurrency.label + " is made base currency")
            dispatch(fetchPaymentSettingsData())
        } catch (error) {
            showAlert("error", error.message)
        }
    }

    const updateRates = async (e) => {
        e.preventDefault()
        fetch(`https://v6.exchangerate-api.com/v6/${apiKey}/latest/${baseCurrency.code}`)
            .then(async response => {
                const data = await response.json();
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    showAlert('error', "Invalid Api Key")
                    return Promise.reject(error);
                } else {
                    try {
                        const response = await axios.post('/admin/settings/update/currency-rates', data);
                        showAlert("success", "Currency Rates Updates")
                        dispatch(fetchPaymentSettingsData())
                    } catch (error) {
                        showAlert("error", error.message)
                    }
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                {!id && <>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Default Display Currency</span>
                        <Select className="mt-1 w-full border border-gray-500"
                                isSearchable={true}
                                value={defaultCurrency}
                                onChange={(item) => setDefault(item)}
                                options={currencies.map(x => ({
                                    value: x.id, label: `${x.name} (${x.code}, ${x.symbol})`, code: x.code
                                }))}/>
                        <div className="flex justify-center mx-2">
                            <button onClick={setDefaultCurrency}
                                    className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md">Set
                                Default Currency
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">Base Currency (Actual Price Currency)</span>
                        <Select className="mt-1 w-full border border-gray-500"
                                isSearchable={true}
                                value={baseCurrency}
                                onChange={(item) => setBase(item)}
                                options={currencies.map(x => ({
                                    value: x.id, label: `${x.name} (${x.code}, ${x.symbol})`, code: x.code
                                }))}/>
                        <div className="flex justify-center mx-2">
                            <button onClick={setBaseCurrency}
                                    className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md">Set
                                Base Currency
                            </button>
                        </div>
                    </div>
                    <form onSubmit={updateRates} className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">Update Currency Rates</span>
                        <div className="w-full">
                            <Link
                                className="cursor-pointer rounded p-2 text-white bg-black hover:bg-green-600 transition-all"
                                to={'/api-settings'}>Change Currency API Key</Link>
                            <div
                                className="py-2 mt-0.5 w-full">{apiKey !== "" ? "API Key:" : "You must get an API Key for updating currency exchange rates"}{apiKey}</div>
                        </div>
                        <div className="flex justify-center mx-2">
                            <button
                                className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                                type="submit">Update Rates
                            </button>
                        </div>
                    </form>

                </>}

                <form onSubmit={submitCurrency} className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">{id ? "Update Currency" : "Add Currency"}</span>
                    <div className="w-full">
                        <span className="">Currency</span>
                        <Select className="mt-1 w-full border border-gray-500"
                                isSearchable={true}
                                onChange={(item) => handleChange(item)}
                                options={[...Object.values(CurrencyList.getAll('en_US')).map(x => ({
                                    value: x, label: x.name
                                }))]}/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="name">Name</label>
                        <input type="text" value={formData.name} required
                               onChange={(e) => setFormData({...formData, name: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="code">Code</label>
                        <input type="text" value={formData.code} required
                               onChange={(e) => setFormData({...formData, code: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="symbol">Symbol</label>
                        <input type="text" value={formData.symbol} required
                               onChange={(e) => setFormData({...formData, symbol: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    {suggestions !== "" && <div>Symbol {suggestions}</div>}
                    <div className="w-full">
                        <label htmlFor="rate">Exchange Rate with respect to Base Currency (1 if it is Base
                            Currency)</label>
                        <input type="number" value={formData.exchange_rate} required
                               onChange={(e) => setFormData({...formData, exchange_rate: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="rate">Change in Price By region (1 if it is Base Currency or No Change)</label>
                        <input type="number" value={formData.region_price_shift} required
                               onChange={(e) => setFormData({...formData, region_price_shift: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <div className="checkbox-wrapper-14 gap-3 flex justify-between items-center">
                            <span>{formData.enabled ? "Visible" : "Not Visible"} on store</span>
                            <input type="checkbox" className="switch"
                                   onChange={(e) => setFormData({
                                       ...formData,
                                       enabled: e.target.checked
                                   })}
                                   checked={formData.enabled === true}/>
                        </div>
                    </div>
                    <div className="flex justify-center p-4 mx-2">
                        <button
                            className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{id ? "Update Currency" : "Add Currency"}
                        </button>
                    </div>
                </form>
                {!id && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={currencies} tableName="Currencies" showAlert={showAlert}/>
                </div>}

            </div>
        </React.Fragment>
    )
}
export default CurrencySettingsPage