import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {fetchProductList} from "../../../redux/actions/ProductActions";
import {useLocation} from "react-router-dom";
import Loading from '../../../Components/loading'
import useAlert from "../../../CustomHooks/useAlert";
const ProductsPage = ({type}) => {
    const {products, success, loading} = useSelector((state) => state.productList);
    let productsList = []
    const dispatch = useDispatch()
    const [showAlert, AlertComponent] = useAlert();
    useEffect(() => {
        productsList = []
        dispatch(fetchProductList())
    }, []);
    success && products.map((x, i) => {
            const obj = {
                id: x.id,
                image: x.image,
                name: x.name,
                description: x.description,
                price: x.price,
                stock: x.stock,
                ...x,
                updated_at: getDate(x.updated_at),
                created_at: getDate(x.created_at)
            }
            if (type === "deleted") {
                if (obj.visibility === 0) {
                    productsList.push(obj)
                }
            } else if (type === "drafted") {
                if (obj.visibility === 2) {
                    productsList.push(obj)
                }
            } else if (type === "all") {
                if (obj.visibility === 1) {
                    productsList.push(obj)
                }
            }

        }
    )

    return (
        <React.Fragment>
            {AlertComponent}
            {(success && Array.isArray(products)) && <TableComponent TableData={productsList} showAlert={showAlert}
                                                                     tableName={capitalizeFirstLetter(type) + " Products"}/>}
            {loading && <Loading/>}
        </React.Fragment>
    )
}
const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
}
const capitalizeFirstLetter = (str) => {
    return str.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
};
export default ProductsPage