import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchPageDataById, fetchPageList} from "../../../../../redux/actions/PagesActions";
import ProductSearch from "../../../../../Components/ProductSearch";
import {setSelectedPageId} from "../../../../../redux/actions/PageEditorActions";

const EditorTopBar = () => {
    useEffect(() => {
        dispatch(fetchPageList())
    }, []);

    const [formData, setFormData] = useState({page_type: "default", page_id: ""})
    const dispatch = useDispatch()
    const {pages, success, loading} = useSelector((state) => state.pageList);
    const {categories} = useSelector((state) => state.categoryList);
    const {collections} = useSelector((state) => state.collectionList);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        setFormData((prevState) => ({...prevState, page_id: ""}))
    }, [formData.page_type]);

    const filteredPages = () => {
        if (formData.page_type === "product") {
            return false;
        } else if (formData.page_type === "category") {
            return categories;
        } else if (formData.page_type === "collection") {
            return collections;
        } else {
            return pages.filter(page => {
                if (formData.page_type === "default") {
                    return page.page_type === "default";
                } else {
                    return page.page_type === "custom";
                }
            });
        }
    }
    const savePageEditor = (e) => {
        e.preventDefault()

    }
    const handleSelectPage = (e) => {
        const page_id = e.target.value
        setFormData({...formData, page_id: page_id})
        dispatch(setSelectedPageId(page_id))
        dispatch(fetchPageDataById(page_id))
    }

    return (success && <form onSubmit={savePageEditor}
                             className="bg-white flex gap-5 justify-between items-center rounded-lg border border-gray-800 px-4 py-2 m-2">
        <div className="w-full text-lg font-bold">Page Editor</div>
        <div className="w-full">
            <select className="py-2 px-3 rounded-md w-full border border-gray-500"
                    id="page_type" value={formData.page_type}
                    onChange={(e) => setFormData({...formData, page_type: e.target.value})}>
                <option value="default">Default Pages</option>
                <option value="custom">Custom Pages</option>
                <option value="product">Product Pages</option>
                <option value="category">Category Pages</option>
                <option value="collection">Collection Pages</option>
            </select>
        </div>
        {formData.page_type === "product" ? <ProductSearch setProduct={setProduct}/> : <div className="w-full mx-5">
            <select className="py-2 px-3 rounded-md w-full border border-gray-500"
                    id="page" value={formData.page_id}
                    onChange={handleSelectPage}>
                <option value="" disabled selected>Select Page</option>
                {filteredPages().map((page, i) => (
                    <option key={page.id} value={page.id}>{page.title || page.name}</option>))}
            </select>
        </div>}
        <input
            className="w-52 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
            type="submit" value="Save & Publish"/>
    </form>)
}
export default EditorTopBar