import React from "react";
import {useItemTypes} from "../../../../../../../CustomHooks/useItemTypes";
import ProductSearch from "../../../../../../../Components/ProductSearch";
import {getBaseUrl} from "../../../../../../../App";
import {useDispatch, useSelector} from "react-redux";
import {PRODUCT_DETAILS_SUCCESS} from "../../../../../../../redux/Constants/ProductConstants";

export const TypeSelect = ({formData, setFormData}) => {

    const {typesData} = useItemTypes()
    const {productDetails} = useSelector((state) => state.productDetails);

    const selectedItemType = typesData.find(type => type.value === formData.type);
    const {array, name} = selectedItemType?.array ? selectedItemType : {array: [], name: ""}
    const dispatch = useDispatch()


    const handleProductClicked = (product) => {
        setFormData({...formData, type_id: product.id, data: product})
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS, payload: product
        })
    }
    const handleItemClicked = (e) => {
        const itemId = e.target.value
        const item = array.find(x => x.id === Number(itemId))
        setFormData((prevState) => ({...prevState, type_id: itemId, data: item}))
        setFormData({...formData, type_id:  itemId, data: item})
    }
    return (formData.type === "product" || formData.type === "single_product_card" ? <><ProductSearch
        setProduct={handleProductClicked}/>
        {formData.type_id === productDetails?.id ? <div
            className="flex border-b-[1px] hover:bg-gray-100 transition-all duration-500 items-center w-[99%] overflow-hidden">
            Selected Product:
            <div className="flex justify-between items-center px-2 w-full">
                <div className="flex justify-center items-center w-1/3">
                    <img className="w-full object-contain h-24"
                         src={getBaseUrl() + productDetails.image} alt={productDetails.name}/>
                </div>
                <div
                    className="flex flex-col ml-2 text-gray-700 py-2 px-2 font-poppins w-2/3">
                    <span
                        className="font-semibold h-6 w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{productDetails.name}</span>
                    <span
                        className="font-semibold text-teal-500">{productDetails.currency + productDetails.price}</span>
                </div>
            </div>
        </div> : "No Product Selected!"}
    </> : <div className="w-full">
        <label htmlFor={name.toLowerCase()}>Choose {name}</label>
        <select
            className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
            id={name.toLowerCase()}
            value={formData.type_id}
            onChange={handleItemClicked}
        >
            <option value={-1} disabled>Select {name}</option>
            {array.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
        </select>
    </div>);
}