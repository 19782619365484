import React from "react";

export const Card = ({children, className}) => {
    return (<div className={`flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3 ${className}`}>
            {children}
        </div>)
}

export const CardTitle = ({children, className}) => {
    return (<>
            <div className={`text-lg font-public-sans font-bold ${className}`}>{children}</div>
            <hr/>
        </>)
}
