import {
    HIDE_SEARCH,
    HIDE_SIDEBAR,
    SET_CURRENCY,
    SET_MENU_ITEMS_ID,
    SET_SELECTED_PAGE,
    SHOW_SEARCH,
    SHOW_SIDEBAR
} from "../Constants/AdminPanelConstants";


export const menuItemsId = (state = 0, action) => {
    switch (action.type) {
        case SET_MENU_ITEMS_ID:
            return action.payload;
        default:
            return state;
    }
}
export const currencyReducer = (state = "", action) => {
    switch (action.type) {
        case SET_CURRENCY:
            return action.payload;
        default:
            return state;
    }
}
export const selectedPage = (state = "All Orders", action) => {
    switch (action.type) {
        case SET_SELECTED_PAGE:
            return action.payload;
        default:
            return state;
    }
}
export const search = (state = false, action) => {
    switch (action.type) {
        case SHOW_SEARCH:
            return true;
        case HIDE_SEARCH:
            return false;
        default:
            return state;
    }
}
export const sidebar = (state = false, action) => {
    switch (action.type) {
        case SHOW_SIDEBAR:
            return true;
        case HIDE_SIDEBAR:
            return false;
        default:
            return state;
    }
}
