import {useSelector} from "react-redux";

export const useItemTypes = () => {
    const {banners} = useSelector((state) => state.bannersData);
    const {categories} = useSelector((state) => state.categoryList);
    const {sliders} = useSelector((state) => state.slidersData);
    const {collections} = useSelector((state) => state.collectionList);
    const {features} = useSelector((state) => state.featureItemsReducer);
    const {teamMembers} = useSelector((state) => state.teamMemberItemsReducer);
    const {contentEditors} = useSelector((state) => state.contentEditorsReducer);
    const {customCodes} = useSelector((state) => state.customCodesReducer);
    const typesData = [{name: "Category", value: "category", array: categories}, {
        name: "Selective Categories", value: "selective_categories", array: categories
    }, {name: "Category Products", value: "category_products", array: categories}, {
        name: "Collection", value: "collection", array: collections
    }, {
        name: "Collection Products", value: "collection_products", array: collections
    }, {
        name: "Selective Collections", value: "selective_collections", array: collections
    },{name: "Single Product Card", value: "single_product_card", array: []},
        {name: "Image Slider", value: "image_slider", array: sliders}, {
        name: "Banner", value: "banner", array: banners
    }, {
        name: "Feature", value: "feature", array: features.map((x) => ({
            name: x.title_text, ...x
        }))
    }, {
        name: "Team Member", value: "team_member", array: teamMembers.map((x) => ({
            name: x.name_text, ...x
        }))
    }, {name: "Rich Content Editor", value: "rich_content_editor", array: contentEditors}, {
        name: "Custom Code", value: "custom_code", array: customCodes
    }];
    const types = [{name: "Product", value: "product"}, {name: "Category", value: "category"}, {
        name: "Collection", value: "collection"
    }, {name: "Image Slider", value: "image_slider"}, {name: "Banner", value: "banner"}, {
        name: "Feature", value: "feature"
    }, {name: "Team Member", value: "team_member"}, // {name: "Custom Designs", value: "custom_designs"},
        {name: "Rich Content Editor", value: "rich_content_editor"}, {
            name: "Custom Code", value: "custom_code"
        }, {name: "Contact Information", value: "contact_information"}, {
            name: "Contact Us Form", value: "contact_us_form"
        }, {name: "Social Links", value: "social_links"},]
    return {types, typesData};
}