import React from "react";

const RichContentEditor = ({css, html}) => {
    return (
        <div>
            <style dangerouslySetInnerHTML={{__html: css}}/>
            <div className="w-full h-full" dangerouslySetInnerHTML={{__html: html}}></div>
        </div>
    )
}
export default RichContentEditor
