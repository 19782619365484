import React, {useEffect, useState} from "react";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";

const EmailSettingsPage = () => {
    const [showAlert, AlertComponent] = useAlert();
    const [formData, setFormData] = useState({
        email_host: "",
        email_port: "",
        email_user:"",
        email_password:"",
        notifying_email:""
    })
    useEffect(() => {
        const fetchEmailData = async () => {
            try {
                const {data} = await axios.get('/admin/settings/email');
                setFormData({
                    email_host: data.host,
                    email_port: data.port,
                    email_user: data.user,
                    email_password: data.password,
                    notifying_email: data.notifying_email
                })
            } catch (error) {
                showAlert("error", error.message)
            }
        }
        fetchEmailData()
    }, []);
    const updateEmailInfo = async () => {
        try {
            const {data} = await axios.post('/admin/settings/update/email', formData);
            showAlert("success", "Email Settings Updated Successfully")
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <div className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">Order Details will be sent from this email</span>
                    <div className="w-full">
                        <label htmlFor="email_host">Email Host</label>
                        <input type="text" id="email_host" required value={formData.email_host}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   email_host: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="email_port">Port</label>
                        <input type="number" id="email_port" required value={formData.email_port}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   email_port: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="user">Email User</label>
                        <input type="text" id="phone" required value={formData.email_user}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   email_user: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="password">Password</label>
                        <input type="text" id="password" required value={formData.email_password}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   email_password: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <span className="text-lg font-public-sans font-bold">Notification Email of Admin</span>
                    <div className="w-full">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" required value={formData.notifying_email}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   notifying_email: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex justify-center py-2 mx-2">
                        <button
                            className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            onClick={updateEmailInfo}>{"Update Email Info"}</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default EmailSettingsPage