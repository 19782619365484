import React from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";
import PlaceholderItem from "./PlaceholderItem";
import {ITEM_OF_PAGE_ITEM, PAGE_ITEM,} from "../../../../../redux/Constants/PageEditorConstants";
import ItemOfPageItem from "./ItemOfPageItem";
import {useDispatch, useSelector} from "react-redux";
import {splitArray, updateIndexOfArray} from "../../../../../utils/UtilityFunctions";
import {usePageEditorItems} from "../../../../../CustomHooks/usePageEditorItems";
import {
    setSelectedItemOfItemOfPageItem,
    setSelectedItemOfPageItem,
    setSelectedPageItem,
    setSelectedTab
} from "../../../../../redux/actions/PageEditorActions";

const PageItem = ({item, pageItemIndex}) => {
    const [pageItems, setPageItems] = usePageEditorItems()
    const selectedPageItem = useSelector((state) => state.selectedPageItem);
    const dispatch = useDispatch()

    const unselectAll = (e) => {
        dispatch(setSelectedPageItem({}))
        dispatch(setSelectedItemOfPageItem({}))
        dispatch(setSelectedItemOfItemOfPageItem({}))
    }
    const handleClick = (e) => {
        e.stopPropagation()
        dispatch(setSelectedPageItem(item))
        dispatch(setSelectedItemOfPageItem({}))
        dispatch(setSelectedItemOfItemOfPageItem({}))
        dispatch(setSelectedTab('Settings'))
    }
    const gridColumns = item.lg_columns === 0 ? item.items.length > 0 ? item.items.length > 5 ? 5 : item.items.length : 1 : item.lg_columns
    const splitItems = splitArray(item.items, gridColumns)
    return (
        <Draggable draggableId={"pageItemIndex " + pageItemIndex} index={pageItemIndex}>
            {(provided) => (<div {...provided.dragHandleProps} {...provided.draggableProps}
                                 ref={provided.innerRef} key={pageItemIndex}>
                <div className="w-full" onClick={unselectAll}>
                    <PlaceholderItem pageIndex={pageItemIndex} type={PAGE_ITEM} vertical={true}
                                     before={true}/>
                </div>
                <Droppable droppableId={"pageItemIndex " + pageItemIndex} type={ITEM_OF_PAGE_ITEM}
                           direction="horizontal">
                    {(provided) => (<div {...provided.droppableProps}
                                         ref={provided.innerRef} className="relative w-full">
                        <div
                            draggable={true}
                            onClick={handleClick}
                            className={`grid cursor-grab gap-2 p-5 bg-white rounded-lg shadow-md border ${selectedPageItem === item ? "border-blue-500" : "border-gray-300"}`}
                            style={{gridTemplateColumns: `repeat(${gridColumns}, minmax(0, 1fr))`}}
                        >
                            {item.items.length === 0 &&
                                <PlaceholderItem itemIndex={0} type={ITEM_OF_PAGE_ITEM}
                                                 vertical={true}
                                                 before={true}
                                                 pageIndex={pageItemIndex}/>}
                            {item.items.map((itemOfPageItem, index) => (
                                <ItemOfPageItem key={index} item={itemOfPageItem} itemOfPageItemIndex={index}
                                                pageItemIndex={pageItemIndex}/>
                            ))}
                            {selectedPageItem === item && (<span
                                onClick={() => deletePageItem(pageItemIndex, setPageItems, false)}
                                className="material-symbols-rounded absolute text-sm top-0 right-0 text-white bg-red-500 rounded-lg px-1 py-0.5 cursor-pointer">delete</span>)}
                        </div>
                        {provided.placeholder}
                    </div>)}
                </Droppable>
                <div className="w-full" onClick={unselectAll}>
                    <PlaceholderItem pageIndex={pageItemIndex} type={PAGE_ITEM} vertical={true}
                                     before={false} last={pageItems.length - 1 === pageItemIndex}/>
                </div>
            </div>)}
        </Draggable>
    )
}
export default PageItem

const deletePageItem = (pageIndex, setPageItems) => {
    setPageItems((prevState) => {
        return updateIndexOfArray(prevState.filter(x => x.index !== pageIndex))
    });
}