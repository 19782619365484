import {
    ORDER_LIST_ERROR,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_REQUEST,
    REVIEWS_LIST_SUCCESS,
} from "../Constants/OrderConstants";
import axios from "axios";
import {PRODUCT_DETAILS_SUCCESS, PRODUCT_LIST_SUCCESS} from "../Constants/ProductConstants";
import {USER_LIST_ERROR, USER_LIST_REQUEST, USER_LIST_SUCCESS} from "../Constants/UserConstants";
import {
    BANNERS_DATA_SUCCESS,
    CONTENT_EDITORS_SUCCESS,
    CONTENT_ITEMS_ERROR,
    CONTENT_ITEMS_REQUEST,
    COUPONS_DATA_ERROR,
    COUPONS_DATA_REQUEST,
    COUPONS_DATA_SUCCESS,
    CUSTOM_CODES_SUCCESS,
    FEATURES_ITEMS_ERROR,
    FEATURES_ITEMS_SUCCESS,
    FOOTER_LINKS_ERROR,
    FOOTER_LINKS_REQUEST,
    FOOTER_LINKS_SUCCESS,
    HOME_ITEMS_ERROR,
    HOME_ITEMS_REQUEST,
    HOME_ITEMS_SUCCESS,
    ITEM_GROUPS_OF_ITEM_ORGANIZER_ERROR,
    ITEM_GROUPS_OF_ITEM_ORGANIZER_REQUEST,
    ITEM_GROUPS_OF_ITEM_ORGANIZER_SUCCESS,
    ITEM_GROUPS_SUCCESS,
    ITEMS_OF_GROUP_ERROR,
    ITEMS_OF_GROUP_REQUEST,
    ITEMS_OF_GROUP_SUCCESS,
    ITEMS_ORGANIZER_ERROR,
    ITEMS_ORGANIZER_REQUEST,
    ITEMS_ORGANIZER_SUCCESS,
    MENU_ITEMS_SUCCESS,
    PAGE_ITEMS_ERROR,
    PAGE_ITEMS_REQUEST,
    PAGE_ITEMS_SUCCESS,
    SLIDER_BANNERS_DATA_SUCCESS,
    SLIDER_DATA_ERROR,
    SLIDER_DATA_REQUEST,
    SLIDER_DATA_SUCCESS,
    TEAM_MEMBER_ITEMS_SUCCESS,
    TOP_BAR_DATA_SUCCESS,
    TOP_BARS_DATA_ERROR,
    TOP_BARS_DATA_REQUEST,
    TOP_BARS_DATA_SUCCESS
} from "../Constants/GeneralItemsConstants";
import {CATEGORY_LIST_SUCCESS, COLLECTION_LIST_SUCCESS} from "../Constants/CategoryConstants";
import {WISHLIST_ITEMS_LIST} from "../Constants/WishlistConstants";
import {PAGE_LIST_ERROR, PAGE_LIST_REQUEST, PAGE_LIST_SUCCESS} from "../Constants/PagesConstants";
import {CONTACT_SETTINGS_DATA_SUCCESS, SOCIAL_SETTINGS_DATA_SUCCESS} from "../Constants/SettingsConstants";

export const fetchDashboardPage = () => async (dispatch) => {
    try {
        dispatch({type: ORDER_LIST_REQUEST})

        const {data} = await axios.get("/admin/home/dashboard")
        const {orders, users, reviews} = data
        const products = data.products
        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: orders
        })
        dispatch({
            type: WISHLIST_ITEMS_LIST,
            payload: products
        })
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: users
        })
        dispatch({
            type: REVIEWS_LIST_SUCCESS,
            payload: reviews
        })
    } catch (e) {
        dispatch({
            type: ORDER_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const fetchNavigationPage = () => async (dispatch) => {
    try {
        const {data} = await axios.get("admin/home/navigation")
        const {menu_items, categories, collections} = data
        dispatch({
            type: MENU_ITEMS_SUCCESS,
            payload: menu_items
        })
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: categories
        })
        dispatch({
            type: COLLECTION_LIST_SUCCESS,
            payload: collections
        })
    } catch (e) {

    }
}
export const fetchFeatures = () => async (dispatch) => {
    try {
        const {data} = await axios.get("/general/content/features")
        dispatch({
            type: FEATURES_ITEMS_SUCCESS,
            payload: data
        })
    } catch (e) {

    }
}

export const fetchContentEditors = () => async (dispatch) => {
    try {
        const {data} = await axios.get("/general/content/content-editors")
        dispatch({
            type: CONTENT_EDITORS_SUCCESS,
            payload: data
        })
    } catch (e) {

    }
}
export const fetchItemGroups = () => async (dispatch) => {
    try {
        const {data} = await axios.get("/general/content/item-groups")
        dispatch({
            type: ITEM_GROUPS_SUCCESS,
            payload: data
        })
    } catch (e) {

    }
}

export const fetchItemGroupsOfItemOrganizer = (id) => async (dispatch) => {

    dispatch({type: ITEM_GROUPS_OF_ITEM_ORGANIZER_REQUEST})
    try {
        const {data} = await axios.get("/general/content/items-of-organizer/"+id)
        dispatch({
            type: ITEM_GROUPS_OF_ITEM_ORGANIZER_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ITEM_GROUPS_OF_ITEM_ORGANIZER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
export const fetchItemsOfGroup = (id) => async (dispatch) => {
    dispatch({type: ITEMS_OF_GROUP_REQUEST})
    try {
        const {data} = await axios.get("/general/content/items-of-group/" + id)
        dispatch({
            type: ITEMS_OF_GROUP_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ITEMS_OF_GROUP_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
export const fetchItemsOfGroupByItemId = (id) => async (dispatch) => {
    dispatch({type: ITEMS_OF_GROUP_REQUEST})
    try {
        const {data} = await axios.get("/general/content/items-of-group-by-item-id/" + id)
        dispatch({
            type: ITEMS_OF_GROUP_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ITEMS_OF_GROUP_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
export const fetchItemsOrganizers = () => async (dispatch) => {
    dispatch({type: ITEMS_ORGANIZER_REQUEST})
    try {
        const {data} = await axios.get("/general/content/items-organizers")
        dispatch({
            type: ITEMS_ORGANIZER_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ITEMS_ORGANIZER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const fetchCustomCodes = () => async (dispatch) => {
    try {
        const {data} = await axios.get("/general/content/custom-codes")
        dispatch({
            type: CUSTOM_CODES_SUCCESS,
            payload: data
        })
    } catch (e) {

    }
}
export const fetchTeamMembers = () => async (dispatch) => {
    try {
        const {data} = await axios.get("/general/content/team-members")
        dispatch({
            type: TEAM_MEMBER_ITEMS_SUCCESS,
            payload: data
        })
    } catch (e) {

    }
}
export const fetchBannersPage = () => async (dispatch) => {
    try {
        const {data} = await axios.get("/admin/home/banners")
        dispatch({
            type: BANNERS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {

    }
}
export const fetchFooterLinks = () => async (dispatch) => {
    try {
        dispatch({type: FOOTER_LINKS_REQUEST})

        const {data} = await axios.get("/admin/home/footer-data")
        dispatch({
            type: FOOTER_LINKS_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: FOOTER_LINKS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchCouponsPage = () => async (dispatch) => {
    try {
        dispatch({type: COUPONS_DATA_REQUEST})

        const {data} = await axios.get("/admin/home/coupons")
        dispatch({
            type: COUPONS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: COUPONS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchPageManager = (slug) => async (dispatch) => {
    try {
        dispatch({type: PAGE_ITEMS_REQUEST})
        let data;
        if (slug === undefined) {
            const response = await axios.get("/admin/home/homepage-manager")
            data = response.data
        } else {
            const response = await axios.get("/admin/pages/items/" + slug)
            data = response.data
        }
        const {categories, page_items, sliders, banners, collections, items_organizers} = data
        dispatch({
            type: PAGE_ITEMS_SUCCESS,
            payload: page_items
        })
        dispatch({
            type: SLIDER_DATA_SUCCESS,
            payload: sliders
        })
        dispatch({
            type: ITEMS_ORGANIZER_SUCCESS,
            payload: items_organizers
        })
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: categories
        })
        dispatch({
            type: COLLECTION_LIST_SUCCESS,
            payload: collections
        })
        dispatch({
            type: BANNERS_DATA_SUCCESS,
            payload: banners
        })

    } catch (e) {
        dispatch({
            type: PAGE_ITEMS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchPageManagerByItemId = (id) => async (dispatch) => {
    try {
        dispatch({type: PAGE_ITEMS_REQUEST})
        const {data} = await axios.get("/admin/pages/items-by-item-id/" + id)
        const {categories, page_items, sliders, banners, collections} = data
        dispatch({
            type: PAGE_ITEMS_SUCCESS,
            payload: page_items
        })
        dispatch({
            type: SLIDER_DATA_SUCCESS,
            payload: sliders
        })
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: categories
        })
        dispatch({
            type: COLLECTION_LIST_SUCCESS,
            payload: collections
        })
        dispatch({
            type: BANNERS_DATA_SUCCESS,
            payload: banners
        })

    } catch (e) {
        dispatch({
            type: PAGE_ITEMS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchContentForGroup = (id) => async (dispatch) => {
    try {
        dispatch({type: CONTENT_ITEMS_REQUEST})
        const {data} = await axios.get("/admin/content/all/" + id)
        const {
            product,
            categories,
            collections,
            sliders,
            banners,
            features,
            team_members,
            content_editors,
            custom_codes, contact_data, social_data
        } = data
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: product
        })
        dispatch({
            type: FEATURES_ITEMS_SUCCESS,
            payload: features
        })
        dispatch({
            type: TEAM_MEMBER_ITEMS_SUCCESS,
            payload: team_members
        })
        dispatch({
            type: CONTENT_EDITORS_SUCCESS,
            payload: content_editors
        })
        dispatch({
            type: CUSTOM_CODES_SUCCESS,
            payload: custom_codes
        })
        dispatch({
            type: SLIDER_DATA_SUCCESS,
            payload: sliders
        })
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: categories
        })
        dispatch({
            type: COLLECTION_LIST_SUCCESS,
            payload: collections
        })
        dispatch({
            type: BANNERS_DATA_SUCCESS,
            payload: banners
        })
        dispatch({
            type: SOCIAL_SETTINGS_DATA_SUCCESS,
            payload: social_data
        })
        dispatch({
            type: CONTACT_SETTINGS_DATA_SUCCESS,
            payload: contact_data
        })

    } catch (e) {
        dispatch({
            type: CONTENT_ITEMS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchSliderPage = () => async (dispatch) => {
    try {
        dispatch({type: SLIDER_DATA_REQUEST})

        const {data} = await axios.get("/admin/home/sliders")
        const {sliders, slider_banners} = data
        dispatch({
            type: SLIDER_DATA_SUCCESS,
            payload: sliders
        })
        dispatch({
            type: SLIDER_BANNERS_DATA_SUCCESS,
            payload: slider_banners
        })

    } catch (e) {
        dispatch({
            type: SLIDER_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const fetchTopBarsPage = () => async (dispatch) => {
    try {
        dispatch({type: TOP_BARS_DATA_REQUEST})

        const {data} = await axios.get("admin/home/top-bars")
        const {top_bar, top_bars} = data
        dispatch({
            type: TOP_BARS_DATA_SUCCESS,
            payload: top_bars
        })
        dispatch({
            type: TOP_BAR_DATA_SUCCESS,
            payload: top_bar
        })

    } catch (e) {
        dispatch({
            type: TOP_BARS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchUsersPage = () => async (dispatch) => {
    try {
        dispatch({type: USER_LIST_REQUEST})

        const {data} = await axios.get("/admin/home/users")
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: USER_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
