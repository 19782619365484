import React, {useState} from "react";
import ItemTypesTab from "../Tabs/ItemTypesTab"
import {useDispatch, useSelector} from "react-redux";
import {setSelectedTab} from "../../../../../redux/actions/PageEditorActions";
import SettingsTab from "../Tabs/SettingsTab/SettingsTab";


const EditorSidebar = () => {
    const tabs = ["Elements", "Settings"]
    const selectedTab = useSelector((state) => state.selectedTab);
    const dispatch = useDispatch()
    return (<>
        <div className="flex items-center w-full text-gray-100">
            {tabs.map(tab=> (
                <div onClick={() => dispatch(setSelectedTab(tab))} className={`text-center w-full cursor-pointer py-2 hover:font-bold hover:bg-gray-900 transition-all ${tab === selectedTab && "font-bold bg-gray-900 border-b-2 border-gray-100"}`}>{tab}</div>
            ))}
        </div>
        <hr className="border-gray-600"/>
        {selectedTab === "Elements" ? <ItemTypesTab/> : <SettingsTab/>}
    </>)
}
export default EditorSidebar