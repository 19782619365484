import {
    ADD_VARIATION_TYPE_REQUEST,
    VARIATION_TYPE_UPDATE_ERROR,
    ADD_VARIATION_TYPE_SUCCESS,
    VARIATION_TYPE_UPDATE_REQUEST,
    VARIATION_TYPE_UPDATE_SUCCESS,
    ADD_VARIATION_TYPE_ERROR,
    ADD_VARIATION_ERROR,
    ADD_VARIATION_REQUEST,
    VARIATIONS_WITH_TYPES_LIST_ERROR,
    ADD_VARIATION_SUCCESS,
    VARIATION_UPDATE_ERROR,
    VARIATION_UPDATE_REQUEST,
    VARIATIONS_WITH_TYPES_LIST_SUCCESS,
    VARIATION_UPDATE_SUCCESS,
    VARIATIONS_WITH_TYPES_LIST_REQUEST,
    VARIATIONS_LIST_ERROR,
    VARIATIONS_LIST_SUCCESS,
    VARIATION_TYPES_LIST_ERROR,
    VARIATION_TYPES_LIST_REQUEST,
    VARIATION_TYPES_LIST_SUCCESS,
    VARIATIONS_LIST_REQUEST,
    PRODUCT_VARIATIONS_WITH_TYPES_LIST_REQUEST,
    PRODUCT_VARIATIONS_WITH_TYPES_LIST_SUCCESS,
    PRODUCT_VARIATIONS_WITH_TYPES_LIST_ERROR

} from "../Constants/VariationConstants";

import axios from "axios"
export const fetchVariationsWithTypes = () => async (dispatch) => {
    try {
        dispatch({type: VARIATIONS_WITH_TYPES_LIST_REQUEST})

        const {data} = await axios.get("general/products/variations-with-types")
        dispatch({
            type: VARIATIONS_WITH_TYPES_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: VARIATIONS_WITH_TYPES_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchProductVariationsWithTypes = () => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_VARIATIONS_WITH_TYPES_LIST_REQUEST})

        const {data} = await axios.get("general/products/product-variations-with-types")
        dispatch({
            type: PRODUCT_VARIATIONS_WITH_TYPES_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: PRODUCT_VARIATIONS_WITH_TYPES_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchVariationTypes = () => async (dispatch) => {
    try {
        dispatch({type: VARIATION_TYPES_LIST_REQUEST})
        const {data} = await axios.get("/general/products/variation-types")
        dispatch({
            type: VARIATION_TYPES_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: VARIATION_TYPES_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchVariations = () => async (dispatch) => {
    try {
        dispatch({type: VARIATIONS_LIST_REQUEST})

        const {data} = await axios.get("/general/products/variations")
        dispatch({
            type: VARIATIONS_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: VARIATIONS_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const postVariation = (variation) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_VARIATION_REQUEST})

        const {data} = await axios.post("/admin/products/update/variations", variation, {withCredentials: true})
        dispatch({
            type: ADD_VARIATION_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ADD_VARIATION_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const postVariationType = (variationType) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_VARIATION_TYPE_REQUEST})

        const {data} = await axios.post("/admin/products/update/variation-types", variationType, {withCredentials: true})
        dispatch({
            type: ADD_VARIATION_TYPE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ADD_VARIATION_TYPE_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}