import React, {useEffect, useState} from "react";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import ImagesUploadComponent from "../../../Components/ImagesUploadComponent";
import Rating from "@mui/material/Rating";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import ProductSearch from "../../../Components/ProductSearch";
import {getBaseUrl} from "../../../App";
import {useParams} from "react-router-dom";

const AddReviewPage = () => {
    const {id} = useParams()
    const [product, setProduct] = useState({id: 0, name: "", image: "", price: ""})
    const [formData, setFormData] = useState({
        customer_name: "",
        comment: "",
        rating: 0,
        entry_date: "",
    })
    const today = new Date().toISOString().split('T')[0];
    const [showAlert, AlertComponent] = useAlert();
    const [profileImg, setProfileImg] = useState(null)
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const getReview = async () => {
            try {
                const {data} = await axios.get('/admin/reviews/id/' + id);
                setFormData(data)
                setProduct(data.product)
                setLoading(false)
            } catch (error) {
                showAlert("error", error.message)
            }
        }
        if (id) {
            getReview()
        } else {
            setLoading(false)
        }

    }, [id]);
    const submitReview = async (e, type) => {
        e.preventDefault()
        try {
            formData.profile_img = profileImg
            formData.product_id = product.id
            formData.images = images
            const {data} = await axios.post(`/admin/reviews/${type === "add" ? "add" : `update/${id}`}`, formData);
            if (data === "success") {
                showAlert("success", `Review ${type === "update" ? "Updated" : "Added"} Successfully`)
            } else {
                showAlert("error", data.detail)
            }
        } catch (error) {
            showAlert("error", error.response.data.detail)
        }
    }

    return (
        !loading && <div className="flex flex-col bg-gray-100 gap-4">
            <form onSubmit={(e) => id ? submitReview(e, "update") : submitReview(e, "add")}>
                {AlertComponent}
                <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">Review Information</span>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="name">Select Product</label>
                        <ProductSearch setProduct={setProduct}/>
                        {product.id !== 0 ? <div
                            className="flex border-b-[1px] hover:bg-gray-100 transition-all duration-500 items-center w-[99%] overflow-hidden">
                            Selected Product:
                            <div className="flex justify-between items-center px-2 w-full">
                                <div className="flex justify-center items-center w-1/3">
                                    <img className="w-full object-contain h-24"
                                         src={getBaseUrl() + product.image} alt={product.name}/>
                                </div>
                                <div
                                    className="flex flex-col ml-2 text-gray-700 py-2 px-2 font-poppins w-2/3">
                    <span
                        className="font-semibold h-6 w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{product.name}</span>
                                    <span
                                        className="font-semibold text-teal-500">{product.currency + product.price}</span>
                                </div>
                            </div>
                        </div> : "No Product Selected!"}
                    </div>
                    <div className="w-full">
                        <label htmlFor="name">Customer Name</label>
                        <input type="text" value={formData.customer_name} required
                               onChange={(e) => setFormData({...formData, customer_name: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <ImageUploadComponent setImage={setProfileImg} title={"Upload Profile Image of Customer"}
                                          required={false}/>
                    {profileImg !== null || (id && <div className="relative w-full">
                        <div
                            className="flex justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                            <img src={getBaseUrl() + formData.profile_img} alt="Preview"
                                 className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                        </div>
                    </div>)}
                    <div className="flex flex-row items-center gap-3">
                        <Rating className="-ml-1" size="large"
                                onChange={(e) => setFormData({...formData, rating: parseInt(e.target.value)})}
                                defaultValue={formData.rating.toString()}/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="short_description">Comment</label>
                        <textarea rows="5" value={formData.comment} required
                                  onChange={(e) => setFormData({...formData, comment: e.target.value})}
                                  className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <ImagesUploadComponent images={images} setImages={setImages}
                                           title={"Reviewed Product Images"}/>
                    {formData.images && formData.images.map((x, i) => (
                        <div className="relative w-full">
                            <div
                                className="flex justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                                <img src={getBaseUrl() + x.image} alt="Preview"
                                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                                <span
                                    // onClick={() =>deleteImage(x.id)}
                                    className="absolute text-center top-4 text-red-500 right-2 rounded-full shadow-lg border-2 px-2 py-2 pt-2 material-symbols-rounded bg-black bg-opacity-10 font-light text-md ">delete</span>
                            </div>
                        </div>
                    ))}
                    <div className="w-full">
                        <label htmlFor="date">Entry Date</label>
                        <input type="date" id="date" value={formData.entry_date} max={today}
                               onChange={(e) => setFormData({...formData, entry_date: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <button type="submit"
                            className="w-full flex justify-center items-center gap-2 hover:bg-teal-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-black rounded cursor-pointer transition-all shadow-md"><span
                        className="material-symbols-rounded text-xl">near_me</span>{(id ? "Update" : "Submit") + " Review"}
                    </button>
                </div>
            </form>
        </div>
    )
}
export default AddReviewPage