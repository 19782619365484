import React, {useState} from "react";
import {useSelector} from "react-redux";
import {effects} from "../../../ItemsOrganizer";
import ProductSearch from "../../../../../../Components/ProductSearch";
import {getBaseUrl} from "../../../../../../App";
import ItemOrganizerSettings from "./ItemOrganizerSettings";
import ItemSettings from "./ItemSettings/ItemSettings";
import PageSettings from "./PageSettings";

const SettingsTab = () => {
    const selectedPageItem = useSelector((state) => state.selectedPageItem);
    const selectedItemOfItemOfPageItem = useSelector((state) => state.selectedItemOfItemOfPageItem);
    return (
        Object.keys(selectedPageItem).length !== 0 ?
            <ItemOrganizerSettings/> : Object.keys(selectedItemOfItemOfPageItem).length !== 0 ?
                <ItemSettings/> : <PageSettings/>
    )
}
export default SettingsTab