import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {fetchOrderList, updateOrder} from "../../../redux/actions/OrderActions";
import {showAlert} from "../../../utils/providers/AlertProvider";
import Loading from "../../../Components/loading";
import {Input, Textarea} from "../../../Components/ui/Input";
import {Select} from "../../../Components/ui/Select";
import {Card, CardTitle} from "../../../Components/ui/Card";
import {Button} from "../../../Components/ui/Button";

const EditOrderPage = () => {
    const dispatch = useDispatch()
    const ordersListData = useSelector((state) => state.ordersList);
    const {ordersList} = ordersListData
    const {id} = useParams()
    const [formData, setFormData] = useState({
        message_by_merchant: "", shop_address: "", estimated_time: "", package_info: "", status: "", payment_status: "",
    })
    const [loading, setLoading] = useState(false)
    const statusTypes = ["Order in Review", "Order Processing", "Pre Production", "In Production", "Dispatched", "Delivered", "Cancelled"]
    const paymentStatuses = ["Payment Awaiting", "Pending Approval", "Amount Received",]

    useEffect(() => {
        if (ordersList && ordersList.length === 0) {
            dispatch(fetchOrderList())
        }
    }, [ordersList]);

    useEffect(() => {
        const order = ordersList.find(x => x.id === Number(id))
        if (ordersListData.success) {
            setFormData({...order})
        }
    }, [ordersListData.success])

    useEffect(() => {
        if (formData.status === "Delivered") {
            showAlert("info", "If saved the customer will be able to review the received products!")
        } else if (formData.status === "Cancelled") {
            showAlert("info", "It is good to write reason of cancelling!")
        }
    }, [formData.status])

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(updateOrder(formData, id, setLoading))
    }

    return (ordersListData.success ? <React.Fragment>
        <form onSubmit={submit}>
            <Card>
                <CardTitle className="flex justify-between items-center pb-2">
                    <span className="text-lg font-bold">Update Order Details</span>
                    <Link to={"/order/" + id}><span className="bg-green-600 text-white rounded px-3 py-1.5">View Order Details</span></Link>
                </CardTitle>
                <Select label="Status"
                        id="status" value={formData.status}
                        onChange={(e) => setFormData({...formData, status: e.target.value})}>
                    <option disabled selected>Select Status</option>
                    {statusTypes.map((value, index) => (<option key={index} value={value}>{value}</option>))}
                </Select>

                <Select id="status" value={formData.payment_status} label="Payment Status"
                        onChange={(e) => setFormData({...formData, payment_status: e.target.value})}>
                    <option disabled selected>Select Payment Status</option>
                    {paymentStatuses.map((value, index) => (<option key={index} value={value}>{value}</option>))}
                </Select>

                <Input required={true} type="text" id="package_info" value={formData.package_info}
                       label="Package Info"
                       onChange={(e) => setFormData({...formData, package_info: e.target.value})}/>

                <Textarea required={true} rows="2" id="shop_address" value={formData.shop_address}
                          label="Shipping From"
                          onChange={(e) => setFormData({...formData, shop_address: e.target.value})}/>

                <Textarea required={true} rows="5" id="message_by_merchant" label="Message By Merchant"
                          value={formData.message_by_merchant}
                          onChange={(e) => setFormData({...formData, message_by_merchant: e.target.value})}/>

                <Input required={true} type="text" id="slug" value={formData.estimated_time} label="Estimated Time"
                       onChange={(e) => setFormData({...formData, estimated_time: e.target.value})}/>

                <Button type="submit">{!loading ? "Update Order" : <Loading small={true}/>}</Button>
            </Card>
        </form>
    </React.Fragment> : <Loading/>)
}
export default EditOrderPage