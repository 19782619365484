import React from "react";
import {useSelector} from "react-redux";
import {Card, CardTitle} from "../../../../Components/ui/Card";
import {Input, Textarea} from "../../../../Components/ui/Input";
import {Select} from "../../../../Components/ui/Select";

const ProductInformationForm = ({formData, setFormData}) => {
    const {categories} = useSelector((state) => state.categoryList);
    return (<Card>
        <CardTitle>Product Information</CardTitle>
        <Input type="text" value={formData.name} required={true}
               onChange={(e) => setFormData({...formData, name: e.target.value})}
               id="name" label="Product Name"/>
        <Textarea rows="5" value={formData.short_description} required={true}
                  onChange={(e) => setFormData({...formData, short_description: e.target.value})}
                  id="short_description" label="Short Description"/>
        <Input type="number" step="any" id="price" value={formData.price} required={true}
               onChange={(e) => setFormData({...formData, price: e.target.value})}
               label="Price"/>
        <Select label="Category"
                id="category" value={formData.category_id} required={true}
                onChange={(e) => setFormData({...formData, category_id: e.target.value})}>
            <option disabled value={0} selected>Select Category</option>
            {categories.filter(x => x.visibility === 1).map(value => (
                <option key={value.id} value={value.id}>{value.name}</option>))}
        </Select>
        <Input type="text" value={formData.SKU} maxLength={12} required={true}
               onChange={(e) => setFormData({
                   ...formData, SKU: e.target.value.replace(/\s/g, '').toUpperCase()
               })} id="SKU"
               label="SKU"/>
        <Input type="number" id="discount_rate" value={formData.discount_rate} required={true}
               onChange={(e) => setFormData({...formData, discount_rate: e.target.value})}
               label="Discount %"/>
        <Input type="number" id="sold_times" value={formData.sold_times} required={true}
               onChange={(e) => setFormData({...formData, sold_times: e.target.value})}
               label="Sold Count"/>

    </Card>)
}
export default ProductInformationForm