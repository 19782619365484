import React, {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";
import Loading from "../../../../Components/loading";
import useAlert from "../../../../CustomHooks/useAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";

const ImportLocations = () => {
    const [allCountries, setAllCountries] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState({value: 0, label: "None"})
    const [selectedCountries, setSelectedCountries] = useState([])
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const {data} = await axios.get('/admin/settings/countries',);
                setAllCountries(data)
                setLoading(false)
            } catch (error) {
            }
        }
        fetchCountries()
    }, []);
    const [showAlert, AlertComponent] = useAlert()
    const importCountry = async () => {
        if (selectedCountry.value === 0) {
            showAlert("error", "Please Select the country")
        } else {
            try {
                const {data} = await axios.post('/admin/settings/import/country/' + selectedCountry.value)
                showAlert("success", selectedCountry.label + "'s Locations Imported Successfully!")
            } catch (error) {
                showAlert("error", error.message)
            }
        }
    }
    const importCountries = async () => {
        if (selectedCountries.length === 0) {
            showAlert("error", "Please Select the countries")
        } else {
            try {
                const {data} = await axios.post('/admin/settings/import/country/multiple', {countries: selectedCountries})
                showAlert("success", "Locations Imported Successfully!")
            } catch (error) {
                showAlert("error", error.message)
            }
        }
    }
    const importAllCountries = async () => {
        try {
            const {data} = await axios.post('/admin/settings/import/country/all')
            showAlert("success", "All Countries' Locations Imported Successfully!")
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    const handleSingleCountry = (selected) => {
        setSelectedCountry(selected)
    }

    const handleMultipleCountries = (selected) => {
        if (selectedCountries.some(x => x.value === selected.value)) {
            showAlert('error', 'Already Added')
        } else {
            setSelectedCountries(prevState => [...prevState, selected])
        }
        // setAllCountries(allCountries.filter(x=> x.id !== selected.value))
    }
    const deleteCountry = (country) => {
        setSelectedCountries(selectedCountries.filter(x => x.value !== country.value))
    }
    return (
        <React.Fragment>
            {loading && <Loading/>}
            {AlertComponent}
            <div className="bg-gray-100 py-1">
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Import Single Country</div>
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...allCountries.map(x => ({
                                value: x.id, label: x.country
                            }))]}
                            onChange={handleSingleCountry} // Set the selected option as the new state
                            value={selectedCountry}
                            placeholder={'Countries'}/>
                    <div className="flex justify-center mt-3 items-center">
                    <span onClick={importCountry}
                          className={'text-center px-4 py-2 bg-green-600 text-white rounded-lg border-black border-[1px] hover:bg-green-950 hover:cursor-pointer transition-all duration-500'}>
                        {`Import ${selectedCountry.value !== 0 ? selectedCountry.label : "This Country"}'s Locations`}
                    </span>
                    </div>
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Import Multiple Countries</div>
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...allCountries.map(x => ({
                                value: x.id, label: x.country
                            }))]}
                            onChange={handleMultipleCountries} // Set the selected option as the new state
                            placeholder={'Countries'}/>
                    <div className="flex gap-2 mt-2 flex-wrap">
                        {selectedCountries.map(x => (
                            <span
                                className={'px-4 py-2 bg-gray-100 text-black rounded-lg border-black border-[1px]' +
                                    'transition-all duration-500'}>
                                {x.label} <FontAwesomeIcon onClick={() => deleteCountry(x)}
                                                           className="hover:text-red-800 cursor-pointer"
                                                           icon={faCircleXmark}/>
                    </span>
                        ))}
                    </div>
                    <div className="flex justify-center mt-3 items-center">
                    <span onClick={importCountries}
                          className={'text-center px-4 py-2 bg-green-600 text-white rounded-lg border-black border-[1px] hover:bg-green-950 hover:cursor-pointer transition-all duration-500'}>Import
                        These Countries' Locations
                    </span>
                    </div>
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Import All Countries</div>
                    <hr/>
                    <div className="flex justify-center mt-3 items-center">
                    <span onClick={importAllCountries}
                          className={'text-center px-4 py-2 bg-green-600 text-white rounded-lg border-black border-[1px] hover:bg-green-950 hover:cursor-pointer transition-all duration-500'}>Import
                        All Countries' Locations
                    </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ImportLocations