import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {fetchContactSettingsData} from "../../../redux/actions/SettingsActions";
import {useParams} from "react-router-dom";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import IconsSelector, {getPathSvg} from "../../../utils/IconsSelector/IconsSelector";

const ContactSettingsPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    useEffect(() => {
        dispatch(fetchContactSettingsData())
    }, []);
    const [formData, setFormData] = useState({
        name: "",
        value: "",
        icon_name: "", icon_svg: "",
    })
    const [showAlert, AlertComponent] = useAlert();
    const {contactSettings, success} = useSelector((state) => state.contactSettingsData);
    useEffect(() => {
        if (id && success) {
            const contact_item = contactSettings.find(x => x.id === Number(id))
            setFormData(contact_item)
        }
    }, [id, success]);
    const submit = async (e) => {
        if (id) {
            try {
                const response = await axios.post('/admin/settings/update/contact-items/' + id, formData);
                showAlert("success", "Contact Info updated successfully")
            } catch (error) {
                showAlert("error", error)
            }
        } else {
            try {
                const response = await axios.post('/admin/settings/update/contact-items', formData
                   );
                showAlert("success", "Contact Info Added!")
            } catch (error) {
                showAlert("error", error)
            }
        }
        dispatch(fetchContactSettingsData())
    }
    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add New"} Contact Info</span>
                    <IconsSelector formData={formData} setFormData={setFormData}/>
                    <div className="w-full">
                        <label htmlFor="name">Name</label>
                        <input type="text" value={formData.name} required={true}
                               onChange={(e) => setFormData({...formData, name: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="name">Value</label>
                        <input type="text" value={formData.value} required={true}
                               onChange={(e) => setFormData({...formData, value: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>

                    <button onClick={submit}
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                    >{(id ? "Update" : "Add")} Contact Info
                    </button>
                </div>
                {!id && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={contactSettings} tableName="Contact Info Items" showAlert={showAlert}/>
                </div>}
            </div>
        </React.Fragment>
    )
}
export default ContactSettingsPage