import {
    SAVE_ORDER_SUCCESS,
    DELETE_ORDER_ERROR,
    SAVE_ORDER_REQUEST,
    SAVE_ORDER_ERROR,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_REQUEST,
    ORDER_LIST_ERROR,
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_ERROR,
    ORDERED_PRODUCTS_LIST_REQUEST,
    ORDERED_PRODUCTS_LIST_ERROR,
    ORDERED_PRODUCTS_LIST_SUCCESS,
    REVIEWS_LIST_ERROR,
    REVIEWS_LIST_REQUEST,
    REVIEWS_LIST_SUCCESS,
    SOLD_PRODUCTS_LIST_SUCCESS,
    SOLD_PRODUCTS_LIST_REQUEST,
    SOLD_PRODUCTS_LIST_ERROR
} from "../Constants/OrderConstants";

export const ordersListReducer = (state = {ordersList: []}, action) => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
            return {loading: true, ordersList: []}
        case ORDER_LIST_SUCCESS:
            return {loading: false, success: true, ordersList: action.payload}
        case ORDER_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}


export const reviewsListReducer = (state = {reviews: []}, action) => {
    switch (action.type) {
        case REVIEWS_LIST_REQUEST:
            return {loading: true, reviews: []}
        case REVIEWS_LIST_SUCCESS:
            return {loading: false, success: true, reviews: action.payload}
        case REVIEWS_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}


export const soldProductsListReducer = (state = {soldProducts: []}, action) => {
    switch (action.type) {
        case SOLD_PRODUCTS_LIST_REQUEST:
            return {loading: true, soldProducts: []}
        case SOLD_PRODUCTS_LIST_SUCCESS:
            return {loading: false, success: true, soldProducts: action.payload}
        case SOLD_PRODUCTS_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const orderedProductsListReducer = (state = {orderedProducts: []}, action) => {
    switch (action.type) {
        case ORDERED_PRODUCTS_LIST_REQUEST:
            return {loading: true, orderedProducts: []}
        case ORDERED_PRODUCTS_LIST_SUCCESS:
            return {loading: false, success: true, orderedProducts: action.payload}
        case ORDERED_PRODUCTS_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const saveOrderReducer = (state = {message: ""}, action) => {
    switch (action.type) {
        case SAVE_ORDER_REQUEST:
            return {loading: true}
        case SAVE_ORDER_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case SAVE_ORDER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const updateOrderReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case UPDATE_ORDER_REQUEST:
            return {loading: true, message: {}}
        case UPDATE_ORDER_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case UPDATE_ORDER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const deleteOrderReducer = (state = {message: ""}, action) => {
    switch (action.type) {
        case DELETE_ORDER_REQUEST:
            return {loading: true}
        case DELETE_ORDER_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case DELETE_ORDER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}