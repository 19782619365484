import React from "react";

const TablePagesComponent = ({pageCount, pageIndex, gotoPage}) => {

    return (
        <React.Fragment>
            <button
                className={`material-symbols-rounded text-gray-700 font-sm rounded-lg border-[1px] p-2 shadow-lg`}
                onClick={() => pageIndex !== 0 ? gotoPage(0) : null}>
                first_page
            </button>
            <button
                className={`material-symbols-rounded text-gray-700 font-sm rounded-lg border-[1px] p-2 shadow-lg`}
                onClick={() => pageIndex !== 0 ? gotoPage(pageIndex - 1) : null}>
                navigate_before
            </button>

            {[...Array(pageCount)].map((x, index) => (
                (index === pageIndex || index === pageIndex + 1 || index === pageIndex - 1) || (pageIndex === pageCount - 1 ? index === pageIndex - 2 : pageIndex === 0 ? index === pageIndex + 2 : false) ?
                    <button
                        className={`text-gray-700 font-sm rounded-lg hover:bg-blue-500 hover:text-white transition-all shadow-lg border-[1px] px-3 py-2 ${pageIndex === index ? "bg-blue-500 text-white" : "bg-white"}`}
                        onClick={() => gotoPage(index)}>
                        {index + 1}
                    </button> : null
            ))}
            <button
                className={`material-symbols-rounded text-gray-700 font-sm rounded-lg border-[1px] p-2 shadow-lg`}
                onClick={() => pageIndex !== pageCount - 1 ? gotoPage(pageIndex + 1) : null}>
                navigate_next
            </button>
            <button
                className={`material-symbols-rounded text-gray-700 font-sm rounded-lg border-[1px] p-2 shadow-lg`}
                onClick={() => pageIndex !== pageCount - 1 ? gotoPage(pageCount - 1) : null}>
                last_page
            </button>
        </React.Fragment>
    )
}
export default TablePagesComponent