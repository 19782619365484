import React from "react";

const ColumnFilterOfTable = ({column}) => {
    const {filterValue, setFilter} = column
    return (
        <React.Fragment>
            <input type="text"
                   className="py-2 px-3 text-md rounded-bl-md rounded-tl-md w-full border border-gray-200"
                   placeholder="Search" value={filterValue} onChange={(e)=>setFilter(e.target.value)}/>
        </React.Fragment>
    )
}
export default ColumnFilterOfTable