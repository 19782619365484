import {
    SLIDER_UPDATE_ERROR,
    SLIDER_UPDATE_SUCCESS,
    SLIDER_DATA_ERROR,
    SLIDER_UPDATE_REQUEST,
    SLIDER_DATA_SUCCESS,
    SLIDER_DATA_REQUEST,
    MENU_ITEMS_REQUEST,
    MENU_ITEMS_UPDATE_SUCCESS,
    MENU_ITEMS_UPDATE_ERROR,
    MENU_ITEMS_ERROR,
    MENU_ITEMS_UPDATE_REQUEST,
    MENU_ITEMS_SUCCESS,
    MENU_ORDER_UPDATE_ERROR,
    MENU_ORDER_UPDATE_SUCCESS,
    MENU_ORDER_UPDATE_REQUEST,
    HOME_ITEMS_SUCCESS,
    HOME_ITEMS_ERROR,
    HOME_ORDER_UPDATE_ERROR,
    HOME_ORDER_UPDATE_REQUEST,
    HOME_ITEMS_UPDATE_SUCCESS,
    HOME_ORDER_UPDATE_SUCCESS,
    HOME_ITEMS_UPDATE_REQUEST,
    HOME_ITEMS_UPDATE_ERROR,
    HOME_ITEMS_REQUEST,
    SLIDER_BANNERS_DATA_REQUEST,
    SLIDER_BANNERS_DATA_SUCCESS,
    SLIDER_BANNERS_DATA_ERROR,
    BANNERS_DATA_REQUEST,
    BANNERS_DATA_ERROR,
    BANNERS_DATA_SUCCESS,
    COUPONS_DATA_SUCCESS,
    COUPONS_DATA_ERROR,
    COUPONS_DATA_REQUEST,
    TOP_BARS_DATA_SUCCESS,
    TOP_BAR_DATA_ERROR,
    TOP_BAR_DATA_REQUEST,
    TOP_BARS_DATA_REQUEST,
    TOP_BARS_DATA_ERROR,
    TOP_BAR_DATA_SUCCESS,
    FEATURES_ITEMS_SUCCESS,
    TEAM_MEMBER_ITEMS_SUCCESS,
    PAGE_ITEMS_REQUEST,
    PAGE_ITEMS_SUCCESS,
    PAGE_ITEMS_ERROR,
    CONTENT_EDITORS_SUCCESS,
    CUSTOM_CODES_SUCCESS,
    ITEM_GROUPS_SUCCESS,
    ITEMS_OF_GROUP_SUCCESS,
    ITEMS_OF_GROUP_REQUEST,
    ITEMS_OF_GROUP_ERROR,
    ITEMS_ORGANIZER_REQUEST,
    ITEMS_ORGANIZER_SUCCESS,
    ITEMS_ORGANIZER_ERROR,
    ITEM_GROUPS_OF_ITEM_ORGANIZER_SUCCESS,
    ITEM_GROUPS_OF_ITEM_ORGANIZER_REQUEST,
    ITEM_GROUPS_OF_ITEM_ORGANIZER_ERROR
} from "../Constants/GeneralItemsConstants";

export const slidersDataReducer = (state = {sliders: []}, action) => {
    switch (action.type) {
        case SLIDER_DATA_REQUEST:
            return {loading: true, sliders: []}
        case SLIDER_DATA_SUCCESS:
            return {loading: false, success: true, sliders: action.payload}
        case SLIDER_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const sliderBannersDataReducer = (state = {sliderBanners: []}, action) => {
    switch (action.type) {
        case SLIDER_BANNERS_DATA_REQUEST:
            return {loading: true, sliderBanners: []}
        case SLIDER_BANNERS_DATA_SUCCESS:
            return {loading: false, success: true, sliderBanners: action.payload}
        case SLIDER_BANNERS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const updateSliderReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case SLIDER_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case SLIDER_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case SLIDER_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const bannersDataReducer = (state = {banners: []}, action) => {
    switch (action.type) {
        case BANNERS_DATA_REQUEST:
            return {loading: true, banners: []}
        case BANNERS_DATA_SUCCESS:
            return {loading: false, success: true, banners: action.payload}
        case BANNERS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const couponsDataReducer = (state = {coupons: []}, action) => {
    switch (action.type) {
        case COUPONS_DATA_REQUEST:
            return {loading: true, coupons: []}
        case COUPONS_DATA_SUCCESS:
            return {loading: false, success: true, coupons: action.payload}
        case COUPONS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const topBarsReducer = (state = {topBars: []}, action) => {
    switch (action.type) {
        case TOP_BARS_DATA_REQUEST:
            return {loading: true, topBars: []}
        case TOP_BARS_DATA_SUCCESS:
            return {loading: false, success: true, topBars: action.payload}
        case TOP_BARS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const topBarReducer = (state = {topBar: {}}, action) => {
    switch (action.type) {
        case TOP_BAR_DATA_REQUEST:
            return {loading: true, topBar: {}}
        case TOP_BAR_DATA_SUCCESS:
            return {loading: false, success: true, topBar: action.payload}
        case TOP_BAR_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const menuItemsReducer = (state = {menuItems: []}, action) => {
    switch (action.type) {
        case MENU_ITEMS_REQUEST:
            return {loading: true, menuItems: []}
        case MENU_ITEMS_SUCCESS:
            return {loading: false, success: true, menuItems: action.payload}
        case MENU_ITEMS_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const featureItemsReducer = (state = {features: []}, action) => {
    switch (action.type) {
        case FEATURES_ITEMS_SUCCESS:
            return {loading: false, success: true, features: action.payload}
        default:
            return state;
    }
}
export const contentEditorsReducer = (state = {contentEditors: []}, action) => {
    switch (action.type) {
        case CONTENT_EDITORS_SUCCESS:
            return {loading: false, success: true, contentEditors: action.payload}
        default:
            return state;
    }
}
export const itemGroupsReducer = (state = {itemGroups: []}, action) => {
    switch (action.type) {
        case ITEM_GROUPS_SUCCESS:
            return {loading: false, success: true, itemGroups: action.payload}
        default:
            return state;
    }
}
export const itemGroupsOfItemsOrganizerReducer = (state = {itemGroupsOfItemsOrganizer: []}, action) => {
    switch (action.type) {
        case ITEM_GROUPS_OF_ITEM_ORGANIZER_REQUEST:
            return {loading: true, itemGroupsOfItemsOrganizer: []}
        case ITEM_GROUPS_OF_ITEM_ORGANIZER_SUCCESS:
            return {loading: false, success: true, itemGroupsOfItemsOrganizer: action.payload}
        case ITEM_GROUPS_OF_ITEM_ORGANIZER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }

}
export const itemsOfGroupReducer = (state = {itemsOfGroup: [], item_group_data: {}}, action) => {
    switch (action.type) {
        case ITEMS_OF_GROUP_REQUEST:
            return {loading: true, itemsOfGroup: [], item_group_data: {}}
        case ITEMS_OF_GROUP_SUCCESS:
            const {items_of_group, item_group_data} = action.payload
            return {loading: false, success: true, itemsOfGroup: items_of_group, item_group_data: item_group_data}
        case ITEMS_OF_GROUP_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const itemsOrganizersReducer = (state = {itemsOrganizers: []}, action) => {
    switch (action.type) {
        case ITEMS_ORGANIZER_REQUEST:
            return {loading: true, itemsOrganizers: []}
        case ITEMS_ORGANIZER_SUCCESS:
            return {loading: false, success: true, itemsOrganizers: action.payload}
        case ITEMS_ORGANIZER_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const customCodesReducer = (state = {customCodes: []}, action) => {
    switch (action.type) {
        case CUSTOM_CODES_SUCCESS:
            return {loading: false, success: true, customCodes: action.payload}
        default:
            return state;
    }
}

export const teamMemberItemsReducer = (state = {teamMembers: []}, action) => {
    switch (action.type) {
        case TEAM_MEMBER_ITEMS_SUCCESS:
            return {loading: false, success: true, teamMembers: action.payload}
        default:
            return state;
    }
}

export const updateMenuItemsReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case MENU_ITEMS_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case MENU_ITEMS_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case MENU_ITEMS_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const updateMenuOrderReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case MENU_ORDER_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case MENU_ORDER_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case MENU_ORDER_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const pageItemsReducer = (state = {pageItems: []}, action) => {
    switch (action.type) {
        case PAGE_ITEMS_REQUEST:
            return {loading: true, pageItems: []}
        case PAGE_ITEMS_SUCCESS:
            return {loading: false, success: true, pageItems: action.payload}
        case PAGE_ITEMS_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const updateHomeItemsReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case HOME_ITEMS_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case HOME_ITEMS_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case HOME_ITEMS_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const updateHomeOrderReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case HOME_ORDER_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case HOME_ORDER_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case HOME_ORDER_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
