import './App.css';
import React, {useEffect} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {LoginScreen} from "./Screens/Auth/LoginScreen";
import AdminPanelTemplate from "./Components/Templates/AdminPanelTemplate";
import {fetchUserData, refreshUser} from "./redux/actions/UserActions";
import {useDispatch, useSelector} from "react-redux";
import RowDetails from "./Components/RowDetails";
import Invoice from "./Screens/AdminPanel/Orders/Invoice";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import {fetchGeneralSettingsData} from "./redux/actions/SettingsActions";
import {routesConfig} from "./routes";

// Set withCredentials to true for all requests
axios.defaults.withCredentials = true;
axios.defaults.baseURL = getBaseUrl()

export function getBaseUrl() {
    const hostname = window.location.hostname
    if (hostname === "localhost") {
        return "http://localhost:8000"
    } else {
        return "https://" + hostname.replace("admin", "api");
    }
}

function App() {
    library.add(fab)
    const dispatch = useDispatch()
    const refreshUserData = useSelector((state) => state.refreshUser);
    const loginUserData = useSelector((state) => state.loginUser);
    useEffect(() => {
        dispatch(refreshUser())
        dispatch(fetchGeneralSettingsData())
    }, [])
    useEffect(() => {
        refreshUserData.success && dispatch(fetchUserData(refreshUserData.access_token))
    }, [refreshUserData.loading])
    useEffect(() => {
        loginUserData.success && dispatch(fetchUserData(loginUserData.access_token))
    }, [loginUserData.loading])

    const renderAdminPanelRoutes = (routes, type) => (routes.map(({path, PageContent, props}) => (<Route
        key={path}
        path={'/' + type + path}
        element={<AdminPanelTemplate
            PageContent={props ? <PageContent {...(props || {})}/> : PageContent}
        />}
    />)));
    return (<BrowserRouter>
        <Routes>
            <Route key={"/"} path={"/"} element={<LoginScreen/>}/>
            {routesConfig.flatMap(({type, routes}) => renderAdminPanelRoutes(routes, type))}
            <Route key="/invoice/:id" path="/invoice/:id" element={<Invoice/>}/>
            {renderAdminPanelRoutes([{path: ":name/:id", PageContent: RowDetails}], "")}
            <Route path="*" element={<Navigate replace to="/"/>}/>
        </Routes>
    </BrowserRouter>)

}

export default App;