import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";
import {fetchProductList} from "../../redux/actions/ProductActions";
import {fetchOrderList, fetchReviewsList} from "../../redux/actions/OrderActions";
import {fetchCategoryList, fetchCollectionList} from "../../redux/actions/CategoryActions";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fetchPageList} from "../../redux/actions/PagesActions";
import {fetchBannersData} from "../../redux/actions/GeneralItemsActions";
import {fetchContactSettingsData, fetchPaymentSettingsData} from "../../redux/actions/SettingsActions";
import {
    fetchContentEditors,
    fetchCouponsPage,
    fetchCustomCodes,
    fetchFooterLinks,
    fetchItemGroups,
    fetchItemsOfGroupByItemId,
    fetchItemsOrganizers,
    fetchNavigationPage,
    fetchPageManagerByItemId,
    fetchSliderPage,
    fetchUsersPage
} from "../../redux/actions/PagesDataActions";
import {fetchVariations, fetchVariationTypes} from "../../redux/actions/VariationActions";
import {getNameFromPathname} from "../../utils/UtilityFunctions";
import {showAlert} from "../../utils/providers/AlertProvider";

const TableActionComponent = React.forwardRef(({id, tableName, slug}) => {
    slug = slug.slice(1)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const pathname = location.pathname
    const deleteRow = async () => {
        if (tableName.toLowerCase().includes("order")) {
            try {
                const response = await axios.post('/admin/orders/delete/' + id);
                showAlert("success", "Order Deleted Successfully")
                dispatch(fetchOrderList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("product")) {
            try {
                const response = await axios.post('/admin/products/delete/' + id);
                showAlert("success", "Product Deleted Successfully")
                dispatch(fetchProductList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("users")) {
            try {
                const response = await axios.post('/admin/home/delete/user/' + id);
                showAlert("success", "User Deleted Successfully")
                dispatch(fetchUsersPage())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("categories")) {
            try {
                const response = await axios.post('/admin/categories/delete/' + id);
                showAlert("success", "Category Deleted Successfully")
                dispatch(fetchCategoryList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("collections")) {
            try {
                const response = await axios.post('/admin/collections/delete/' + id);
                showAlert("success", "Collection Deleted Successfully")
                dispatch(fetchCollectionList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("pages")) {
            try {
                const response = await axios.post('/admin/pages/delete/' + slug);
                showAlert("success", "Page Deleted Successfully")
                dispatch(fetchPageList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("sliders")) {
            try {
                const response = await axios.post('/admin/home/delete/slider/' + id);
                showAlert("success", "Slider Deleted Successfully")
                dispatch(fetchSliderPage())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("slider")) {
            try {
                const response = await axios.post('/admin/home/delete/slider-banner/' + id);
                showAlert("success", "Slider Item Deleted Successfully")
                dispatch(fetchSliderPage())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("banner")) {
            try {
                const response = await axios.post('/admin/home/delete/banner/' + id);
                showAlert("success", "Banner Deleted Successfully")
                dispatch(fetchBannersData())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("countries")) {
            try {
                const response = await axios.post('/admin/settings/delete/country/' + id);
                showAlert("success", "Country Deleted Successfully")
                dispatch(fetchPaymentSettingsData())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("states")) {
            try {
                const response = await axios.post('/admin/settings/delete/state/' + id);
                showAlert("success", "State Deleted Successfully")
                dispatch(fetchPaymentSettingsData())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("cities")) {
            try {
                const response = await axios.post('/admin/settings/delete/city/' + id);
                showAlert("success", "City Deleted Successfully")
                dispatch(fetchPaymentSettingsData())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("coupon")) {
            try {
                const response = await axios.post('/admin/home/delete/coupon/' + id);
                showAlert("success", "Coupon Deleted Successfully")
                dispatch(fetchCouponsPage())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("reviews")) {
            try {
                const response = await axios.post('/admin/reviews/delete/' + id);
                showAlert("success", "Coupon Deleted Successfully")
                dispatch(fetchReviewsList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("footer")) {
            try {
                const response = await axios.post('/admin/home/delete/footer-link/' + id);
                showAlert("success", "Footer Link Deleted Successfully")
                dispatch(fetchFooterLinks())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("payment")) {
            try {
                const response = await axios.post('/admin/settings/delete/payment-option/' + id);
                showAlert("success", "Payment Option Deleted Successfully")
                dispatch(fetchPaymentSettingsData())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("page items")) {
            try {
                const response = await axios.post('/admin/pages/delete/items/' + id);
                showAlert("success", "Page Item Deleted Successfully")
                dispatch(fetchPageManagerByItemId(id))
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("feedback")) {
            try {
                const response = await axios.post('/admin/orders/delete/feedbacks/' + id);
                showAlert("success", "Feedback Deleted Successfully")
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("currencies")) {
            try {
                const response = await axios.post('/admin/settings/delete/currency/' + id);
                showAlert("success", "Currency Deleted Successfully")
                dispatch(fetchPaymentSettingsData())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("menu items")) {
            try {
                const response = await axios.post('/admin/home/delete/menu-items/' + id);
                showAlert("success", "Menu Item Deleted Successfully")
                dispatch(fetchNavigationPage())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("contact info")) {
            try {
                const response = await axios.post('/admin/settings/delete/contact-items/' + id);
                showAlert("success", "Contact Info Item Deleted Successfully")
                dispatch(fetchContactSettingsData())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("content editors")) {
            try {
                const response = await axios.post('/admin/content/delete/content-editor/' + id);
                showAlert("success", "Content Editor Deleted Successfully")
                dispatch(fetchContentEditors())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("custom code")) {
            try {
                const response = await axios.post('/admin/content/delete/custom-code/' + id);
                showAlert("success", "Custom Code Deleted Successfully")
                dispatch(fetchCustomCodes())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("item groups")) {
            try {
                const response = await axios.post('/admin/content/delete/item-group/' + id);
                showAlert("success", "Item Group Deleted Successfully")
                dispatch(fetchItemGroups())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("items of group")) {
            try {
                const response = await axios.post('/admin/content/delete/item-of-group/' + id);
                showAlert("success", "Item of Group Deleted Successfully")
                dispatch(fetchItemsOfGroupByItemId(id))
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("items organizer")) {
            try {
                const response = await axios.post('/admin/content/delete/items-organizer/' + id);
                showAlert("success", "Items Organizer Deleted Successfully")
                dispatch(fetchItemsOrganizers())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("variation type")) {
            try {
                const response = await axios.post('/admin/products/delete/variation-type/' + id);
                showAlert("success", "Variation Type Deleted Successfully")
                dispatch(fetchVariationTypes())
                dispatch(fetchVariations())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        } else if (tableName.toLowerCase().includes("variations")) {
            try {
                const response = await axios.post('/admin/products/delete/variation/' + id);
                showAlert("success", "Variation Deleted Successfully")
                dispatch(fetchVariations())
            } catch (error) {
                showAlert("error", error.response.data.detail || error.message)
            }
        }

    }
    const restoreRow = async () => {
        if (tableName.toLowerCase().includes("order")) {
            try {
                const response = await axios.post('/admin/orders/restore/' + id);
                showAlert("success", "Order Restored Successfully")
                dispatch(fetchOrderList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("product")) {
            try {
                const response = await axios.post('/admin/products/restore/' + id);
                dispatch(fetchProductList())
            } catch (error) {
                showAlert("error", error.message)
            }
            showAlert("success", "Product Restored Successfully")

        } else if (tableName.toLowerCase().includes("categories")) {
            try {
                const response = await axios.post('/admin/categories/restore/' + id);
                showAlert("success", "Category Restored Successfully")
                dispatch(fetchCategoryList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("collections")) {
            try {
                const response = await axios.post('/admin/collections/restore/' + id);
                showAlert("success", "Collection Restored Successfully")
                dispatch(fetchCollectionList())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("pages")) {
            try {
                const response = await axios.post('/admin/pages/restore/' + slug);
                showAlert("success", "Page Restored Successfully")
                dispatch(fetchPageList())
            } catch (error) {
                showAlert("error", error.message)
            }
        }
    }
    const viewRow = () => {
        if (tableName.toLowerCase().includes("order")) {
            navigate('/order/' + id)
        } else if (tableName.toLowerCase().includes("product")) {
            navigate('/product/' + id)
        } else if (tableName.toLowerCase().includes("categories")) {
            navigate('/category/' + id)
        } else if (tableName.toLowerCase().includes("invoices")) {
            navigate('/invoice/' + id)
        } else if (tableName.toLowerCase().includes("pages")) {
            navigate("/page/" + slug)
        }
    }
    const generateEditUrl = () => {
        let url = `${pathname}/update/${id}`;
        if (tableName.toLowerCase().includes("sliders")) {
            url = `/home/image-sliders/update/${id}`;
        } else if (tableName.toLowerCase().includes("slider item")) {
            url = `/home/image-slider-banners/update/${id}`;
        } else if (tableName.toLowerCase().includes("variation types")) {
            url = `/products/variation-types/update/${id}`;
        } else if (tableName.toLowerCase().includes("variation")) {
            url = `/products/variations/update/${id}`;
        } else if (tableName.toLowerCase().includes("reviews")) {
            url = `/reviews/update/${id}`;
        } else if (tableName.toLowerCase().includes("orders")) {
            url = `/orders/update/${id}`;
        }
        return url;
    };

    const editUrl = generateEditUrl()

    function renderActions() {
        const baseSpan = "material-symbols-rounded cursor-pointer text";
        if (tableName.includes("Deleted")) {
            return (
                <span className={`${baseSpan}-blue-500`} onClick={restoreRow}>
        <FontAwesomeIcon icon={faRotateRight}/>
      </span>
            );
        }

        if (tableName.includes("Invoices")) {
            return (
                <span className={`${baseSpan}-blue-500`} onClick={viewRow}>
        visibility
      </span>
            );
        }
        const commonEntities = ["Slider", "Banner", "Users", "States", "Cities", "Countries", "Coupons", 'Review', "Footer Links", "Payment", "Feedback", "Page Items", "Currencies", "Menu Items", "Contact Info", "Collection", "Content Editors", "Custom Code", "Item Groups", "Items of Group", "Items Organizers", "Variations", "Variation Types"];
        if (commonEntities.some(entity => tableName.includes(entity))) {
            return (
                <>
                    {["States", "Cities", "Countries", "Coupons", "Review", "Footer Links", "Payment", "Page Items", "Currencies", "Slider", "Menu Items", "Contact Info", "Collection", "Content Editors", "Custom Code", "Item Groups", "Items of Group", "Items Organizers", "Variations", "Variation Types"].some(entity => tableName.includes(entity)) && (
                        <Link to={editUrl}>  <span className={`${baseSpan}-green-500 mx-2`}>
            edit
                        </span></Link>
                    )}
                    <span className={`${baseSpan}-red-500`} onClick={deleteRow}>
          delete
        </span>
                </>
            );
        }

        // Default action set for other cases
        return (
            <>
      <span className={`${baseSpan}-blue-500`} onClick={viewRow}>
        visibility
      </span>
                <Link to={editUrl}> <span className={`${baseSpan}-green-500 mx-2`}>
        edit
      </span></Link>
                <span className={`${baseSpan}-red-500`} onClick={deleteRow}>
        delete
      </span>
            </>
        );
    }

    return (
        <>
            {renderActions()}
        </>
    );
})
export default TableActionComponent