import React, {useEffect, useState} from "react";
import useAlert from "../../../CustomHooks/useAlert";
import CurrencyList from 'currency-list'
import Select from "react-select";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {fetchPaymentSettingsData} from "../../../redux/actions/SettingsActions";
import ReactQuill from "react-quill";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {useParams} from "react-router-dom";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import Img from "../../../utils/Image";

const paymentMethodTypes = [{name: "Custom", value: "custom"},
    {name: "Credit/Debit Card", value: "card_stripe"},
    {name: "Paypal", value: "paypal"},
    {name: "Google Pay", value: "google_pay_stripe"},
    {name: "Link Pay", value: "link_stripe"},]
const PaymentSettingsPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPaymentSettingsData())
    }, []);
    const {id} = useParams()
    const [showAlert, AlertComponent] = useAlert();
    const [settingData, setSettingData] = useState(
        [{name: "minimum_order", value: "0", enabled: true},
            {name: "free_delivery", value: "0", enabled: true},])
    const [formData, setFormData] = useState({
        title: "",
        message: "",
        parent_id: 0,
        enabled: true,
        img_required: false,
        img_title: "",
        default_selected: false,
        additional_shipping: 0,
        free_shipping: false,
        discount: 0,
        type: "custom",
        minimum_order: 0,
    })
    const [visualData, setVisualData] = useState({
        icon_image_only: false,
        button_color: "#000000",
        text_color: "#ffffff",
    })
    const [iconImage, setIconImage] = useState({})

    const [isPaymentMethod, setIsPaymentMethod] = useState(false)

    let {paymentSettings, success} = useSelector((state) => state.paymentSettingsData);
    let {payment_settings, payment_options} = paymentSettings
    paymentSettings = payment_settings
    useEffect(() => {
        if (success && paymentSettings.length === settingData.length) {
            setSettingData(paymentSettings)
        }
    }, [success]);
    useEffect(() => {
        if (id && payment_options) {
            payment_options = flattenPaymentOptions(payment_options)
            const paymentOption = payment_options.find(x => x.id === Number(id))
            const formDataKeys = [
                "title", "message", "parent_id", "enabled", "img_required", "img_title",
                "default_selected", "additional_shipping", "free_shipping", "discount",
                "type", "minimum_order"
            ]

            const visualDataKeys = [
                "icon_image_only", "button_color", "text_color"
            ]
            const newFormData = formDataKeys.reduce((acc, key) => {
                if (key in paymentOption) {
                    acc[key] = paymentOption[key]
                }
                return acc
            }, {})
            const newVisualData = visualDataKeys.reduce((acc, key) => {
                if (key in paymentOption) {
                    acc[key] = paymentOption[key]
                }
                return acc
            }, {})
            setFormData(newFormData)
            setVisualData({...newVisualData, icon_image: paymentOption.icon_image})
            setIconImage({})
            if (paymentOption.parent_id !== 0) {
                setIsPaymentMethod(true)
            } else {
                setIsPaymentMethod(false)
            }
        } else {
            setIconImage({})
        }
    }, [id, payment_options]);

    function flattenPaymentOptions(paymentOptions) {
        let flattenedOptions = [];

        function flatten(option) {
            const {child, ...restOption} = option; // Destructure to separate child from the rest of the properties
            flattenedOptions.push(restOption); // Push the current option without the child property

            if (child && child.length) {
                child.forEach(flatten); // Recursively flatten each child
            }
        }

        paymentOptions.forEach(flatten); // Start the flattening process for each top-level option
        return flattenedOptions;
    }

    const submit = async (e) => {
        try {
            const response = await axios.post('/admin/payments/update/settings', settingData);
            showAlert("success", "Payment Settings Updated")
        } catch (error) {
            showAlert("error", error.message)
        }

    }
    const submitPaymentOption = async (e) => {
        e.preventDefault()
        const uploadData = {...formData, ...visualData, icon_image: iconImage}
        try {
            if (id) {
                const response = await axios.post('/admin/payments/update/option/' + id, uploadData);
                showAlert("success", "Payment Option Updated")
            } else {
                if (isPaymentMethod && formData.parent_id === 0) {
                    showAlert('error', 'Please Select an Order Method')
                } else {
                    const response = await axios.post('/admin/payments/add/option', uploadData);
                    showAlert("success", "Payment Option Added")
                }
            }
            dispatch(fetchPaymentSettingsData())
        } catch (error) {
            showAlert("error", error.message)
        }
    }

    const handleChange = (value, name) => {
        setSettingData(settingData.map(data => {
            if (data.name === name) {
                return {
                    ...data,
                    value: value
                };
            }
            return data;
        }))
    }

    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                {!id && <div className="flex flex-col">
                    <div className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Minimum Order (Base Currency) (Applied Before Checkout)</span>
                        <input type="number" value={settingData.find(x => x.name === "minimum_order").value}
                               onChange={(e) => handleChange(e.target.value, "minimum_order")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>

                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span
                                className="text-lg font-public-sans font-bold">Delivery Free Over (Base Currency)</span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "free_delivery").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "free_delivery") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "free_delivery").enabled}/>
                            </div>
                        </div>
                        <input type="number" value={settingData.find(x => x.name === "free_delivery").value}
                               onChange={(e) => handleChange(e.target.value, "free_delivery")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex justify-center p-4 mx-2">
                        <button
                            className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            onClick={submit}>{"Update Payment Settings"}</button>
                    </div>
                </div>}
                <form onSubmit={submitPaymentOption} className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Payment Option</span>
                    <div className="w-full">
                        <label htmlFor="type">Type</label>
                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500" required
                                value={isPaymentMethod ? "true" : "false"}
                                id="type" onChange={(e) => setIsPaymentMethod(e.target.value === "true")}>
                            <option disabled selected>Select Type</option>
                            <option value={false}>Order Method</option>
                            <option value={true}>Payment Method</option>
                        </select>
                    </div>
                    <div className="w-full">
                        <label htmlFor="title">Title</label>
                        <input type="text" value={formData.title} required
                               onChange={(e) => setFormData({...formData, title: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    {isPaymentMethod === true && <>
                        <div className="w-full">
                            <label htmlFor="message">Message (optional)</label>
                            <ReactQuill id="message" value={formData.message}
                                        onChange={(value) => setFormData({...formData, message: value})}/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="type">Order Method</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500" required
                                    value={formData.parent_id}
                                    id="type" onChange={(e) => setFormData({...formData, parent_id: e.target.value})}>
                                <option value={0} disabled selected>Select Order Method</option>
                                {payment_options.filter(x => x.parent_id === 0).map(x => (
                                    <option value={x.id}>{x.title}</option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full">
                            <label htmlFor="payment_method_type">Payment Method Type</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500" required
                                    value={formData.type}
                                    id="payment_method_type"
                                    onChange={(e) => setFormData({...formData, type: e.target.value})}>
                                {paymentMethodTypes.map(x => (
                                    <option value={x.value}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="checkbox-wrapper-14 gap-3 flex justify-between items-center">
                            <span>Image Upload {formData.img_required === true ? "is required" : "not required"}</span>
                            <input type="checkbox" className="switch"
                                   onChange={(e) => setFormData({
                                       ...formData,
                                       img_required: e.target.checked
                                   })}
                                   checked={formData.img_required === true}/>
                        </div>
                        {formData.img_required && <div className="w-full">
                            <label htmlFor="title">Image Upload Title</label>
                            <input type="text" value={formData.img_title} required
                                   onChange={(e) => setFormData({...formData, img_title: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>}
                    </>}
                    <div className="checkbox-wrapper-14 gap-3 flex justify-between items-center">
                        <span>{formData.default_selected === false && "Not "}Default Selected</span>
                        <input type="checkbox" className="switch"
                               onChange={(e) => setFormData({
                                   ...formData,
                                   default_selected: e.target.checked
                               })}
                               checked={formData.default_selected === true}/>
                    </div>
                    {isPaymentMethod === true && <>
                        <div className="w-full">
                            <label htmlFor="title">Discount Percentage for this Method</label>
                            <input type="text" value={formData.discount} required
                                   onChange={(e) => setFormData({...formData, discount: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="checkbox-wrapper-14 gap-3 flex justify-between items-center">
                            <span>Shipping is {formData.free_shipping === false && "not "}free for this method</span>
                            <input type="checkbox" className="switch"
                                   onChange={(e) => setFormData({
                                       ...formData,
                                       free_shipping: e.target.checked
                                   })}
                                   checked={formData.free_shipping === true}/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="minimum_order">Minimum Order for this Method</label>
                            <input type="text" value={formData.minimum_order} required
                                   onChange={(e) => setFormData({...formData, minimum_order: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        {!formData.free_shipping && <div className="w-full">
                            <label htmlFor="title">Additional Shipping Cost for this Method</label>
                            <input type="text" value={formData.additional_shipping} required
                                   onChange={(e) => setFormData({...formData, additional_shipping: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>}</>}
                    <div className="flex flex-col rounded gap-4 border-2 border-black p-5">
                        <div className="text-center font-bold text-lg">Visual Data</div>
                        <div>
                            <label htmlFor="text-color"
                                   style={{marginRight: '10px'}}>Text Color</label>
                            <input
                                id="text-color"
                                type={"color"}
                                value={visualData.text_color}
                                onChange={(e) => setVisualData({...visualData, text_color: e.target.value})}
                                className={`mt-0.5 rounded-md w-10 p-0 border border-gray-500`}
                                placeholder="Text Color"
                            />
                        </div>
                        <div>
                            <label htmlFor="text-color"
                                   style={{marginRight: '10px'}}>Button Color</label>
                            <input
                                id="button-color"
                                type={"color"}
                                value={visualData.button_color}
                                onChange={(e) => setVisualData({...visualData, button_color: e.target.value})}
                                className={`mt-0.5 rounded-md w-10 p-0 border border-gray-500`}
                                placeholder="Button Color"
                            />
                        </div>
                        <ImageUploadComponent required={false} title={'Upload Icon Image for this method'}
                                              setImage={setIconImage}/>
                        {id && Object.keys(iconImage).length === 0 && <div className="relative w-full">
                            <div
                                className="flex w-full justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-56">
                                <Img src={visualData.icon_image} alt="Preview"
                                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                            </div>
                        </div>}
                        <div className="checkbox-wrapper-14 gap-3 flex justify-between items-center">
                            <span>{visualData.icon_image_only === false ? "Showing both image & title" : "Only Image is shown"}</span>
                            <input type="checkbox" className="switch"
                                   onChange={(e) => setVisualData({
                                       ...visualData,
                                       icon_image_only: e.target.checked
                                   })}
                                   checked={visualData.icon_image_only === true}/>
                        </div>
                    </div>
                    <div className="flex justify-center p-4 mx-2">
                        <button
                            className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{id ? "Update" : "Add"} Payment Option
                        </button>
                    </div>
                </form>
                {success && !id && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={flattenPaymentOptions(payment_options)} tableName="Payment Options"/>
                </div>}
            </div>
        </React.Fragment>
    )
}
export default PaymentSettingsPage