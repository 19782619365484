import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {useSelector} from "react-redux";

function RichTextEditor({value, onChange, height = 500, ...props}) {
    const {apiSettings, success} = useSelector((state) => state.apiSettingsData);
    const tinymce = apiSettings.find(x => x.name === "tinymce")
    const apiKey = tinymce.secret_key
    return (<>
            {(apiKey !== "" && tinymce.enabled) &&
                <div className="overflow-hidden my-2" style={{height: height - 26 + "px"}}>
                    <Editor
                        {...props}
                        apiKey={apiKey}
                        value={value}
                        onEditorChange={onChange}
                        init={{
                            height: height,
                            menubar: 'file edit view insert format tools table help',
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                                'emoticons template textcolor colorpicker textpattern',
                                'importcss', "image", "media", "table", "code",
                                // Ensure you have all the necessary plugins for the features you want to include
                            ],
                            toolbar: 'undo redo | styles | bold italic underline strikethrough | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | link image media | ' +
                                'forecolor backcolor emoticons | ' +
                                'cut copy paste | charmap | fullscreen | ' +
                                'insertdatetime | table | code | ' + // Added 'table' and 'code' here
                                'subscript superscript | ' + // Added subscript and superscript
                                'removeformat | help', // Added removeformat for clear formatting

                            toolbar_mode: 'sliding',
                            /* Image tool options */
                            image_advtab: true, // Enables advanced tab for image dialog
                            /* Media plugin options */
                            media_alt_source: true, // Enables alternate source input field in the media dialog
                            media_poster: true, // Enables poster URL input field in the media dialog
                            media_live_embeds: true,
                            contextmenu: "link image imagetools table spellchecker",
                        }}
                    />
                </div>}
            {(apiKey === "" || !tinymce.enabled) && <ReactQuill value={value}
                                    onChange={onChange} {...props}/>}
        </>

    );
}

export default RichTextEditor;
