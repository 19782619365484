import React, {useEffect, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserData, loginUser, refreshUser} from "../../redux/actions/UserActions";
import AlertBox from "../../utils/providers/AlertProvider/AlertBox";

export function LoginScreen() {
    const [formData, setFormData] = useState({email: "", password: ""})
    const dispatch = useDispatch()
    const loginUserData = useSelector((state) => state.loginUser);
    const auth = useSelector((state) => state.auth);
    const {loading, success, error} = loginUserData
    const generalSettingsData = useSelector((state) => state.generalSettingsData);
    const {generalSettings} = generalSettingsData;
    useEffect(() => {
        if (generalSettingsData.success) {
            document.title = `Login | ${generalSettings.app_name} | Panel`;
        }
    }, [generalSettingsData.success]);
    const submit = (e) => {
        e.preventDefault()
        dispatch(loginUser(formData))
    }
    if (auth === true) {
        return <Navigate to="/home/dashboard"/>
    }
    return (
        <div className="flex flex-col justify-center bg-gray-500 items-center h-screen w-screen">
            {error && <AlertBox type="error" message={error}/>}
            <div className="flex p-4 mb-2 bg-gray-100 flex-row justify-center items-center w-full">
                <span className="material-symbols-rounded mr-2 mt-0.5">login</span>
                <div className="text-xl font-bold">Log In</div>
            </div>
            <div
                className="flex w-full mt-7 flex-col justify-center items-center bg-gray-100 rounded font-poppins gap-5 p-5 mx-2">
                <span>Welcome to {generalSettings.app_name}</span>
                <form onSubmit={submit}>
                    <div className="flex flex-col justify-center items-center gap-5">
                        <div className="w-full">
                            <label htmlFor="email">Email</label>
                            <input type="text" id="email" required value={formData.email}
                                   onChange={(e) => setFormData((prevState) => ({
                                       ...prevState,
                                       email: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" required value={formData.password}
                                   onChange={(e) => setFormData((prevState) => ({
                                       ...prevState,
                                       password: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="flex flex-row justify-between w-full">
                            <div className="self-start"><input type="checkbox"/> Remember me</div>
                            <div className="self-end"><Link to="/forgot-password">Forgot password?</Link></div>
                        </div>
                        <button
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl my-15 mx-10 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{loading ? "Loading..." : "Log In"}</button>
                    </div>
                </form>

            </div>
        </div>
    )
}