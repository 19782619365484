export const SLIDER_DATA_REQUEST = "SLIDER_DATA_REQUEST"
export const SLIDER_DATA_SUCCESS = "SLIDER_DATA_SUCCESS"
export const SLIDER_DATA_ERROR = "SLIDER_DATA_ERROR"

export const BANNERS_DATA_REQUEST = "BANNERS_DATA_REQUEST"
export const BANNERS_DATA_SUCCESS = "BANNERS_DATA_SUCCESS"
export const BANNERS_DATA_ERROR = "BANNERS_DATA_ERROR"

export const FOOTER_LINKS_REQUEST = "FOOTER_LINKS_REQUEST"
export const FOOTER_LINKS_SUCCESS = "FOOTER_LINKS_SUCCESS"
export const FOOTER_LINKS_ERROR = "FOOTER_LINKS_ERROR"

export const SLIDER_BANNERS_DATA_REQUEST = "SLIDER_BANNERS_DATA_REQUEST"
export const SLIDER_BANNERS_DATA_SUCCESS = "SLIDER_BANNERS_DATA_SUCCESS"
export const SLIDER_BANNERS_DATA_ERROR = "SLIDER_BANNERS_DATA_ERROR"

export const SLIDER_UPDATE_REQUEST = "SLIDER_UPDATE_REQUEST"
export const SLIDER_UPDATE_SUCCESS = "SLIDER_UPDATE_SUCCESS"
export const SLIDER_UPDATE_ERROR = "SLIDER_UPDATE_ERROR"

export const MENU_ITEMS_REQUEST = "MENU_ITEMS_REQUEST"
export const MENU_ITEMS_SUCCESS = "MENU_ITEMS_SUCCESS"
export const MENU_ITEMS_ERROR = "MENU_ITEMS_ERROR"


export const FEATURES_ITEMS_REQUEST = "FEATURES_ITEMS_REQUEST"
export const FEATURES_ITEMS_SUCCESS = "FEATURES_ITEMS_SUCCESS"
export const CONTENT_EDITORS_SUCCESS = "CONTENT_EDITORS_SUCCESS"
export const ITEM_GROUPS_SUCCESS = "ITEM_GROUPS_SUCCESS"
export const ITEM_GROUPS_OF_ITEM_ORGANIZER_REQUEST = "ITEM_GROUPS_OF_ITEM_ORGANIZER_REQUEST"

export const ITEM_GROUPS_OF_ITEM_ORGANIZER_SUCCESS = "ITEM_GROUPS_OF_ITEM_ORGANIZER_SUCCESS"
export const ITEM_GROUPS_OF_ITEM_ORGANIZER_ERROR = "ITEM_GROUPS_OF_ITEM_ORGANIZER_ERROR"
export const ITEMS_OF_GROUP_REQUEST = "ITEMS_OF_GROUP_REQUEST"
export const ITEMS_OF_GROUP_SUCCESS = "ITEMS_OF_GROUP_SUCCESS"
export const ITEMS_OF_GROUP_ERROR = "ITEMS_OF_GROUP_ERROR"

export const ITEMS_ORGANIZER_REQUEST = "ITEMS_ORGANIZER_REQUEST"
export const ITEMS_ORGANIZER_SUCCESS = "ITEMS_ORGANIZER_SUCCESS"
export const ITEMS_ORGANIZER_ERROR = "ITEMS_ORGANIZER_ERROR"

export const CUSTOM_CODES_SUCCESS = "CUSTOM_CODES_SUCCESS"
export const FEATURES_ITEMS_ERROR = "FEATURES_ITEMS_ERROR"
export const TEAM_MEMBER_ITEMS_REQUEST = "TEAM_MEMBER_ITEMS_REQUEST"
export const TEAM_MEMBER_ITEMS_SUCCESS = "TEAM_MEMBER_ITEMS_SUCCESS"
export const TEAM_MEMBER_ITEMS_ERROR = "TEAM_MEMBER_ITEMS_ERROR"


export const COUPONS_DATA_REQUEST = "COUPONS_DATA_REQUEST"
export const COUPONS_DATA_SUCCESS = "COUPONS_DATA_SUCCESS"
export const COUPONS_DATA_ERROR = "COUPONS_DATA_ERROR"


export const TOP_BARS_DATA_REQUEST = "TOP_BARS_DATA_REQUEST"
export const TOP_BARS_DATA_SUCCESS = "TOP_BARS_DATA_SUCCESS"
export const TOP_BARS_DATA_ERROR = "TOP_BARS_DATA_ERROR"


export const TOP_BAR_DATA_REQUEST = "TOP_BAR_DATA_REQUEST"
export const TOP_BAR_DATA_SUCCESS = "TOP_BAR_DATA_SUCCESS"
export const TOP_BAR_DATA_ERROR = "TOP_BAR_DATA_ERROR"

export const MENU_ITEMS_UPDATE_REQUEST = "MENU_ITEMS_UPDATE_REQUEST"
export const MENU_ITEMS_UPDATE_SUCCESS = "MENU_ITEMS_UPDATE_SUCCESS"
export const MENU_ITEMS_UPDATE_ERROR = "MENU_ITEMS_UPDATE_ERROR"

export const MENU_ORDER_UPDATE_REQUEST = "MENU_ORDER_UPDATE_REQUEST"
export const MENU_ORDER_UPDATE_SUCCESS = "MENU_ORDER_UPDATE_SUCCESS"
export const MENU_ORDER_UPDATE_ERROR = "MENU_ORDER_UPDATE_ERROR"


export const HOME_ITEMS_REQUEST = "HOME_ITEMS_REQUEST"
export const HOME_ITEMS_SUCCESS = "HOME_ITEMS_SUCCESS"
export const HOME_ITEMS_ERROR = "HOME_ITEMS_ERROR"

export const PAGE_ITEMS_REQUEST = "PAGE_ITEMS_REQUEST"
export const PAGE_ITEMS_SUCCESS = "PAGE_ITEMS_SUCCESS"
export const PAGE_ITEMS_ERROR = "PAGE_ITEMS_ERROR"


export const CONTENT_ITEMS_REQUEST = "CONTENT_ITEMS_REQUEST"
export const CONTENT_ITEMS_SUCCESS = "CONTENT_ITEMS_SUCCESS"
export const CONTENT_ITEMS_ERROR = "CONTENT_ITEMS_ERROR"

export const HOME_ITEMS_UPDATE_REQUEST = "HOME_ITEMS_UPDATE_REQUEST"
export const HOME_ITEMS_UPDATE_SUCCESS = "HOME_ITEMS_UPDATE_SUCCESS"
export const HOME_ITEMS_UPDATE_ERROR = "HOME_ITEMS_UPDATE_ERROR"

export const HOME_ORDER_UPDATE_REQUEST = "HOME_ORDER_UPDATE_REQUEST"
export const HOME_ORDER_UPDATE_SUCCESS = "HOME_ORDER_UPDATE_SUCCESS"
export const HOME_ORDER_UPDATE_ERROR = "HOME_ORDER_UPDATE_ERROR"
