import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "grapesjs/dist/css/grapes.min.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./store";
import {AlertProvider} from "./utils/providers/AlertProvider";

store.subscribe(() => store.getState())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
    <AlertProvider>
        <App/>
    </AlertProvider>
</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
