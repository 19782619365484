import React, {useEffect, useState} from "react";
import useAlert from "../../../CustomHooks/useAlert";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {fetchAPISettingsData} from "../../../redux/actions/SettingsActions";

const APISettingsPage = () => {
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    const [settingData, setSettingData] = useState(
        [{name: "header_code", public_key: "", secret_key: "", enabled: true},
            {name: "gtag", public_key: "", secret_key: "", enabled: true},
            {name: "tinymce", public_key: "", secret_key: "", enabled: true},
            {name: "currency", public_key: "", secret_key: "", enabled: true},
            {name: "facebook_login", public_key: "", secret_key: "", enabled: true},
            {name: "google_login", public_key: "", secret_key: "", enabled: true},
        ])
    const {apiSettings, success} = useSelector((state) => state.apiSettingsData);
    useEffect(() => {
        if (success) {
            const updatedSettings = settingData.map(setting => {
                if (setting.name === "header_code") {
                    const apiData = apiSettings.find(apiSetting => apiSetting.name === "header_code")
                    if (apiData) {
                        return {
                            ...setting,
                            public_key: apiData.public_key,
                            secret_key: apiData.secret_key,
                            enabled: apiData.enabled,
                        };
                    }
                } else if (setting.name === "gtag") {
                    const apiData = apiSettings.find(apiSetting => apiSetting.name === "gtag")
                    if (apiData) {
                        return {
                            ...setting,
                            public_key: apiData.public_key,
                            secret_key: apiData.secret_key,
                            enabled: apiData.enabled,
                        };
                    }
                } else if (setting.name === "tinymce") {
                    const apiData = apiSettings.find(apiSetting => apiSetting.name === "tinymce")
                    if (apiData) {
                        return {
                            ...setting,
                            public_key: apiData.public_key,
                            secret_key: apiData.secret_key,
                            enabled: apiData.enabled,
                        };
                    }
                } else if (setting.name === "currency") {
                    const apiData = apiSettings.find(apiSetting => apiSetting.name === "currency")
                    return {
                        ...setting,
                        public_key: apiData.public_key,
                        secret_key: apiData.secret_key,
                        enabled: apiData.enabled,
                    };
                } else if (setting.name === "facebook_login") {
                    const apiData = apiSettings.find(apiSetting => apiSetting.name === "facebook_login")
                    if (apiData) {
                        return {
                            ...setting,
                            public_key: apiData.public_key,
                            secret_key: apiData.secret_key,
                            enabled: apiData.enabled,
                        };
                    }
                } else if (setting.name === "google_login") {
                    const apiData = apiSettings.find(apiSetting => apiSetting.name === "google_login")
                    if (apiData) {
                        return {
                            ...setting,
                            public_key: apiData.public_key,
                            secret_key: apiData.secret_key,
                            enabled: apiData.enabled,
                        };
                    }
                }
                return setting;
            });
            setSettingData(updatedSettings)
        }
    }, [success]);

    const submit = async (e) => {
        try {
            const response = await axios.post('/admin/settings/update/apis', settingData);
            showAlert("success", "API Settings Updated")
            dispatch(fetchAPISettingsData())
        } catch (error) {
            showAlert("error", error.message)
        }
    }

    const handleChange = (value, name, key = "pub") => {
        setSettingData(settingData.map(data => {
            if (key === "pub" && data.name === name) {
                return {
                    ...data,
                    public_key: value
                };
            } else if (key === "sec" && data.name === name) {
                return {
                    ...data,
                    secret_key: value
                };
            }
            return data;
        }))
    }

    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <div className="flex flex-col">
                    <div className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span className="text-lg font-public-sans">Header Code</span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "header_code").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "header_code") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "header_code").enabled}/>
                            </div>
                        </div>
                        <textarea rows={4} value={settingData.find(x => x.name === "header_code").public_key}
                                  onChange={(e) => handleChange(e.target.value, "header_code")}
                                  className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span className="text-lg font-public-sans">Google Analytics Tag</span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "gtag").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "gtag") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "gtag").enabled}/>
                            </div>
                        </div>
                        <input value={settingData.find(x => x.name === "gtag").public_key} placeholder={"G-TAG"}
                               onChange={(e) => handleChange(e.target.value, "gtag")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span className="text-lg font-public-sans">Currency Exchange Rates API Key <a
                                target="_blank"
                                className="cursor-pointer rounded p-2 text-white bg-black hover:bg-green-600 transition-all"
                                href="https://app.exchangerate-api.com/sign-up">Get API Key</a></span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "currency").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "currency") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "currency").enabled}/>
                            </div>
                        </div>
                        <input value={settingData.find(x => x.name === "currency").secret_key}
                               onChange={(e) => handleChange(e.target.value, "currency", "sec")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span className="text-lg font-public-sans">TinyMce API Key (For Rich Text Editing) <a
                                target="_blank"
                                className="cursor-pointer rounded p-2 text-white bg-black hover:bg-green-600 transition-all"
                                href="https://www.tiny.cloud/auth/signup/">Get API Key</a></span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "tinymce").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "tinymce") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "tinymce").enabled}/>
                            </div>
                        </div>
                        <input value={settingData.find(x => x.name === "tinymce").secret_key}
                               onChange={(e) => handleChange(e.target.value, "tinymce", "sec")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span className="text-lg font-public-sans">Facebook Login App Credentials</span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "facebook_login").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "facebook_login") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "facebook_login").enabled}/>
                            </div>
                        </div>
                        <input value={settingData.find(x => x.name === "facebook_login").public_key}
                               placeholder="Public Key"
                               onChange={(e) => handleChange(e.target.value, "facebook_login")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        <input value={settingData.find(x => x.name === "facebook_login").secret_key}
                               placeholder="Secret Key"
                               onChange={(e) => handleChange(e.target.value, "facebook_login", "sec")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between">
                            <span className="text-lg font-public-sans">Google Login Credentials</span>
                            <div className="checkbox-wrapper-14 gap-3 flex items-center">
                                <span>{settingData.find(x => x.name === "google_login").enabled ? "Enabled" : "Disabled"}</span>
                                <input type="checkbox" className="switch"
                                       onChange={(e) => setSettingData(settingData.map(data => {
                                           if (data.name === "google_login") {
                                               return {
                                                   ...data,
                                                   enabled: e.target.checked
                                               };
                                           }
                                           return data;
                                       }))}
                                       checked={settingData.find(x => x.name === "google_login").enabled}/>
                            </div>
                        </div>
                        <input value={settingData.find(x => x.name === "google_login").public_key}
                               placeholder="Public Key"
                               onChange={(e) => handleChange(e.target.value, "google_login")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        <input value={settingData.find(x => x.name === "google_login").secret_key}
                               placeholder="Secret Key"
                               onChange={(e) => handleChange(e.target.value, "google_login", "sec")}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex justify-center p-4 mx-2">
                        <button
                            className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            onClick={submit}>{"Update API Settings"}</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default APISettingsPage