import React, { useState, useMemo, useCallback } from "react";
import svgIcons from "./svgIcons.json";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import debounce from 'lodash.debounce';

const height = 45; // Height of each option row
const IconListComponent = (props) => {
    const { options, children, maxHeight, getValue } = props;

    if (!children.length) {
        // No options to display
        return <div style={{ padding: '10px', textAlign: 'center' }}>No icons found!</div>;
    }

    const [value] = getValue();
    const initialOffset = options.findIndex(option => option.value === value?.value) * height;

    return (
        <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
    );
};

const IconsSelector = ({ formData, setFormData }) => {
    const [searchInput, setSearchInput] = useState('');

    const Option = ({ innerProps, label }) => (
        <div {...innerProps} className="flex justify-between items-center">
            <span className="ml-2">{label}</span>
            <span className="font-light mr-2">
                <div dangerouslySetInnerHTML={{ __html: svgIcons[label] }} />
            </span>
        </div>
    );

    const debouncedSetSearchInput = useMemo(
        () => debounce((input) => setSearchInput(input), 300),
        []
    );

    const handleInputChange = useCallback((inputValue) => {
        debouncedSetSearchInput(inputValue);
    }, [debouncedSetSearchInput]);

    const filteredOptions = useMemo(() => {
        return Object.keys(svgIcons)
            .filter(iconName => iconName.toLowerCase().includes(searchInput.toLowerCase()))
            .map(iconName => ({
                value: getPathSvg(iconName),
                label: iconName
            }));
    }, [searchInput]);

    return (
        <div className="w-full">
            <label className="flex justify-between items-center" htmlFor="icon">
                <span>Icon (Out of {Object.keys(svgIcons).length} icons)</span>
                <div dangerouslySetInnerHTML={{ __html: svgIcons[formData.icon_name] }} />
            </label>
            <Select
                className="mt-1 w-full border border-gray-500"
                id="icon"
                isSearchable={true}
                options={filteredOptions}
                components={{ Option, MenuList: IconListComponent }}
                required={true}
                value={{ label: formData.icon_name, value: formData.icon_svg }}
                onChange={(e) => setFormData((prevState) => ({
                    ...prevState,
                    icon_name: e.label,
                    icon_svg: getPathSvg(e.label)
                }))}
                onInputChange={handleInputChange}
            />
        </div>
    );
};

export default IconsSelector;

// The rest of your code remains unchanged.

export const getPathSvg = (icon) => {
    const svgString = svgIcons[icon];
    const dValueRegex = /<path[^>]*d="([^"]*)"[^>]*>/g;
    let matches;
    let combinedDValue = '';

    // Use a loop to extract all matches
    while ((matches = dValueRegex.exec(svgString)) !== null) {
        // If a match is found, add it to the combinedDValue string
        // You might also add a space or other delimiter if necessary
        combinedDValue += ' ' + matches[1];
    }

    // If no paths are found, return null; otherwise, trim the combinedDValue
    return combinedDValue === '' ? null : combinedDValue.trim();
};
