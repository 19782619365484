import { useState } from 'react';
import AlertBox from '../utils/providers/AlertProvider/AlertBox';

const useAlert = () => {
    const [alert, setAlert] = useState(null);

    const showAlert = (type, message) => {
        setAlert({ type, message });
        setTimeout(() => {
            setAlert(null);
        }, 5000);  // AlertProvider will automatically disappear after 5 seconds.
    };

    const AlertComponent = alert ? <AlertBox type={alert.type} message={alert.message} /> : null;

    return [showAlert, AlertComponent];
};

export default useAlert;