import React, {useEffect, useRef, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import {fetchPageManager, fetchPageManagerByItemId} from "../../../redux/actions/PagesDataActions";
import useAlert from "../../../CustomHooks/useAlert";
import axios from "axios";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import DragDropHandler from "../../../Components/DragDropHandler";

const effects = [
    {name: "simple", title: "Simple"},
    {name: "fade", title: "Fade"},
    {name: "cube", title: "Cube"},
    {name: "flip", title: "Flip"},
    {name: "coverflow", title: "Coverflow"},
    {name: "cards", title: "Cards"},
];
const types = [{name: "Banner", value: "banner"},
    {name: "Slider", value: "slider"},
    {name: "Categories", value: "categories"},
    {name: "Items Organizer", value: "items_organizer"},
    {name: "Category Products", value: "category_products"},
    {name: "All Collections", value: "all_collections"},
    {name: "Collection Products", value: "collection_products"},
    {name: "Top Rated Products", value: "top_rated"},
    {name: "Most Sold Products", value: "most_sold"},
    {name: "Free Delivery Products", value: "free_delivery"},
    {name: "Discounted Products", value: "discounted"},
    {name: "Latest Products", value: "latest_products"},
]
const PageManager = ({id, slug}) => {
    const dispatch = useDispatch()
    const [showAlert, AlertComponent] = useAlert();
    useEffect(() => {
        dispatch(fetchPageManager(slug))
    }, []);
    const [formData, setFormData] = useState({
        name: "",
        show_name: false,
        is_slider: false,
        slider_speed: 1500,
        slider_effect: "simple",
        type: "",
        type_id: -1,
        max_rows: 0,
        slug: slug ? slug : ""
    })
    const {banners} = useSelector((state) => state.bannersData);
    const {categories} = useSelector((state) => state.categoryList);
    const {sliders} = useSelector((state) => state.slidersData);
    const {collections} = useSelector((state) => state.collectionList);
    const {itemsOrganizers} = useSelector((state) => state.itemsOrganizersReducer);
    const {pageItems, success, loading} = useSelector((state) => state.pageItemsReducer);

    useEffect(() => {
        if (id && success) {
            const pageItem = pageItems.find(x => x.id === Number(id))
            setFormData(pageItem)
        }
    }, [id, success]);

    const submit = async () => {
        if (formData.type === "") {
            showAlert('error', 'Please Select a Type')
        } else if (formData.name === "") {
            showAlert('error', 'Please write some title to identify')
        } else if (formData.type_id === -1 && formData.type !== "discounted" && formData.type !== "top_rated" && formData.type !== "most_sold" && formData.type !== "latest_products" && formData.type !== "all_collections") {
            showAlert('error', 'Please Select ' + formData.type)
        } else if (formData.max_rows === 0 && formData.type !== "banner" && formData.type !== "slider" && formData.type !== "items_organizer") {
            showAlert('error', 'Please Select Number of Rows')
        } else {
            if (id) {
                try {
                    const response = await axios.post('/admin/pages/update/items/' + id, formData);
                    showAlert("success", "Page Item Updated")
                    dispatch(fetchPageManagerByItemId(id))
                } catch (e) {
                    showAlert("error", e.message)
                }
            } else {
                try {
                    const response = await axios.post("/admin/pages/update/items", formData)
                    showAlert("success", "Page Item Added Successfully!")
                    dispatch(fetchPageManager(slug))
                } catch (e) {
                    showAlert("error", e.message)
                }
            }
        }
    }

    const submitArrangement = async (reorderedArray) => {
        const response = await axios.post("/admin/pages/items/update/order", reorderedArray)
        showAlert("success", "Page Items Rearranged Successfully!")
    }

    const prevTypeRef = useRef(formData.type);
    const prevTypeIdRef = useRef(formData.type_id);

    useEffect(() => {
        if (prevTypeRef.current !== formData.type && prevTypeIdRef.current === formData.type_id) {
            setFormData((prevFormData) => ({...prevFormData, type_id: -1}));
        }
    }, [formData.type]);

    useEffect(() => {
        prevTypeRef.current = formData.type;
        prevTypeIdRef.current = formData.type_id;
    }, [formData.type, formData.type_id]);

    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <div className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Page Item</span>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="order">Title</label>
                        <input type="text" id="order" value={formData.name} required={true}
                               onChange={(e) => setFormData((prevState) => ({...prevState, name: e.target.value}))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="checkbox-wrapper-14 flex justify-between my-2">
                        <span>Show Title</span>
                        <input type="checkbox" className="switch" checked={formData.show_name}
                               onChange={(e) => setFormData({...formData, show_name: e.target.checked})}/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="type">Type</label>
                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                id="type" value={formData.type} required={true}
                                onChange={(e) => setFormData({...formData, type: e.target.value})}>
                            <option value={""} disabled selected>Select Type</option>
                            {types.map(type => (
                                <option key={type.value} value={type.value}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                    {formData.type === "category_products" ?
                        <div className="w-full">
                            <label htmlFor="category">Category</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="category" value={formData.type_id}
                                    onChange={(e) => setFormData({...formData, type_id: e.target.value})}>
                                <option value={-1} disabled selected>Select Category</option>
                                {categories.map(value => (
                                    <option key={value.id} value={value.id}>{value.name}</option>
                                ))}
                            </select>
                        </div> : formData.type === "items_organizer" ?
                            <div className="w-full">
                                <label htmlFor="items_organizer">Items Organizers</label>
                                <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                        id="items_organizer" value={formData.type_id}
                                        onChange={(e) => setFormData({...formData, type_id: e.target.value})}>
                                    <option value={-1} disabled selected>Select Items Organizer</option>
                                    {itemsOrganizers.map(value => (
                                        <option key={value.id} value={value.id}>{value.name}</option>
                                    ))}
                                </select>
                            </div> : formData.type === "collection_products" ?
                                <div className="w-full">
                                    <label htmlFor="category">Collection</label>
                                    <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                            id="category" value={formData.type_id}
                                            onChange={(e) => setFormData({...formData, type_id: e.target.value})}>
                                        <option value={-1} disabled selected>Select Collection</option>
                                        {collections.map(value => (
                                            <option key={value.id} value={value.id}>{value.name}</option>
                                        ))}
                                    </select>
                                </div> : formData.type === "slider" ?
                                    <div className="w-full">
                                        <label htmlFor="slider">Slider</label>
                                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                                id="banner" value={formData.type_id}
                                                onChange={(e) => setFormData({...formData, type_id: e.target.value})}>
                                            <option value={-1} disabled selected>Select Slider</option>
                                            {sliders.map(value => (
                                                <option key={value.id} value={value.id}>{value.name}</option>
                                            ))}
                                        </select>
                                    </div> : formData.type === "banner" ?
                                        <div className="w-full">
                                            <label htmlFor="banner">Banner</label>
                                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                                    id="banner" value={formData.type_id}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        type_id: e.target.value
                                                    })}>
                                                <option value={-1} disabled selected>Select Banner</option>
                                                {banners.map(value => (
                                                    <option key={value.id} value={value.id}>{value.name}</option>
                                                ))}
                                            </select>
                                        </div> : formData.type === "categories" ? <div className="w-full">
                                            <label htmlFor="categories">Choose All/Parent Category</label>
                                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                                    id="categories" value={formData.type_id}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        type_id: e.target.value
                                                    })}>
                                                <option value={-1} disabled selected>Select Category</option>
                                                <option value={-10}>All Categories</option>
                                                {categories.map(value => (
                                                    <option key={value.id} value={value.id}>{value.name}</option>
                                                ))}
                                            </select>
                                        </div> : null}

                    {((formData.type !== "banner" && formData.type !== "slider" && formData.type !== "items_organizer") && formData.max_rows !== 100) &&
                        <>
                            <div className="checkbox-wrapper-14 flex justify-between my-2">
                                <span>Slider</span>
                                <input type="checkbox" className="switch" checked={formData.is_slider}
                                       onChange={(e) => setFormData({...formData, is_slider: e.target.checked})}/>
                            </div>
                            {formData.is_slider && <>
                                <div className="w-full">
                                    <label htmlFor="speed">Slider Speed in Milliseconds</label>
                                    <input type="number" id="speed" value={formData.slider_speed} required={true}
                                           onChange={(e) => setFormData(prevState => ({
                                               ...prevState,
                                               slider_speed: e.target.value
                                           }))}
                                           className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                                </div>
                                <div className="w-full">
                                    <label htmlFor="effect">Slider Effect/Animation</label>
                                    <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                            id="effect" value={formData.slider_effect}
                                            onChange={(e) => setFormData(prevState => ({
                                                ...prevState,
                                                slider_effect: e.target.value
                                            }))}>
                                        <option disabled selected>Select Effect/Animation</option>
                                        {effects.map(x => (
                                            <option key={x.name} value={x.name}>{x.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </>}
                        </>
                    }
                    {(formData.type !== "banner" && formData.type !== "slider" && formData.type !== "items_organizer") &&
                        <div className="w-full">
                            <label htmlFor="max_rows">Max Rows</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="max_rows" value={formData.max_rows}
                                    onChange={(e) => setFormData({...formData, max_rows: parseInt(e.target.value)})}>
                                <option value={0} disabled selected>Select No. of Max Rows</option>
                                {[...Array(5)].map((x, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                ))}
                                {formData.type === 'latest_products' && <option value={100}>All Products</option>}
                            </select>
                        </div>}
                    <button onClick={submit}
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{(id ? "Update" : "Add")} Page Item
                    </button>
                </div>
                {!id && <DragDropHandler ItemComponent={ContentItemComponent} submitArrangement={submitArrangement}
                                     itemsArray={pageItems}/>}
                {!id && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={pageItems.map(({id, name, type, max_rows, updated_at}) => ({
                        id, name,
                        type,
                        max_rows: max_rows === 0 ? "Not Applicable" : max_rows,
                        updated_at
                    }))} tableName="Page Items" showAlert={showAlert}/>
                </div>}
            </div>
        </React.Fragment>
    )
}
export const ContentItemComponent = ({item}) => {
    return (
        <div
            className="w-full flex flex-col justify-center gap-2 items-center">
            <div>{item.name}</div>
            <div>Type: {types.find(x => x.value === item.type).name}</div>
        </div>
    )
}
export default PageManager