import React from "react";
import {capitalizeFirstLetter} from "../../../../../utils/UtilityFunctions";

const DefaultItem = ({item}) => {
    return (
         <div className="flex flex-col gap-2 justify-center items-center">
                <span className="text-lg font-semibold">Edit in Settings</span>
                <span
                    className="text-sm text-gray-500">{item.type !== "" && "Type:"} {capitalizeFirstLetter(item.type, true)}</span>
            </div>)
}
export default DefaultItem