import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMessage, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";

const ContactForm = ({title}) => {
    return (
        <div className="flex flex-col gap-6 w-full relative">
            <span className="absolute inset-0 bg-black bg-opacity-0 z-50"></span>
            {title !== null && title !== "" && <div className="flex flex-col">
                <span className="text-2xl font-bold">{title}</span>
                <span className="h-0.5 w-20 bg-teal-500"></span>
            </div>}
            <form>
                <div
                    className={`flex flex-col bg-gray-100 rounded-lg items-center px-5 py-3 lg:px-10 lg:py-7 gap-6 w-full`}>
                    <div className="w-full">
                        <label className="text-lg text-gray-700" htmlFor="name">Name</label>
                        <div className="flex rounded w-full bg-white items-center py-4 px-4 mt-1.5">
                                <span
                                    className="text-gray-700"><FontAwesomeIcon icon={faUser}/></span>
                            <input type="text" id="name" required
                                   placeholder="Enter Name"
                                   className="border-none outline-none ml-4 w-full"/>
                        </div>
                    </div>
                    <div className="w-full">
                        <label className="text-lg text-gray-700" htmlFor="email">Email Address</label>
                        <div className="flex rounded w-full bg-white items-center py-4 px-4 mt-1.5">
                                <span
                                    className="text-gray-700"><FontAwesomeIcon icon={faEnvelope}/></span>
                            <input type="email" id="email" required
                                   placeholder="Enter Email Address"
                                   className="border-none outline-none ml-4 w-full"/>
                        </div>
                    </div>
                    <div className="w-full">
                        <label className="text-lg text-gray-700" htmlFor="phone">Phone Number</label>
                        <div className="flex rounded w-full bg-white items-center py-4 px-4 mt-1.5">
                                <span
                                    className="text-gray-700"><FontAwesomeIcon icon={faPhone}/></span>
                            <input type="text" id="phone" required
                                   placeholder="Enter Phone Number"
                                   className="border-none outline-none ml-4 w-full"/>
                        </div>
                    </div>
                    <div className="w-full">
                        <label className="text-lg text-gray-700" htmlFor="message">Message</label>
                        <div className="flex rounded w-full bg-white py-4 px-4 mt-1.5">
                                <span
                                    className="text-gray-700"><FontAwesomeIcon icon={faMessage}/></span>
                            <textarea rows={5} id="message" required
                                      placeholder="Enter Message"
                                      className="border-none outline-none ml-4 w-full"/>
                        </div>
                    </div>
                    <button
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl my-15 mx-10 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit">{"Send Message"}</button>
                </div>
            </form>
        </div>
    )
}
export default ContactForm