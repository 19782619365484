'use client'
import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore from 'swiper';
import 'swiper/css';
import {useMediaQuery} from "react-responsive";
import {
    Autoplay, EffectCards, EffectCoverflow, EffectCreative, EffectCube, EffectFade, EffectFlip, Navigation, Pagination
} from "swiper/modules";

// Register the necessary Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, EffectCube, EffectFlip, EffectCards, EffectFade, EffectCoverflow, EffectCreative]);

interface SliderProps {
    children: React.ReactNode;
    effect?: string;
    slidesLgNumber?: number;
    slidesMbNumber?: number;
    isPagination?: boolean;
    isAutoplay?: boolean;
    isNavigation?: boolean;
    loop?: boolean;
    speed?: number;
    gap?: number;
    centeredSlides?: boolean;
    className?: string;
}

const effects = [// Default effects
    {name: 'fade', object: null, creative: false}, {name: 'cube', object: null, creative: false}, {
        name: 'flip',
        object: null,
        creative: false
    }, {name: 'coverflow', object: null, creative: false}, {name: 'cards', object: null, creative: false},

    // Creative effects with static configurations
    {
        name: 'cascadeslide', object: {
            prev: {
                opacity: 0.5, // Fade out for the outgoing slide
                scale: [0.8, 1], // Scale down slightly for a smooth transition
            }, next: {
                opacity: 1, // Fade in for the incoming slide
                scale: [1, 0.8], // Scale up to create depth
            }
        }, creative: true
    }, {
        name: 'elegantshift', object: {
            prev: {
                opacity: 0.6, translate: ['-120%', 0, -500], rotate: [0, 0, -180],
            }, next: {
                opacity: 0.6, translate: ['120%', 0, -500], rotate: [0, 0, 180],
            }
        }, creative: true
    }, {
        name: 'depthzoom', object: {
            prev: {
                opacity: 0.7, scale: 0.8, translate: ['-10%', 0, -200],
            }, next: {
                opacity: 0.7, scale: 0.8, translate: ['10%', 0, -200],
            }
        }, creative: true
    }, {
        name: 'fliphorizon', object: {
            prev: {
                rotate: [0, -90, 0], translate: ['-100%', 0, 0],
            }, next: {
                rotate: [0, 90, 0], translate: ['100%', 0, 0],
            }
        }, creative: true
    }, {
        name: 'slideover', object: {
            prev: {
                scale: 0.9, translate: ['-50%', 0, 0],
            }, next: {
                scale: 0.9, translate: ['50%', 0, 0],
            }
        }, creative: true
    }, {
        name: 'eclipseshift', object: {
            prev: {
                opacity: 0, scale: 0.95, before: {
                    saturation: 0, opacity: 0.7, color: "#000",
                },
            }, next: {
                opacity: 1, scale: [0.95, 1], before: {
                    opacity: 0,
                },
            }
        }, creative: true
    }, {
        name: 'verticalfall', object: {
            prev: {
                opacity: 0.6, translate: [0, '-50%', 0], rotate: [0, 0, 0],
            }, next: {
                opacity: 0.6, translate: [0, '50%', 0], rotate: [0, 0, 0],
            }
        }, creative: true
    }, {
        name: 'opacitytwist', object: {
            prev: {
                opacity: 0.3, rotate: [0, 0, -45], translate: [0, '15%', 0],
            }, next: {
                opacity: 0.3, rotate: [0, 0, 45], translate: [0, '-15%', 0],
            }
        }, creative: true
    },];

const SwiperSlider: React.FC<SliderProps> = ({
                                                 children,
                                                 effect = 'simple',
                                                 slidesLgNumber = 1,
                                                 slidesMbNumber = slidesLgNumber,
                                                 isPagination = true,
                                                 isAutoplay = true,
                                                 isNavigation = false,
                                                 loop = false,
                                                 speed = 1500,
                                                 gap = 5,
                                                 centeredSlides = false,
                                                 className = "",
                                                 ...props
                                             }) => {
    const isMobile = useMediaQuery({query: '(max-width: 900px)'});
    const effectConfig = effects.find(x => x.name === effect);
    // Determine if the effect is creative and get its configuration
    const isCreative = effectConfig && effectConfig.creative;
    const creativeConfig = isCreative ? effectConfig.object : {};
    const sliderItemsPerView = isMobile ? slidesMbNumber : slidesLgNumber
    return (<Swiper
            spaceBetween={gap}
            slidesPerView={sliderItemsPerView}
            speed={speed}
            effect={React.Children.count(children) > sliderItemsPerView ? isCreative ? "creative" : effect : "simple"} // This is where you specify the effect name
            centeredSlides={centeredSlides}
            cubeEffect={{shadow: false}}
            loop={loop}
            creativeEffect={isCreative ? creativeConfig : {}}
            grabCursor={true}
            pagination={isPagination ? {clickable: true, dynamicBullets: true, type: 'bullets'} : false}
            autoplay={isAutoplay ? {delay: 1500} : false}
            {...props}
        >
            {React.Children.map(children, (child) => (<SwiperSlide>{child}</SwiperSlide>))}
        </Swiper>);
};

export default SwiperSlider;
