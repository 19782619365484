import React from "react";
import SvgIcon from "../../../../../utils/IconsSelector/SvgIcon";

const FeatureCard = ({
                     title_text,
                     description_text,
                     icon_svg,
                     title_text_color,
                     icon_color,
                     description_text_color,
                     icon_background_color,
                     background_gradient_direction,
                     background_start_color,
                     background_end_color,
                 }) => {
    return (
            <div
                style={{backgroundImage: `linear-gradient(${background_gradient_direction}, ${background_start_color}, ${background_end_color})`}}
                className="p-8 text-white rounded-lg shadow-lg animate__animated animate__fadeInUp">
                <div style={{backgroundColor: hexToRGBA(icon_background_color, 0.3)}}
                     className="mx-10 p-8 flex bg-opacity-20 justify-center rounded-full animate__animated animate__fadeInBottomRight">
                    <div className="text-3xl"><SvgIcon d={icon_svg} style={{color: icon_color}} width="100"
                                                       height="100"/></div>
                </div>
                <h2 style={{color: title_text_color}}
                    className="text-2xl text-center font-bold mt-4 animate__animated animate__lightSpeedInRight">{title_text}</h2>
                <p style={{color: description_text_color}}
                   className="mt-2 text-center animate__animated animate__fadeIn">{description_text}</p>
            </div>
    )
}
const hexToRGBA = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
};
export default FeatureCard
