import React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchProductDetails} from "../redux/actions/ProductActions";
import ProductDetailsPage from "../Screens/AdminPanel/Products/ProductDetailsPage";
import OrderDetailsPage from "../Screens/AdminPanel/Orders/OrderDetailsPage";
import CategoryDetailsPage from "../Screens/AdminPanel/Categories/CategoryDetailsPage";

const RowDetails = () => {
    const orderListData = useSelector((state) => state.ordersList);
    const {ordersList} = orderListData
    const productData = useSelector((state) => state.productDetails);
    const {productDetails} = productData
    const categoryData = useSelector((state) => state.categoryList);
    const {categories} = categoryData
    const {id, name} = useParams()
    const dispatch = useDispatch()
    const handleImage = () => {

    }
    useEffect(() => {
        if (name === "product") {
            dispatch(fetchProductDetails(id))
        }
    }, [])
    if (name === "order" && orderListData.success) {
        const order = ordersList.find(x => x.id == id)
        return (
            <OrderDetailsPage order={order} getDate={getDate}/>
        )
    } else if (name === "product" && productData.success) {
        return (
            <ProductDetailsPage productDetails={productDetails} getDate={getDate}/>
        )
    } else if (name === "category" && categoryData.success) {
        const category = categories.find(x => x.id == id)
        return (
            <CategoryDetailsPage category={category} getDate={getDate}/>
        )
    }
}

const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        minute: "numeric",
        hour: "numeric",
        second: "numeric"
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
}
export default RowDetails