import React, {useEffect, useState} from "react";
import useAlert from "../../../CustomHooks/useAlert";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchItemGroups, fetchItemGroupsOfItemOrganizer,
    fetchItemsOrganizers, fetchNavigationPage
} from "../../../redux/actions/PagesDataActions";
import axios from "axios";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import DragDropHandler from "../../../Components/DragDropHandler";


export const effects = [
    {name: "simple", title: "Simple"},
    {name: "fade", title: "Fade"},
    {name: "cube", title: "Cube"},
    {name: "flip", title: "Flip"},
    {name: "coverflow", title: "Coverflow"},
    {name: "cards", title: "Cards"},
];


const ItemsOrganizer = () => {
    const {id, itemId} = useParams()
    const dispatch = useDispatch()
    const initialFormData = {
        name: "",
        mb_columns: 0,
        lg_columns: 0,
        is_slider: false,
        slider_speed: 1500,
        slider_effect: "simple",
    }
    const [formData, setFormData] = useState(initialFormData)
    const [itemsGroupId, setItemsGroupId] = useState('')
    const [itemGroupsOfItemsOrganizerId, setItemGroupsOfItemsOrganizerId] = useState('')
    useEffect(() => {
        dispatch(fetchItemGroups())
        dispatch(fetchItemsOrganizers())
    }, []);
    const [showAlert, AlertComponent] = useAlert();
    const itemGroupsData = useSelector((state) => state.itemGroupsReducer);
    const itemGroupsOfItemsOrganizerData = useSelector((state) => state.itemGroupsOfItemsOrganizerReducer);
    const {itemGroupsOfItemsOrganizer} = itemGroupsOfItemsOrganizerData
    const {itemsOrganizers, success} = useSelector((state) => state.itemsOrganizersReducer);
    const {itemGroups} = itemGroupsData
    useEffect(() => {
        if (success && id) {
            const itemsOrganizer = itemsOrganizers.find(x => x.id === Number(id))
            setFormData(itemsOrganizer)
            dispatch(fetchItemGroupsOfItemOrganizer(id))
        } else {
            setFormData(initialFormData)
        }
    }, [id]);
    const submit = async (e) => {
        e.preventDefault()
        if (id) {
            try {
                const {data} = await axios.post('/admin/content/update/items-organizer/' + id, formData);
                showAlert("success", "Item Organizer Updated Successfully")
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
        } else {
            try {
                const {data} = await axios.post('/admin/content/add/items-organizer', formData);
                showAlert("success", "Item Organizer Added Successfully")
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
        }
        dispatch(fetchItemsOrganizers())
    }
    const submitArrangement = async (reorderedArray) => {
        try {
            const {data} = await axios.post("/admin/content/update/order/item-of-organizer", reorderedArray)
            showAlert("success", "Items Groups Arranged Successfully!")
        } catch (e) {
            showAlert("error", e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message)
        }
        dispatch(fetchItemGroupsOfItemOrganizer(id))
    }
    const AddItemsGroup = async (e) => {
        e.preventDefault()
        try {
            const {data} = await axios.post("admin/content/add/item-of-organizer", {
                item_group_id: itemsGroupId,
                items_organizer_id: id
            })
            showAlert("success", "Items Group Added Successfully!")
        } catch (e) {
            showAlert("error", e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message)
        }
        dispatch(fetchItemGroupsOfItemOrganizer(id))
        setItemsGroupId("")
    }
    const DeleteItemsGroup = async (e) => {
        e.preventDefault()
        try {
            const {data} = await axios.post("admin/content/delete/item-of-organizer/" + itemGroupsOfItemsOrganizerId)
            showAlert("success", "Items Group Added Successfully!")
        } catch (e) {
            showAlert("error", e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message)
        }
        dispatch(fetchItemGroupsOfItemOrganizer(id))
        setItemGroupsOfItemsOrganizerId("")
    }
    return (
        <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
            {AlertComponent}
            <form onSubmit={submit} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Items Organizer</span>
                <hr/>
                <div className="w-full">
                    <label htmlFor="order">Title</label>
                    <input type="text" id="order" value={formData.name} required={true}
                           onChange={(e) => setFormData((prevState) => ({...prevState, name: e.target.value}))}
                           className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                </div>

                <div className="w-full">
                    <label htmlFor="lg_columns">Desktop Columns</label>
                    <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                            id="type" value={formData.lg_columns} required={true}
                            onChange={(e) => setFormData({...formData, lg_columns: e.target.value})}>
                        <option value={0} disabled selected>Select No. of Desktop Columns</option>
                        {[...Array(5)].map((x, i) => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                </div>
                <div className="w-full">
                    <label htmlFor="mb_columns">Mobile Columns</label>
                    <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                            id="type" value={formData.mb_columns} required={true}
                            onChange={(e) => setFormData({...formData, mb_columns: e.target.value})}>
                        <option value={0} disabled selected>Select No. of Mobile Columns</option>
                        {[...Array(3)].map((x, i) => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                </div>
                <div className="checkbox-wrapper-14 flex justify-between my-2">
                    <span>Slider</span>
                    <input type="checkbox" className="switch" checked={formData.is_slider}
                           onChange={(e) => setFormData({...formData, is_slider: e.target.checked})}/>
                </div>
                {formData.is_slider && <>
                    <div className="w-full">
                        <label htmlFor="speed">Slider Speed in Milliseconds</label>
                        <input type="number" id="speed" value={formData.slider_speed} required={true}
                               onChange={(e) => setFormData(prevState => ({
                                   ...prevState,
                                   slider_speed: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="effect">Slider Effect/Animation</label>
                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                id="effect" value={formData.slider_effect}
                                onChange={(e) => setFormData(prevState => ({
                                    ...prevState,
                                    slider_effect: e.target.value
                                }))}>
                            <option disabled selected>Select Effect/Animation</option>
                            {effects.map(x => (
                                <option key={x.name} value={x.name}>{x.title}</option>
                            ))}
                        </select>
                    </div>
                </>}
                <button
                    className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                    type="submit">{(id ? "Update" : "Add")} Items Organizer
                </button>
            </form>
            {id && <>
                <form onSubmit={AddItemsGroup} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">Add Items Group</span>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="type">Name</label>
                        <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                id="type" value={itemsGroupId} required={true}
                                onChange={(e) => setItemsGroupId(e.target.value)}>
                            <option value={""} disabled selected>Select Items Group</option>
                            {itemGroups.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                    <button
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit">Add Items Group
                    </button>
                </form>
                {itemGroupsOfItemsOrganizerData.success &&
                    <DragDropHandler ItemComponent={ContentItemComponent} submitArrangement={submitArrangement}
                                     itemsArray={itemGroupsOfItemsOrganizer}/>}
                {itemGroupsOfItemsOrganizer.length > 0 &&
                    <form onSubmit={DeleteItemsGroup} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">Delete Items Group</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="type">Name</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="type" value={itemGroupsOfItemsOrganizerId} required={true}
                                    onChange={(e) => setItemGroupsOfItemsOrganizerId(e.target.value)}>
                                <option value={""} disabled selected>Select Items Group</option>
                                {itemGroupsOfItemsOrganizer.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <button
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">Delete Items Group
                        </button>
                    </form>}
            </>}
            {!id && success && <div className="rounded-lg bg-white mx-2 mb-4">
                <TableComponent TableData={itemsOrganizers.map(({
                                                                    id,
                                                                    name,
                                                                    lg_columns,
                                                                    mb_columns,
                                                                    is_slider,
                                                                    updated_at
                                                                }) => ({
                    id, name,
                    desktop_columns: lg_columns,
                    mobile_columns: mb_columns,
                    is_slider,
                    updated_at
                }))} tableName="Items Organizers" showAlert={showAlert}/>
            </div>}
        </div>
    )
}
const ContentItemComponent = ({item}) => {
    return (
        <div
            className="w-full flex flex-col justify-center gap-2 items-center">
            <div>{item.name}</div>
        </div>
    )
}
export default ItemsOrganizer