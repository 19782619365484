import {
    SLIDER_DATA_REQUEST,
    SLIDER_UPDATE_ERROR,
    SLIDER_UPDATE_SUCCESS,
    SLIDER_DATA_SUCCESS,
    SLIDER_UPDATE_REQUEST,
    SLIDER_DATA_ERROR,
    MENU_ITEMS_UPDATE_ERROR,
    MENU_ITEMS_UPDATE_SUCCESS,
    MENU_ITEMS_UPDATE_REQUEST,
    MENU_ITEMS_ERROR,
    MENU_ITEMS_SUCCESS,
    MENU_ITEMS_REQUEST,
    MENU_ORDER_UPDATE_REQUEST,
    MENU_ORDER_UPDATE_SUCCESS,
    MENU_ORDER_UPDATE_ERROR,
    HOME_ITEMS_REQUEST,
    HOME_ITEMS_UPDATE_ERROR,
    HOME_ITEMS_UPDATE_REQUEST,
    HOME_ORDER_UPDATE_SUCCESS,
    HOME_ITEMS_SUCCESS,
    HOME_ITEMS_UPDATE_SUCCESS,
    HOME_ORDER_UPDATE_REQUEST,
    HOME_ORDER_UPDATE_ERROR,
    HOME_ITEMS_ERROR,
    SLIDER_BANNERS_DATA_ERROR,
    SLIDER_BANNERS_DATA_SUCCESS,
    SLIDER_BANNERS_DATA_REQUEST,
    BANNERS_DATA_REQUEST,
    BANNERS_DATA_SUCCESS,
    BANNERS_DATA_ERROR,
    COUPONS_DATA_REQUEST,
    COUPONS_DATA_ERROR,
    COUPONS_DATA_SUCCESS,
    TOP_BAR_DATA_ERROR,
    TOP_BAR_DATA_REQUEST,
    TOP_BAR_DATA_SUCCESS,
    TOP_BARS_DATA_ERROR,
    TOP_BARS_DATA_REQUEST,
    TOP_BARS_DATA_SUCCESS
} from "../Constants/GeneralItemsConstants";
import axios from "axios"

export const fetchSliderBannersData = () => async (dispatch) => {
    try {
        dispatch({type: SLIDER_BANNERS_DATA_REQUEST})

        const {data} = await axios.get("/admin/home/slider-items")
        dispatch({
            type: SLIDER_BANNERS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: SLIDER_BANNERS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchSlidersData = () => async (dispatch) => {
    try {
        dispatch({type: SLIDER_DATA_REQUEST})

        const {data} = await axios.get("/api/sliders")
        dispatch({
            type: SLIDER_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: SLIDER_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const fetchBannersData = () => async (dispatch) => {
    try {
        dispatch({type: BANNERS_DATA_REQUEST})

        const {data} = await axios.get("/admin/home/banners")
        dispatch({
            type: BANNERS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: BANNERS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const fetchMenuItems = () => async (dispatch) => {
    try {
        dispatch({type: MENU_ITEMS_REQUEST})

        const {data} = await axios.get("/api/menu-items")
        dispatch({
            type: MENU_ITEMS_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: MENU_ITEMS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const fetchTopBars = () => async (dispatch) => {
    try {
        dispatch({type: TOP_BARS_DATA_REQUEST})

        const {data} = await axios.get("/api/top-bars")
        dispatch({
            type: TOP_BARS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: TOP_BARS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchTopBar = () => async (dispatch) => {
    try {
        dispatch({type: TOP_BAR_DATA_REQUEST})

        const {data} = await axios.get("/api/top-bar")
        dispatch({
            type: TOP_BAR_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: TOP_BAR_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchCouponsData = () => async (dispatch) => {
    try {
        dispatch({type: COUPONS_DATA_REQUEST})

        const {data} = await axios.get("/api/coupons")
        dispatch({
            type: COUPONS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: COUPONS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const updateMenuItems = (menuItem) => async (dispatch, getState) => {
    try {
        dispatch({type: MENU_ITEMS_UPDATE_REQUEST})

        const {data} = await axios.post("admin/home/update/menu-items", menuItem, {withCredentials: true})
        dispatch({
            type: MENU_ITEMS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: MENU_ITEMS_UPDATE_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const updateMenuOrder = (menu) => async (dispatch, getState) => {
    try {
        dispatch({type: MENU_ORDER_UPDATE_REQUEST})

        const {data} = await axios.post("admin/home/update/menu-order", menu, {withCredentials: true})
        dispatch({
            type: MENU_ORDER_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: MENU_ORDER_UPDATE_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const fetchHomeItems = () => async (dispatch) => {
    try {
        dispatch({type: HOME_ITEMS_REQUEST})

        const {data} = await axios.get("/api/home-items")
        dispatch({
            type: HOME_ITEMS_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: HOME_ITEMS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const updateHomeItems = (homeItem) => async (dispatch, getState) => {
    try {
        dispatch({type: HOME_ITEMS_UPDATE_REQUEST})

        const {data} = await axios.post("admin/home/update/home-items", homeItem, {withCredentials: true})
        dispatch({
            type: HOME_ITEMS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: HOME_ITEMS_UPDATE_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const updateHomeOrder = (homeItems) => async (dispatch, getState) => {
    try {
        dispatch({type: HOME_ORDER_UPDATE_REQUEST})

        const {data} = await axios.post("admin/home/update/home-order", homeItems, {withCredentials: true})
        dispatch({
            type: HOME_ORDER_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: HOME_ORDER_UPDATE_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}