import {
    PAGE_LIST_SUCCESS,
    PAGE_LIST_ERROR,
    PAGE_DATA_ERROR,
    PAGE_DATA_REQUEST,
    PAGE_DATA_SUCCESS,
    PAGE_LIST_REQUEST,
    MEDIA_LIST_REQUEST,
    MEDIA_LIST_SUCCESS,
    MEDIA_LIST_ERROR,
    EDITOR_PAGE_DATA_REQUEST,
    EDITOR_PAGE_DATA_SUCCESS,
    EDITOR_PAGE_DATA_ERROR,
} from "../Constants/PagesConstants";
import axios from "axios"

export const fetchPageList = () => async (dispatch) => {
    try {
        dispatch({type: PAGE_LIST_REQUEST})

        const {data} = await axios.get("/admin/pages/")
        dispatch({
            type: PAGE_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: PAGE_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchMediaList = (pathname) => async (dispatch) => {
    if (pathname === undefined) pathname = ""
    try {
        dispatch({type: MEDIA_LIST_REQUEST})

        const {data} = await axios.get("/admin/content/media?path=" + pathname)
        dispatch({
            type: MEDIA_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: MEDIA_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchPageData = (slug) => async (dispatch) => {
    try {
        dispatch({type: PAGE_DATA_REQUEST})

        const {data} = await axios.get("general/content/page/" + slug)
        dispatch({
            type: PAGE_DATA_SUCCESS,
            payload: data.page
        })
    } catch (e) {
        dispatch({
            type: PAGE_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchPageDataById = (id) => async (dispatch) => {
    try {
        dispatch({type: EDITOR_PAGE_DATA_REQUEST})

        const {data} = await axios.get("admin/pages/" + id)
        dispatch({
            type: EDITOR_PAGE_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: EDITOR_PAGE_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
