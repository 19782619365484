import {
    DELETE_ORDER_ERROR,
    DELETE_ORDER_SUCCESS,
    SAVE_ORDER_ERROR,
    DELETE_ORDER_REQUEST,
    SAVE_ORDER_SUCCESS,
    SAVE_ORDER_REQUEST,
    ORDER_LIST_ERROR,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_REQUEST,
    UPDATE_ORDER_ERROR,
    UPDATE_ORDER_REQUEST,
    SOLD_PRODUCTS_LIST_ERROR,
    REVIEWS_LIST_ERROR,
    REVIEWS_LIST_REQUEST,
    REVIEWS_LIST_SUCCESS,
    UPDATE_ORDER_SUCCESS,
    SOLD_PRODUCTS_LIST_REQUEST,
    SOLD_PRODUCTS_LIST_SUCCESS,
    ORDERED_PRODUCTS_LIST_SUCCESS,
    ORDERED_PRODUCTS_LIST_REQUEST,
    ORDERED_PRODUCTS_LIST_ERROR
} from "../Constants/OrderConstants";
import axios from "axios";
import {showAlert} from "../../utils/providers/AlertProvider";

export const fetchOrderList = () => async (dispatch) => {
    try {
        dispatch({type: ORDER_LIST_REQUEST})

        const {data} = await axios.get("/admin/orders/")
        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ORDER_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const fetchOrderedProductsList = () => async (dispatch) => {
    try {
        dispatch({type: ORDERED_PRODUCTS_LIST_REQUEST})

        const {data} = await axios.get("/api/ordered-products")
        dispatch({
            type: ORDERED_PRODUCTS_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ORDERED_PRODUCTS_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const fetchSoldProductsList = () => async (dispatch) => {
    try {
        dispatch({type: SOLD_PRODUCTS_LIST_REQUEST})

        const {data} = await axios.get("/api/sold-products")
        dispatch({
            type: SOLD_PRODUCTS_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: SOLD_PRODUCTS_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const fetchReviewsList = () => async (dispatch) => {
    try {
        dispatch({type: REVIEWS_LIST_REQUEST})

        const {data} = await axios.get("/admin/reviews/")
        dispatch({
            type: REVIEWS_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: REVIEWS_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}


export const saveOrder = (orderData) => async (dispatch, getState) => {
    try {
        dispatch({type: SAVE_ORDER_REQUEST})

        const {data} = await axios.post("/api/update/orders", orderData, {withCredentials: true})
        dispatch({
            type: SAVE_ORDER_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: SAVE_ORDER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}


export const updateOrder = (order, id, setLoading) => async (dispatch, getState) => {
    try {
        dispatch({type: UPDATE_ORDER_REQUEST})
        const {data} = await axios.post("/admin/orders/update/" + id, order, {withCredentials: true})
        dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: data.message
        })
        showAlert('success', "Order Updated Successfully")
    } catch (e) {
        dispatch({
            type: UPDATE_ORDER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
        showAlert('error', e.response && e.response.data.detail ?
            e.response.data.detail :
            e.message)
    } finally {
        setLoading(false)
    }
}
