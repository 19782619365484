// AlertContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import AlertBox from './AlertBox';

const AlertContext = createContext();

let showAlertFunction = null;

export const useAlert = () => {
    return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null);

    const showAlert = useCallback((type, message) => {
        setAlert({ type, message });
        setTimeout(() => {
            setAlert(null);
        }, 5000);
    }, []);

    showAlertFunction = showAlert;

    return (
        <AlertContext.Provider value={showAlert}>
            {children}
            {alert && <AlertBox type={alert.type} message={alert.message} />}
        </AlertContext.Provider>
    );
};

export const showAlert = (type, message) => {
    if (showAlertFunction) {
        showAlertFunction(type, message);
    } else {
        console.warn('showAlert function is not initialized yet.');
    }
};
