export const GENERAL_SETTINGS_DATA_REQUEST = "GENERAL_SETTINGS_DATA_REQUEST"
export const GENERAL_SETTINGS_DATA_SUCCESS = "GENERAL_SETTINGS_DATA_SUCCESS"
export const GENERAL_SETTINGS_DATA_ERROR = "GENERAL_SETTINGS_DATA_ERROR"

export const CONTACT_SETTINGS_DATA_REQUEST = "CONTACT_SETTINGS_DATA_REQUEST"
export const CONTACT_SETTINGS_DATA_SUCCESS = "CONTACT_SETTINGS_DATA_SUCCESS"
export const CONTACT_SETTINGS_DATA_ERROR = "CONTACT_SETTINGS_DATA_ERROR"

export const SOCIAL_SETTINGS_DATA_REQUEST = "SOCIAL_SETTINGS_DATA_REQUEST"
export const SOCIAL_SETTINGS_DATA_SUCCESS = "SOCIAL_SETTINGS_DATA_SUCCESS"
export const SOCIAL_SETTINGS_DATA_ERROR = "SOCIAL_SETTINGS_DATA_ERROR"


export const PAYMENT_SETTINGS_DATA_REQUEST = "PAYMENT_SETTINGS_DATA_REQUEST"
export const PAYMENT_SETTINGS_DATA_SUCCESS = "PAYMENT_SETTINGS_DATA_SUCCESS"
export const PAYMENT_SETTINGS_DATA_ERROR = "PAYMENT_SETTINGS_DATA_ERROR"

export const SET_TINYMCE_API = "SET_TINYMCE_API"

export const API_SETTINGS_DATA_REQUEST = "API_SETTINGS_DATA_REQUEST"
export const API_SETTINGS_DATA_SUCCESS = "API_SETTINGS_DATA_SUCCESS"
export const API_SETTINGS_DATA_ERROR = "API_SETTINGS_DATA_ERROR"


export const STRIPE_PAYMENTS_DATA_REQUEST = "STRIPE_PAYMENTS_DATA_REQUEST"
export const STRIPE_PAYMENTS_DATA_SUCCESS = "STRIPE_PAYMENTS_DATA_SUCCESS"
export const STRIPE_PAYMENTS_DATA_ERROR = "STRIPE_PAYMENTS_DATA_ERROR"

export const GENERAL_SETTINGS_UPDATE_REQUEST = "GENERAL_SETTINGS_UPDATE_REQUEST"
export const GENERAL_SETTINGS_UPDATE_SUCCESS = "GENERAL_SETTINGS_UPDATE_SUCCESS"
export const GENERAL_SETTINGS_UPDATE_ERROR = "GENERAL_SETTINGS_UPDATE_ERROR"
