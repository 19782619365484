import React from "react";

const SvgIcon = ({d, height = "24", className = "text-black", viewBox = "0 -960 960 960", ...props}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={height}
             height={height} viewBox={viewBox}
             className={className + " fill-current"} style={{fill: "currentColor"}} {...props}>
            <path d={d}/>
        </svg>
    )
}
export default SvgIcon