export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST"
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS"
export const ORDER_LIST_ERROR = "ORDER_LIST_ERROR"

export const ORDERED_PRODUCTS_LIST_REQUEST = "ORDERED_PRODUCTS_LIST_REQUEST"
export const ORDERED_PRODUCTS_LIST_SUCCESS = "ORDERED_PRODUCTS_LIST_SUCCESS"
export const ORDERED_PRODUCTS_LIST_ERROR = "ORDERED_PRODUCTS_LIST_ERROR"


export const SOLD_PRODUCTS_LIST_REQUEST = "SOLD_PRODUCTS_LIST_REQUEST"
export const SOLD_PRODUCTS_LIST_SUCCESS = "SOLD_PRODUCTS_LIST_SUCCESS"
export const SOLD_PRODUCTS_LIST_ERROR = "SOLD_PRODUCTS_LIST_ERROR"


export const REVIEWS_LIST_REQUEST = "REVIEWS_LIST_REQUEST"
export const REVIEWS_LIST_SUCCESS = "REVIEWS_LIST_SUCCESS"
export const REVIEWS_LIST_ERROR = "REVIEWS_LIST_ERROR"


export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST"
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS"
export const SAVE_ORDER_ERROR = "SAVE_ORDER_ERROR"

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR"

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR"
