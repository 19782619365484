import React, {useCallback, useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";
import {useSelector} from "react-redux";
import {getBaseUrl} from "../App";
import Loading from "./loading";


const Currency = () => {
    let currency = useSelector((state) => state.currencyReducer);
    if (currency === "₨") {
        currency = "Rs."
    }
    return currency;
};

function debounce(func, wait) {
    let timeout;

    const debouncedFunction = function (...args) {
        const later = () => {
            timeout = null;
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };

    debouncedFunction.cancel = function () {
        clearTimeout(timeout);
    };

    return debouncedFunction;
}

const ProductSearch = ({setProduct}) => {
    let currency = useSelector((state) => state.currencyReducer);
    const [searchValue, setSearchValue] = useState("")
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false)
    const debouncedSearch = useCallback(
        debounce(async (query) => {
            if (query.length > 0) {
                const URL = `/general/products/search/${query}?page=1`
                const {data} = await axios.get(URL)
                setProducts(data.products);
            } else {
                setProducts([]);
            }
            setLoading(false)
        }, 500), []
    );

    useEffect(() => {
        if (searchValue === "") {
            setProducts([])
        } else {
            setProducts([])
            setLoading(true);
            debouncedSearch(searchValue);

            // Cleanup function to cancel the debounced call
            return () => {
                debouncedSearch.cancel();
            };
        }
    }, [searchValue]);
    const NoOptionsMessage = (props) => {
        return <div className={"py-2 text-center"} {...props}>{loading ?
            <Loading small={true}
                     height={'4vh'}/> : searchValue === "" ? "Search the desired product" : "No products found!"}</div>;
    };
    const customStyles = {
        // Override styles for the dropdown indicator
        dropdownIndicator: (provided) => ({
            ...provided, display: 'none', // This hides the dropdown indicator
        }), // Styles for the container
        container: (provided) => ({
            ...provided, // Border roundedness using Tailwind's 'rounded-md'
        }),

        // Styles for the control (the input itself)
        control: (provided) => ({
            ...provided, borderTopLeftRadius: '0.375rem',         // Top-right border roundedness using Tailwind's 'rounded-md'
            borderBottomLeftRadius: '0.375rem', borderTopRightRadius: '0rem',         // Top-right border roundedness using Tailwind's 'rounded-md'
            borderBottomRightRadius: '0rem', backgroundColor: 'rgba(229, 231, 235)', // Background color using Tailwind's 'bg-gray-200'
            color: 'rgba(55, 65, 81)',              // Text color using Tailwind's 'text-gray-700'
            // Border roundedness using Tailwind's 'rounded-md'
            boxShadow: 'none', cursor: "text", borderColor: 'rgba(229, 231, 235)',
        }),

        // Styles for the singleValue (the selected value)
        singleValue: (provided) => ({
            ...provided, color: 'rgba(55, 65, 81)',              // Text color using Tailwind's 'text-gray-700'
        }),
    }
    const Option = (props) => (
        <div {...props.innerProps}
             className="relative border-b-[1px] hover:bg-gray-100 transition-all duration-500 items-center w-[99%] overflow-hidden cursor-pointer">
            <div className="flex justify-between items-center px-2 w-full">
                <div className="flex justify-center items-center w-1/3">
                    <img className="w-full object-contain h-24"
                         src={getBaseUrl() + props.data.image} alt={props.data.value}/>
                </div>
                {props.data.discount_rate > 0 &&
                    <span
                        className="absolute z-10 text-center text-white rounded-br-full rounded-tr-full font-semibold top-2 left-0 px-2 py-1 bg-red-600  text-sm">{props.data.discount_rate}% Off</span>}

                <div
                    className="flex flex-col ml-2 text-gray-700 py-2 px-2 font-poppins w-2/3">
                    <span
                        className="font-semibold h-6 w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{props.label}</span>
                    <div className="flex justify-between">
                        <span
                            className="font-semibold text-teal-500"><Currency/>{Number((props.data.price * (100 - props.data.discount_rate) / 100).toFixed(2))}</span>

                        {props.data.discount_rate > 0 &&
                            <span
                                className="ml-2 line-through text-gray-500 px-2"><Currency/>{Number(props.data.price.toFixed(2))}</span>}
                    </div>

                </div>
            </div>
        </div>)

    const handleOptionChanged = (data) => {
        setProduct(data)
    }
    const handleInputChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setSearchValue(newValue);
        }
    };
    const handleBlur = () => {
        // Keep the current inputValue when the Select loses focus
        setSearchValue(searchValue);
    };

    return (
        <div className="relative w-full my-2">
            <div className="w-full">
                <Select className="text-md rounded-bl-md rounded-md w-full border border-gray-200 z-10"
                        id="search_bar" isSearchable={true}
                        options={[...products.map(x => ({
                            ...x,
                            value: x.id,
                            label: x.name,
                        }))]}
                        filterOption={() => true}
                        components={{
                            Option, NoOptionsMessage
                        }}
                        styles={customStyles}
                        onInputChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder={searchValue === "" ? "Quick Search" : searchValue}
                        onChange={handleOptionChanged}/>

            </div>
        </div>
    )
}
export default ProductSearch
