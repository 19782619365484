export const USER_LIST_REQUEST = "USER_LIST_REQUEST"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_ERROR = "USER_LIST_ERROR"

export const USER_DATA_REQUEST = "USER_DATA_REQUEST"
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS"
export const USER_DATA_ERROR = "USER_DATA_ERROR"

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR"

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR"

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR"

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"

export const REFRESH_USER_REQUEST = "REFRESH_USER_REQUEST"
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS"
export const REFRESH_USER_ERROR = "REFRESH_USER_ERROR"

export const AUTH_TRUE = "AUTH_TRUE"
export const AUTH_FALSE = "AUTH_FALSE"