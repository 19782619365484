import React, {useState} from "react";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import AlertBox from "../../../utils/providers/AlertProvider/AlertBox";
import axios from "axios";
import {fetchBannersPage} from "../../../redux/actions/PagesDataActions";
import useAlert from "../../../CustomHooks/useAlert";

const BannersPage = () => {
    const dispatch = useDispatch()
    const [showAlert, AlertComponent] = useAlert();
    useEffect(() => {
        dispatch(fetchBannersPage())
    }, []);
    const [bannerImage, setBannerImage] = useState({})
    const [formData, setFormData] = useState({
        name: "", link: "", is_popup: false
    })

    const {banners, success} = useSelector((state) => state.bannersData);
    const submit = (e) => {
        formData.image = bannerImage
        uploadBanner(formData).then(() => {
        }).catch(error => {
            console.error("Failed to upload banner:", error);
        });
    }

    const uploadBanner = async (uploadData) => {
        try {
            const response = await axios.post('/admin/home/update/banners', uploadData);
            showAlert("success", "Banner Added Successfully!")
        } catch (error) {
            console.error("There was an error!", error);
        }
    };

    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                <div className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">Add Banner</span>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="banner_name">Banner Name</label>
                        <input type="text" id="banner_name" value={formData.name}
                               onChange={(e) => setFormData({...formData, name: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>

                    </div>
                    <ImageUploadComponent setImage={setBannerImage} title={"Banner Image"}/>
                    <div className="w-full">
                        <label htmlFor="link">Link (optional)</label>
                        <input type="text" id="link" value={formData.link}
                               onChange={(e) => setFormData({...formData, link: e.target.value})}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="checkbox-wrapper-14 flex gap-2 justify-between my-2">
                        <span>Pop Up</span>
                        <input type="checkbox" className="switch" checked={formData.is_popup}
                               onChange={(e) => setFormData({...formData, is_popup: e.target.checked})}
                        />
                    </div>
                    <button onClick={submit}
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-1 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{"Add Banner"}</button>
                </div>
                {success && banners.length !== 0 && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={banners} tableName="Banners"/>
                </div>}
            </div>

        </React.Fragment>)
}
export default BannersPage