import {
    ADD_VARIATION_TYPE_REQUEST,
    ADD_VARIATION_TYPE_SUCCESS,
    ADD_VARIATION_TYPE_ERROR,
    ADD_VARIATION_ERROR,
    ADD_VARIATION_REQUEST,
    VARIATIONS_WITH_TYPES_LIST_ERROR,
    PRODUCT_VARIATIONS_WITH_TYPES_LIST_SUCCESS,
    PRODUCT_VARIATIONS_WITH_TYPES_LIST_ERROR,
    ADD_VARIATION_SUCCESS,
    PRODUCT_VARIATIONS_WITH_TYPES_LIST_REQUEST,
    VARIATIONS_WITH_TYPES_LIST_SUCCESS,
    VARIATIONS_WITH_TYPES_LIST_REQUEST,
    VARIATIONS_LIST_SUCCESS,
    VARIATIONS_LIST_REQUEST,
    VARIATIONS_LIST_ERROR,
    VARIATION_TYPES_LIST_REQUEST, VARIATION_TYPES_LIST_ERROR, VARIATION_TYPES_LIST_SUCCESS

} from "../Constants/VariationConstants";

export const variationsWithTypesListReducer = (state = {variationsWithTypes: []}, action) => {
    switch (action.type) {
        case VARIATIONS_WITH_TYPES_LIST_REQUEST:
            return {loading: true, variationsWithTypes: []}
        case VARIATIONS_WITH_TYPES_LIST_SUCCESS:
            return {loading: false, success: true, variationsWithTypes: action.payload}
        case VARIATIONS_WITH_TYPES_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const productVariationsWithTypesListReducer = (state = {productVariationsWithTypes: []}, action) => {
    switch (action.type) {
        case PRODUCT_VARIATIONS_WITH_TYPES_LIST_REQUEST:
            return {loading: true, productVariationsWithTypes: []}
        case PRODUCT_VARIATIONS_WITH_TYPES_LIST_SUCCESS:
            return {loading: false, success: true, productVariationsWithTypes: action.payload}
        case PRODUCT_VARIATIONS_WITH_TYPES_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const variationsListReducer = (state = {variations: []}, action) => {
    switch (action.type) {
        case VARIATIONS_LIST_REQUEST:
            return {loading: true, variations: []}
        case VARIATIONS_LIST_SUCCESS:
            return {loading: false, success: true, variations: action.payload}
        case VARIATIONS_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const variationTypesListReducer = (state = {variationTypes: []}, action) => {
    switch (action.type) {
        case VARIATION_TYPES_LIST_REQUEST:
            return {loading: true, variationTypes: []}
        case VARIATION_TYPES_LIST_SUCCESS:
            return {loading: false, success: true, variationTypes: action.payload}
        case VARIATION_TYPES_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const addVariationTypeReducer = (state = {variationType: {}}, action) => {
    switch (action.type) {
        case ADD_VARIATION_TYPE_REQUEST:
            return {loading: true}
        case ADD_VARIATION_TYPE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case ADD_VARIATION_TYPE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const addVariationReducer = (state = {variation: {}}, action) => {
    switch (action.type) {
        case ADD_VARIATION_REQUEST:
            return {loading: true}
        case ADD_VARIATION_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case ADD_VARIATION_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}