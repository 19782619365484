import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {fetchTeamMembers} from "../../../redux/actions/PagesDataActions";
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import TeamMember from "../Pages/Components/TeamMember";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import {getBaseUrl} from "../../../App";
import Img from "../../../utils/Image";

const TeamMembersPage = () => {
    const initialFormState = {
        name_text: "",
        position_text: "",
        description_text: "",
        position_text_color: "#ffffff",
        name_text_color: "#ffffff",
        description_text_color: "#ffffff",
        background_gradient_direction: "to right",
        background_start_color: "#001721",
        background_end_color: "#00b1ff",
    }
    const [formData, setFormData] = useState(initialFormState);
    const [image, setImage] = useState({})
    const [preview, setPreview] = useState(null)
    const {id} = useParams()
    const [showAlert, AlertComponent] = useAlert();
    useEffect(() => {
        if (!id) {
            setFormData(initialFormState)
        }
        setImage({})
        dispatch(fetchTeamMembers())
    }, [id]);

    const {teamMembers, success} = useSelector((state) => state.teamMemberItemsReducer);
    const dispatch = useDispatch()
    useEffect(() => {
        if (id && success) {
            const teamMember = teamMembers.find(x => x.id === Number(id))
            setFormData({
                name_text: teamMember.name_text,
                position_text: teamMember.position_text,
                description_text: teamMember.description_text,
                position_text_color: teamMember.position_text_color,
                name_text_color: teamMember.name_text_color,
                description_text_color: teamMember.description_text_color,
                background_gradient_direction: teamMember.background_gradient_direction,
                background_start_color: teamMember.background_start_color,
                background_end_color: teamMember.background_end_color,
                image: teamMember.image
            })
        }
    }, [id, success]);
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Gradient direction options
    const gradientDirections = [
        {label: 'To Right', value: 'to right'},
        {label: 'To Left', value: 'to left'},
        {label: 'To Top', value: 'to top'},
        {label: 'To Bottom', value: 'to bottom'},
        {label: 'To Top Right', value: 'to top right'},
        {label: 'To Top Left', value: 'to top left'},
        {label: 'To Bottom Right', value: 'to bottom right'},
        {label: 'To Bottom Left', value: 'to bottom left'},
    ];

    const isColorField = (fieldName) => {
        return fieldName.endsWith('_color');
    };

    const submitTeamMember = async (e) => {
        e.preventDefault()
        formData.image = image
        if (id) {
            try {
                const response = await axios.post("/admin/content/update/team-member/" + id, formData)
                showAlert("success", "Team Member Updated")
            } catch (e) {
                console.log(e)
                showAlert('error', e.message)
            }
        } else {
            try {
                const response = await axios.post("/admin/content/add/team-member", formData)
                showAlert("success", "Team Member Added")
            } catch (e) {
                console.log(e)
                showAlert('error', e.message)
            }
        }
        await dispatch(fetchTeamMembers())
    }
    const delTeamMember = async (id) => {
        try {
            const response = await axios.post('/admin/content/delete/team-member/' + id);
            showAlert("success", "Team Member Deleted Successfully")
            dispatch(fetchTeamMembers())
        } catch (error) {
            showAlert("error", error.response.data.detail || error.message)
        }
    }
    return (
        <div className="flex flex-col bg-gray-100 gap-4">
            {AlertComponent}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 px-3 mt-2">
                <div></div>
                <TeamMember {...formData} preview={preview}/>
            </div>
            <form onSubmit={submitTeamMember} className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3">
                <span className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Team Member</span>
                <hr/>
                <ImageUploadComponent required={!id} setImage={setImage} title={"Upload Team Member Image"}
                                      setPreview={setPreview}/>
                {id && Object.keys(image).length === 0 && <div className="relative w-full">
                    <div
                        className="flex w-full justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-56">
                        <Img src={formData.image} alt="Preview"
                             className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                    </div>
                </div>}
                {Object.keys(formData).map((key) => (
                    <div key={key} style={{marginBottom: '10px'}} className="w-full">
                        <label htmlFor={key}
                               style={{marginRight: '10px'}}>{key === "image" ? null : capitalizeWords(key.replaceAll('_', ' '))}</label>
                        {key === 'background_gradient_direction' ? (
                            <select
                                id={key}
                                className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                            >
                                {gradientDirections.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        ) : key === "description_text" ?
                            <textarea rows="5" value={formData[key]} required={true} name={key}
                                      onChange={handleChange}
                                      className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/> : key === "image" ? null : (
                                <input
                                    id={key}
                                    type={isColorField(key) ? "color" : "text"} // Determines if the input should be a color picker
                                    name={key}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    required={!isColorField(key)}
                                    className={` mt-0.5 rounded-md ${isColorField(key) ? "w-10 p-0" : "w-full py-2 px-3"} border border-gray-500`}
                                    placeholder={capitalizeWords(key.replaceAll('_', ' '))}
                                />
                            )}
                    </div>
                ))}
                <button
                    className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                    type="submit">{(id ? "Update" : "Add") + " Team Member"}</button>
            </form>
            {!id && success && teamMembers.length > 0 && <div className="rounded-lg bg-white mx-2 mb-4">
                <span className="text-lg m-3 font-public-sans font-bold">All Team Members:</span>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 px-3 mt-2">
                    {teamMembers.map(x => (
                        <div className="relative">
                            <TeamMember key={x.id} {...x} />
                            <Link to={'/team-member/' + x.id}
                                  className="material-symbols-rounded absolute top-2 left-2 bg-white bg-opacity-70 rounded-full p-4 cursor-pointer text-green-500">edit</Link>
                            <span onClick={() => delTeamMember(x.id)}
                                  className="material-symbols-rounded absolute top-2 right-2 bg-white bg-opacity-70 rounded-full p-4 cursor-pointer text-red-500">delete</span>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    );
}
export default TeamMembersPage

function capitalizeWords(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}