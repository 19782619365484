import {
    DELETE_WISHLIST_ITEM,ADD_WISHLIST_ITEM,WISHLIST_ITEMS_LIST
} from "../Constants/WishlistConstants";

const initialState = localStorage.getItem("wishlistItemsStorage") ?
    JSON.parse(localStorage.getItem("wishlistItemsStorage")) : []
export const wishlistItemsListReducer = (state = {wishlist: initialState}, action) => {
    switch (action.type) {
        case WISHLIST_ITEMS_LIST:
            return {listed: true, wishlist: action.payload}
        case ADD_WISHLIST_ITEM:
            return {added: true, wishlist: action.payload}
        case DELETE_WISHLIST_ITEM:
            return {deleted: true, wishlist: action.payload}
        default:
            return state;
    }
}