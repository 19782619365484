import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {fetchMediaList} from "../../../redux/actions/PagesActions";
import Loading from "../../../Components/loading";
import {
    faArrowLeft,
    faCircleCheck,
    faFolder,
    faFolderPlus,
    faImage,
    faImages,
    faPencil,
    faSearch,
    faTimes,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./MediaPage.css";
import ImagesUploadComponent from "../../../Components/ImagesUploadComponent";
import axios from "axios";
import {getBaseUrl} from "../../../App";
import {showAlert} from "../../../utils/providers/AlertProvider";

const MediaPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const {mediaList, success, loading} = useSelector((state) => state.mediaListReducer);
    const {directories, files, relative_path, current_directory} = mediaList;

    const [filteredImages, setFilteredImages] = useState([])
    const [filteredFolders, setFilteredFolders] = useState([])
    const [images, setImages] = useState([]);

    const [state, setState] = useState({
        showAddFolder: false,
        showAddImages: false,
        selectMode: false,
        showRename: false,
        folderName: "",
        searchValue: "",
        selectedItems: [],
    });

    const {
        showAddFolder, showAddImages, selectMode,
        showRename, folderName, searchValue,
        selectedItems
    } = state;

    useEffect(() => {
        dispatch(fetchMediaList());
    }, [location]);

    useEffect(() => {
        if (success) {
            setState(prevState => ({
                ...prevState,
                filteredFolders: directories,
                filteredImages: files,
            }));
        }
    }, [success, directories, files]);

    const updateState = (updates) => setState(prevState => ({...prevState, ...updates}));
    const getPath = () => relative_path.split("/").slice(1).join("/");
    const handleFolderClick = (folder) => dispatch(fetchMediaList(folder.path));

    const goBack = () => {
        const result = getPath().replace(current_directory, '').replace(/(^\/+|\/+$)/g, '');
        dispatch(fetchMediaList(result));
    }
    const handleSearch = () => {
        updateState({
            filteredImages: files.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase())),
            filteredFolders: directories.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase())),
        });
    }
    const handleAction = async (url, data, successMessage) => {
        try {
            await axios.post(url, data);
            showAlert("success", successMessage);
            dispatch(fetchMediaList(getPath()));
        } catch (error) {
            showAlert("error", error.response?.data?.detail || error.message);
        }
    }
    const handleUploadImages = () => {
        if (images.length > 0) {
            const formData = new FormData();
            images.forEach(image => formData.append('images', image));
            formData.append('path', getPath());

            handleAction('/admin/content/media/upload-images', formData, "Images Uploaded Successfully");
            updateState({showAddImages: false, images: []});
        } else {
            showAlert('error', 'Please Select Images');
        }
    };
    const handleCreateFolder = () => {
        handleAction('/admin/content/media/create-folder', {
            path: getPath(),
            folder_name: folderName
        }, "Folder Added Successfully");
        updateState({showAddFolder: false, folderName: ""});
    };
    const handleSelectItems = (file) => {
        if (selectMode) {
            updateState({
                selectedItems: selectedItems.includes(file)
                    ? selectedItems.filter(x => x !== file)
                    : [...selectedItems, file],
            });
        }
    };

    const toggleSelectMode = () => {
        updateState({selectMode: !selectMode, selectedItems: []});
    };

    const deleteMedia = () => {
        if (selectedItems.length > 0) {
            handleAction('/admin/content/media/delete', {paths: selectedItems}, "Media Deleted Successfully");
            toggleSelectMode();
        } else {
            showAlert("error", "Please Select Media to Delete");
        }
    };

    const renameMedia = () => {
        if (selectedItems.length > 0) {
            handleAction('/admin/content/media/rename', {
                rename: folderName,
                paths: selectedItems
            }, "Media Renamed Successfully");
            updateState({showRename: false, folderName: ""});
            toggleSelectMode();
        } else {
            showAlert("error", "Please Select Media to Rename");
        }
    };

    return (<React.Fragment>
        {success && (<div className="bg-gradient-to-r from-blue-500 to-green-300 rounded-lg m-10">
            {showRename && <RenameDialog renameMedia={renameMedia} folderName={folderName} updateState={updateState}/>}
            {showAddFolder && <AddFolderDialog handleCreateFolder={handleCreateFolder} folderName={folderName}
                                               updateState={updateState}/>}
            {showAddImages &&
                <AddImagesDialog handleUploadImages={handleUploadImages} images={images} setImages={setImages}
                                 updateState={updateState}/>}
            <div className="path-header flex justify-between px-5">
                <div className="grid grid-cols-1 lg:flex lg:grid-cols-3 gap-2">
                    <button
                        className="bg-white text-black rounded-full px-3 h-9 text-sm hover:bg-black hover:text-white  transition-all"
                        onClick={goBack}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <button
                        className="bg-white text-blue-500 flex items-center gap-2 hover:bg-blue-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all"
                        onClick={() => updateState({showAddImages: true})}>
                        <FontAwesomeIcon icon={faImages}/>
                        <span>Upload Images</span>
                    </button>
                    <button
                        className="bg-white text-yellow-600 flex items-center gap-2 hover:bg-yellow-600 hover:text-white rounded-full px-3 h-9 text-sm  transition-all"
                        onClick={() => updateState({showAddFolder: true})}>
                        <FontAwesomeIcon icon={faFolderPlus}/>
                        <span>Add Folder</span>
                    </button>
                    <button
                        className={`flex items-center gap-2 hover:bg-blue-900 hover:text-white ${selectMode ? "bg-blue-900 text-white" : "bg-white text-blue-900"} rounded-full px-3 h-9 text-sm  transition-all`}
                        onClick={() => toggleSelectMode()}>
                        <FontAwesomeIcon icon={faCircleCheck}/>
                        <span>Select</span>
                    </button>

                    {selectMode && selectedItems.length > 0 && <button
                        className={`flex items-center gap-2 hover:bg-fuchsia-700 hover:text-white bg-fuchsia-800 text-white rounded-full px-3 h-9 text-sm  transition-all`}
                        onClick={() => updateState({showRename: true})}>
                        <FontAwesomeIcon icon={faPencil}/>
                        <span>Rename</span>
                    </button>}
                    {selectMode && selectedItems.length > 0 && <button
                        className={`flex items-center gap-2 hover:bg-red-800 hover:text-white bg-red-800 text-white rounded-full px-3 h-9 text-sm  transition-all`}
                        onClick={() => deleteMedia()}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                        <span>Delete</span>
                    </button>}
                </div>
                <div
                    className={`flex mb-2 mx-1 max-w-[50%] justify-center items-center transition-all duration-500`}>
                    <input type="text" id="searchBar" value={searchValue}
                           onChange={(e) => updateState({searchValue: e.target.value})}
                           className="py-2 px-3 text-md rounded-bl-md rounded-tl-md w-full text-black outline-none border border-gray-200"
                           placeholder="Search"/>
                    <button onClick={handleSearch}
                            className="text-md bg-white rounded-br-md rounded-tr-md -ml-0.5 cursor-pointer border border-gray-200 font-light hover:font-normal py-2 px-4 text-gray-600">
                        <FontAwesomeIcon icon={faSearch}/></button>
                </div>
            </div>
            <div className="path-header">{relative_path}</div>
            <section className="media-container">
                {filteredFolders.map((directory, index) => {
                    const filePath = relative_path + "/" + directory.name
                    return (<div onClick={() => selectMode ? handleSelectItems(filePath) : handleFolderClick(directory)}
                                 key={index} className="media-item relative">
                        {selectedItems.some(x => x === filePath) &&
                            <div className="absolute inset-0 bg-blue-700 bg-opacity-30 rounded"></div>}
                        <FontAwesomeIcon icon={faFolder} className="media-icon folder"/>
                        <span className="media-name">{directory.name}</span>
                    </div>)
                })}
                {filteredImages.map((file, index) => {
                    const filePath = relative_path + "/" + file.name
                    const content = <div key={index}
                                         onClick={() => handleSelectItems(filePath)}
                                         className="media-item relative">
                        {selectedItems.some(x => x === filePath) &&
                            <div className="absolute inset-0 bg-blue-700 bg-opacity-30 rounded"></div>}
                        <FontAwesomeIcon icon={faImage} className="media-icon file"/>
                        <span className="media-name">{file.name}</span>
                    </div>
                    return (selectMode ? content :
                        <a target="_blank" href={getBaseUrl() + "/media/" + relative_path + "/" + file.name}>
                            {content}
                        </a>)
                })}
                {directories.length === 0 && files.length === 0 ?
                    <div className='h-[50vh] flex items-center justify-center'>
                        <span>This Folder is Empty!</span>
                    </div> : filteredFolders.length === 0 && filteredImages.length === 0 &&
                    <div className='h-[50vh] flex items-center justify-center'>
                        <span>No search result was found!</span>
                    </div>}
            </section>
        </div>)}
        {loading && <Loading/>}
    </React.Fragment>)
}

export default MediaPage;


const RenameDialog = ({renameMedia, folderName, updateState}) => {
    return (<div className="modal-overlay">
        <div className="modal">
            <input
                type="text"
                placeholder="New Name"
                value={folderName}
                onChange={(e) => updateState({folderName: e.target.value})}
                className="modal-input"
            />
            <button onClick={renameMedia}
                    className="bg-gray-100 flex items-center justify-center gap-2 text-teal-800 border-[1px] hover:bg-teal-800 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                <FontAwesomeIcon icon={faPencil}/>
                <span>Rename</span>
            </button>
            <button onClick={() => updateState({showRename: false})}
                    className="bg-gray-100 flex items-center justify-center gap-2 text-red-500 border-[1px] hover:bg-red-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                <FontAwesomeIcon icon={faTimes}/>
                <span>Close</span>
            </button>
        </div>
    </div>)
}

const AddFolderDialog = ({handleCreateFolder, folderName, updateState}) => {
    return (
        <div className="modal-overlay">
            <div className="modal">
                <input
                    type="text"
                    placeholder="Folder Name"
                    value={folderName}
                    onChange={(e) => updateState({folderName: e.target.value})}
                    className="modal-input"
                />
                <button onClick={handleCreateFolder}
                        className="bg-gray-100 flex items-center justify-center gap-2 text-yellow-500 border-[1px] hover:bg-yellow-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                    <FontAwesomeIcon icon={faFolderPlus}/>
                    <span>Create Folder</span>
                </button>
                <button onClick={() => updateState({showAddFolder: false})}
                        className="bg-gray-100 flex items-center justify-center gap-2 text-red-500 border-[1px] hover:bg-red-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                    <FontAwesomeIcon icon={faTimes}/>
                    <span>Close</span>
                </button>
            </div>
        </div>
    )
}
const AddImagesDialog = ({handleUploadImages, images, setImages, updateState}) => {
    return (<div className="modal-overlay overflow-scroll">
        <div className="modal w-[50%] max-h-[90vh] overflow-y-auto">
            <ImagesUploadComponent media={true} title={"Upload Image/Images"} buttonText={"Select Image/Images"}
                                   setImages={setImages} images={images}/>
            <button onClick={handleUploadImages}
                    className="bg-gray-50 flex items-center justify-center p-2 gap-2 text-blue-500 border-[1px] hover:bg-blue-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                <FontAwesomeIcon icon={faImages}/>
                <span>Upload Images</span>
            </button>
            <button onClick={() => updateState({showAddImages: false})}
                    className="bg-gray-50 flex items-center justify-center p-2 gap-2 text-red-500 border-[1px] hover:bg-red-500 hover:text-white rounded-full px-3 h-9 text-sm  transition-all">
                <FontAwesomeIcon icon={faTimes}/>
                <span>Close</span>
            </button>
        </div>
    </div>)
}