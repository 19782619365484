import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import IconsSelector from "../../../../../../utils/IconsSelector/IconsSelector";

const SettingsTab = () => {
    const [formData, setFormData] = useState({title: "", description: "", keywords: "", icon_name: "", icon_svg: ""})
    const {pageData, pageItems, success, loading} = useSelector((state) => state.editorPageData);
    const dispatch = useDispatch()
    useEffect(() => {
        if (success) {
            setFormData(pageData)
        }
        console.log(pageItems)
    }, [loading]);
    const submitPageData = (e) => {
        e.preventDefault()
        console.log(formData)
    }

    return (
        <form onSubmit={submitPageData} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
            <span className="text-lg font-public-sans font-bold">Page Settings</span>
            <hr/>
            <IconsSelector formData={formData} setFormData={setFormData}/>
            <div className="w-full">
                <label htmlFor="order">Title</label>
                <input type="text" id="order" value={formData.title}
                       onChange={(e) => setFormData((prevState) => ({
                           ...prevState,
                           title: e.target.value,
                       }))}
                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
            </div>
            <div className="w-full">
                <label htmlFor="short_description">Description</label>
                <textarea rows="5" value={formData.description}
                          onChange={(e) => setFormData({...formData, description: e.target.value})}
                          className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
            </div>
            <div className="w-full">
                <label htmlFor="keywords">Keywords</label>
                <textarea rows="1" value={formData.keywords}
                          onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                          className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
            </div>
            <input className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                    type="submit" value="Save"/>
        </form>
    )
}
export default SettingsTab