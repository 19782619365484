import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {fetchReviewsList} from "../../../redux/actions/OrderActions";


const ReviewsPage = ({type}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchReviewsList())
    }, []);
    const {reviews, success} = useSelector((state) => state.reviewsList);
    return (
        success && <React.Fragment>
            <TableComponent TableData={reviews.reverse().filter(x => x.visibility === (type === "pending" ? 2 : 1))}
                            tableName={(type === "pending" ? "Pending" : "Approved") + " Reviews"}/>
        </React.Fragment>
    )
}
export default ReviewsPage