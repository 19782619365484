import React, {useEffect} from "react";
import {Select} from "../../../../Components/ui/Select";
import {Input} from "../../../../Components/ui/Input";
import {useSelector} from "react-redux";

const VariationsAndTypes = ({
                                variationTypesState,
                                setVariationTypesState,
                                variationsState,
                                setVariationsState,
                                formData,
                                setFormData
                            }) => {
    const variationsListData = useSelector((state) => state.variationsList);
    const {variations} = variationsListData
    const variationTypesListData = useSelector((state) => state.variationTypesList);
    const {variationTypes, success} = variationTypesListData

    useEffect(() => {
        if (variationsState.length > 0) {
            const groupedById = variationsState.reduce((acc, item) => {
                // If the key (variation_type_id) does not exist, create it and assign an array
                if (!acc[item.variation_type_id]) {
                    acc[item.variation_type_id] = [];
                }
                // Push the current item into the corresponding array
                acc[item.variation_type_id].push(item);
                return acc;
            }, {});

            // To convert the grouped object back to an array of arrays (if needed)
            const groupedArrays = Object.values(groupedById);
            setFormData({
                ...formData, stock: groupedArrays[0].reduce((acc, item) => Number(acc) + Number(item.stock), 0)
            })
        }
    }, [variationsState]);


    const handleVariationsSelection = (e) => {
        const selectedValue = e.target.value;
        if (!variationsState.find((item) => item.variation === selectedValue)) {
            setVariationsState((prevSelectedValues) => [...prevSelectedValues, {
                id: getIdByVariation(selectedValue),
                variation: selectedValue,
                variation_type_id: getVariationTypeIdByVariation(selectedValue),
                price: 0,
                stock: 0
            }]);
        }

    }

    const handleVariantPriceChange = (e, id) => {
        const price = e.target.value
        setVariationsState(prevItems => prevItems.map(item => item.id === id ? {...item, price: price} : item))
    }
    const handleVariantStockChange = (e, id) => {
        const stock = e.target.value
        setVariationsState(prevItems => prevItems.map(item => item.id === id ? {...item, stock: stock} : item))

    }

    const handleVariationTypesSelection = (e) => {
        const selectedValue = e.target.value;
        if (!variationTypesState.find((item) => item.variation_type === selectedValue)) {
            setVariationTypesState((prevSelectedValues) => [...prevSelectedValues, {
                id: getVariationIdByType(selectedValue), variation_type: selectedValue
            }]);
        }
    }
    const handleDeleteVariation = (id) => {
        const updated = variationsState.filter(item => item.id !== id);
        setVariationsState(updated)
    }
    const handleDeleteVariationType = (id) => {
        const updated = variationTypesState.filter(item => item.id !== id);
        setVariationTypesState(updated)
        const updatedVariations = variationsState.filter(item => item.variation_type_id !== id);
        setVariationsState(updatedVariations)
    }
    const getVariationIdByType = (variation_type) => {
        const foundItem = variationTypes.find((item) => item.variation_type === variation_type);
        if (foundItem) {
            return foundItem.id;
        }
        return null;
    }
    const getIdByVariation = (variation) => {
        const foundItem = variations.find((item) => item.variation === variation);
        if (foundItem) {
            return foundItem.id;
        }
        return null;
    }
    const getVariationTypeIdByVariation = (variation) => {
        const foundItem = variations.find((item) => item.variation === variation);
        if (foundItem) {
            return foundItem.variation_type_id;
        }
        return null;
    }
    return (<>
        <Select label="Variation Types"
                id="variationTypes" onChange={handleVariationTypesSelection}>
            <option disabled selected>Select Variation Types</option>
            {success && variationTypes.map(value => (
                <option key={value.id} value={value.variation_type}>{value.variation_type}</option>))}
        </Select>
        <div className="w-full flex-col">
            {variationTypesState.map((variation_type) => (<div
                className="flex justify-between py-1 px-3 mt-1 rounded-md w-full border border-gray-500">
                <span>{variation_type.variation_type}</span>
                <span onClick={() => handleDeleteVariationType(variation_type.id)}
                      className="material-symbols-rounded cursor-pointer text-red-500 mr-0.5 font-light">delete</span>
            </div>))}
        </div>
        {variationTypesState.map(variation_type => (<>
            <Select label={`${variation_type.variation_type} Variations`}
                    id="variations" onChange={handleVariationsSelection}>
                <option disabled selected>Select Variations</option>
                {variations.map(value => (value.variation_type_id === variation_type.id ? <option key={value.id}
                                                                                                  value={value.variation}>{value.variation}</option> : null))}
            </Select>
            <div className="w-full flex-col">
                {variationsState.map((variation) => (variation.variation_type_id === variation_type.id ? <div
                    className="flex justify-between py-1 px-3 mt-1 items-center rounded-md w-full border border-gray-500">
                    <span>{variation.variation}</span>
                    <div className="flex items-center justify-center w-auto gap-2">
                        <div>
                            <span className="py-0 px-0">Price: </span>
                            <input type="number" value={variation.price}
                                   onChange={(e) => handleVariantPriceChange(e, variation.id)}
                                   className="p-1 focus:outline-0 w-20"/>
                        </div>
                        <div>
                            <span className="py-0 px-0">Stock: </span>
                            <input type="number" value={variation.stock}
                                   onChange={(e) => handleVariantStockChange(e, variation.id)}
                                   className="p-1 focus:outline-0 w-20"/>
                        </div>
                    </div>
                    <span onClick={() => handleDeleteVariation(variation.id)}
                          className="material-symbols-rounded cursor-pointer text-red-500 mr-0.5 font-light">delete</span>
                </div> : null))}
            </div>
        </>))}
        <Input type="number" id="stock" value={formData.stock} required={true}
               disabled={variationsState.length > 0}
               label="Stock Count"
               onChange={(e) => setFormData({...formData, stock: e.target.value})}/>
    </>)
}
export default VariationsAndTypes