import React from 'react';
import {getBaseUrl} from "../../../../App";

const TeamMember = ({
                        name_text,
                        image,
                        preview,
                        description_text,
                        position_text,
                        position_text_color,
                        name_text_color,
                        description_text_color,
                        background_gradient_direction,
                        background_start_color,
                        background_end_color,
                    }) => {
    // Constructing the gradient background style
    const backgroundStyle = {
        backgroundImage: `linear-gradient(${background_gradient_direction}, ${background_start_color}, ${background_end_color})`,
    };

    const imageUrl = preview ? preview : getBaseUrl() + image;

    return (
        <div style={backgroundStyle} className="p-4 rounded-lg shadow-lg animate__animated animate__fadeIn">
            <div
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                className="rounded-full w-32 h-32 mx-auto mb-4 overflow-hidden animate__animated animate__zoomIn animate__delay-1s">
                {/* Image is now set as a background to div */}
            </div>
            <div className="text-center">
                <h3 style={{color: name_text_color}}
                    className="text-xl font-bold animate__animated animate__fadeInDown">{name_text}</h3>
                <p style={{color: position_text_color}}
                   className="font-semibold animate__animated animate__fadeIn animate__delay-1s">{position_text}</p>
                <p style={{color: description_text_color}}
                   className="text-sm mt-2 animate__animated animate__lightSpeedInRight animate__delay-2s">{description_text}</p>
            </div>
        </div>
    );
};

export default TeamMember;
