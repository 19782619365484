import {
    GENERAL_SETTINGS_UPDATE_ERROR,
    GENERAL_SETTINGS_UPDATE_SUCCESS,
    GENERAL_SETTINGS_UPDATE_REQUEST,
    GENERAL_SETTINGS_DATA_ERROR,
    GENERAL_SETTINGS_DATA_SUCCESS,
    GENERAL_SETTINGS_DATA_REQUEST,
    CONTACT_SETTINGS_DATA_REQUEST,
    CONTACT_SETTINGS_DATA_ERROR,
    CONTACT_SETTINGS_DATA_SUCCESS,
    SOCIAL_SETTINGS_DATA_REQUEST,
    SOCIAL_SETTINGS_DATA_ERROR,
    SOCIAL_SETTINGS_DATA_SUCCESS,
    PAYMENT_SETTINGS_DATA_REQUEST,
    PAYMENT_SETTINGS_DATA_ERROR,
    PAYMENT_SETTINGS_DATA_SUCCESS,
    API_SETTINGS_DATA_REQUEST,
    API_SETTINGS_DATA_SUCCESS,
    API_SETTINGS_DATA_ERROR,
    STRIPE_PAYMENTS_DATA_REQUEST,
    STRIPE_PAYMENTS_DATA_SUCCESS,
    STRIPE_PAYMENTS_DATA_ERROR
} from "../Constants/SettingsConstants";
import axios from "axios"

export const fetchGeneralSettingsData = () => async (dispatch) => {
    try {
        dispatch({type: GENERAL_SETTINGS_DATA_REQUEST})

        const {data} = await axios.get("/general/home/general-settings")
        dispatch({
            type: GENERAL_SETTINGS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: GENERAL_SETTINGS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchContactSettingsData = () => async (dispatch) => {
    try {
        dispatch({type: CONTACT_SETTINGS_DATA_REQUEST})

        const {data} = await axios.get("general/home/contact-items")
        dispatch({
            type: CONTACT_SETTINGS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: CONTACT_SETTINGS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchSocialSettingsData = () => async (dispatch) => {
    try {
        dispatch({type: SOCIAL_SETTINGS_DATA_REQUEST})

        const {data} = await axios.get("admin/settings/social")
        dispatch({
            type: SOCIAL_SETTINGS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: SOCIAL_SETTINGS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}


export const fetchPaymentSettingsData = () => async (dispatch) => {
    try {
        dispatch({type: PAYMENT_SETTINGS_DATA_REQUEST})

        const {data} = await axios.get("/general/orders/payment-details")
        dispatch({
            type: PAYMENT_SETTINGS_DATA_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({
            type: PAYMENT_SETTINGS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchAPISettingsData = () => async (dispatch) => {
    try {
        dispatch({type: API_SETTINGS_DATA_REQUEST})

        const {data} = await axios.get("/admin/settings/apis")
        dispatch({
            type: API_SETTINGS_DATA_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({
            type: API_SETTINGS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchStripePaymentsData = () => async (dispatch) => {
    try {
        dispatch({type: STRIPE_PAYMENTS_DATA_REQUEST})

        const {data} = await axios.get("/admin/payments/stripe/details")
        dispatch({
            type: STRIPE_PAYMENTS_DATA_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: STRIPE_PAYMENTS_DATA_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}



export const updateGeneralSettingsData = (generalSettingsData) => async (dispatch, getState) => {
    try {
        dispatch({type: GENERAL_SETTINGS_UPDATE_REQUEST})

        const {data} = await axios.post("/admin/settings/update/general", generalSettingsData, {withCredentials: true})
        dispatch({
            type: GENERAL_SETTINGS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: GENERAL_SETTINGS_UPDATE_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

