import React from "react";
import {Link} from "react-router-dom";

const CategoryDetailsPage = ({category, getDate}) => {
    return (
        <React.Fragment>
            <div className="bg-gray-100 py-1">
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div className="flex border-b-2 justify-between items-center pb-2">
                        <span className="text-lg font-bold">Category Details</span>
                        <Link to={"/update/category/"+category.id}><span className="bg-green-600 text-white rounded px-3 py-1.5">Edit Category</span></Link>
                    </div>
                    <div>Category ID: {category.id + 100000}</div>
                    <hr/>
                    <div><b>Name:</b> {category.name}</div>
                    <div><b>Description:</b> {category.description}</div>
                    <div><b>Slug:</b> {category.slug}</div>
                    <div><b>Keywords:</b> {category.keywords}</div>
                    <div><b>Image:</b><br/><img className="h-56 w-auto border-2 rounded" src={category.image}/>
                    </div>
                    <div><b>Visibility:</b> {category.visibility ? "Visible on site" : "Not visible of site"}</div>
                    <div><b>Edited on:</b> {getDate(category.updated_at)}</div>
                    <div><b>Created on:</b> {getDate(category.created_at)}</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default CategoryDetailsPage