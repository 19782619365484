import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TypeSelect} from "./TypeSelect";
import SliderOptions from "../../../../../../../Components/SliderOptions";
import {capitalizeFirstLetter, findAndReplace} from "../../../../../../../utils/UtilityFunctions";
import {useItemTypes} from "../../../../../../../CustomHooks/useItemTypes";
import {usePageEditorItems} from "../../../../../../../CustomHooks/usePageEditorItems";
import {setSelectedItemOfItemOfPageItem} from "../../../../../../../redux/actions/PageEditorActions";

const ItemSettings = () => {
    const selectedItemOfItemOfPageItem = useSelector((state) => state.selectedItemOfItemOfPageItem);
    const {typesData} = useItemTypes()
    const dispatch = useDispatch()
    const [pageItems, setPageItems] = usePageEditorItems()
    const [formData, setFormData] = useState({
        name: "",
        type: "",
        type_id: -1,
        show_name: false,
        is_slider: false,
        slider_speed: 500,
        slider_effect: 'simple',
        max_rows: 1,
        data: null
    })
    useEffect(() => {
        setFormData(selectedItemOfItemOfPageItem)
    }, [selectedItemOfItemOfPageItem]);

    const saveItem = (e) => {
        e.preventDefault()
        setPageItems(findAndReplace(pageItems, selectedItemOfItemOfPageItem, formData))
        dispatch(setSelectedItemOfItemOfPageItem(formData))
    }

    return (<form onSubmit={saveItem} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
        <div
            className="text-lg font-public-sans font-bold">Edit {capitalizeFirstLetter(selectedItemOfItemOfPageItem.type, true)}</div>
        <hr/>
        <div className="checkbox-wrapper-14 flex justify-between my-2">
            <span>Show Title</span>
            <input type="checkbox" className="switch" checked={formData.show_name}
                   onChange={(e) => setFormData({...formData, show_name: e.target.checked})}/>
        </div>
        {formData.show_name && <div className="w-full">
            <label htmlFor="order">Title</label>
            <input type="text" id="order" value={formData.name} required={true}
                   onChange={(e) => setFormData((prevState) => ({...prevState, name: e.target.value}))}
                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
        </div>}

        {typesData.some(x => x.value === formData.type) &&
            <TypeSelect formData={formData} setFormData={setFormData}/>}
        {(formData.type.includes("products") || formData.type.includes("all") || formData.type.includes("selective")) && <>
            <div className="w-full">
                <label htmlFor="max_rows">Max Rows</label>
                <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                        id="max_rows" value={formData.max_rows}
                        onChange={(e) => setFormData({...formData, max_rows: parseInt(e.target.value)})}>
                    <option value={0} disabled selected>Select No. of Max Rows</option>
                    {[...Array(5)].map((x, i) => (<option key={i + 1} value={i + 1}>{i + 1}</option>))}
                    {formData.type === 'latest_products' && <option value={100}>All Products</option>}
                </select>
            </div>
            <SliderOptions formData={formData} setFormData={setFormData}/>
        </>}
        <button
            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
            type="submit">Save
        </button>
    </form>)
}
export default ItemSettings