import React, {useEffect, useRef, useState} from "react";
import csv from "csvtojson"
import * as XLSX from 'xlsx';
import {showAlert} from "../../../utils/providers/AlertProvider";
import {toTitleCase} from "../../../utils/UtilityFunctions";
import {useLocation} from "react-router-dom";
import axios from "axios";
import Loading from "../../../Components/loading";
import {Button} from "../../../Components/ui/Button";

const MigrateProductsPage = () => {
    const [loading, setLoading] = useState(false)
    const [responses, setResponses] = useState([])
    const [file, setFile] = useState(null)
    const location = useLocation()
    const pathname = location.pathname
    const handleFileUpload = (event) => {
        setFile(event.target.files[0])
    }
    const uploadStatusRef = useRef(null);

    const scrollToBottom = () => {
        if (uploadStatusRef.current) {
            uploadStatusRef.current.scrollTop = uploadStatusRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        // Automatically scroll to bottom when responses change
        scrollToBottom();
    }, [responses]);
    const submit = () => {
        if (file) {
            if (file.name.slice(-5) === ".xlsx") {
                xlsxFileRead(file)
            } else if (file.name.slice(-4) === ".csv") {
                csvFileRead(file)
            } else {
                console.log("File Type not Supported")
            }
        }
    }
    const uploadData = async (data) => {
        const products = data.products;
        const attributes = data.attributes;
        try {
            setLoading(true);
            const { data: attributeData } = await axios.post('/admin/products/update/shopify/attributes', attributes);
            attributeData.categories.map(x => setResponses(prevState => ([...prevState, x + " (Category)"])));
            attributeData.collections.map(x => setResponses(prevState => ([...prevState, x + " (Collection)"])));
            attributeData.variation_types.map(x => setResponses(prevState => ([...prevState, x + " (Variation Type)"])));
            attributeData.variations.map(x => setResponses(prevState => ([...prevState, x + " (Variation)"])));
        } catch (error) {
            showAlert("error", error.message);
            setLoading(false); // Stop loading if there's an error in the attributes update
            return;
        }

        try {
            const productPromises = products.map(async (product) => {
                try {
                    const { data: productData } = await axios.post('/admin/products/update/shopify/single', product);
                    setResponses(prevState => ([...prevState, productData + " (Product)"]));
                } catch (error) {
                    setResponses(prevState => ([...prevState, error.message]));
                }
            });

            await Promise.all(productPromises); // Wait for all product uploads to finish
        } catch (error) {
            showAlert("error", error.message);
        } finally {
            setLoading(false);
        }
    };

    const csvFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const csvData = e.target.result;
            const jsonData = await csv().fromString(csvData);
            uploadData(convert_products(jsonData))
            // Perform further operations with the converted JSON data
        };
        reader.readAsText(file);
    }
    const convert_products = (products) => {
        if (pathname.includes('shopify')) {
            return convert_shopify_products(products)
        } else {

        }
    }
    const convert_shopify_products = (products) => {
        products = products.map((product, i) => {
            return {
                id: i,
                title: product["Title"],
                description: product["Body (HTML)"],
                variant_price: product["Variant Price"],
                variant_stock: Math.abs(parseInt(product["Variant Inventory Qty"])),
                image: product["Image Src"],
                collections: product["Tags"],
                category: product["Type"],
                variation_type: product["Option1 Name"],
                variation: product["Option1 Value"],
            }
        })
        products = transformProducts(products)
        const attributes = getUniqueAttributes(products)
        products = updateProducts(products).filter(x => !x.name.includes("Untitled"))
        console.log(products)
        return {products, attributes}
    }
    const updateProducts = (products) => {
        return products.map((product) => {
            // Find the lowest price among the variations
            const lowestPrice = Math.min(...product.variation_types[0].variants.map(variant => parseInt(variant.price, 10)));
            const highestStock = Math.max(...product.variation_types[0].variants.map(variant => parseInt(variant.stock, 10)));

            // Update each variation's price to be the difference from the lowest price
            const updatedVariants = product.variation_types[0].variants.map(variant => ({
                ...variant, price: parseInt(variant.price, 10) - lowestPrice
            }));

            // Add the lowest price as the new "price" attribute for the product
            return {
                ...product, variation_types: [{
                    ...product.variation_types[0], variants: updatedVariants
                }], price: lowestPrice, stock: highestStock, images: product.images.filter(x => x !== "")
            };
        });
    };
    const transformProducts = (products) => {
        const transformed = [];
        let currentProduct = null;

        products.forEach((product) => {
            if (product.title) {
                if (currentProduct) {
                    transformed.push(currentProduct);
                }
                const category = product.category.split(", ").filter(x => x !== "")[0]

                currentProduct = {
                    name: toTitleCase(product.title),
                    description: product.description,
                    collections: product.collections.split(", ").map(item => toTitleCase(item.trim())).filter(x => x !== ""),
                    category: toTitleCase(category === undefined ? "Uncategorized" : category),
                    images: [product.image],
                    variation_types: [{
                        name: toTitleCase(product.variation_type), variants: [{
                            variation: toTitleCase(product.variation),
                            price: product.variant_price,
                            stock: product.variant_stock,
                        }]
                    },],
                };

            } else {
                if (product.variation_type) {
                    currentProduct.variation_types.push({
                        name: toTitleCase(product.variation_type), variants: [{
                            variation: toTitleCase(product.variation),
                            price: product.variant_price,
                            stock: product.variant_stock,
                        }]
                    });

                } else if (product.variation) {
                    currentProduct.variation_types[currentProduct.variation_types.length - 1].variants.push({
                        variation: toTitleCase(product.variation),
                        price: product.variant_price,
                        stock: product.variant_stock,
                    });
                }
                if (product.image) {
                    currentProduct.images.push(product.image)
                }
            }
        });

        if (currentProduct) {
            transformed.push(currentProduct);
        }

        return transformed;
    };
    const getUniqueAttributes = (products) => {
        const getUniqueVariationTypes = (products) => {
            const variationTypes = {};

            products.forEach(product => {
                product.variation_types.forEach(type => {
                    if (!variationTypes[toTitleCase(type.name)]) {
                        variationTypes[toTitleCase(type.name)] = new Set();
                    }
                    type.variants.forEach(variant => {
                        variationTypes[toTitleCase(type.name)].add(toTitleCase(variant.variation));
                    });
                });
            });

            // Convert sets to arrays
            Object.keys(variationTypes).forEach(key => {
                variationTypes[key] = Array.from(variationTypes[key]);
            });

            return Object.keys(variationTypes).map((key) => ({
                name: key, variants: variationTypes[key]
            }));
        };
        const categories = new Set();
        const collections = [];

        products.forEach(product => {
            if (product.category) categories.add(product.category);
            if (product.collections) collections.push(product.collections);
        });

        return {
            categories: Array.from(categories),
            collections: [...new Set(collections.flat())],
            variationTypes: getUniqueVariationTypes(products),
        };
    };
    const xlsxFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});

            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one to convert
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            uploadData(convert_products(jsonData))
        }
        reader.readAsArrayBuffer(file);
        // Perform further operations with the converted JSON data
    }


    return (<React.Fragment>
        <div className="bg-gray-100 py-1">
            <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                <div>Migrate Complete Data From Shopify</div>
                <hr/>
                <div>
                    <label htmlFor="file_uploader"
                           className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                        <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                        <span className="text-lg text-gray-600">Supported format: csv</span>
                        <span
                            className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">Browse File</span>
                    </label>
                    {!loading && <input id="file_uploader" type="file" accept=".csv, .xlsx" className="hidden"
                                        onChange={handleFileUpload}/>}
                </div>
                {file && file.name}
                <Button onClick={!loading && submit}>{loading ?
                    <Loading height="25px" small={true}/> : "Migrate Now!"}</Button>
                <div ref={uploadStatusRef} className="w-full border rounded-lg p-2 flex flex-col gap-1 overflow-y-auto max-h-52">
                    {responses.map((x, i) => {
                        const bgColor = x.includes("already") ? "bg-orange-600" : x.includes("error") ? "bg-red-600" : "bg-green-500"
                        return (<div
                            className={`${bgColor} px-2 py-1 text-gray-100 rounded-lg`}>{(i + 1) + ". " + x}</div>)
                    })}
                </div>
            </div>

        </div>

    </React.Fragment>)
}
export default MigrateProductsPage