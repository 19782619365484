import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchFooterLinks} from "../../../redux/actions/PagesDataActions";
import {useParams} from "react-router-dom";

const FooterLinksPage = () => {
    const {footerData, success} = useSelector((state) => state.footerLinksReducer);
    const {footer_links, footer_headers} = footerData
    const [columnHeaders, setColumnHeaders] = useState({first: "", second: ""})
    const [footerLinks, setFooterLinks] = useState([])
    const [formData, setFormData] = useState({
        header_id: 0,
        title: "", link: ""
    })
    const {id} = useParams()
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    useEffect(() => {
        if (success) {
            setColumnHeaders({first: footer_headers[0].name, second: footer_headers[1].name})
            setFooterLinks(transformFooterLinks(footer_links, footer_headers))
        }
    }, [success]);
    useEffect(() => {
        dispatch(fetchFooterLinks())
    }, []);
    useEffect(() => {
        if (id && footer_links) {
            const footerLinksData = transformFooterLinks(footer_links, footer_headers)
            setFormData(footerLinksData.find(x => x.id === Number(id)))
        }
    }, [id, footer_links]);
    const transformFooterLinks = (footer_links, footer_headers) => {
        return footer_links.map(link => {
            // Find the matching header object
            const matchedHeader = footer_headers.find(header => header.id === link.header_id);

            // Return a new object with all properties of the current link and an additional 'header' property
            return {
                ...link,
                header: matchedHeader ? matchedHeader.name : null, // Use the header name if found, otherwise set to null
            };
        });
    };
    const submitFooterHeaders = async (e) => {
        e.preventDefault()
        try {
            const {data} = await axios.post('/admin/home/update/footer-headers', columnHeaders);
            showAlert("success", "Footer Headers Updated Successfully")
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    const submitFooterLink = async (e) => {
        e.preventDefault()
        try {
            if(id) {
                const {data} = await axios.post('/admin/home/update/footer-link/'+id, formData);
                showAlert("success", "Footer Link Updated Successfully")
            } else {
                if(formData.header_id === 0) {
                    showAlert('error', 'Please Select a Column Header')
                } else {
                    const {data} = await axios.post('/admin/home/update/footer-links', formData);
                    showAlert("success", "Footer Link Added Successfully")
                    dispatch(fetchFooterLinks())
                }
            }
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                {!id && <form onSubmit={submitFooterHeaders}>
                    <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Header Titles</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="first">1st Header</label>
                            <input type="text" id="first" value={columnHeaders.first} required
                                   onChange={(e) => setColumnHeaders({...columnHeaders, first: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="second">2nd Header</label>
                            <input type="text" id="second" value={columnHeaders.second} required
                                   onChange={(e) => setColumnHeaders({...columnHeaders, second: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value="Update Headers"/>
                    </div>
                </form>}
                <form onSubmit={submitFooterLink}>
                    <div className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Footer Link</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="header">Select Header</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="header" value={formData.header_id}
                                    onChange={(e) => setFormData({...formData, header_id: e.target.value})}>
                            <option value={0} selected disabled>Select Footer Header</option>
                                <option value={1}>{columnHeaders.first}</option>
                                <option value={2}>{columnHeaders.second}</option>
                            </select>
                        </div>
                        <div className="w-full">
                            <label htmlFor="name">Link Title</label>
                            <input type="text" id="name" value={formData.title}
                                   onChange={(e) => setFormData({...formData, title: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="name">Link</label>
                            <input type="text" id="name" value={formData.link}
                                   onChange={(e) => setFormData({...formData, link: e.target.value})}
                                   placeholder={"https://"}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value={id ? "Update" : "Add" + " Footer Link"}/>
                    </div>
                </form>
                {footerLinks.length !== 0 && !id && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={footerLinks} tableName="Footer Links"/>
                </div>}
            </div>
        </React.Fragment>
    )
}
export default FooterLinksPage