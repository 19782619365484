import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {fetchNavigationPage} from "../../../redux/actions/PagesDataActions";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import useAlert from "../../../CustomHooks/useAlert";
import {useParams} from "react-router-dom";
import IconsSelector from "../../../utils/IconsSelector/IconsSelector";
import DragDropHandler from "../../../Components/DragDropHandler";
import AddMultipleObjects from "../../../Components/ui/AddMultipleObjects";


const types = [{name: "Internal Link", value: "internal_link"}, {
    name: "External Link",
    value: "external_link"
}, {name: "All Categories", value: "all_categories"}, {
    name: "Selective Categories",
    value: "selective_categories"
}, {name: "Child Categories", value: "child_categories"}, {
    name: "All Collections",
    value: "all_collections"
}, {name: "Selective Collections", value: "selective_collections"},]

const NavigationPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    useEffect(() => {
        dispatch(fetchNavigationPage())
    }, []);
    const [showAlert, AlertComponent] = useAlert();
    const [formData, setFormData] = useState({
        name: "",
        external_link: "https://",
        internal_link: "/",
        type: "",
        icon_name: "",
        icon_svg: ""
    })

    const [selectedTypes, setSelectedTypes] = useState([])

    const {categories} = useSelector((state) => state.categoryList);
    const {collections} = useSelector((state) => state.collectionList);
    const menuItemsData = useSelector((state) => state.menuItems);
    const {menuItems} = menuItemsData

    useEffect(() => {
        if (id && menuItemsData.success) {
            const menuItem = menuItems.find(x => x.id === Number(id))
            setFormData(menuItem)
            let objectsArray = []
            if (menuItem.type.includes("_categories")) {
                objectsArray = categories
            } else if (menuItem.type.includes("_collections")) {
                objectsArray = collections
            }
            setSelectedTypes(objectsArray.reduce((acc, item) => {
                // Check if the item.id is included in product.collections
                if (JSON.parse(menuItem.type_ids).some(x => x === item.id)) {
                    acc.push({
                        label: item.name, value: item.id
                    });
                }
                return acc;
            }, []));
        }
    }, [id, menuItemsData.success]);

    const submit = async () => {
        if (id) {
            try {
                const response = await axios.post("/admin/home/update/menu-item/" + id, {
                    ...formData, type_ids: selectedTypes.map(x => x.value)
                })
                showAlert("success", "Menu Item Updated")
            } catch (e) {
                console.log(e)
                showAlert('error', e.message)
            }
        } else {
            try {
                const response = await axios.post("/admin/home/update/menu-items", {
                    ...formData, type_ids: selectedTypes.map(x => x.value)
                })
                showAlert("success", "Menu Item Added")
            } catch (e) {
                console.log(e)
                showAlert('error', e.message)
            }
        }
        dispatch(fetchNavigationPage())
    }
    const submitArrangement = async (reorderedArray) => {
        try {
            const {data} = await axios.post("admin/home/update/menu-order", reorderedArray)
            showAlert("success", "Menu Item Arranged Successfully!")
        } catch (e) {
            showAlert("error", e.response && e.response.data.detail ? e.response.data.detail : e.message)
        }
        dispatch(fetchNavigationPage())
    }

    useEffect(() => {
        if(!id) {
            setSelectedTypes([])
        }
    }, [formData.type]);


    return (menuItemsData.success && <React.Fragment>
        {AlertComponent}
        <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
            <div className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                <span className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Link To Menu</span>
                <hr/>
                <IconsSelector formData={formData} setFormData={setFormData}/>
                <div className="w-full">
                    <label htmlFor="order">Title</label>
                    <input type="text" id="order" value={formData.name}
                           onChange={(e) => setFormData((prevState) => ({...prevState, name: e.target.value}))}
                           className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                </div>
                <div className="w-full">
                    <label htmlFor="type">Type</label>
                    <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                            id="type" value={formData.type} required={true}
                            onChange={(e) => setFormData({...formData, type: e.target.value})}>
                        <option value={""} disabled selected>Select Type</option>
                        {types.map(type => (<option key={type.value} value={type.value}>{type.name}</option>))}
                    </select>
                </div>
                {formData.type === "child_categories" || formData.type === "selective_categories" ?
                    <div className="w-full">
                        <label htmlFor="category">Category</label>
                        <AddMultipleObjects objectsArray={categories} setSelectedObjects={setSelectedTypes}
                                            selectedObjects={selectedTypes} showAlert={showAlert}/>
                    </div> : formData.type === "selective_collections" ? <div className="w-full">
                        <label htmlFor="collection">Collection</label>
                        <AddMultipleObjects objectsArray={collections} setSelectedObjects={setSelectedTypes}
                                            selectedObjects={selectedTypes} showAlert={showAlert}/>
                    </div> : formData.type === "internal_link" ? <div className="w-full">
                        <label htmlFor="internal_link">Internal Link (Within Your Website)</label>
                        <input type="text" id="internal_link" value={formData.internal_link}
                               onChange={(e) => {
                                   let value = e.target.value;
                                   if (!value.startsWith('/')) {
                                       value = '/' + value;
                                   }
                                   setFormData((prevState) => ({
                                       ...prevState, internal_link: value
                                   }))
                               }}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div> : formData.type === "external_link" ? <div className="w-full">
                        <label htmlFor="external_link">External Link (Any Other Website)</label>
                        <input type="text" id="external_link" value={formData.external_link}
                               onChange={(e) => {
                                   let value = e.target.value;
                                   if (!value.startsWith('https://')) {
                                       value = 'https://' + value;
                                   }
                                   setFormData((prevState) => ({
                                       ...prevState, external_link: value
                                   }))
                               }}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div> : null}
                <button onClick={submit}
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit">{(id ? "Update" : "Add") + " Menu Item"}</button>
            </div>
            {!id && <DragDropHandler ItemComponent={MenuComponent} submitArrangement={submitArrangement}
                                     itemsArray={menuItems}/>}
            {!id && <div className="rounded-lg bg-white mx-2 mb-4">
                <TableComponent TableData={menuItems} tableName="Menu Items" showAlert={showAlert}/>
            </div>}
        </div>
    </React.Fragment>)
}

const MenuComponent = ({item}) => {
    return (<div className="flex items-center">
           <span className="font-light mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                     height="24" viewBox="0 -960 960 960"
                     className="text-black fill-current">
                    <path d={item.icon_svg}/>
                 </svg>
          </span>
        <span>{item.name}</span>
    </div>)
}
export default NavigationPage

