import React, {useEffect, useState} from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import {GrapesjsReact} from 'grapesjs-react';
import basic from "grapesjs-blocks-basic"
import imageEditor from 'grapesjs-tui-image-editor'
import textEditor from "grapesjs-plugin-ckeditor"
import gradientStyle from "grapesjs-style-gradient"
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import tabs from "grapesjs-tabs"
import customCode from "grapesjs-custom-code"
import bgStyle from "grapesjs-style-bg"

const GrapesJSEditor = ({customHtml, customCss, setFormData, formData}) => {
    const [editor, setEditor] = useState(null);
    const plugins = [basic, textEditor, imageEditor, tabs, customCode, bgStyle, gradientStyle, gjsPresetWebpage];
    const pluginsOpts = {
        imageEditor: {
            config: {
                includeUI: {
                    initMenu: 'filter',
                },
            },
        },
    };

    // useEffect(() => {
    //     if (!editor) { // Only initialize if there is no existing instance
    //         const editorInstance = grapesjs.init({
    //             container: '#gjs',
    //             storageManager: false,
    //             fromElement: true,
    //             customUI: true,
    //             plugins: [basic, textEditor, imageEditor, tabs, customCode, bgStyle, gradientStyle, gjsPresetWebpage],
    //             pluginsOpts: {
    //                 imageEditor: {
    //                     config: {
    //                         includeUI: {
    //                             initMenu: 'filter',
    //                         },
    //                     },
    //                 }
    //             }
    //         });
    //         editorInstance.setComponents(customHtml);
    //         editorInstance.setStyle(customCss);
    //         setEditor(editorInstance); // Update state only after complete setup
    //         return () => {
    //             editorInstance.destroy();
    //             setEditor(null)
    //         }
    //     }
    //
    // }, []); // Dependencies should ensure re-init only when necessary

    useEffect(() => {
        if (editor) {
            const updateFormData = () => {
                const html = editor.getHtml();
                const css = editor.getCss();
                setFormData({...formData, html: html, css: css})
            };

            editor.on('component:update', updateFormData);
            editor.on('style:update', updateFormData);

            return () => {
                editor.off('component:update', updateFormData);
                editor.off('style:update', updateFormData);
            };
        }
    }, [editor, formData]); // React only on editor or formData changes

    useEffect(() => {
        if(editor) {
            editor.setComponents(customHtml);
            editor.setStyle(customCss);
        }
    }, [editor, customCss, customHtml]);

    return (
        <GrapesjsReact
            id="gjs"
            plugins={plugins}
            pluginsOpts={pluginsOpts}
            onInit={editorInstance => {
                setEditor(editorInstance);
                editorInstance.setComponents(customHtml || '');
                editorInstance.setStyle(customCss || '');
            }}
            styleManager={{}}
            blockManager={{}}
            traitManager={{}}
            storageManager={{
                type: null // Disable local storage management
            }}
            height="100vh"
            width="auto"
        />
    );
}

export default GrapesJSEditor;
