import React from "react";

export const Input = ({className, id, label, ...props}) => {
    return (<div className="w-full">
        {label && <label htmlFor={id}>{label}</label>}
        <input id={id} {...props}
               className={`py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500 ${className}`}/>
    </div>)
}

export const Textarea = ({className, id, label, ...props}) => {
    return (<div className="w-full">
        {label && <label htmlFor={id}>{label}</label>}
        <textarea id={id} {...props}
                  className={`py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500 ${className}`}/>
    </div>)
}

