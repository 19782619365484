import React from "react";
import {Draggable} from "react-beautiful-dnd";
import PlaceholderItem from "./PlaceholderItem";
import {ITEM_OF_ITEM_OF_PAGE_ITEM} from "../../../../../redux/Constants/PageEditorConstants";
import {useDispatch, useSelector} from "react-redux";
import {
    setSelectedItemOfItemOfPageItem, setSelectedItemOfPageItem, setSelectedPageItem, setSelectedTab
} from "../../../../../redux/actions/PageEditorActions";
import {updateIndexOfArray} from "../../../../../utils/UtilityFunctions";
import {usePageEditorItems} from "../../../../../CustomHooks/usePageEditorItems";
import DefaultItem from "../ContentVisuals/DefaultItem";
import ProductCard from "../ContentVisuals/ProductCard";
import RichContentEditor from "../ContentVisuals/RichContentEditor";
import CustomCode from "../ContentVisuals/CustomCode";
import TeamMember from "../ContentVisuals/TeamMember";
import FeatureCard from "../ContentVisuals/FeatureCard";
import ImageSlider from "../ContentVisuals/ImageSlider/ImageSlider";
import ContactForm from "../ContentVisuals/ContactForm";
import ContactUsInformation from "../ContentVisuals/ContactUsInformation";
import SocialLinks from "../ContentVisuals/SocialLinks";
import CategoryItem from "../ContentVisuals/CategoryItem";
import CollectionItem from "../ContentVisuals/CollectionItem";

const ItemOfItemOfPageItem = ({item, itemOfItemOfPageItemIndex, itemOfPageItemIndex, pageItemIndex}) => {
    const [pageItems, setPageItems] = usePageEditorItems()
    const selectedItemOfItemOfPageItem = useSelector((state) => state.selectedItemOfItemOfPageItem);
    const dispatch = useDispatch()

    const componentMapping = [{
        type: 'single_product_card',
        component: ProductCard,
        props: {product: item.data}
    }, {type: 'rich_content_editor', component: RichContentEditor, props: {...item.data}}, {
        type: 'custom_code',
        component: CustomCode,
        props: {...item.data}
    }, {type: 'feature', component: FeatureCard, props: {...item.data}}, {
        type: 'team_member',
        component: TeamMember,
        props: {...item.data}
    }, {type: 'image_slider', component: ImageSlider, props: {slider: item.data}}, {
        type: 'contact_us_form',
        component: ContactForm,
        props: {title: item.show_name ? item.name : null}
    }, {
        type: 'contact_information', component: ContactUsInformation, props: {title: item.show_name ? item.name : null}
    }, {
        type: 'social_links',
        component: SocialLinks,
        props: {title: item.show_name ? item.name : null}
    }, {type: 'category', component: CategoryItem, props: {category: item.data}}, {
        type: 'collection',
        component: CollectionItem,
        props: {collection: item.data}
    },];
    const RenderSelectedItem = () => {
        const requiredTypes = ["products", "selective", "all"];
        const excludedTypes = ["contact", "social"];

        if (requiredTypes.some(type => item.type.includes(type)) || (item.type_id === -1 && !excludedTypes.some(type => item.type.includes(type)))) {
            return <DefaultItem item={item}/>;
        }
        const mapping = componentMapping.find(mapping => mapping.type === item.type);
        return mapping ? React.createElement(mapping.component, mapping.props) : null;
    };

    const handleClick = (e) => {
        e.stopPropagation()
        dispatch(setSelectedPageItem({}))
        dispatch(setSelectedItemOfPageItem({}))
        dispatch(setSelectedItemOfItemOfPageItem(item))
        dispatch(setSelectedTab('Settings'))
    }
    return (<Draggable
            draggableId={"itemOfItemOfPageItemIndex " + itemOfItemOfPageItemIndex + ", itemOfPageItemIndex " + itemOfPageItemIndex + ", pageItemIndex " + pageItemIndex}
            index={itemOfItemOfPageItemIndex}>
            {(provided) => (<div {...provided.dragHandleProps} {...provided.draggableProps}
                                 ref={provided.innerRef}
                                 className="w-full">
                <PlaceholderItem before={true}
                                 vertical={true}
                                 itemIndex={itemOfPageItemIndex}
                                 type={ITEM_OF_ITEM_OF_PAGE_ITEM}
                                 itemOfItemIndex={itemOfItemOfPageItemIndex}
                                 pageIndex={pageItemIndex}/>
                <div
                    className={`relative w-full object-contain cursor-grab min-h-[100px] flex justify-center items-center gap-5 p-5 overflow-hidden rounded border-2 ${selectedItemOfItemOfPageItem === item ? "border-blue-500" : "border-gray-300"} bg-white shadow-sm hover:shadow-md`}
                    onClick={handleClick}>
                    {RenderSelectedItem()}
                    {selectedItemOfItemOfPageItem === item && (<span
                        onClick={() => deleteItemOfItemOfPageItem(itemOfPageItemIndex, pageItemIndex, itemOfItemOfPageItemIndex, setPageItems, false)}
                        className="material-symbols-rounded absolute text-sm top-0 right-0 text-white bg-red-500 rounded-lg px-1 py-0.5 cursor-pointer">delete</span>)}
                </div>
                <PlaceholderItem before={false}
                                 vertical={true}
                                 itemIndex={itemOfPageItemIndex}
                                 type={ITEM_OF_ITEM_OF_PAGE_ITEM}
                                 itemOfItemIndex={itemOfItemOfPageItemIndex}
                                 pageIndex={pageItemIndex}/>
            </div>)}
        </Draggable>)
}
export default ItemOfItemOfPageItem

const deleteItemOfItemOfPageItem = (itemIndex, pageIndex, itemOfItemIndex, setPageItems) => {
    setPageItems((prevState) => {
        return prevState.map(pageItem => {
            if (pageItem.index === pageIndex) {
                return {
                    ...pageItem, items: pageItem.items.map(innerItem => {
                        if (innerItem.index === itemIndex) {
                            const newItems = [...innerItem.items];
                            newItems.splice(itemOfItemIndex, 1);
                            return {
                                ...innerItem, items: updateIndexOfArray(newItems)
                            };
                        }
                        return innerItem;
                    })
                };
            }
            return pageItem;
        });
    });
}
