export const VARIATIONS_WITH_TYPES_LIST_REQUEST = "VARIATIONS_WITH_TYPES_LIST_REQUEST"
export const VARIATIONS_WITH_TYPES_LIST_SUCCESS = "VARIATIONS_WITH_TYPES_LIST_SUCCESS"
export const VARIATIONS_WITH_TYPES_LIST_ERROR = "VARIATIONS_WITH_TYPES_LIST_ERROR"

export const PRODUCT_VARIATIONS_WITH_TYPES_LIST_REQUEST = "PRODUCT_VARIATIONS_WITH_TYPES_LIST_REQUEST"
export const PRODUCT_VARIATIONS_WITH_TYPES_LIST_SUCCESS = "PRODUCT_VARIATIONS_WITH_TYPES_LIST_SUCCESS"
export const PRODUCT_VARIATIONS_WITH_TYPES_LIST_ERROR = "PRODUCT_VARIATIONS_WITH_TYPES_LIST_ERROR"

export const VARIATION_TYPES_LIST_REQUEST = "VARIATION_TYPES_LIST_REQUEST"
export const VARIATION_TYPES_LIST_SUCCESS = "VARIATION_TYPES_LIST_SUCCESS"
export const VARIATION_TYPES_LIST_ERROR = "VARIATION_TYPES_LIST_ERROR"

export const VARIATIONS_LIST_REQUEST = "VARIATIONS_LIST_REQUEST"
export const VARIATIONS_LIST_SUCCESS = "VARIATIONS_LIST_SUCCESS"
export const VARIATIONS_LIST_ERROR = "VARIATIONS_LIST_ERROR"

export const VARIATION_UPDATE_REQUEST = "VARIATION_UPDATE_REQUEST"
export const VARIATION_UPDATE_SUCCESS = "VARIATION_UPDATE_SUCCESS"
export const VARIATION_UPDATE_ERROR = "VARIATION_UPDATE_ERROR"

export const ADD_VARIATION_REQUEST = "ADD_VARIATION_REQUEST"
export const ADD_VARIATION_SUCCESS = "ADD_VARIATION_SUCCESS"
export const ADD_VARIATION_ERROR = "ADD_VARIATION_ERROR"

export const VARIATION_TYPE_UPDATE_REQUEST = "VARIATION_TYPE_UPDATE_REQUEST"
export const VARIATION_TYPE_UPDATE_SUCCESS = "VARIATION_TYPE_UPDATE_SUCCESS"
export const VARIATION_TYPE_UPDATE_ERROR = "VARIATION_TYPE_UPDATE_ERROR"

export const ADD_VARIATION_TYPE_REQUEST = "ADD_VARIATION_TYPE_REQUEST"
export const ADD_VARIATION_TYPE_SUCCESS = "ADD_VARIATION_TYPE_SUCCESS"
export const ADD_VARIATION_TYPE_ERROR = "ADD_VARIATION_TYPE_ERROR"