import React, {useEffect, useState} from "react";
import useAlert from "../../../CustomHooks/useAlert";
import {useParams} from "react-router-dom";
import axios from "axios";
import {fetchCustomCodes} from "../../../redux/actions/PagesDataActions";
import {useDispatch, useSelector} from "react-redux";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github_dark";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";

const CustomCodes = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {customCodes, success} = useSelector((state) => state.customCodesReducer);
    useEffect(() => {
        dispatch(fetchCustomCodes())
    }, []);
    const [formData, setFormData] = useState({
        name: "",
        javascript: "",
        html: "",
        css: ""
    })
    useEffect(() => {
        if (id && success) {
            const customCode = customCodes.find(x => x.id === Number(id))
            setFormData(customCode)
        }
    }, [id, success]);
    const [showAlert, AlertComponent] = useAlert();
    const submit = async (e) => {
        e.preventDefault()
        if (id) {
            try {
                const response = await axios.post('/admin/content/update/custom-code/' + id, formData);
                showAlert("success", "Custom Code Updated")
                dispatch(fetchCustomCodes())
            } catch (error) {
                showAlert("error", error.message)
            }
        } else {
            try {
                const response = await axios.post('/admin/content/add/custom-code', formData);
                showAlert("success", "Custom Code Added")
                dispatch(fetchCustomCodes())
            } catch (error) {
                showAlert("error", error.message)
            }
        }
    }
    const handleChange = (value, field) => {
        setFormData(prevState => ({...prevState, [field]: value}));
    };

    return (
        <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
            {AlertComponent}
            <form onSubmit={submit} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Custom Code</span>
                <hr/>
                <div className="w-full">
                    <label htmlFor="order">Title</label>
                    <input type="text" id="order" value={formData.name} required={true}
                           onChange={(e) => setFormData((prevState) => ({...prevState, name: e.target.value}))}
                           className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                </div>
                <div className="w-full">
                    <div>HTML</div>
                    <AceEditor
                        mode="html"
                        theme="github_dark"
                        value={formData.html}
                        onChange={(value) => handleChange(value, 'html')}
                        name="htmlEditor"
                        editorProps={{$blockScrolling: true}}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        style={{width: '100%', height: '150px'}}
                    />
                    <div className="mt-4">CSS</div>
                    <AceEditor
                        mode="css"
                        theme="github_dark"
                        value={formData.css}
                        onChange={(value) => handleChange(value, 'css')}
                        name="cssEditor"
                        editorProps={{$blockScrolling: true}}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        style={{width: '100%', height: '150px'}}
                    />
                    <div className="mt-4">Javascript</div>
                    <AceEditor
                        mode="javascript"
                        theme="github_dark"
                        value={formData.javascript}
                        onChange={(value) => handleChange(value, 'javascript')}
                        name="javascriptEditor"
                        editorProps={{$blockScrolling: true}}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        style={{width: '100%', height: '150px'}}
                    />
                </div>
                <button
                    className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                    type="submit">{(id ? "Update" : "Add")} Custom Code
                </button>
            </form>
            {success && customCodes.length > 0 && !id && <div className="rounded-lg bg-white mx-2 mb-4">
                <TableComponent TableData={customCodes.map(({id, name, updated_at}) => ({
                    id,
                    name,
                    updated_at
                }))} tableName="Custom Codes" showAlert={showAlert}/>
            </div>}
        </div>
    )
}
export default CustomCodes