import React from "react";
import Img from "../../../../../utils/Image";


const CollectionItem = ({collection}) => {
    return (
            <div className="flex items-center rounded-lg px-5 py-3 border-[1px]">
                <div className="relative h-10 w-10 mr-5">
                    <Img className="object-contain h-10 w-10" src={collection.image} alt={collection.name}/>
                </div>
                <span className="font-semibold font-poppins">{collection.name}</span>
            </div>
    )
}
export default CollectionItem