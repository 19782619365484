export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST"
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS"
export const PRODUCT_LIST_ERROR = "PRODUCT_LIST_ERROR"

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST"
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS"
export const PRODUCT_DETAILS_ERROR = "PRODUCT_DETAILS_ERROR"

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST"
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS"
export const PRODUCT_UPDATE_ERROR = "PRODUCT_UPDATE_ERROR"

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR"

export const DELETE_PRODUCT_VARIANT_IMAGE_REQUEST = "DELETE_PRODUCT_VARIANT_IMAGE_REQUEST"
export const DELETE_PRODUCT_VARIANT_IMAGE_SUCCESS = "DELETE_PRODUCT_VARIANT_IMAGE_SUCCESS"
export const DELETE_PRODUCT_VARIANT_IMAGE_ERROR = "DELETE_PRODUCT_VARIANT_IMAGE_ERROR"

export const PRODUCTS_UPDATE_REQUEST = "PRODUCTS_UPDATE_REQUEST"
export const PRODUCTS_UPDATE_SUCCESS = "PRODUCTS_UPDATE_SUCCESS"
export const PRODUCTS_UPDATE_ERROR = "PRODUCTS_UPDATE_ERROR"

export const ADD_PRODUCTS_REQUEST = "ADD_PRODUCTS_REQUEST"
export const ADD_PRODUCTS_SUCCESS = "ADD_PRODUCTS_SUCCESS"
export const ADD_PRODUCTS_ERROR = "ADD_PRODUCTS_ERROR"