import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchCategoryList, updateCategory} from "../../../redux/actions/CategoryActions";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import {useParams} from "react-router-dom";
import AlertBox from "../../../utils/providers/AlertProvider/AlertBox";
import {getBaseUrl} from "../../../App";

const EditCategoryPage = () => {
    const dispatch = useDispatch()
    const categoriesListData = useSelector((state) => state.categoryList);
    const {categories} = categoriesListData
    useEffect(() => {
        if (categories.length < 1) dispatch(fetchCategoryList())
    }, [])
    const editCategoryStatus = useSelector((state) => state.updateCategory);
    const {success, error, loading, message} = editCategoryStatus
    const [image, setImage] = useState({})
    const [fieldsError, setFieldsError] = useState(null);
    const {id} = useParams()
    const [showAlert, setShowAlert] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [formData, setFormData] = useState({
        name: "", keywords: "", description: "", parent_id: 0, category_order: 0, visibility: true
    })
    useEffect(() => {
        setShowAlert(false)
        setIsSubmit(false)
        const category = categories.find(x => x.id == id)
        categoriesListData.success && setFormData({...category, image: category.image.slice(7)})
    }, [categoriesListData.success])
    const submit = (e) => {
        e.preventDefault()
        if (Object.keys(image).length !== 0) {
            setFieldsError(null)
            formData.image = image
            dispatch(updateCategory(formData, id))
            setIsSubmit(true)
        } else {
            dispatch(updateCategory(formData, id))
            setIsSubmit(true)
        }

    }
    const ShowAlertBox = (type, message) => {
        setShowAlert({type: type, message: message})
        const timer = setTimeout(() => {
            setShowAlert(false);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }
    useEffect(() => {
        if (isSubmit) {
            message && ShowAlertBox("success", "Category Updated Successfully!")
            error && ShowAlertBox("error", error)
        }
    }, [message, error])
    return (categoriesListData.success && <React.Fragment>
            {showAlert && <AlertBox type={showAlert.type} message={showAlert.message}/>}
            <div className="flex flex-col bg-gray-100 gap-4">
                <form onSubmit={submit}>
                    <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Category Information</span>
                        <hr/>

                        <div className="w-full">
                            <label htmlFor="name">Category Name</label>
                            <input required={true} type="text" id="full_name" value={formData.name}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="short_description">Short Description</label>
                            <textarea required={true} rows="5" id="short_description" value={formData.description}
                                      onChange={(e) => setFormData({...formData, description: e.target.value})}
                                      className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="category_order">Category Order</label>
                            <input required={true} type="number" id="category_order" value={formData.category_order}
                                   onChange={(e) => setFormData({...formData, category_order: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="category">Parent Category</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="category" value={formData.parent_id}
                                    onChange={(e) => setFormData({...formData, parent_id: e.target.value})}>
                                <option value={0}>None</option>
                                {categories.map(value => (
                                    <option key={value.id} value={value.id}>{value.name}</option>))}
                            </select>
                        </div>
                        <ImageUploadComponent setImage={setImage} title={"Set Category Image"}/>
                        {Object.keys(image).length === 0 && <div><img
                            className="h-56 w-auto border-2 rounded"
                            src={getBaseUrl() + "/media/" + formData.image}/>
                        </div>}
                        <span>
                            Category Image should be an icon with small size and dimensions
                    </span>

                        <div className="w-full">
                            <label htmlFor="keywords">Key Words</label>
                            <input type="text" id="keywords" value={formData.keywords}
                                   onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <button
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{!loading ? "Update Category" : "Loading..."}</button>
                    </div>
                </form>
            </div>
        </React.Fragment>)
}
export default EditCategoryPage