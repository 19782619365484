import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {useEffect} from "react";
import {fetchCategoryList, fetchCollectionList} from "../../../redux/actions/CategoryActions";
import {useLocation} from "react-router-dom";
import Loading from "../../../Components/loading";
import useAlert from "../../../CustomHooks/useAlert";

const CollectionsPage = ({type}) => {
    const {collections, success, loading} = useSelector((state) => state.collectionList);
    let collectionsData = []
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        collectionsData = []
        dispatch(fetchCollectionList())
    }, [location])
    success && collections.map((x, i) => {
            const obj = {
                id: x.id,
                image: x.image,
                name: x.name,
                description: x.description,
                ...x,
                updated_at: getDate(x.updated_at),
                created_at: getDate(x.created_at)
            }
            if (type === "deleted") {
                if (obj.visibility === 0) {
                    collectionsData.push(obj)
                }
            } else if (type === "drafted") {
                if (obj.visibility === 2) {
                    collectionsData.push(obj)
                }
            } else if (type === "all") {
                if (obj.visibility === 1) {
                    collectionsData.push(obj)
                }
            }

        }
    )

    return (
        <React.Fragment>
            {AlertComponent}
            {success &&
                <TableComponent TableData={collectionsData} tableName={capitalizeFirstLetter(type) + " Collections"} showAlert={showAlert}/>}
            {loading && <Loading/>}
        </React.Fragment>
    )
}
const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
}
const capitalizeFirstLetter = (str) => {
    return str.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
};
export default CollectionsPage