import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const DragDropHandler = ({submitArrangement, ItemComponent, itemsArray}) => {
    const [items, setItems] = useState(itemsArray.sort((a, b) => a.order - b.order))

    const handleDragDrop = (results) => {
        const {source, destination, type} = results
        if (!destination) return;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;
        if (type === "group") {
            const reorderedItems = [...items]
            const [removedItem] = reorderedItems.splice(source.index, 1)
            reorderedItems.splice(destination.index, 0, removedItem)
            return setItems(reorderedItems);
        }
    }

    return (
        itemsArray.length > 0 && <React.Fragment>
            <DragDropContext onDragEnd={handleDragDrop}>
                <div className="flex flex-col rounded-lg my-3 mx-10 p-4 bg-white gap-3">
                    <Droppable droppableId="ROOT" type={"group"}>
                        {(provided) => (
                            <div {...provided.droppableProps}
                                 ref={provided.innerRef}>
                                {items.map((item, index) => (
                                    <Draggable draggableId={"" + index} index={index}>
                                        {(provided) => (
                                            <div {...provided.dragHandleProps} {...provided.draggableProps}
                                                 ref={provided.innerRef} className={`text-gray-800 pb-3 mt-1 mb-1 pt-3 cursor-pointer flex items-center justify-between
                                                hover:shadow-[0_8px_15px_-15px_#333] border-b-[1px] leading-[100%] border-gray-200`}>
                                                <ItemComponent item={item}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <button onClick={() => submitArrangement(items)}
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{"Save Arrangement"}</button>
                </div>
            </DragDropContext>
        </React.Fragment>
    )
}
export default DragDropHandler

