import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import useAlert from "../../../../CustomHooks/useAlert";

const EditLocation = () => {
    const {id, type} = useParams()
    const [showAlert, AlertComponent] = useAlert();
    const [location, setLocation] = useState({name: "", shipping_rate: ""})
    useEffect(() => {
        const getLocation = async () => {
            try {
                const {data} = await axios.get(`/admin/settings/location/${type}/` + id)
                const name = type === 'country' ? data.country : type === 'state' ? data.state : data.city;
                setLocation({name: name, shipping_rate: data.shipping_rate})
            } catch (error) {
                console.log(error)
            }
        }
        getLocation()
    }, []);
    const submitLocation = async (e) => {
        e.preventDefault()
        try {
            const {data} = await axios.post(`/admin/settings/update/${type}/` + id, location)
            showAlert("success", location.name+" updated!")
        } catch (error) {
            console.log(error)
        }
    }
    return (
            <form onSubmit={submitLocation} className="">
                {AlertComponent}
                <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                    <div className="flex text-lg font-public-sans font-bold">
                        <span>Edit {type}</span>
                    </div>
                    <hr/>
                    <div className="w-full">
                        <label htmlFor="name">{type}</label>
                        <input type="text" id="name" value={location.name}
                               onChange={(e) => setLocation((prevState) => ({
                                   ...prevState, name: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="name">Shipping Rate</label>
                        <input type="text" id="name" value={location.shipping_rate}
                               onChange={(e) => setLocation((prevState) => ({
                                   ...prevState, shipping_rate: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <input
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit" value={`Update ${type}`}/>
                </div>
            </form>
    )
}
export default EditLocation