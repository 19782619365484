import React from "react";

export const Button = ({children, className, type, ...props}) => {
    return (
        <button {...props}
            className={`w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md ${className}`}
            type={type}>{children}</button>
    )
}

