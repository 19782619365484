import React, {useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {useParams} from "react-router-dom";
import {fetchCollectionList} from "../../../redux/actions/CategoryActions";
import Img from "../../../utils/Image";

const AddCollectionPage = () => {
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    const {collections, success} = useSelector((state) => state.collectionList);
    const [image, setImage] = useState({})
    const {id} = useParams()
    const [formData, setFormData] = useState({
        name: "",
        keywords: "",
        description: "",
        collection_order: 0
    })
    useEffect(() => {
        if (id && collections.length === 0) {
            dispatch(fetchCollectionList())
        }
        if (id && collections.length > 0) {
            const collection = collections.find(x => x.id === Number(id))
            setFormData(collection)
        }
    }, [id, collections]);
    const submit = async (e) => {
        e.preventDefault()
        formData.image = image
        if (id) {
            try {
                const response = await axios.post("/admin/collections/update/" + id, formData)
                showAlert('success', "Collection Updated Successfully")
            } catch (e) {
                showAlert('error', e.response && e.response.data.detail ?
                    e.response.data.detail :
                    e.message)
            }
        } else {
            try {
                const response = await axios.post("/admin/collections/update", formData)
                showAlert('success', "Collection Added Successfully")
            } catch (e) {
                showAlert('error', e.response && e.response.data.detail ?
                    e.response.data.detail :
                    e.message)
            }
        }
    }
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                <form onSubmit={submit}>
                    <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Collection Information</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="name">Name</label>
                            <input required={true} type="text" id="full_name" value={formData.name}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="short_description">Short Description</label>
                            <textarea required={true} rows="5" id="short_description" value={formData.description}
                                      onChange={(e) => setFormData({...formData, description: e.target.value})}
                                      className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="category_order">Collection Order</label>
                            <input required={true} type="number" id="category_order" value={formData.collection_order}
                                   onChange={(e) => setFormData({...formData, collection_order: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <ImageUploadComponent setImage={setImage} title={"Set Collection Image"} required={!id}/>
                        {(id && Object.keys(image).length === 0) && <div
                            className="flex w-full justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-56">
                            <Img
                                className="object-contain h-auto w-auto max-w-none max-h-full"
                                src={formData.image}/>
                        </div>}
                        <span>
                            Collection Image should be an icon with small size and dimensions
                    </span>
                        <div className="w-full">
                            <label htmlFor="keywords">Key Words</label>
                            <input type="text" id="keywords" value={formData.keywords} required={true}
                                   onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <button
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{id ? "Update" : "Add"} Collection
                        </button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
export default AddCollectionPage