import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SliderOptions from "../../../../../../Components/SliderOptions";
import {usePageEditorItems} from "../../../../../../CustomHooks/usePageEditorItems";

const ItemOrganizerSettings = () => {
    const selectedPageItem = useSelector((state) => state.selectedPageItem);
    const [pageItems, setPageItems] = usePageEditorItems()
    const [organizerData, setOrganizerData] = useState({
        lg_columns: 5,
        mb_columns: 3,
        is_slider: false,
        slider_speed: 500,
        slider_effect: 'simple',
        name: "",
        show_name: false
    })
    useEffect(() => {
        setOrganizerData(selectedPageItem)
    }, [selectedPageItem]);

    const submitOrganizer = (e) => {
        e.preventDefault()
        setPageItems(() => {
            const newArray = [...pageItems]
            newArray[newArray.findIndex((pageItem) => pageItem.index === selectedPageItem.index)] = organizerData
            return newArray
        })
    }
    return (

        <form onSubmit={submitOrganizer} className="flex text-gray-100 flex-col rounded-lg mt-4 mx-2 p-4 gap-3">
                    <span
                        className="text-lg font-public-sans font-bold">Edit Items Organizer</span>
            <hr/>
            <div className="checkbox-wrapper-14 flex justify-between my-2">
                <span>Show Title</span>
                <input type="checkbox" className="switch" checked={organizerData.show_name}
                       onChange={(e) => setOrganizerData({...organizerData, show_name: e.target.checked})}/>
            </div>
            {organizerData.show_name && <div className="w-full">
                <label htmlFor="order">Title</label>
                <input type="text" id="order" value={organizerData.name} required={true}
                       onChange={(e) => setOrganizerData((prevState) => ({...prevState, name: e.target.value}))}
                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500 bg-gray-800"/>
            </div>}
            <div className="w-full">
                <label htmlFor="lg_columns">Desktop Columns</label>
                <select className="py-2 px-3 mt-1 rounded-md w-full bg-gray-800 border border-gray-500"
                        id="type" value={organizerData.lg_columns} required={true}
                        onChange={(e) => setOrganizerData({...organizerData, lg_columns: e.target.value})}>
                    <option value={0} disabled selected>Select No. of Desktop Columns</option>
                    {[...Array(5)].map((x, i) => (
                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                </select>
            </div>
            <div className="w-full">
                <label htmlFor="mb_columns">Mobile Columns</label>
                <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500 bg-gray-800"
                        id="type" value={organizerData.mb_columns} required={true}
                        onChange={(e) => setOrganizerData({...organizerData, mb_columns: e.target.value})}>
                    <option value={0} disabled selected>Select No. of Mobile Columns</option>
                    {[...Array(3)].map((x, i) => (
                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                </select>
            </div>
            <SliderOptions formData={organizerData} setFormData={setOrganizerData} dark={true}/>
            <button
                className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                type="submit">Save
            </button>
        </form>
    )
}
export default ItemOrganizerSettings