import {
    GENERAL_SETTINGS_DATA_ERROR,
    GENERAL_SETTINGS_DATA_REQUEST,
    GENERAL_SETTINGS_DATA_SUCCESS,
    GENERAL_SETTINGS_UPDATE_ERROR,
    GENERAL_SETTINGS_UPDATE_REQUEST,
    SOCIAL_SETTINGS_DATA_SUCCESS,
    SOCIAL_SETTINGS_DATA_ERROR,
    SOCIAL_SETTINGS_DATA_REQUEST,
    GENERAL_SETTINGS_UPDATE_SUCCESS,
    CONTACT_SETTINGS_DATA_ERROR,
    CONTACT_SETTINGS_DATA_REQUEST,
    CONTACT_SETTINGS_DATA_SUCCESS,
    PAYMENT_SETTINGS_DATA_SUCCESS,
    PAYMENT_SETTINGS_DATA_ERROR,
    PAYMENT_SETTINGS_DATA_REQUEST,
    API_SETTINGS_DATA_REQUEST,
    API_SETTINGS_DATA_SUCCESS,
    API_SETTINGS_DATA_ERROR,
    SET_TINYMCE_API, STRIPE_PAYMENTS_DATA_REQUEST, STRIPE_PAYMENTS_DATA_SUCCESS, STRIPE_PAYMENTS_DATA_ERROR,
} from "../Constants/SettingsConstants";
import {FOOTER_LINKS_ERROR, FOOTER_LINKS_REQUEST, FOOTER_LINKS_SUCCESS} from "../Constants/GeneralItemsConstants";

export const generalSettingsDataReducer = (state = {generalSettings: {}}, action) => {
    switch (action.type) {
        case GENERAL_SETTINGS_DATA_REQUEST:
            return {loading: true, generalSettings: {}}
        case GENERAL_SETTINGS_DATA_SUCCESS:
            return {loading: false, success: true, generalSettings: action.payload}
        case GENERAL_SETTINGS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const footerLinksReducer = (state = {footerData: {}}, action) => {
    switch (action.type) {
        case FOOTER_LINKS_REQUEST:
            return {loading: true, footerData: {}}
        case FOOTER_LINKS_SUCCESS:
            return {loading: false, success: true, footerData: action.payload}
        case FOOTER_LINKS_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const contactSettingsDataReducer = (state = {contactSettings: []}, action) => {
    switch (action.type) {
        case CONTACT_SETTINGS_DATA_REQUEST:
            return {loading: true, contactSettings: []}
        case CONTACT_SETTINGS_DATA_SUCCESS:
            return {loading: false, success: true, contactSettings: action.payload}
        case CONTACT_SETTINGS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}


export const paymentSettingsDataReducer = (state = {paymentSettings: []}, action) => {
    switch (action.type) {
        case PAYMENT_SETTINGS_DATA_REQUEST:
            return {loading: true, paymentSettings: []}
        case PAYMENT_SETTINGS_DATA_SUCCESS:
            return {loading: false, success: true, paymentSettings: action.payload}
        case PAYMENT_SETTINGS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const tinymceApi = (state = {tinymceApi: []}, action) => {
    switch (action.type) {
        case SET_TINYMCE_API:
            return {success: true, tinymceApi: action.payload}
        default:
            return state;
    }
}
export const apiSettingsDataReducer = (state = {apiSettings: []}, action) => {
    switch (action.type) {
        case API_SETTINGS_DATA_REQUEST:
            return {loading: true, apiSettings: []}
        case API_SETTINGS_DATA_SUCCESS:
            return {loading: false, success: true, apiSettings: action.payload}
        case API_SETTINGS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const stripePaymentsDataReducer = (state = {stripePayments: []}, action) => {
    switch (action.type) {
        case STRIPE_PAYMENTS_DATA_REQUEST:
            return {loading: true, stripePayments: []}
        case STRIPE_PAYMENTS_DATA_SUCCESS:
            return {loading: false, success: true, stripePayments: action.payload}
        case STRIPE_PAYMENTS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}


export const socialSettingsDataReducer = (state = {socialSettings: []}, action) => {
    switch (action.type) {
        case SOCIAL_SETTINGS_DATA_REQUEST:
            return {loading: true, socialSettings: []}
        case SOCIAL_SETTINGS_DATA_SUCCESS:
            return {loading: false, success: true, socialSettings: action.payload}
        case SOCIAL_SETTINGS_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const updateGeneralSettingsReducer = (state = {message: {}}, action) => {
    switch (action.type) {
        case GENERAL_SETTINGS_UPDATE_REQUEST:
            return {loading: true, message: {}}
        case GENERAL_SETTINGS_UPDATE_SUCCESS:
            return {loading: false, success: true, message: action.payload}
        case GENERAL_SETTINGS_UPDATE_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}