import React from "react";

export const Select = ({children, className, label, id, ...props}) => {
    return (<div className="w-full">
        {label && <label htmlFor={id}>{label}</label>}
        <select className={`py-2 px-3 mt-1 rounded-md w-full border border-gray-500 ${className}`}
                id={id} {...props}>
            {children}
        </select>
    </div>)
}

