import React, {useEffect} from "react";
import PlaceholderItem from './PlaceholderItem'
import {ITEM_OF_ITEM_OF_PAGE_ITEM, ITEM_OF_PAGE_ITEM, PAGE_ITEM} from "../../../../../redux/Constants/PageEditorConstants";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import PageItem from "./PageItem";
import {getObjectFromString, updateIndexOfArray} from "../../../../../utils/UtilityFunctions";
import {usePageEditorItems} from "../../../../../CustomHooks/usePageEditorItems";
import useAlert from "../../../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import {fetchPageDataById} from "../../../../../redux/actions/PagesActions";
import {fetchContentForGroup} from "../../../../../redux/actions/PagesDataActions";

const PageEditor = () => {
    const [pageItems, setPageItems] = usePageEditorItems()
    const [showAlert, AlertComponent] = useAlert();
    const selectedPageId = useSelector((state) => state.selectedPageId);
    const dispatch = useDispatch()
    useEffect(() => {
        if(selectedPageId !== -1) {

        }
    }, [selectedPageId]);
    useEffect(() => {
        dispatch(fetchContentForGroup(0))
    }, []);

    const handleDragDrop = (results) => {
        const {source, destination, draggableId, type} = results
        const draggableType = type
        if (!destination) return;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;
        const draggedData = getObjectFromString(draggableId)
        const droppedAtData = {...getObjectFromString(destination.droppableId), index: destination.index}

        if (draggableType === PAGE_ITEM) {
            const reorderedItems = [...pageItems]
            const [removedItem] = reorderedItems.splice(source.index, 1)
            reorderedItems.splice(destination.index, 0, removedItem)
            return setPageItems(updateIndexOfArray(reorderedItems));
        } else if (draggableType === ITEM_OF_PAGE_ITEM) {
            const {pageItemIndex} = draggedData
            const {pageItemIndex: destinationPageItemIndex} = droppedAtData
            const reorderedItems = [...pageItems]
            const pageItem = reorderedItems[pageItemIndex]
            const [removedItem] = pageItem.items.splice(source.index, 1)
            reorderedItems[pageItemIndex] = {
                ...pageItem, items: pageItem.items
            }
            reorderedItems[destinationPageItemIndex].items.splice(destination.index, 0, removedItem)
            return setPageItems(updateIndexOfArray(reorderedItems));
        } else if (draggableType === ITEM_OF_ITEM_OF_PAGE_ITEM) {
            const {pageItemIndex, itemOfPageItemIndex} = draggedData
            const {
                pageItemIndex: destinationPageItemIndex, itemOfPageItemIndex: destinationItemOfPageItemIndex
            } = droppedAtData
            const reorderedItems = [...pageItems]
            const pageItem = reorderedItems[pageItemIndex]
            const item = pageItem.items[itemOfPageItemIndex]
            const [removedItem] = item.items.splice(source.index, 1)
            reorderedItems[pageItemIndex] = {
                ...pageItem, items: pageItem.items
            }
            reorderedItems[destinationPageItemIndex].items[destinationItemOfPageItemIndex].items.splice(destination.index, 0, removedItem)
            return setPageItems(updateIndexOfArray(reorderedItems));
        }
    }

    return (<DragDropContext className="page-editor-container bg-gray-100 p-8" onDragEnd={handleDragDrop}>
        {AlertComponent}
        {pageItems.length === 0 &&
            <PlaceholderItem pageIndex={0} type={PAGE_ITEM} vertical={true} before={true} last={true}/>}
        <Droppable droppableId="PageEditor 0" type={PAGE_ITEM}>
            {(provided) => (<div {...provided.droppableProps}
                                 ref={provided.innerRef}>
                {pageItems.map((pageItem, index) => (<PageItem key={index} item={pageItem} pageItemIndex={index}/>))}
                {provided.placeholder}
            </div>)}
        </Droppable>
    </DragDropContext>)
}
export default PageEditor


