export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST"
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS"
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR"

export const COLLECTION_LIST_REQUEST = "COLLECTION_LIST_REQUEST"
export const COLLECTION_LIST_SUCCESS = "COLLECTION_LIST_SUCCESS"
export const COLLECTION_LIST_ERROR = "COLLECTION_LIST_ERROR"

export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST"
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS"
export const CATEGORY_UPDATE_ERROR = "CATEGORY_UPDATE_ERROR"

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR"

export const CATEGORIES_UPDATE_REQUEST = "CATEGORIES_UPDATE_REQUEST"
export const CATEGORIES_UPDATE_SUCCESS = "CATEGORIES_UPDATE_SUCCESS"
export const CATEGORIES_UPDATE_ERROR = "CATEGORIES_UPDATE_ERROR"

export const ADD_CATEGORIES_REQUEST = "ADD_CATEGORIES_REQUEST"
export const ADD_CATEGORIES_SUCCESS = "ADD_CATEGORIES_SUCCESS"
export const ADD_CATEGORIES_ERROR = "ADD_CATEGORIES_ERROR"