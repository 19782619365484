import React from "react";
import {useParams} from "react-router-dom";
import PageManager from "../Content/PageManager";

const HomePageManagerPage = () => {
    const {id} = useParams()
    return (
        <PageManager id={id}/>
    )
}
export default HomePageManagerPage