import React, {useEffect, useState} from "react";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {fetchCouponsPage, fetchNavigationPage} from "../../../redux/actions/PagesDataActions";
import {useParams} from "react-router-dom";

const CouponsPage = () => {
    const [showAlert, AlertComponent] = useAlert();
    const {coupons, success} = useSelector((state) => state.couponsData);
    const {id} = useParams()
    const [formData, setFormData] = useState(
        {
            name: "",
            type: "discount",
            code: '',
            max_discount: 0,
            max_times: 0,
            min_subtotal: 0,
            percentage: 0,
            expiry_date: "",
            is_expired: false
        })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCouponsPage())
    }, []);
    useEffect(() => {
        if (id && coupons) {
            const coupon = coupons.find(x => x.id === Number(id))
            setFormData(coupon)
        }
    }, [id, coupons]);
    const submitNewCoupon = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/admin/home/update/coupons', formData);
            if (response.data === "success") {
                showAlert("success", "Coupon Added Successfully")
                dispatch(fetchCouponsPage())
            } else {
                showAlert("error", response.data)
            }
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    const submitUpdateCoupon = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/admin/home/update/coupon/' + id, formData);
            if (response.data === "success") {
                showAlert("success", "Coupon Updated Successfully")
            } else {
                showAlert("error", response.data)
            }
        } catch (error) {
            showAlert("error", error.message)
        }
    }

    const today = new Date().toISOString().split('T')[0];
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                <form onSubmit={id ? submitUpdateCoupon : submitNewCoupon}>
                    <div className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3">
                        <span
                            className="text-lg font-public-sans font-bold">{id ? "Edit Coupon" : "Create New Coupon"}</span>
                        <hr/>

                        <div className="w-full">
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" value={formData.name}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="name">Type</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="name" value={formData.type}
                                    onChange={(e) => setFormData({...formData, type: e.target.value})}>
                                <option value="discount">Discount</option>
                                <option value="gift">Gift</option>
                            </select>
                        </div>

                        <div className="w-full">
                            <label htmlFor="code">Code</label>
                            <input type="text" id="code" value={formData.code}
                                   onChange={(e) => setFormData({
                                       ...formData,
                                       code: e.target.value.replace(/\s/g, '').toUpperCase()
                                   })}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>

                        {formData.type === "discount" && <div className="w-full">
                            <label htmlFor="percentage">Percentage</label>
                            <input type="number" id="percentage" value={formData.percentage}
                                   onChange={(e) => setFormData({...formData, percentage: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>}

                        <div className="w-full">
                            <label htmlFor="min">Min Subtotal</label>
                            <input type="number" id="min" value={formData.min_subtotal}
                                   onChange={(e) => setFormData({...formData, min_subtotal: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>

                        {formData.type === "discount" && <div className="w-full">
                            <label htmlFor="max">Max Discount (0 = Depends on Percentage)</label>
                            <input type="number" id="max" value={formData.max_discount}
                                   onChange={(e) => setFormData({...formData, max_discount: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>}
                        <div className="w-full">
                            <label htmlFor="max_times">Times Usable (0 = Infinity)</label>
                            <input type="number" id="max_times" value={formData.max_times}
                                   onChange={(e) => setFormData({...formData, max_times: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="date">Expiry Date</label>
                            <input type="date" id="date" value={formData.expiry_date} min={today}
                                   onChange={(e) => setFormData({...formData, expiry_date: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        {id && <div className="checkbox-wrapper-14 flex justify-between my-2">
                            <span>Expired</span>
                            <input type="checkbox" className="switch" checked={formData.is_expired}
                                   onChange={(e) => setFormData({...formData, is_expired: e.target.checked})}/>
                        </div>}
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value={id ? "Update Coupon" : "Add Coupon"}/>
                    </div>
                </form>
                {(success && coupons.length !== 0 && id === undefined) &&
                    <div className="flex flex-col rounded-lg mb-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">All Coupons</span>
                        <hr/>
                        <div className="rounded-lg bg-white mx-2 mb-4">
                            <TableComponent TableData={coupons} tableName="Coupons" showAlert={showAlert}/>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
export default CouponsPage