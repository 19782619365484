import React from "react";
import Rating from "@mui/material/Rating";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTruck} from "@fortawesome/free-solid-svg-icons";
import Img from "../../../../../utils/Image";
import SvgIcon from "../../../../../utils/IconsSelector/SvgIcon";
import {ShoppingCart, Visibility} from "../../../../../utils/IconsSelector/SvgIconsData";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {useSelector} from "react-redux";


const ProductCard = ({product}) => {
    const currency = useSelector((state) => state.currencyReducer);
    return (<React.Fragment>
        <div
            className={`bg-white flex flex-col rounded border-[1px] w-full mx-2 my-2 text-sm font-medium font-poppins`}>
            <div className="relative">
                    <span style={{zIndex: 2}}
                          className="absolute text-center top-2 right-3 rounded-full px-2 py-2 pt-2 bg-white bg-opacity-70 font-light text-md "><SvgIcon
                        d={ShoppingCart} fillOpacity={0.7}/></span>
                <span
                    style={{
                        position: 'absolute', top: '3.5rem', // Converted from top-14
                        right: '0.75rem', // Converted from right-3
                        backgroundColor: 'rgba(255, 255, 255, 0.7)', // bg-white bg-opacity-70
                        padding: '0.5rem', // Converted from px-2 py-2,
                        zIndex: 2
                    }}
                >
                    <FavoriteBorderIcon color="action"/>
                </span>
                {product.discount_rate > 0 && <span
                    className="absolute z-10 text-center text-white rounded-br-full rounded-tr-full font-semibold top-2 left-0 px-2 py-1 bg-red-600  text-sm">{product.discount_rate}% Off</span>}
                {product.free_delivery && <div
                    className="absolute z-10 text-center text-white rounded-br-lg rounded-tr-lg bottom-2 left-0 px-2 py-1 bg-teal-600 text-sm">
                    <div className="flex gap-2 items-center">
                        <FontAwesomeIcon icon={faTruck}/>
                        <span>Free Delivery</span></div>
                </div>}
                <div
                    className="flex overflow-hidden border-b-[1px] justify-center items-center rounded h-40 w-[50wh]">
                    <Img className="object-contain max-w-none max-h-40 hover:scale-125 transition-all"
                         alt={product.name}
                         src={product.image}/>
                </div>
            </div>

            <div className="flex flex-col gap-1 px-3 py-2">
                <div
                    className="h-6 max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{product.name}</div>
                <div className="flex text-sm items-center">
                            <span
                                className="text-teal-500">{currency + (product.price * (100 - product.discount_rate) / 100)}</span>
                    {product.discount_rate > 0 && <span
                        className="ml-2 line-through text-gray-500">{currency + product.price}</span>}
                </div>
                <div className="flex text-xs items-center font-light">
                    <Rating className="-ml-0.5" name="half-rating-read" size="small"
                            defaultValue={product.rating} precision={0.5} readOnly/>
                    <span>({product.rating})</span>
                </div>
                <div className="flex justify-between font-xsm mt-1">
                            <span
                                className={`${product.stock_status === "Low Stock" ? "text-orange-500" : "text-gray-500"}`}>{product.stock_status === "Low Stock" ? product.stock_status : product.sold_times + " Sold"}</span>
                    <div className="flex items-center">
                        <span className="font-light text-sm mr-1"><SvgIcon d={Visibility}/></span>
                        <span className="text-xs font-light">{product.page_views}</span>
                    </div>
                </div>
                {product.stock_status !== "Out of Stock" ? <div
                        className="w-full text-center bg-gray-700 rounded-lg py-1 my-1 text-white">
                        Add</div> :
                    <div className="w-full text-center bg-yellow-700 rounded-lg py-1 my-1 text-white">Out of
                        Stock</div>}
            </div>

        </div>
    </React.Fragment>)
}
export default ProductCard