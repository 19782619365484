import {
    EDITOR_PAGE_DATA_ERROR,
    EDITOR_PAGE_DATA_REQUEST,
    EDITOR_PAGE_DATA_SUCCESS,
    MEDIA_LIST_ERROR,
    MEDIA_LIST_REQUEST,
    MEDIA_LIST_SUCCESS,
    PAGE_DATA_ERROR,
    PAGE_DATA_REQUEST,
    PAGE_DATA_SUCCESS,
    PAGE_LIST_ERROR,
    PAGE_LIST_REQUEST,
    PAGE_LIST_SUCCESS,
} from "../Constants/PagesConstants";

export const pageListReducer = (state = {pages: []}, action) => {
    switch (action.type) {
        case PAGE_LIST_REQUEST:
            return {loading: true, pages: []}
        case PAGE_LIST_SUCCESS:
            return {loading: false, success: true, pages: action.payload}
        case PAGE_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const mediaListReducer = (state = {mediaList: []}, action) => {
    switch (action.type) {
        case MEDIA_LIST_REQUEST:
            return {loading: true, mediaList: []}
        case MEDIA_LIST_SUCCESS:
            return {loading: false, success: true, mediaList: action.payload}
        case MEDIA_LIST_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

export const pageDataReducer = (state = {page: {}}, action) => {
    switch (action.type) {
        case PAGE_DATA_REQUEST:
            return {loading: true, page: {}}
        case PAGE_DATA_SUCCESS:
            return {loading: false, success: true, page: action.payload}
        case PAGE_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}
export const editorPageDataReducer = (state = {pageData: {}, pageItems: []}, action) => {
    switch (action.type) {
        case EDITOR_PAGE_DATA_REQUEST:
            return {loading: true, pageData: {}, pageItems: []}
        case EDITOR_PAGE_DATA_SUCCESS:
            return {loading: false, success: true, pageData: action.payload.page, pageItems: action.payload.page_items}
        case EDITOR_PAGE_DATA_ERROR:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}

