import React, {useEffect, useState} from "react";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {fetchSliderPage} from "../../../redux/actions/PagesDataActions";
import Loading from "../../../Components/loading";
import useAlert from "../../../CustomHooks/useAlert";
import {useParams} from "react-router-dom";
import {getBaseUrl} from "../../../App";

const effects = [
    {name: "simple", title: "Simple"},
    {name: "fade", title: "Fade"},
    {name: "cube", title: "Cube"},
    {name: "flip", title: "Flip"},
    {name: "coverflow", title: "Coverflow"},
    {name: "cards", title: "Cards"},
    {name: "cascadeslide", title: "Cascade Slide"},
    {name: "elegantshift", title: "Elegant Shift"},
    {name: "depthzoom", title: "Depth Zoom"},
    {name: "fliphorizon", title: "Flip Horizon"},
    {name: "slideover", title: "Slide Over"},
    {name: "eclipseshift", title: "Eclipse Shift"},
    {name: "verticalfall", title: "Vertical Fall"},
    {name: "opacitytwist", title: "Opacity Twist"}
];


const SlidersPage = () => {
    const [sliderBannerImg, setSliderBannerImg] = useState({})
    const [bannerFormData, setBannerFormData] = useState({
        slider_id: -1, link: "", order: 0,
    })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSliderPage())
    }, []);

    const {sliderId, bannerId} = useParams()
    const editing = sliderId || bannerId
    const [showAlert, AlertComponent] = useAlert();
    const {error, loading, message, success} = useSelector((state) => state.updateSlider);
    const slidersData = useSelector((state) => state.slidersData);
    const {sliders} = slidersData
    const {sliderBanners} = useSelector((state) => state.sliderBannersData);
    const [sliderFormData, setSliderFormData] = useState({name: "", speed: 1500, effect: "simple"})
    useEffect(() => {
        if (sliderId && slidersData.success) {
            const slider = sliders.find(x => x.id === Number(sliderId))
            setSliderFormData(slider)
        }
        if (bannerId && slidersData.success) {
            const banner = sliderBanners.find(x => x.id === Number(bannerId))
            setBannerFormData(banner)
        }
    }, [sliderId, bannerId, slidersData.success]);
    const submitBanner = async (e) => {
        e.preventDefault()
        bannerFormData.image = sliderBannerImg
        if (bannerId) {
            try {
                const response = await axios.post('/admin/home/update/slider-item/' + bannerId, bannerFormData);
                showAlert("success", "Slider Updated Successfully!")
            } catch (error) {
                console.error("There was an error!", error);
                showAlert("error", error)
            }
        } else {
            try {
                const response = await axios.post('/admin/home/update/slider-items', bannerFormData);
                showAlert("success", "Slider Item Added Successfully!")
            } catch (error) {
                console.error("There was an error!", error);
                showAlert("error", error)
            }
        }
        dispatch(fetchSliderPage())
    }
    const submitSlider = async (e) => {
        e.preventDefault()
        if (sliderId) {
            try {
                const response = await axios.post('/admin/home/update/slider/' + sliderId, sliderFormData);
                showAlert("success", "Slider Updated Successfully!")
            } catch (error) {
                console.error("There was an error!", error);
            }
        } else {
            try {
                const response = await axios.post('/admin/home/update/sliders', sliderFormData);
                showAlert("success", "Slider Added Successfully!")
            } catch (error) {
                console.error("There was an error!", error);
            }
        }
        dispatch(fetchSliderPage())
    };
    useEffect(() => {
        if (success) {
            message && showAlert("success", "Slider Item Added Successfully!")
        }
        if (error) {
            error && showAlert("error", error)
        }
    }, [message, error, success])
    return (
        <React.Fragment>
            {slidersData.success && <div className="flex flex-col bg-gray-100 gap-4">
                {AlertComponent}
                {!bannerId &&
                    <form onSubmit={submitSlider} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Slider</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="name">Title</label>
                            <input type="text" id="name" value={sliderFormData.name} required={true}
                                   onChange={(e) => setSliderFormData(prevState => ({
                                       ...prevState,
                                       name: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="speed">Speed in Milliseconds</label>
                            <input type="number" id="speed" value={sliderFormData.speed} required={true}
                                   onChange={(e) => setSliderFormData(prevState => ({
                                       ...prevState,
                                       speed: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="effect">Effect/Animation</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="effect" value={sliderFormData.effect}
                                    onChange={(e) => setSliderFormData(prevState => ({
                                        ...prevState,
                                        effect: e.target.value
                                    }))}>
                                <option disabled selected>Select Effect/Animation</option>
                                {effects.map(x => (
                                    <option key={x.name} value={x.name}>{x.title}</option>
                                ))}
                            </select>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value={sliderId ? "Update Slider" : "Add Slider"}/>
                    </form>}
                {!sliderId &&
                    <form onSubmit={submitBanner} className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Slider Item</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="slider_name">Slider Name</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="slider_name" value={bannerFormData.slider_id} required={true}
                                    onChange={(e) => setBannerFormData({...bannerFormData, slider_id: e.target.value})}>
                                <option value={-1} selected disabled>Select Slider</option>
                                {sliders.map((slider, index) => (
                                    <option value={slider.id}>{slider.name}</option>
                                ))}
                            </select>
                        </div>
                        <ImageUploadComponent setImage={setSliderBannerImg} title={"Sliding Image"}
                                              required={!bannerId}/>
                        {bannerId && Object.keys(sliderBannerImg).length === 0 && <div className="relative w-full">
                            <div
                                className="flex w-full justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                                <img src={getBaseUrl() + bannerFormData.image} alt="Preview"
                                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                            </div>
                        </div>}
                        <div className="w-full">
                            <label htmlFor="order">Order</label>
                            <input type="number" id="order" value={bannerFormData.order} required={true}
                                   onChange={(e) => setBannerFormData({...bannerFormData, order: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="link">Link (optional)</label>
                            <input type="text" id="link" value={bannerFormData.link}
                                   onChange={(e) => setBannerFormData({...bannerFormData, link: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value={bannerId ? "Update Slider Item" : "Add Slider Item"}/>
                    </form>}
                {!editing && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={sliders} tableName="Sliders" showAlert={showAlert}/>
                </div>}
                {!editing && <div className="rounded-lg bg-white mx-2 mb-4">
                    <TableComponent TableData={sliderBanners} tableName="Slider Items" showAlert={showAlert}/>
                </div>}
            </div>}
            {slidersData.loading && <Loading/>}

        </React.Fragment>)
}
export default SlidersPage