import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import {useLocation} from "react-router-dom";
import {fetchPageList} from "../../../redux/actions/PagesActions";
import Loading from "../../../Components/loading";
import useAlert from "../../../CustomHooks/useAlert";

const ContentPages = ({type}) => {
    const [showAlert, AlertComponent] = useAlert();
    const {pages, success, loading} = useSelector((state) => state.pageList);
    let pagesList = []
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        dispatch(fetchPageList())
        pagesList = []
    }, [location]);
    success && pages.map((x, i) => {
            const obj = {
                id: x.id,
                title: x.title,
                slug: "/" + x.slug,
                visibility: x.visibility,
                updated_at: getDate(x.updated_at),
                created_at: getDate(x.created_at)
            }
            if (type === "deleted") {
                if (obj.visibility === 0) {
                    pagesList.push(obj)
                }
            } else if (type === "drafted") {
                if (obj.visibility === 2) {
                    pagesList.push(obj)
                }
            } else if (type === "all") {
                if (obj.visibility === 1) {
                    pagesList.push(obj)
                }
            }

        }
    )

    return (
        <React.Fragment>
            {AlertComponent}
            {(success && Array.isArray(pages)) && <TableComponent TableData={pagesList} showAlert={showAlert}
                                                                     tableName={capitalizeFirstLetter(type) + " Pages"}/>}
            {loading && <Loading/>}
        </React.Fragment>
    )
}
const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    return date.toLocaleDateString('en-US', options)
}
const capitalizeFirstLetter = (str) => {
    return str.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
};
export default ContentPages