import React from "react";
import Img from "../../../../../utils/Image";


const CategoryItem = ({category}) => {
    return (
            <div className="flex items-center rounded-lg px-5 py-3 border-[1px]">
                <div className="relative h-10 w-10 mr-5">
                    <Img className="object-contain h-10 w-10" src={category.image} alt={category.name}/>
                </div>
                <span className="font-semibold font-poppins">{category.name}</span>
            </div>
    )
}
export default CategoryItem