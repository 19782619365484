import {
    ADD_PRODUCT_ERROR,
    ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, PRODUCT_DETAILS_ERROR, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_ERROR,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS, PRODUCT_UPDATE_REQUEST, PRODUCT_UPDATE_ERROR, PRODUCT_UPDATE_SUCCESS,
    DELETE_PRODUCT_VARIANT_IMAGE_ERROR, DELETE_PRODUCT_VARIANT_IMAGE_REQUEST, DELETE_PRODUCT_VARIANT_IMAGE_SUCCESS
} from "../Constants/ProductConstants";
import axios from "axios"

export const fetchProductList = () => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_LIST_REQUEST})

        const {data} = await axios.get("/general/products/?all_products=true")
        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: PRODUCT_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const fetchProductDetails = (slug) => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_DETAILS_REQUEST})
        const {data} = await axios.get(`/general/products/` + slug)
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: PRODUCT_DETAILS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const getProductData = (id) => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_DETAILS_REQUEST})
        const {data} = await axios.get(`/admin/content/product/` + id)
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: PRODUCT_DETAILS_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const postProduct = (product) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_PRODUCT_REQUEST})

        const {data} = await axios.post("/admin/products/update", product, {withCredentials: true})
        dispatch({
            type: ADD_PRODUCT_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ADD_PRODUCT_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const updateProduct = (product, id) => async (dispatch, getState) => {
    try {
        dispatch({type: PRODUCT_UPDATE_REQUEST})

        const {data} = await axios.post("/admin/products/update/" + id, product, {withCredentials: true})
        dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: PRODUCT_UPDATE_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}


export const deleteProductImageVariant = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_PRODUCT_VARIANT_IMAGE_REQUEST})

        const {data} = await axios.post("/admin/products/delete/image/" + id, {}, {withCredentials: true})
        dispatch({
            type: DELETE_PRODUCT_VARIANT_IMAGE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: DELETE_PRODUCT_VARIANT_IMAGE_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}