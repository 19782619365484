export const PAGE_LIST_REQUEST = "PAGE_LIST_REQUEST"
export const PAGE_LIST_SUCCESS = "PAGE_LIST_SUCCESS"
export const PAGE_LIST_ERROR = "PAGE_LIST_ERROR"

export const MEDIA_LIST_REQUEST = "MEDIA_LIST_REQUEST"
export const MEDIA_LIST_SUCCESS = "MEDIA_LIST_SUCCESS"
export const MEDIA_LIST_ERROR = "MEDIA_LIST_ERROR"

export const PAGE_DATA_REQUEST = "PAGE_DATA_REQUEST"
export const PAGE_DATA_SUCCESS = "PAGE_DATA_SUCCESS"
export const PAGE_DATA_ERROR = "PAGE_DATA_ERROR"

export const EDITOR_PAGE_DATA_REQUEST = "EDITOR_PAGE_DATA_REQUEST"
export const EDITOR_PAGE_DATA_SUCCESS = "EDITOR_PAGE_DATA_SUCCESS"
export const EDITOR_PAGE_DATA_ERROR = "EDITOR_PAGE_DATA_ERROR"