import React, {useEffect, useState} from "react";

const AlertBox = ({type, message}) => {
    const [showAlert, setShowAlert] = useState(false);
    const [showEndAnimation, setShowEndAnimation] = useState(false);
    useEffect(() => {
       setShowAlert(true)
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowEndAnimation(true);
        }, 4000);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAlert(false);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        showAlert &&
        <div style={{zIndex:200}}
            className={`fixed ${!showEndAnimation? "animate-fade-in":"animate-fade-out"} rounded-lg py-2 px-4 top-5  transition-all right-5 ${type === "success" ? "bg-green-700" : type === "error" ? "bg-red-700" : type === "info" ? "bg-blue-700" : type === "warning" ? "bg-yellow-600" : ""} text-white`}>
            <div className="flex items-center">
                <span className="material-symbols-rounded text-xl mr-2  text-white opacity-100">{type === "success" ? "check_circle" : type === "error" ? "cancel" : type === "info" ? "info" : type === "warning" ? "warning" : ""}</span>
                <div className="flex-col">{message}</div>
            </div>
        </div>
    )
}
export default AlertBox