import React from "react";
import ProductSearch from "../../../../Components/ProductSearch";
import {getBaseUrl} from "../../../../App";
import {showAlert} from "../../../../utils/providers/AlertProvider";

const BoughtTogetherProducts = ({products, setProducts}) => {
    const addProduct = (product) => {
        if (products.find(x => x.id === product.id)) {
            showAlert('error', "Product is Already Added in Bought Together")
        } else {
            setProducts((prev => [...prev, product]))
        }
    }

    const deleteProduct = (id) => {
        setProducts(products.filter(x => x.id !== id))
    }

    return (<div className="w-full">
        <label htmlFor="name">Bought Together With These Products</label>
        <ProductSearch setProduct={addProduct}/>
        {products.length !== 0 ? products.map(product => (<div
            className="relative border-b-[1px] hover:bg-gray-100 transition-all duration-500 items-center w-[99%] overflow-hidden">
            <div className="flex justify-between items-center px-2 w-full">
                <div className="flex justify-center items-center w-1/3">
                    <img className="w-full object-contain h-24"
                         src={getBaseUrl() + product.image} alt={product.name}/>
                </div>
                {product.discount_rate > 0 && <span
                    className="absolute z-10 text-center text-white rounded-br-full rounded-tr-full font-semibold top-2 left-0 px-2 py-1 bg-red-600  text-sm">{product.discount_rate}% Off</span>}
                <span onClick={() => deleteProduct(product.id)}
                      className="absolute text-center cursor-pointer top-4 text-red-500 right-1 rounded-full shadow-lg border-2 px-2 py-2 pt-2 material-symbols-rounded bg-black bg-opacity-10 font-light text-md ">delete</span>
                <div
                    className="flex flex-col ml-2 text-gray-700 py-2 px-2 font-poppins w-2/3">
                                                <span
                                                    className="font-semibold h-6 w-full overflow-hidden overflow-ellipsis whitespace-nowrap">{product.name}</span>
                    <div className="flex gap-2">
                                                        <span
                                                            className="font-semibold text-teal-500">{product.currency + Number((product.price * (100 - product.discount_rate) / 100).toFixed(2))}</span>
                        {product.discount_rate > 0 && <span
                            className="ml-2 line-through text-gray-500 px-2">{product.currency + Number(product.price.toFixed(2))}</span>}
                    </div>
                </div>
            </div>
        </div>)) : "No Product Selected!"}
    </div>)
}
export default BoughtTogetherProducts