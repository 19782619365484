import {
    USER_LIST_REQUEST,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    USER_UPDATE_ERROR,
    LOGIN_USER_REQUEST,
    REGISTER_USER_ERROR,
    USER_LIST_ERROR,
    USER_LIST_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_DATA_ERROR,
    USER_DATA_SUCCESS,
    USER_DATA_REQUEST,
    AUTH_TRUE,
    AUTH_FALSE,
    REFRESH_USER_REQUEST,
    REFRESH_USER_SUCCESS,
    REFRESH_USER_ERROR,
    LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS, LOGOUT_USER_ERROR
} from "../Constants/UserConstants";
import axios from "axios";

export const fetchUserList = () => async (dispatch) => {
    try {
        dispatch({type: USER_LIST_REQUEST})

        const {data} = await axios.get("/api/users")
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: USER_LIST_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const fetchUserData = (access_token) => async (dispatch) => {

    try {
        dispatch({type: USER_DATA_REQUEST})

        const {data} = await axios.get("/auth/user", {
            headers: {
                'Authorization': `Bearer ${access_token}`
            },
        })
        dispatch({
            type: USER_DATA_SUCCESS,
            payload: data

        })
        dispatch({
            type: AUTH_TRUE
        })
    } catch (e) {
        dispatch({
            type: USER_DATA_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })

    }
}

export const registerUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({type: REGISTER_USER_REQUEST})

        const {data} = await axios.post("/api/register", user)
        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: REGISTER_USER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}


export const loginUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({type: LOGIN_USER_REQUEST})

        const {data} = await axios.post("/auth/login", user, {withCredentials: true})
        dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: data.token
        })
        dispatch({
            type: AUTH_TRUE
        })
        dispatch({
            type: LOGOUT_USER_ERROR,
            payload: false
        })
    } catch (e) {
        dispatch({
            type: LOGIN_USER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const refreshUser = () => async (dispatch, getState) => {
    try {
        dispatch({type: REFRESH_USER_REQUEST})
        const {data} = await axios.post("/auth/refresh",{} ,{withCredentials: true})
        dispatch({
            type: REFRESH_USER_SUCCESS,
            payload: data.token
        })
    } catch (e) {
        dispatch({
            type: AUTH_FALSE
        })
        dispatch({
            type: REFRESH_USER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}


export const logoutUser = () => async (dispatch, getState) => {
    try {
        dispatch({type: LOGOUT_USER_REQUEST})
        const {data} = await axios.post("/auth/logout",{}, {withCredentials: true})
        dispatch({
            type: LOGOUT_USER_SUCCESS,
            payload: data.message
        })
        dispatch({
            type: AUTH_FALSE
        })
        dispatch({
            type: LOGIN_USER_ERROR,
            payload:false
        })
        dispatch({
            type: USER_DATA_ERROR,
            payload:false
        })
    } catch (e) {
        dispatch({
            type: LOGOUT_USER_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}

export const setAuth = (auth) => {
    if(auth) {
        return {
            type: AUTH_TRUE
        }
    } else {
        return {
            type: AUTH_FALSE
        }
    }
}
