import React from "react";

const CustomCode = ({css, html, javascript}) => {
    return (
        <div className="relative">
            <span className="absolute inset-0 bg-black bg-opacity-0"></span>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{__html: javascript}}
            />
            <style dangerouslySetInnerHTML={{__html: css}}/>
            <div className="w-full h-full" dangerouslySetInnerHTML={{__html: html}}></div>
        </div>
    )
}
export default CustomCode
