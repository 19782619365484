import React from "react";
import PageEditor from "./PageEditor/PageEditor";
import EditorSidebar from "./Sidebar/EditorSidebar";
import {useViewport} from "../../../../CustomHooks/useViewport";
import EditorTopBar from "./TopBar/EditorTopBar";


const CustomPageEditor = () => {
    const {isDesktop} = useViewport()
    return (isDesktop ?
        <div className="w-full flex flex-col">
            <EditorTopBar/>
            <div
                className="grid grid-cols-8 h-[80vh] border-2 border-black m-2 rounded-lg">
                <div className="col-span-6 m-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                    <PageEditor/>
                </div>
                <div
                    className="flex col-span-2 flex-col border-black border-l-2 bg-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 ">
                    <EditorSidebar/>
                </div>
            </div>
        </div> :
        <div className='h-screen w-screen flex justify-center items-center'>You must use desktop to use the page
            editor</div>)
}
export default CustomPageEditor