import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import {fetchSocialSettingsData} from "../../../redux/actions/SettingsActions";


const SocialSettingsPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSocialSettingsData())
    }, []);
    const [showAlert, AlertComponent] = useAlert();
    const {socialSettings, success} = useSelector((state) => state.socialSettingsData);

    const socialMediaIcons = [
        {icon: "facebook-square", placeholder: "Enter Facebook Username", color: "blue", link: "https://facebook.com/"},
        {
            icon: "square-x-twitter",
            placeholder: "Enter X-Twitter Username",
            color: "black",
            link: "https://twitter.com/"
        },
        {
            icon: "instagram-square",
            placeholder: "Enter Instagram Username",
            color: "#7a009c",
            link: "https://www.instagram.com/"
        },
        {
            icon: "square-threads",
            placeholder: "Enter Threads Username",
            color: "black",
            link: "https://www.threads.net/@"
        },
        {icon: "whatsapp-square", placeholder: "Enter Whatsapp Number", color: "green", link: "https://wa.me/"},
        {
            icon: "pinterest-square",
            placeholder: "Enter Pinterest Username",
            color: "red",
            link: "https://www.pinterest.com/"
        },
        {
            icon: "snapchat-square",
            placeholder: "Enter Snapchat Username",
            color: "#fac102",
            link: "https://www.snapchat.com/add/"
        },
        {icon: "youtube-square", placeholder: "Enter Youtube Username", color: "red", link: "https://www.youtube.com/"},
        {icon: "tiktok", placeholder: "Enter Tiktok Username", color: "black", link: "https://www.tiktok.com/@"},

    ];
    const [socialData, setSocialData] = useState(socialMediaIcons)
    useEffect(() => {
        if (success && socialSettings.length !== 0) {
            setSocialData(socialSettings)
        }
    }, [success]);
    const submit = async (e) => {
        try {
            const response = await axios.post('/admin/settings/update/social', socialData);
            showAlert("success", "Social Media Links Updated")
        } catch (error) {
            showAlert("error", error.message)
        }

    }
    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">Social Media Links</span>
                    {socialMediaIcons.map((item, index) => (
                        <div key={index} className="flex items-center mb-4">
                            <FontAwesomeIcon style={{color: item.color}} className="w-2/12 text-5xl"
                                             icon={['fab', item.icon]}/>
                            <input type="text" value={socialData[index].user_input}
                                   onChange={(e) => {
                                       const updatedSocialData = socialData.map(data => {
                                           if (data.icon === item.icon) {
                                               return {
                                                   ...data,
                                                   user_input: e.target.value
                                               };
                                           }
                                           return data;
                                       });
                                       setSocialData(updatedSocialData);
                                   }}
                                   required={true}
                                   className="py-2 px-3 mt-0.5 rounded-md w-10/12 border border-gray-500"
                                   placeholder={item.placeholder}/>
                        </div>
                    ))}
                    <button
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        onClick={submit}>{"Update Links"}</button>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SocialSettingsPage