import React from "react";

const DashboardCard = ({card, index}) => {
    return (
        <React.Fragment>
            <div style={{backgroundColor: card.color}} className={`flex w-full justify-between bg-opacity-50 rounded cursor-pointer group px-4 py-6`} >
                <div className="flex flex-col w-1/2">
                    <span className="text-xl mb-1 font-bold text-white">{numberWithCommas(card.count)}</span>
                    <span className="text-white text-sm">{card.label}</span>
                </div>
                <span className="material-symbols-rounded text-4xl transition-all group-hover:text-5xl text-black opacity-20 font-bold">{card.icon}</span>
            </div>
        </React.Fragment>
    )
}
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export default DashboardCard