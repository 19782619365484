import React, {useState} from "react";

const ImagesUploadComponent = ({images, setImages, title, required, media = false}) => {
    const [previewImages, setPreviewImages] = useState([])
    const [imagesObj, setImagesObj] = useState([])
    const handleUpload = (event) => {
        if (event.target.files) {
            for (let i = 0; i < event.target.files.length; i++) {
                const file = event.target.files[i]
                if (!images.find(img => (img.name === file.name && img.size === file.size))) {
                    file.uploading = true
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (media) {
                            setImages((prevState) => [...prevState, file])
                            setImagesObj((prevState) => [...prevState, {img: reader.result, file: file}])
                        } else {
                            setImages((prevState) => [...prevState, reader.result]);
                        }
                        setPreviewImages((prevState) => [...prevState, reader.result])
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    }
    const deleteImage = (img) => {
        if (media) {
            const filteredImagesObj = imagesObj.filter(x => (x.img !== img))
            const updated = previewImages.filter(x => (x !== img))
            setImagesObj(filteredImagesObj)
            const filteredImages = []
            filteredImagesObj.map(x => (
                filteredImages.push(x.file)
            ))
            setPreviewImages(filteredImages)
            setPreviewImages(updated)
        } else {
            const updated = images.filter(x => (x !== img))
            setImages(updated)
        }
    }
    return (
        <React.Fragment>
            <div className="w-full flex flex-col">
                <span>{title}</span>
                <label htmlFor="images"
                       className="cursor-pointer mt-1.5 flex py-2 px-3 gap-3 justify-center items-center text-white w-full rounded-lg bg-green-600 border-[1.5px] border-gray-500">
                    <span className="material-symbols-rounded text-3xl ">cloud_upload</span>
                    <span className="text-xl">Select Images</span>
                </label>
                <input multiple type="file" accept=".jpg, .jpeg, .png, .webp, .ico" id="images" size="40"
                       onChange={handleUpload} required={required}
                       className=" h-[1px] w-[1px]"/>
                {previewImages.map(img => (
                    <div className="relative w-full">
                        <div
                            className="flex justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                            <img src={img} alt="Preview"
                                 className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                            <span onClick={() => deleteImage(img)}
                                  className="cursor-pointer absolute text-center top-4 text-red-500 right-2 rounded-full shadow-lg border-2 px-2 py-2 pt-2 material-symbols-rounded bg-black bg-opacity-10 font-light text-md ">delete</span>
                        </div>
                    </div>
                ))
                }

            </div>
        </React.Fragment>
    )
}
export default ImagesUploadComponent