import React, { useRef, useState} from "react";

const ImageUploadComponent = ({setImage, title, required}) => {
    const fileInputRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const handleUpload = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result)
                setPreviewImage(reader.result)
            };
            reader.readAsDataURL(file);
        }
    }
    const deleteImage = () => {
        setImage({})
        setPreviewImage(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }
    return (
        <React.Fragment>
            <div className="w-full flex flex-col">
                <span>{title}</span>
                <label htmlFor={title}
                       className="cursor-pointer mt-1.5 flex py-2 px-3 gap-3 justify-center items-center text-white w-full rounded-lg bg-green-600 border-[1.5px] border-gray-500">
                    <span className="material-symbols-rounded text-2xl ">cloud_upload</span>
                    <span className="text-lg">Select Image</span>
                </label>
                {previewImage && (
                    <div className="relative w-full">
                        <div
                            className="flex justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                            <img src={previewImage} alt="Preview"
                                 className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                            <span onClick={deleteImage}
                                  className="cursor-pointer absolute text-center top-4 text-red-500 right-2 rounded-full shadow-lg border-2 px-2 py-2 pt-2 material-symbols-rounded bg-black bg-opacity-10 font-light text-md ">delete</span>
                        </div>
                    </div>
                )}
                <input name={"image"} type="file" accept=".jpg, .jpeg, .png, .webp, .ico" id={title} size="40"
                       onChange={handleUpload} ref={fileInputRef} required={required}
                       className=" h-[1px] w-[1px]"/>
            </div>
        </React.Fragment>
    )
}
export default ImageUploadComponent