import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";

const AccountSettingsPage = () => {
    const {user, success} = useSelector((state) => state.userData);
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        postal_code: ""
    })
    const [showAlert, AlertComponent] = useAlert();
    useEffect(() => {
        if (success) {
            setFormData({
                full_name: user.full_name,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                state: user.state,
                postal_code: user.postal_code
            })
        }
    }, [success]);
    const [passwordData, setPasswordData] = useState({
        old_password: "",
        new_password: "",
        password_confirm: ""
    })
    const updateAccountInfo = async () => {
        try {
            const {data} = await axios.post('/auth/update/user-info', formData);
            showAlert("success", "User Data Updated Successfully")
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    const updatePassword = async (e) => {
        e.preventDefault()
        try {
            const {data} = await axios.post('/auth/update/user-password', passwordData);
            showAlert("info", data)
            if (data.includes("uccess")) {
                setPasswordData({
                    old_password: "",
                    new_password: "",
                    password_confirm: ""
                })
            }
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <div className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">
                    <span className="text-lg font-public-sans font-bold">Account Info</span>
                    <div className="w-full">
                        <label htmlFor="full_name">Full Name</label>
                        <input type="text" id="full_name" required value={formData.full_name}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   full_name: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" required value={formData.email}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   email: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="phone">Phone</label>
                        <input type="text" id="phone" required value={formData.phone}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   phone: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="w-full">
                        <label htmlFor="address">Address</label>
                        <textarea rows={2} id="address" required value={formData.address}
                                  onChange={(e) => setFormData((prevState) => ({
                                      ...prevState,
                                      address: e.target.value
                                  }))}
                                  className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="w-full">
                            <label htmlFor="city">City</label>
                            <input type="text" id="city" required value={formData.city}
                                   onChange={(e) => setFormData((prevState) => ({
                                       ...prevState,
                                       city: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="state">State</label>
                            <input type="text" id="address" required value={formData.state}
                                   onChange={(e) => setFormData((prevState) => ({
                                       ...prevState,
                                       state: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                    </div>
                    <div className="w-full">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input type="text" id="postal_code" required value={formData.postal_code}
                               onChange={(e) => setFormData((prevState) => ({
                                   ...prevState,
                                   postal_code: e.target.value
                               }))}
                               className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    </div>
                    <div className="flex justify-center py-2 mx-2">
                        <button
                            className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            onClick={updateAccountInfo}>{"Update Account Info"}</button>
                    </div>
                </div>
                <form onSubmit={updatePassword}>
                    <div className="flex flex-col rounded-lg mt-2 mx-2 p-4 bg-white gap-3">

                        <span className="text-lg font-public-sans font-bold">Change Password</span>
                        <div className="w-full">
                            <label htmlFor="password">Old Password</label>
                            <input type="password" id="password" required value={passwordData.old_password}
                                   onChange={(e) => setPasswordData((prevState) => ({
                                       ...prevState,
                                       old_password: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="password">New Password</label>
                            <input type="password" id="password" required value={passwordData.new_password}
                                   onChange={(e) => setPasswordData((prevState) => ({
                                       ...prevState,
                                       new_password: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="password_confirm">Confirm Password</label>
                            <input type="password" id="password_confirm" required value={passwordData.password_confirm}
                                   onChange={(e) => setPasswordData((prevState) => ({
                                       ...prevState,
                                       password_confirm: e.target.value
                                   }))}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="flex justify-center py-2 mx-2">
                            <button
                                className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                                type="submit">{"Update Password"}</button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
export default AccountSettingsPage