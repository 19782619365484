import React, {useEffect, useState} from 'react';
import Feature from '../Pages/Components/Feature'
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {fetchFeatures} from "../../../redux/actions/PagesDataActions";
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import IconsSelector, {getPathSvg} from "../../../utils/IconsSelector/IconsSelector";

const FeaturesPage = () => {
    const initialFormState = {
        title_text: '',
        description_text: '',
        icon: {value: '', label: ""},
        title_text_color: '#ffffff',
        icon_color: '#ffffff',
        description_text_color: '#ffffff',
        icon_background_color: '#ffffff',
        background_gradient_direction: 'to right',
        background_start_color: '#00ff70',
        background_end_color: '#2c8100',
    }
    const [formData, setFormData] = useState(initialFormState);
    const {id} = useParams()
    const [showAlert, AlertComponent] = useAlert();
    useEffect(() => {
        if (!id) {
            setFormData(initialFormState)
        }
        dispatch(fetchFeatures())
    }, [id]);

    const {features, success} = useSelector((state) => state.featureItemsReducer);
    const dispatch = useDispatch()
    useEffect(() => {
        if (id && success) {
            const feature = features.find(x => x.id === Number(id))
            setFormData({
                title_text: feature.title_text,
                description_text: feature.description_text,
                icon: {value: feature.icon_name, label: feature.icon_name},
                title_text_color: feature.title_text_color,
                icon_color: feature.icon_color,
                description_text_color: feature.description_text_color,
                icon_background_color: feature.icon_background_color,
                background_gradient_direction: feature.background_gradient_direction,
                background_start_color: feature.background_start_color,
                background_end_color: feature.background_end_color,
            })
        }
    }, [id, success]);
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Gradient direction options
    const gradientDirections = [
        {label: 'To Right', value: 'to right'},
        {label: 'To Left', value: 'to left'},
        {label: 'To Top', value: 'to top'},
        {label: 'To Bottom', value: 'to bottom'},
        {label: 'To Top Right', value: 'to top right'},
        {label: 'To Top Left', value: 'to top left'},
        {label: 'To Bottom Right', value: 'to bottom right'},
        {label: 'To Bottom Left', value: 'to bottom left'},
    ];

    const isColorField = (fieldName) => {
        return fieldName.endsWith('_color');
    };

    const submitFeature = async (e) => {
        e.preventDefault()
        const dValue = getPathSvg(formData.icon.value)
        if (id) {
            try {
                const response = await axios.post("/admin/content/update/feature/" + id, {
                    ...formData,
                    icon_name: formData.icon.value,
                    icon_svg: dValue
                })
                showAlert("success", "Feature Updated")
            } catch (e) {
                console.log(e)
                showAlert('error', e.message)
            }
        } else {
            try {
                const response = await axios.post("/admin/content/add/feature", {
                    ...formData,
                    icon_name: formData.icon.value,
                    icon_svg: dValue
                })
                showAlert("success", "Feature Added")
            } catch (e) {
                console.log(e)
                showAlert('error', e.message)
            }
        }
        dispatch(fetchFeatures())
    }
    const delFeature = async (id) => {
        try {
            const response = await axios.post('/admin/content/delete/feature/' + id);
            showAlert("success", "Feature Deleted Successfully")
            dispatch(fetchFeatures())
        } catch (error) {
            showAlert("error", error.response.data.detail || error.message)
        }
    }
    return (
        <div className="flex flex-col bg-gray-100 gap-4">
            {AlertComponent}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 px-3 mt-2">
                <div></div>
                <Feature {...formData} iconPath={getPathSvg(formData.icon.value)}/>
            </div>
            <form onSubmit={submitFeature} className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3">
                <span className="text-lg font-public-sans font-bold">{id ? "Edit" : "Add"} Feature</span>
                <hr/>
                {Object.keys(formData).map((key) => (
                    <div key={key} style={{marginBottom: '10px'}} className="w-full">
                        <label htmlFor={key}
                               style={{marginRight: '10px'}}>{key === "icon" ? null : capitalizeWords(key.replaceAll('_', ' '))}</label>
                        {key === 'background_gradient_direction' ? (
                            <select
                                id={key}
                                className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                            >
                                {gradientDirections.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        ) : key === 'icon' ?
                            <IconsSelector formData={formData}
                                           setFormData={setFormData}/> : key === "description_text" ?
                                <textarea rows="5" value={formData[key]} required={true} name={key}
                                          onChange={handleChange}
                                          className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/> : (
                                    <input
                                        id={key}
                                        type={isColorField(key) ? "color" : "text"} // Determines if the input should be a color picker
                                        name={key}
                                        value={formData[key]}
                                        onChange={handleChange}
                                        required={!isColorField(key)}
                                        className={` mt-0.5 rounded-md ${isColorField(key) ? "w-10 p-0" : "w-full py-2 px-3"} border border-gray-500`}
                                        placeholder={capitalizeWords(key.replaceAll('_', ' '))}
                                    />
                                )}
                    </div>
                ))}
                <button
                    className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                    type="submit">{(id ? "Update" : "Add") + " Feature"}</button>
            </form>
            {!id && success && features.length > 0 && <div className="rounded-lg bg-white mx-2 mb-4">
                <span className="text-lg m-3 font-public-sans font-bold">All Features:</span>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 px-3 mt-2">
                    {features.map(x => (
                        <div className="relative">
                            <Feature key={x.id} iconPath={x.icon_svg} {...x} />
                            <Link to={'/feature/' + x.id}
                                  className="material-symbols-rounded absolute top-2 left-2 bg-white bg-opacity-70 rounded-full p-4 cursor-pointer text-green-500">edit</Link>
                            <span onClick={() => delFeature(x.id)}
                                  className="material-symbols-rounded absolute top-2 right-2 bg-white bg-opacity-70 rounded-full p-4 cursor-pointer text-red-500">delete</span>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    );
}
export default FeaturesPage

function capitalizeWords(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}