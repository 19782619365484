import {
    ADD_CATEGORY_ERROR,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    CATEGORY_LIST_ERROR,
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_ERROR,
    CATEGORY_UPDATE_REQUEST,
    COLLECTION_LIST_ERROR,
    COLLECTION_LIST_SUCCESS,
    COLLECTION_LIST_REQUEST
} from "../Constants/CategoryConstants";

import axios from "axios"

export const fetchCategoryList = () => async (dispatch) => {
    try {
        dispatch({type: CATEGORY_LIST_REQUEST})

        const {data} = await axios.get("/admin/categories/")
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: CATEGORY_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}
export const fetchCollectionList = () => async (dispatch) => {
    try {
        dispatch({type: COLLECTION_LIST_REQUEST})

        const {data} = await axios.get("/admin/collections/")
        dispatch({
            type: COLLECTION_LIST_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: COLLECTION_LIST_ERROR,
            payload: e.response && e.response.data.message ?
                e.response.data.message :
                e.message
        })
    }
}

export const postCategory = (category) => async (dispatch, getState) => {
    try {
        dispatch({type: ADD_CATEGORY_REQUEST})

        const {data} = await axios.post("/admin/categories/update", category, {withCredentials: true})
        dispatch({
            type: ADD_CATEGORY_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: ADD_CATEGORY_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}
export const updateCategory = (category, id) => async (dispatch, getState) => {
    try {
        dispatch({type: CATEGORY_UPDATE_REQUEST})

        const {data} = await axios.post("/admin/categories/update/" + id, category, {withCredentials: true})
        dispatch({
            type: CATEGORY_UPDATE_SUCCESS,
            payload: data
        })
    } catch (e) {
        dispatch({
            type: CATEGORY_UPDATE_ERROR,
            payload: e.response && e.response.data.detail ?
                e.response.data.detail :
                e.message
        })
    }
}