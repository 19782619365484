import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PageManager from "../Content/PageManager";
import IconsSelector, {getPathSvg} from "../../../utils/IconsSelector/IconsSelector";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import {fetchPageData} from "../../../redux/actions/PagesActions";
import {showAlert} from "../../../utils/providers/AlertProvider";

const AddPage = () => {
    const [formData, setFormData] = useState({
        title: "",
        slug: "",
        icon_name:"",
        icon_svg:"",
        description: "",
        keywords: ""
    })
    const navigate = useNavigate();
    const {id, slug} = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        if(slug) {
            dispatch(fetchPageData(slug))
        }
    }, [slug]);
    const {page, success} = useSelector((state) => state.pageData);
    useEffect(() => {
        if (success && slug) {
            setFormData(page)
        }
    }, [success, slug]);
    useEffect(() => {
        const originalTitle = document.title;

        // Change the title if `success` is true
        if (success) {
            document.title = "Edit " + page.title;
        }

        // Cleanup function to reset the title when the effect re-runs or component unmounts
        return () => {
            document.title = originalTitle;
        };
    }, [slug, success]);
    const submit = async (e) => {
        e.preventDefault()
        if (slug) {
            try {
                const {data} = await axios.post('/admin/pages/edit/' + slug, formData);
                showAlert("success", "Page Saved Successfully")
                if (data === "success") {
                    navigate('/update/page/' + formData.slug)
                } else {
                    showAlert("error", data.detail)
                }
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
        } else {
            try {
                const {data} = await axios.post('/admin/pages/update', formData);
                showAlert("success", "Page Saved Successfully")
                if (data === "success") {
                    navigate('/update/page/' + formData.slug)
                } else {
                    showAlert("error", data.detail)
                }
            } catch (error) {
                showAlert("error", error.response.data.detail)
            }
        }

    }
    return (
        <>
            {!id && <div className="flex flex-col rounded-lg mt-4 mx-2 p-4 bg-white gap-3">
                <span className="text-lg font-public-sans font-bold">{slug ? "Edit" : "Add"} Page</span>
                <hr/>
                <IconsSelector formData={formData} setFormData={setFormData}/>
                <div className="w-full">
                    <label htmlFor="order">Title</label>
                    <input type="text" id="order" value={formData.title}
                           onChange={(e) => setFormData((prevState) => ({
                               ...prevState,
                               title: e.target.value,
                               page_id: getSlug(e.target.value)
                           }))}
                           className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                </div>
                <div className="w-full">
                    <label htmlFor="slug">Slug</label>
                    <input type="text" id="slug" value={formData.slug}
                           onChange={(e) => setFormData((prevState) => ({...prevState, slug: getSlug(e.target.value)}))}
                           className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                    <span>Suggested Slug: {getSlug(formData.title)}</span>
                </div>

                <div className="w-full">
                    <label htmlFor="short_description">Description</label>
                    <textarea rows="5" value={formData.description}
                              onChange={(e) => setFormData({...formData, description: e.target.value})}
                              className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                </div>
                <div className="w-full">
                    <label htmlFor="keywords">Keywords</label>
                    <textarea rows="1" value={formData.keywords}
                              onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                              className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                </div>
                <button onClick={submit}
                        className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        type="submit">{(slug ? "Update" : "Add") + " Page"}</button>
            </div>}
            {(slug || id) && <PageManager slug={slug} id={id}/>}
        </>
    )
}
export default AddPage

const getSlug = (value) => {
    return value.toLowerCase()
        .replace(/ /g, "-")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\//g, "")
        .replace(/&/g, "and")  // Replaces '&' with 'and'
        .replace(/%/g, "")     // Removes '%'
        .replace(/\|/g, "-")   // Replaces '|' with '-'
        .replace(/ - /g, "-")  // Normalizes spaces and dashes to a single dash
        .replace(/,|\./g, "")  // Removes commas and periods
        .replace(/[^a-zA-Z0-9\-]+/g, "")
}