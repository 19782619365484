import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import {fetchTopBar, fetchTopBars} from "../../../redux/actions/GeneralItemsActions";
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import {fetchGeneralSettingsData} from "../../../redux/actions/SettingsActions";
import {fetchTopBarsPage} from "../../../redux/actions/PagesDataActions";

const TopBarPage = () => {
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchTopBarsPage())
    }, []);
    const generalSettingsData = useSelector((state) => state.generalSettingsData);
    const {topBars, success} = useSelector((state) => state.topBars);
    const topBarData = useSelector((state) => state.topBar);
    const [topBarState, setTopBarState] = useState(true)
    const {topBar} = topBarData
    const [formData, setFormData] = useState({text: "", is_slider: false, is_showing: true})
    const submit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('admin/home/update/top-bars', formData);
            showAlert("success", "Top Bar Updated Successfully")
            dispatch(fetchTopBarsPage())
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    useEffect(() => {
        if(generalSettingsData.success) {
            setTopBarState(generalSettingsData.generalSettings.is_top_bar)
        }
    }, [generalSettingsData.success]);
    useEffect(() => {
        if (topBarData.success && Object.keys(topBar).length !== 0) {
            setFormData(topBar)
        }
    }, [topBarData.success]);

    const deleteTopBar = async (x) => {
        try {
            const response = await axios.post('admin/home/update/top-bars', {
                text: x.text,
                is_slider: x.is_slider,
                is_showing: false
            });
            showAlert("success", "Top Bar Deleted Successfully")
            dispatch(fetchTopBar())
            dispatch(fetchTopBars())
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    const handleTopBarStatus = async (e) => {
        try {
            const response = await axios.post('admin/home/update/top-bar', {
                is_top_bar: !topBarState
            });
            showAlert("success", "Top Bar " + (topBarState ? "Disabled" : "Enabled") + " Successfully")
            setTopBarState(!topBarState)
            dispatch(fetchGeneralSettingsData())
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    return (
        topBarData.success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                <form onSubmit={submit}>
                    <div className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3">
                        <div className="flex justify-between items-center">
                            <span className="text-lg font-public-sans font-bold">Edit TopBar</span>
                            <div className="checkbox-wrapper-14 flex gap-2 justify-between my-2">
                                <span>{!topBarState ? "Disabled" : "Enabled"}</span>
                                <input type="checkbox" className="switch" checked={topBarState}
                                       onChange={handleTopBarStatus}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="name">Text</label>
                            <textarea rows="3" type="text" id="name" value={formData.text}
                                      onChange={(e) => setFormData({...formData, text: e.target.value})}
                                      className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="checkbox-wrapper-14 flex justify-between my-2">
                            <span>Slider</span>
                            <input type="checkbox" className="switch" checked={formData.is_slider}
                                   onChange={(e) => setFormData({...formData, is_slider: e.target.checked})}/>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value="Save Topbar"/>
                    </div>
                </form>
                {(success && topBars.length !== 0) &&
                    <div className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3 h-[50vh] overflow-y-auto">
                        <span className="text-lg font-public-sans font-bold">History</span>
                        <hr/>
                        {topBars.map(x => (
                            <div className="w-full flex flex-col">
                                <span>{x.text}</span>
                                <div className="flex gap-3 my-2 justify-between w-full items-center">
                                    <span>{getDate(x.created_at)}</span>
                                    <div className="flex gap-3">
                                <span onClick={() => setFormData({...formData, text: x.text, is_slider: x.is_slider})}
                                      className="bg-blue-600 px-2 py-1 rounded text-white"><FontAwesomeIcon
                                    icon={faEdit}/></span>
                                        <span onClick={() => deleteTopBar(x)}
                                              className="bg-red-600 px-2 py-1 rounded text-white"><FontAwesomeIcon
                                            icon={faTrashCan}/></span>
                                    </div>
                                </div>
                                <hr/>
                            </div>))}
                    </div>}
            </div>
        </React.Fragment>
    )
}
const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
}
export default TopBarPage