import React, {useEffect} from "react";
import {useState} from "react";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import AlertBox from "../../../utils/providers/AlertProvider/AlertBox";
import {fetchGeneralSettingsData, updateGeneralSettingsData} from "../../../redux/actions/SettingsActions";
import {useDispatch, useSelector} from "react-redux";
import {getBaseUrl} from "../../../App";
import useAlert from "../../../CustomHooks/useAlert";

const GeneralSettingsPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        keywords: "",
        url:"",
    })
    const [showAlert, AlertComponent] = useAlert();
    const [logoImage, setLogoImage] = useState({})
    const [faviconImage, setFaviconImage] = useState({})
    const {error, loading, message, success} = useSelector((state) => state.updateGeneralSettings);
    const generalSettingsData = useSelector((state) => state.generalSettingsData);
    const {generalSettings} = generalSettingsData
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchGeneralSettingsData())
    }, []);
    useEffect(() => {
        if (generalSettingsData.success) {
            setFormData({
                name: generalSettings.app_name,
                description: generalSettings.app_description,
                keywords: generalSettings.app_keywords,
                url: generalSettings.app_url
            })
        }
    }, [generalSettingsData.success])
    const submit = (e) => {
        e.preventDefault()
        formData.favicon = faviconImage
        formData.logo = logoImage
        dispatch(updateGeneralSettingsData(formData))
    }

    useEffect(() => {
        if (success) {
            message && showAlert("success", "General Settings Updated Successfully!")
        }
        if (!success) {
            error && showAlert("error", error)
        }
    }, [message, error, success])
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
                <form onSubmit={submit}>
                    <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                        <div className="w-full">
                            <label htmlFor="name">App URL</label>
                            <input type="text" value={formData.url} required={true}
                                   placeholder={"https://www.yourwebsite.com"}
                                   onChange={(e) => setFormData({...formData, url: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="name">App Name</label>
                            <input type="text" value={formData.name} required={true}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="short_description">Description</label>
                            <textarea rows="5" value={formData.description} required={true}
                                      onChange={(e) => setFormData({...formData, description: e.target.value})}
                                      className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="name">Keywords</label>
                            <input type="text" value={formData.keywords} required={true}
                                   onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <ImageUploadComponent setImage={setLogoImage} title={"App Logo"}/>
                        {Object.keys(logoImage).length === 0 && <div className="relative w-full">
                            <div
                                className="flex w-full justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                                <img src={getBaseUrl()+generalSettings.app_logo} alt="Preview"
                                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                            </div>
                        </div>}
                        <ImageUploadComponent setImage={setFaviconImage} title={"Favicon"}/>
                        {Object.keys(faviconImage).length === 0 && <div className="relative w-full">
                            <div
                                className="flex w-full justify-center border-2 rounded-lg items-center object-cover overflow-hidden mt-2 h-40 w-[50wh]">
                                <img src={getBaseUrl()+generalSettings.app_favicon} alt="Preview"
                                     className={"object-contain h-auto w-auto max-w-none max-h-full"}/>
                            </div>
                        </div>}
                        <button
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl mt-5 px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{!loading ? "Update General Settings" : "Loading...."}</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
export default GeneralSettingsPage