import {
    SET_MENU_ITEMS_ID,
    SET_SELECTED_PAGE,
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    SHOW_SEARCH,
    HIDE_SEARCH
} from "../Constants/AdminPanelConstants";

export const setMenuItemsId = (id) => {
    return {
        type: SET_MENU_ITEMS_ID,
        payload: id
    }
}
export const showSidebar = () => {
    return {
        type: SHOW_SIDEBAR
    }
}
export const hideSidebar = () => {
    return {
        type: HIDE_SIDEBAR
    }
}
export const showSearch = () => {
    return {
        type: SHOW_SEARCH
    }
}
export const hideSearch = () => {
    return {
        type: HIDE_SEARCH
    }
}
export const setSelectedPage = (page) => {
    return {
        type: SET_SELECTED_PAGE,
        payload: page
    }
}