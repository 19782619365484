import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchPaymentSettingsData} from "../../../redux/actions/SettingsActions";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";
import Select from "react-select";
import axios from "axios";
import useAlert from "../../../CustomHooks/useAlert";
import {Link} from "react-router-dom";
import Loading from "../../../Components/loading";

const ShippingSettingsPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPaymentSettingsData())
    }, []);
    const [showAlert, AlertComponent] = useAlert()
    const {paymentSettings, success, loading} = useSelector((state) => state.paymentSettingsData);
    const {shipping_data} = paymentSettings
    const [countryForm, setCountryForm] = useState({name: "", shipping_rate: ""})
    const [stateForm, setStateForm] = useState({name: "", shipping_rate: "", country_id: 0})
    const [cityForm, setCityForm] = useState({name: "", shipping_rate: "", state_id: 0})
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const handleOptionChanged = async (id, type) => {
        if (type === "country") {
            const data = shipping_data.find(x => x.id === id);
            if (data) {
                setStateForm((prevState) => ({...prevState, country_id: id}))
                try {
                    const states = await axios.get('/general/orders/country/' + data.id)
                    setStates(states.data)
                } catch (error) {
                    console.log(error)
                }
                // Assuming data.states is an array of states
            }
        } else if (type === "state") {
            const data = states.find(x => x.id === id)
            if (data) {
                setCityForm((prevState) => ({...prevState, state_id: id}))
                setCities([...data.cities]);
            }
        }
    }
    const submitCountry = async (e) => {
        e.preventDefault()
        await addData("countries", "Country", countryForm)
    }
    const submitState = async (e) => {
        e.preventDefault()
        await addData("states", "State", stateForm)

    }
    const submitCity = async (e) => {
        e.preventDefault()
        await addData("cities", "City", cityForm)
    }
    const addData = async (type, name, uploadData) => {
        try {
            const response = await axios.post('/admin/settings/update/' + type, uploadData);
            showAlert("success", name + " Added Successfully!")
            setStates([])
            setCities([])
            if (name === "Country") setCountryForm({name: "", shipping_rate: ""})
            else if (name === "State") setStateForm({name: "", shipping_rate: "", country_id: 0})
            else if (name === "City") setCityForm({name: "", shipping_rate: "", state_id: 0})
            dispatch(fetchPaymentSettingsData())
        } catch (error) {
            console.error("There was an error!", error);
        }
    };
    return (
        <>
            {loading && <Loading/>}
            {success && <React.Fragment>
                {AlertComponent}
                <div className="flex flex-col bg-gray-100 gap-4">
                    <div className="text-center p-4 rounded bg-white mx-4">Note: Total shipping cost will be an
                        accumulation
                        of country, state and city shipping rates
                    </div>
                    <div className="flex justify-center mt-3 items-center">
                        <Link to={'/import-locations'}>
                    <span
                        className={'text-center px-6 py-4 bg-purple-950 text-white rounded-lg border-black border-[1px] hover:bg-black hover:cursor-pointer transition-all duration-500'}>Import
                        Country's Locations
                    </span>
                        </Link>
                    </div>
                    <form onSubmit={submitCountry}>
                        <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                            <span className="text-lg font-public-sans font-bold">Add Country</span>
                            <hr/>
                            <div className="w-full">
                                <label htmlFor="name">Country</label>
                                <input type="text" id="name" value={countryForm.name}
                                       onChange={(e) => setCountryForm((prevState) => ({
                                           ...prevState, name: e.target.value
                                       }))}
                                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="name">Shipping Rate</label>
                                <input type="text" id="name" value={countryForm.shipping_rate}
                                       onChange={(e) => setCountryForm((prevState) => ({
                                           ...prevState, shipping_rate: e.target.value
                                       }))}
                                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                            </div>
                            <input
                                className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                                type="submit" value="Add Country"/>
                        </div>
                    </form>
                    <TableComponent tableName={"Countries"} TableData={shipping_data}/>
                    <form onSubmit={submitState}>
                        <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                            <span className="text-lg font-public-sans font-bold">Add State</span>
                            <hr/>
                            <div className="w-full">
                                <label htmlFor="country">Country</label>
                                <Select className="mt-1 w-full border border-gray-500"
                                        id="country" isSearchable={true}
                                        options={shipping_data.map(x => ({value: x.id, label: x.country}))}
                                        onChange={(option) => handleOptionChanged(option.value, "country")}/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="name">State</label>
                                <input type="text" id="name" value={stateForm.name}
                                       onChange={(e) => setStateForm((prevState) => ({
                                           ...prevState, name: e.target.value
                                       }))}
                                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="name">Shipping Rate</label>
                                <input type="text" id="name" value={stateForm.shipping_rate}
                                       onChange={(e) => setStateForm((prevState) => ({
                                           ...prevState, shipping_rate: e.target.value
                                       }))}
                                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                            </div>
                            <input
                                className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                                type="submit" value="Add State"/>
                        </div>
                    </form>
                    {states.length !== 0 && <TableComponent tableName={"States"} TableData={states}/>}
                    <form onSubmit={submitCity}>
                        <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                            <span className="text-lg font-public-sans font-bold">Add City</span>
                            <hr/>
                            <div className="w-full">
                                <label htmlFor="state">State</label>
                                <Select className="mt-1 w-full border border-gray-500"
                                        id="state" isSearchable={true}
                                        options={[...states.map(x => ({
                                            value: x.id, label: x.state
                                        }))]}
                                        onChange={(option) => handleOptionChanged(option.value, "state")}/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="name">City</label>
                                <input type="text" id="name" value={cityForm.name}
                                       onChange={(e) => setCityForm((prevState) => ({
                                           ...prevState, name: e.target.value
                                       }))}
                                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                            </div>
                            <div className="w-full">
                                <label htmlFor="name">Shipping Rate</label>
                                <input type="text" id="name" value={cityForm.shipping_rate}
                                       onChange={(e) => setCityForm((prevState) => ({
                                           ...prevState, shipping_rate: e.target.value
                                       }))}
                                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                            </div>
                            <input
                                className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                                type="submit" value="Add City"/>
                        </div>
                    </form>
                    {cities.length !== 0 && <TableComponent tableName={"Cities"} TableData={cities}/>}
                </div>
            </React.Fragment>}
        </>
    )
}
export default ShippingSettingsPage