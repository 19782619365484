import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {setMenuItemsId} from "../../redux/actions/AdminPanelActions";
import {getBaseUrl} from "../../App";

const SidebarIcons = ({icons}) => {
    const menuItemsId = useSelector((state) => state.menuItemsId);
    const dispatch = useDispatch()
    const {user, success} = useSelector((state) => state.userData);
    return (
        <React.Fragment>
            <div className="flex flex-col h-screen w-[22%] bg-blue-700 items-center">
                <div className="h-20 w-full flex justify-center items-center">
                    <img className="rounded-full w-9 h-9 bg-cover" src={success && getBaseUrl() + user.profile_pic}
                         alt=""/>
                </div>
                <div
                    className="flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 ">
                    {icons.map(icon => (
                        icon.name !== "empty" && <div key={icon.id} onClick={() => dispatch(setMenuItemsId(icon.id))}
                                                      className={`hover:bg-black hover:bg-opacity-20 hover:rounded-md hover:opacity-100 flex h-14 w-12 mb-1 flex-col justify-center items-center cursor-pointer text-white transition-all
                        ${menuItemsId === icon.id ? " bg-black bg-opacity-20 rounded-md opacity-100" : "opacity-50"}`}>

                            <span className={`material-symbols-rounded`}>{icon.name}</span>
                            <span className={`text-[0.5rem]`}>{icon.label}</span>

                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}
export default SidebarIcons