import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {fetchAPISettingsData, fetchStripePaymentsData} from "../../../redux/actions/SettingsActions";
import useAlert from "../../../CustomHooks/useAlert";
import TableComponent from "../../../Components/TableComponentFiles/TableComponent";

const StripePage = () => {
    const {apiSettings} = useSelector((state) => state.apiSettingsData);
    const {stripePayments, success} = useSelector((state) => state.stripePaymentsDataReducer);
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    const [stripeData, setStripeData] = useState(
        {name: "stripe", public_key: "", secret_key: "", enabled: true})
    useEffect(() => {
        dispatch(fetchStripePaymentsData())
    }, []);
    useEffect(() => {
        const apiData = apiSettings.find(apiSetting => apiSetting.name === "stripe")
        if (success && apiData) {
            setStripeData({
                ...stripeData,
                public_key: apiData.public_key,
                secret_key: apiData.secret_key,
            })
        }
    }, [success])
    const submit = async (e) => {
        try {
            const response = await axios.post('/admin/payments/update/stripe', stripeData);
            showAlert("success", "Stripe Keys Updated")
            dispatch(fetchAPISettingsData())
        } catch (error) {
            showAlert("error", error.message)
        }
    }
    return (
        <div className="flex flex-col bg-gray-100 min-h-[84vh] gap-4">
            {AlertComponent}
            <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                <div className="flex justify-between">
                    <span className="text-lg font-public-sans">Stripe Keys</span>
                </div>
                <input value={stripeData.public_key}
                       placeholder="Public Key"
                       onChange={(e) => setStripeData({...stripeData, public_key: e.target.value})}
                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                <input value={stripeData.secret_key}
                       placeholder="Secret Key"
                       onChange={(e) => setStripeData({...stripeData, secret_key: e.target.value})}
                       className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                <div className="flex justify-center p-4 mx-2">
                    <button
                        className="w-10/12 hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                        onClick={submit}>{"Update Stripe Keys"}</button>
                </div>
            </div>
            <div className="flex flex-col rounded-lg mx-2 p-4 bg-white gap-3">
                <ul>For PayPal Activation, you must
                    <li>-> Activate it from your stripe account</li>
                    <li>-> Must Add USD currency in currency settings, and payment will be proceeded according to the
                        exchange rates provided there
                    </li>
                    <li>-> It's recommended to use currency exchanger API</li>
                </ul>
            </div>
            {success && <div className="rounded-lg bg-white mx-2 mb-4">
                <TableComponent TableData={stripePayments.map(({
                                                                   id,
                                                                   tracking_code,
                                                                   customer_email,
                                                                   payment_intent,
                                                                   payment_status,
                                                                   payment_made,
                                                                   amount_required,
                                                                   payment_made_in_currency,
                                                                   amount_required_in_currency,
                                                                   checkout_session_id,
                                                                   updated_at
                                                               }) => ({
                    id,
                    tracking_code,
                    payment_ID: payment_intent,
                    customer_email,
                    payment_status,
                    payment_made,
                    amount_required,
                    payment_made_in_currency,
                    amount_required_in_currency,
                    session_ID: checkout_session_id,
                    updated_at
                }))} tableName="Stripe Payments"/>
            </div>}
        </div>
    )
}
export default StripePage
