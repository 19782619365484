export const getObjectFromString = (inputString) => {// Step 1: Split the string by ', ' to get individual key-value pairs
    const pairs = inputString.split(', ');

// Step 2: Initialize an empty object to hold the result
    const resultObject = {};

// Step 3: Iterate over each pair, split by space, and add to the object
    pairs.forEach(pair => {
        const [key, value] = pair.split(' ');
        resultObject[key] = parseInt(value, 10); // Convert the value to a number
    });
    return resultObject
}
export const updateIndexOfArray = (array) => {
    return array.map((item, idx) => ({
        ...item, index: idx
    }));
};

export function findObject(nestedArray, target) {
    for (const item of nestedArray) {
        // Check if the current item matches the target
        if (item === target) {
            return item;
        }
        // If the current item has an "items" array, search within it
        if (item.items && Array.isArray(item.items)) {
            const result = findObject(item.items, target);
            if (result) {
                return result;
            }
        }
    }
    return null; // Return null if the object is not found
}

export function findAndReplace(nestedArray, target, newObject) {
    return nestedArray.map(item => {
        // Check if the current item matches the target
        if (item === target) {
            return newObject;
        }
        // If the current item has an "items" array, search and replace within it
        if (item.items && Array.isArray(item.items)) {
            return {
                ...item,
                items: findAndReplace(item.items, target, newObject)
            };
        }
        return item;
    });
}
export function toTitleCase(str) {
    str = str.trim()
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
export const getNameFromPathname = (pathname, page) => {
    let index = 1
    if (page) {
        index = 2
    }
    return pathname.split("/")[index].replaceAll("-", " ").split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const splitArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

export const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    return date.toLocaleDateString('en-US', options)
}

export const capitalizeFirstLetter = (str, convertUnderscores = false) => {
    if (convertUnderscores) {
        // Replace underscores with spaces and capitalize the first letter of each word
        return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    } else {
        // Capitalize only the first letter of the string
        return str.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
    }
};