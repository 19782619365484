import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../Components/TableComponentFiles/TableComponent";
import {
    fetchVariations,
    fetchVariationsWithTypes, fetchVariationTypes,
    postVariation,
    postVariationType
} from "../../redux/actions/VariationActions";
import {useEffect} from "react";
import axios from "axios";
import useAlert from "../../CustomHooks/useAlert";
import {useParams} from "react-router-dom";

const VariationsPage = () => {
    const {variationTypeId, variationId} = useParams()
    const [showAlert, AlertComponent] = useAlert();
    const [variationType, setVariationType] = useState(null)
    const dispatch = useDispatch();
    const variationsListData = useSelector((state) => state.variationsList);
    const {variations} = variationsListData
    const variationTypesListData = useSelector((state) => state.variationTypesList);
    const {variationTypes, success} = variationTypesListData
    const [formData, setFormData] = useState({
        variation: "",
        variation_type_id: "",
    })
    const fetchData = () => {
        dispatch(fetchVariations())
        dispatch(fetchVariationTypes())
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        if (variationId && variations.length !== 0) {
            const variation = variations.find(x => x.id === Number(variationId))
            setFormData(variation)
        }
    }, [variationId, variations]);
    useEffect(() => {
        if (variationTypeId && variationTypes.length !== 0) {
            const variation_type = variationTypes.find(x => x.id === Number(variationTypeId))
            setVariationType(variation_type.variation_type)
        }
    }, [variationTypeId, variationTypes]);
    const submitVariation = async (e) => {
        e.preventDefault()
        if (variationId) {
            try {
                const {data} = await axios.post("/admin/products/update/variation/" + variationId, formData)
                showAlert('success', "Variation Updated Successfully")
            } catch (e) {
                showAlert('error', e.message)
            }
        } else {
            try {
                const {data} = await axios.post("/admin/products/update/variations", formData)
                showAlert('success', "Variation Added Successfully")
                setFormData({
                    variation: "",
                    variation_type_id: "",
                })
            } catch (e) {
                showAlert('error', e.message)
            }
        }
        fetchData()
    }
    const submitVariationType = async (e) => {
        e.preventDefault()
        if (variationTypeId) {
            try {
                const {data} = await axios.post("/admin/products/update/variation-type/" + variationTypeId, {variation_type: variationType})
                showAlert('success', "Variation Type Updated Successfully")
            } catch (e) {
                showAlert('error', e.message)
            }
        } else {
            try {
                const {data} = await axios.post("/admin/products/update/variation-types", {variation_type: variationType})
                showAlert('success', "Variation Type Added Successfully")
                setVariationType('')
            } catch (e) {
                showAlert('error', e.message)
            }
        }
        fetchData()
    }
    return (
        <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                {!variationId && <form onSubmit={submitVariationType}>
                    <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Variation Type</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="name">Type Name</label>
                            <input type="text" id="name" value={variationType}
                                   onChange={(e) => setVariationType(e.target.value)}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value={`${variationTypeId ? "Update" : "Add"} Variation Type`}/>
                    </div>
                </form>}
                {!variationTypeId && <form onSubmit={submitVariation}>
                    <div className="flex flex-col rounded-lg mb-5 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Variations</span>
                        <hr/>
                        <div className="w-full">
                            <label htmlFor="category">Variation Type</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="category" value={formData.variation_type_id}
                                    onChange={(e) => setFormData({...formData, variation_type_id: e.target.value})}>
                                <option value={""} selected disabled>Select Variation Type</option>
                                {success && variationTypes.map((value, index) => (
                                    <option value={value.id}>{value.variation_type}</option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full">
                            <label htmlFor="name">Variation Name</label>
                            <input type="text" id="name" value={formData.variation}
                                   onChange={(e) => setFormData({...formData, variation: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <input
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit" value={`${variationId ? "Update" : "Add"} Variation`}/>
                    </div>
                </form>}
                {success && variationTypes.length !== 0 && !variationId && !variationTypeId &&
                    <div className="flex flex-col rounded-lg mb-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Variation Types Data</span>
                        <hr/>
                        <div className="rounded-lg bg-white mx-2 mb-4">
                            <TableComponent TableData={variationTypes} tableName="Variation Types" showAlert={showAlert}/>
                        </div>
                    </div>}
                {success && variations.length !== 0 && !variationId && !variationTypeId &&
                    <div className="flex flex-col rounded-lg mb-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Variations Data</span>
                        <hr/>
                        <div className="rounded-lg bg-white mx-2 mb-4">
                            <TableComponent TableData={variations} tableName="Variations" showAlert={showAlert}/>
                        </div>
                    </div>}
            </div>
        </React.Fragment>
    )
}
export default VariationsPage