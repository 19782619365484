import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getBaseUrl} from "../../../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTruck} from "@fortawesome/free-solid-svg-icons";

const OrderDetailsPage = ({order, getDate}) => {
    let currency = useSelector((state) => state.currencyReducer);
    const {paymentSettings, success} = useSelector((state) => state.paymentSettingsData);
    const {currencies} = paymentSettings
    const foundCurrency = currencies.find(x => x.code === order.currency)
    if (foundCurrency) {
        currency = foundCurrency.symbol
    }
    return (
        <React.Fragment>
            <div className="bg-gray-100 py-1">
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div className="flex border-b-2 justify-between items-center pb-2">
                        <span className="text-lg font-bold">Order Details</span>
                        <Link to={"/invoice/" + order.id}><span className="bg-green-600 text-white rounded px-3 py-1.5">View Invoice</span></Link>
                        <Link to={"/update/order/" + order.id}><span
                            className="bg-green-600 text-white rounded px-3 py-1.5">Edit Details</span></Link>
                    </div>
                    <div>Order ID: {order.id}</div>
                    <div>Order Tracking Code: {order.tracking_code}</div>
                    <hr/>
                    <div><b>Status:</b> {order.status}</div>
                    <div><b>Payment Method:</b> {order.payment_method}</div>
                    <div><b>Payment
                        Status:</b> {order.payment_status}
                    </div>
                    {order.receipt_image &&
                        <div>Please Check the receipt, if genuine you can change payment status by editing this
                            order</div>}
                    {order.receipt_image &&
                        <div><b>Uploaded Image:</b> <a href={getBaseUrl() + order.receipt_image} target="_blank"><img
                            className="h-56 w-auto border-2 rounded"
                            src={getBaseUrl() + order.receipt_image + "?width=300"}/></a>
                        </div>}
                    <div><b>Status Updated on:</b> {getDate(order.updated_at)}</div>
                    <div><b>Order Placed on:</b> {getDate(order.created_at)}</div>
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <span className="text-lg font-bold">Customer Details</span>
                    <hr/>
                    <div><b>Name:</b> {order.customer.name}</div>
                    <div><b>Email:</b> {order.customer.email}</div>
                    <div><b>Phone:</b> {order.customer.phone}</div>
                    <div><b>Address:</b> {order.customer.address}</div>
                    <div><b>Country:</b> {order.customer.country}</div>
                    <div><b>State:</b> {order.customer.state}</div>
                    <div><b>City:</b> {order.customer.city}</div>
                    <div><b>Postal Code:</b> {order.customer.postal_code}</div>
                </div>

                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <span className="text-lg font-bold">Ordered Products</span>
                    <hr/>
                    {order.ordered_products.map((product, index) => (
                        <div className="relative">
                            {product.free_delivery &&
                                <div
                                    className="absolute z-10 text-center text-white rounded-br-lg rounded-tr-lg bottom-2 left-0 px-2 py-1 bg-teal-600 text-xs">
                                    <div className="flex gap-2 items-center">
                                        <span>Free Delivery</span></div>
                                </div>}
                            <div className='flex gap-2 my-1'>
                                <div
                                    className="h-24 w-24 overflow-hidden flex justify-center px-2 items-center rounded">
                                    <img className="w-auto h-auto object-contain max-h-full max-w-none"
                                         src={getBaseUrl() + product.image + "?width=200"}/>
                                </div>
                                <div className="flex flex-col">
                                    {index !== 0 ? <hr/> : ""}
                                    <div><b>Product Name:</b> {product.name}</div>
                                    <div><b>Product ID:</b> {product.product_id}</div>
                                    {product.variations.length !== 0 &&
                                        <div><b>Variation:</b> {product.variations}</div>}
                                    <div><b>Quantity:</b> {product.qty}</div>
                                    <div><b>Price:</b> {currency + (product.price * product.qty).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div><b>Subtotal:</b> {currency + order.subtotal.toFixed(2)}</div>
                    <div>
                        <b>Shipping:</b> {order.shipping_rate === 0 ? "Free" : currency + order.shipping_rate.toFixed(2)}
                    </div>
                    <div>
                        <b>Coupon:</b> {order.coupon_code + order.coupon_type === "gift" ? "Gift" : order.coupon_discount === 0 ? "Not Applied" : currency + order.coupon_discount}
                    </div>
                    {order.payment_method_discount > 0 && <div>
                        <b> Payment Option
                            Discount:</b> {currency + (order.payment_method_discount * order.subtotal / 100).toFixed(2)}
                    </div>
                    }
                    <div><b>Total:</b> {currency + order.total_price.toFixed(2)}</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default OrderDetailsPage