import {
    CART_ITEMS_LIST, DELETE_CART_ITEM, REMOVE_CART_ITEM, ADD_CART_ITEM,CLEAR_CART
} from "../Constants/CartConstants";

const initialState = localStorage.getItem("cartItemsStorage") ?
    JSON.parse(localStorage.getItem("cartItemsStorage")) : []
export const cartItemsListReducer = (state = {list: initialState}, action) => {
    switch (action.type) {
        case CART_ITEMS_LIST:
            return {listed: true, list: action.payload}
        case ADD_CART_ITEM:
            return {added: true, list: action.payload}
        case REMOVE_CART_ITEM:
            return {removed: true, list: action.payload}
        case DELETE_CART_ITEM:
            return {deleted: true, list: action.payload}
        case CLEAR_CART:
            return {cleared: true, list: []}
        default:
            return state;
    }
}