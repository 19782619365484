import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrderList} from "../../../redux/actions/OrderActions";
import {fetchContactSettingsData} from "../../../redux/actions/SettingsActions";
import {getBaseUrl} from "../../../App";
import Loading from "../../../Components/loading";

const Invoice = () => {
    const {id} = useParams()
    const currency = useSelector((state) => state.currencyReducer);
    const {generalSettings} = useSelector((state) => state.generalSettingsData);
    const {contactSettings, success, loading} = useSelector((state) => state.contactSettingsData);
    const orderListData = useSelector((state) => state.ordersList);
    const {ordersList} = orderListData
    const dispatch = useDispatch()
    const [order, setOrder] = useState({})
    useEffect(() => {
        dispatch(fetchContactSettingsData())
    }, [])
    useEffect(() => {
        if (orderListData.success) {
            const order = ordersList.find(x => x.id === Number(id))
            setOrder(order)
        }
    }, [orderListData.success])
    return (
         <React.Fragment>
             {(Object.keys(order).length !== 0 && orderListData.success) && <div className="bg-gray-100 py-1 flex justify-center break-words">
                 <div className="w-full border-2">
                     <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                         <div className="flex border-b-2 justify-between items-center pb-2">
                             <span className="text-lg font-bold">Invoice</span>
                         </div>
                         <div>Invoice ID: {order.tracking_code}</div>
                         <hr/>
                         <div><b>Status:</b> Dispatched</div>
                         <div><b>Payment Method:</b> {order.payment_method}</div>
                         <div><b>Payment
                             Status:</b> {order.payment_method.includes("Bank") ? "Payment Processed" : "Payment Awaiting"}
                         </div>
                         <div><b>Order Placed on:</b> {getDate(order.created_at)}</div>
                         <div><b>Order Dispatched on:</b> {getDate(order.updated_at)}</div>

                     </div>
                     <hr/>
                     <div className="grid grid-cols-2">
                         <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                             <span className="text-lg font-bold">Seller Details</span>
                             <hr/>
                             <div><b>Name:</b> {generalSettings.app_name}</div>
                             <div><b>Email:</b> {contactSettings.find(x => x.name === "Email")?.value}</div>
                             <div><b>Phone:</b> {contactSettings.find(x => x.name === "Phone")?.value}</div>
                             <div>
                                 <b>Address:</b> {order.shop_address === "pending" ? (contactSettings.find(x => x.name === "Location")?.value || order.shop_address) : order.shop_address}
                             </div>
                             <div><b>Message:</b> {order.message_by_merchant}</div>
                         </div>
                         <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                             <span className="text-lg font-bold">Customer Details</span>
                             <hr/>
                             <div><b>Name:</b> {order.customer.name}</div>
                             <div><b>Email:</b> {order.customer.email}</div>
                             <div><b>Phone:</b> {order.customer.phone}</div>
                             <div>
                                 <b>Address:</b> {order.customer.address + ", " + order.customer.city + ", " + order.customer.state + ", " + order.customer.country}
                             </div>
                             <div><b>Postal Code:</b> {order.customer.postal_code}</div>
                         </div>
                     </div>
                     <hr/>
                     <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                         <span className="text-lg font-bold">Ordered Products</span>
                         <hr/>
                         <div className="grid grid-cols-2">
                             {order.ordered_products.map((product, index) => (
                                 <div className="relative">
                                     {product.free_delivery &&
                                         <div
                                             className="absolute z-10 text-center text-white rounded-br-lg rounded-tr-lg bottom-2 left-0 px-2 py-1 bg-teal-600 text-xs">
                                             <div className="flex gap-2 items-center">
                                                 <span>Free Delivery</span></div>
                                         </div>}
                                     <div className='flex gap-2 my-1'>
                                         <div
                                             className="h-24 w-24 overflow-hidden flex justify-center px-2 items-center rounded">
                                             <img className="w-auto h-auto object-contain max-h-full max-w-none"
                                                  src={getBaseUrl() + product.image + "?width=200"}/>
                                         </div>
                                         <div className="flex flex-col">
                                             {index !== 0 ? <hr/> : ""}
                                             <div><b>Product Name:</b> {product.name}</div>
                                             {product.variations.length !== 0 &&
                                                 <div><b>Variation:</b> {product.variations}</div>}
                                             <div><b>Quantity:</b> {product.qty}</div>
                                             <div><b>Price:</b> {currency + (product.price * product.qty).toFixed(2)}
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             ))}
                         </div>
                     </div>
                     <hr/>
                     <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                         <div><b>Subtotal:</b> {currency + order.subtotal}</div>
                         <div><b>Shipping:</b> {order.shipping_rate === 0 ? "Free" : currency + order.shipping_rate}
                         </div>
                         <div>
                             <b>Coupon:</b> {order.coupon_code + order.coupon_type === "gift" ? "Gift" : order.coupon_discount === 0 ? "Not Applied" : currency + order.coupon_discount}
                         </div>
                         {order.payment_method_discount > 0 && <div>
                             <b> Payment Option
                                 Discount:</b> {currency + (order.payment_method_discount * order.subtotal / 100).toFixed(2)}
                         </div>
                         }
                         <div className="w-full flex justify-between"><b>Total: {currency + order.total_price}</b><span
                             className="cursor-pointer bg-gray-400 text-white rounded px-2 py-1"
                             onClick={() => window.print()}>Print</span></div>
                     </div>
                 </div>
             </div>}
             {loading && <Loading/>}
         </React.Fragment>
    )
}

const getDate = (givenDate) => {
    const date = new Date(givenDate);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        minute: "numeric",
        hour: "numeric",
        second: "numeric"
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
}
export default Invoice