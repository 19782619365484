import DashboardPage from "./Screens/AdminPanel/Home/DashboardPage";
import NavigationPage from "./Screens/AdminPanel/Home/NavigationPage";
import FooterLinksPage from "./Screens/AdminPanel/Home/FooterLinksPage";
import SlidersPage from "./Screens/AdminPanel/Home/SlidersPage";
import BannersPage from "./Screens/AdminPanel/Home/BannersPage";
import CouponsPage from "./Screens/AdminPanel/Home/CouponsPage";
import TopBarPage from "./Screens/AdminPanel/Home/TopBarPage";
import UsersPage from "./Screens/AdminPanel/Home/UsersPage";
import HomePageManagerPage from "./Screens/AdminPanel/Home/HomePageManagerPage";
import OrdersPage from "./Screens/AdminPanel/Orders/OrdersPage";
import Feedbacks from "./Screens/AdminPanel/Orders/Feedbacks";
import EditOrderPage from "./Screens/AdminPanel/Orders/EditOrderPage";
import ProductsPage from "./Screens/AdminPanel/Products/ProductsPage";
import UploadBulkProductPage from "./Screens/AdminPanel/Products/UploadBulkProductPage";
import VariationsPage from "./Screens/AdminPanel/VariationsPage";
import AddCategoryPage from "./Screens/AdminPanel/Categories/AddCategoryPage";
import EditCategoryPage from "./Screens/AdminPanel/Categories/EditCategoryPage";
import CategoriesPage from "./Screens/AdminPanel/Categories/CategoriesPage";
import UploadBulkCategoryPage from "./Screens/AdminPanel/Categories/UploadBulkCategoryPage";
import AddCollectionPage from "./Screens/AdminPanel/Collections/AddCollectionPage";
import CollectionsPage from "./Screens/AdminPanel/Collections/CollectionsPage";
import UploadBulkCollectionPage from "./Screens/AdminPanel/Collections/UploadBulkCollectionPage";
import MediaPage from "./Screens/AdminPanel/Pages/MediaPage";
import CustomPageEditor from "./Screens/AdminPanel/Content/CustomPageEditor/CustomPageEditor";
import FeaturesPage from "./Screens/AdminPanel/Content/FeaturesPage";
import TeamMembersPage from "./Screens/AdminPanel/Content/TeamMembersPage";
import ItemsOrganizer from "./Screens/AdminPanel/Content/ItemsOrganizer";
import ItemGroups from "./Screens/AdminPanel/Content/ItemGroups/ItemGroups";
import CustomCodes from "./Screens/AdminPanel/Content/CustomCodes";
import VisualSettingsPage from "./Screens/AdminPanel/Settings/VisualSettingsPage";
import ContentEditors from "./Screens/AdminPanel/Content/ContentEditors";
import ContentPages from "./Screens/AdminPanel/Pages/ContentPages";
import AddPage from "./Screens/AdminPanel/Pages/AddPage";
import ReviewsPage from "./Screens/AdminPanel/Reviews/ReviewsPage";
import AddReviewPage from "./Screens/AdminPanel/Reviews/AddReviewPage";
import UploadBulkReviewsPage from "./Screens/AdminPanel/Reviews/UploadBulkReviewsPage";
import ShippingSettingsPage from "./Screens/AdminPanel/Couriers/ShippingSettingsPage";
import ImportLocations from "./Screens/AdminPanel/Couriers/ImportLocations/ImportLocations";
import EditLocation from "./Screens/AdminPanel/Couriers/ImportLocations/EditLocation";
import GeneralSettingsPage from "./Screens/AdminPanel/Settings/GeneralSettingsPage";
import ContactSettingsPage from "./Screens/AdminPanel/Settings/ContactSettingsPage";
import SocialSettingsPage from "./Screens/AdminPanel/Settings/SocialSettingsPage";
import APISettingsPage from "./Screens/AdminPanel/Settings/APISettingsPage";
import EmailSettingsPage from "./Screens/AdminPanel/Settings/EmailSettingsPage";
import AccountSettingsPage from "./Screens/AdminPanel/Settings/AccountSettingsPage";
import PaymentSettingsPage from "./Screens/AdminPanel/Payments/PaymentSettingsPage";
import CurrencySettingsPage from "./Screens/AdminPanel/Payments/CurrencySettingsPage";
import StripePage from "./Screens/AdminPanel/Payments/StripePage";
import ProductPage from "./Screens/AdminPanel/Products/ProductPage";
import MigrateProductsPage from "./Screens/AdminPanel/Products/MigrateProductsPage";

const homeRoutes = [{path: "/dashboard", PageContent: DashboardPage}, {
    path: "/navigation", PageContent: NavigationPage
}, {path: "/navigation/update/:id", PageContent: NavigationPage}, {
    path: "/footer-links", PageContent: FooterLinksPage
}, {path: "/footer-links/update/:id", PageContent: FooterLinksPage}, {
    path: "/image-sliders", PageContent: SlidersPage
}, {path: "/image-slider-banners/update/:bannerId", PageContent: SlidersPage}, {
    path: "/image-sliders/update/:sliderId", PageContent: SlidersPage
}, {path: "/banners", PageContent: BannersPage}, {
    path: "/coupons", PageContent: CouponsPage
}, {path: "/coupons/update/:id", PageContent: CouponsPage}, {path: "/top-bar", PageContent: TopBarPage}, {
    path: "/users", PageContent: UsersPage
}, {path: "/homepage-manager", PageContent: HomePageManagerPage},]
const orderRoutes = [{
    path: "/all-orders", PageContent: OrdersPage, props: {type: "all"}
}, {path: "/delivered-orders", PageContent: OrdersPage, props: {type: "delivered"}}, {
    path: "/pending-orders", PageContent: OrdersPage, props: {type: "pending"}
}, {path: "/cancelled-orders", PageContent: OrdersPage, props: {type: "cancelled"}}, {
    path: "/invoices", PageContent: OrdersPage, props: {type: "invoices"}
}, {path: "/feedbacks", PageContent: Feedbacks},
    {path: "/update/:id", PageContent: EditOrderPage, props: {}},]
const productsRoutes = [

    {path: "/add-product", PageContent: ProductPage}, {
        path: "/all-products", PageContent: ProductsPage, props: {type: "all"}
    }, {path: "/all-products/update/:slug", PageContent: ProductPage, props: {}}, {
        path: "/upload-bulk-products", PageContent: UploadBulkProductPage
    }, {
        path: "/migrate-from-shopify", PageContent: MigrateProductsPage
    }, {
        path: "/migrate-from-wordpress", PageContent: MigrateProductsPage
    }, {path: "/drafted-products", PageContent: ProductsPage, props: {type: "drafted"}}, {
        path: "/variations", PageContent: VariationsPage
    }, {path: "/variation-types/update/:variationTypeId", PageContent: VariationsPage}, {
        path: "/variations/update/:variationId", PageContent: VariationsPage
    },]
const categoryRoutes = [{path: "/add-category", PageContent: AddCategoryPage}, {
    path: "/all-categories/update/:id", PageContent: EditCategoryPage, props: {}
}, {path: "/all-categories", PageContent: CategoriesPage, props: {type: "all"}}, {
    path: "/drafted-categories", PageContent: CategoriesPage, props: {type: "drafted"}
}, {path: "/bulk-category-upload", PageContent: UploadBulkCategoryPage},]
const collectionRoutes = [{
    path: "/add-collection", PageContent: AddCollectionPage
}, {path: "/all-collections/update/:id", PageContent: AddCollectionPage}, {
    path: "/all-collections", PageContent: CollectionsPage, props: {type: "all"}
}, {
    path: "/drafted-collections", PageContent: CollectionsPage, props: {type: "drafted"}
}, {path: "/bulk-collection-upload", PageContent: UploadBulkCollectionPage},]
const contentRoutes = [{path: "/media-manager", PageContent: MediaPage}, {
    path: "/page-editor", PageContent: CustomPageEditor
}, {path: "/features", PageContent: FeaturesPage}, {
    path: "/features/update/:id", PageContent: FeaturesPage
}, {path: "/team-members", PageContent: TeamMembersPage}, {
    path: "/team-members/update/:id", PageContent: TeamMembersPage
}, {path: "/items-organizer", PageContent: ItemsOrganizer}, {
    path: "/items-organizer/update/:id", PageContent: ItemsOrganizer
}, {path: "/item-groups", PageContent: ItemGroups}, {
    path: "/item-groups/update/:id", PageContent: ItemGroups
}, {path: "/items-of-group/:itemId", PageContent: ItemGroups}, {
    path: "/custom-code", PageContent: CustomCodes
}, {path: "/custom-code/update/:id", PageContent: CustomCodes}, {
    path: "/custom-design", PageContent: VisualSettingsPage
}, {path: "/rich-content-editors", PageContent: ContentEditors}, {
    path: "/rich-content-editors/update/:id", PageContent: ContentEditors
},

    {path: "/all-pages", PageContent: ContentPages, props: {type: "all"}}, {
        path: "/drafted-pages", PageContent: ContentPages, props: {type: "drafted"}
    }, {path: "/add-page", PageContent: AddPage}, {
        path: "/all-pages/update/:slug", PageContent: AddPage
    }, {path: "/update/page-item/:id", PageContent: AddPage},

]
const reviewsRoutes = [{
    path: "/approved-reviews", PageContent: ReviewsPage, props: {type: "approved"}
}, {path: "/pending-reviews", PageContent: ReviewsPage, props: {type: "pending"}}, {
    path: "/add-review", PageContent: AddReviewPage, props: {}
}, {path: "/update/:id", PageContent: AddReviewPage, props: {}}, {
    path: "/upload-bulk-reviews", PageContent: UploadBulkReviewsPage, props: {}
},]
const couriersRoutes = [{path: "/shipping-settings", PageContent: ShippingSettingsPage}, {
    path: "/import-locations", PageContent: ImportLocations
}, {path: "/location/:type/:id", PageContent: EditLocation}, {
    path: "/sonic-trax", PageContent: VisualSettingsPage
}, {path: "/tcs", PageContent: VisualSettingsPage}, {path: "/leopards", PageContent: VisualSettingsPage},]
const settingsRoutes = [{path: "/general-settings", PageContent: GeneralSettingsPage}, {
    path: "/product-settings", PageContent: VisualSettingsPage
}, {path: "/contact-settings", PageContent: ContactSettingsPage}, {
    path: "/contact-settings/update/:id", PageContent: ContactSettingsPage
}, {path: "/social-settings", PageContent: SocialSettingsPage}, {
    path: "/api-settings", PageContent: APISettingsPage
}, {path: "/email-settings", PageContent: EmailSettingsPage}, {
    path: "/visual-settings", PageContent: VisualSettingsPage
}, {path: "/account-settings", PageContent: AccountSettingsPage},]
const trashRoutes = [{
    path: "/deleted-pages", PageContent: ContentPages, props: {type: "deleted"}
}, {
    path: "/deleted-categories", PageContent: CategoriesPage, props: {type: "deleted"}
}, {
    path: "/deleted-collections", PageContent: CollectionsPage, props: {type: "deleted"}
}, {path: "/deleted-products", PageContent: ProductsPage, props: {type: "deleted"}}, {
    path: "/deleted-orders", PageContent: OrdersPage, props: {type: "deleted"}
},]
const paymentRoutes = [{path: "/payment-settings", PageContent: PaymentSettingsPage}, {
    path: "/payment-settings/update/:id", PageContent: PaymentSettingsPage
}, {path: "/currency-settings", PageContent: CurrencySettingsPage}, {
    path: "/currency-settings/update/:id", PageContent: CurrencySettingsPage
}, {path: "/stripe", PageContent: StripePage},];

export const routesConfig = [
    { type: 'home', routes: homeRoutes },
    { type: 'orders', routes: orderRoutes },
    { type: 'content', routes: contentRoutes },
    { type: 'products', routes: productsRoutes },
    { type: 'categories', routes: categoryRoutes },
    { type: 'collections', routes: collectionRoutes },
    { type: 'settings', routes: settingsRoutes },
    { type: 'payments', routes: paymentRoutes },
    { type: 'couriers', routes: couriersRoutes },
    { type: 'trash', routes: trashRoutes },
    { type: 'reviews', routes: reviewsRoutes }
];
