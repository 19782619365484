import React from "react";
import SvgIcon from "../../../../../utils/IconsSelector/SvgIcon";
import {useSelector} from "react-redux";

const ContactUsInformation = ({title = "Get In Touch"}) => {
    const {contactSettings} = useSelector((state) => state.contactSettingsData);
    return (
        <div className={`align-self-start col-span-1`}>
            <div className="sticky top-0">
                <div className="flex flex-col gap-6">
                    {title !== "" && title !== null && <div className="flex flex-col">
                        <span className="text-2xl font-bold">{title}</span>
                        <span className="h-0.5 w-24 bg-teal-500"></span>
                    </div>}
                    {contactSettings.map(x => (
                        <div className="flex bg-gray-100 rounded-lg items-center px-6 py-3 gap-4">
                        <span
                            className=" bg-teal-500 rounded-lg text-white px-2 py-1.5"> <SvgIcon className="text-white"
                                                                                                 d={x.icon_svg}/></span>
                            <div className="flex flex-col">
                                <span className="font-bold text-lg">{x.name}</span>
                                <span>{x.value}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ContactUsInformation

