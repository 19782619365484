import React, {useEffect, useRef, useState} from "react";
import csv from "csvtojson"
import * as XLSX from 'xlsx';
import axios from "axios";
import Select from 'react-select';
import useAlert from "../../../CustomHooks/useAlert";
import {useDispatch, useSelector} from "react-redux";
import {fetchVariations, fetchVariationTypes} from "../../../redux/actions/VariationActions";
import {getBaseUrl} from "../../../App";
import {fetchCollectionList, fetchCategoryList} from "../../../redux/actions/CategoryActions";
import {Button} from "../../../Components/ui/Button";
import Loading from "../../../Components/loading";
import {showAlert} from "../../../utils/providers/AlertProvider";

const UploadBulkProductPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchVariations())
        dispatch(fetchVariationTypes())
        dispatch(fetchCollectionList())
        dispatch(fetchCategoryList())
    }, [])
    const {variations} = useSelector((state) => state.variationsList);
    const {variationTypes} = useSelector((state) => state.variationTypesList);
    const {categories} = useSelector((state) => state.categoryList);
    const {collections} = useSelector((state) => state.collectionList);

    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [responses, setResponses] = useState([])
    const uploadStatusRef = useRef(null);

    const scrollToBottom = () => {
        if (uploadStatusRef.current) {
            uploadStatusRef.current.scrollTop = uploadStatusRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        // Automatically scroll to bottom when responses change
        scrollToBottom();
    }, [responses]);
    const handleFileUpload = (event) => {
        setFile(event.target.files[0])
    }
    const submit = () => {
        if (file) {
            if (file.name.slice(-5) === ".xlsx") {
                xlsxFileRead(file)
            } else if (file.name.slice(-4) === ".csv") {
                csvFileRead(file)
            } else {
                console.log("File Type not Supported")
            }
        }
    }

    const uploadData = async (categories) => {
        try {
            setLoading(true);
            let completedCount = 0;

            const updateCompletedCount = () => {
                completedCount += 1;
                // If all categories are uploaded, stop loading
                if (completedCount === categories.length) {
                    setLoading(false);
                }
            };

            // Use a for...of loop for sequential execution
            for (const category of categories) {
                try {
                    const { data } = await axios.post('/admin/products/update/bulk', category);
                    setResponses(prevState => ([...prevState, data]));
                } catch (error) {
                    setResponses(prevState => ([...prevState, error.message]));
                } finally {
                    updateCompletedCount();
                }
            }

        } catch (error) {
            showAlert("error", error.message);
            setLoading(false); // Handle this in case the error occurs outside of category uploads
        }
    };

    const csvFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const csvData = e.target.result;
            const jsonData = await csv().fromString(csvData);
            uploadData(jsonData)
            // Perform further operations with the converted JSON data
        };
        reader.readAsText(file);
    }
    const xlsxFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});

            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one to convert
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            uploadData(jsonData)
        }
        reader.readAsArrayBuffer(file);
        // Perform further operations with the converted JSON data
    }
    const Option = ({innerProps, data}) => (
        <div {...innerProps} className="flex justify-between items-center cursor-pointer hover:bg-gray-200">
            <span className="ml-2">{data.name}</span>
            <span className="mr-2">{data.id}</span>
        </div>
    );

    return (
        <>
            <div className="bg-gray-100 py-1">
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Upload Bulk Product</div>
                    <hr/>
                    <div>
                        <label htmlFor="file_uploader"
                               className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                            <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                            <span className="text-lg text-gray-600">Supported format: csv, xlsx</span>
                            <span
                                className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">Browse File</span>
                        </label>
                        <input id="file_uploader" type="file" accept=".csv, .xlsx" className="hidden"
                               onChange={handleFileUpload}/>
                    </div>
                    {file && file.name}
                    <Button onClick={!loading && submit}>{loading ?
                        <Loading height="25px" small={true}/> : "Start Uploading!"}</Button>
                    <div ref={uploadStatusRef} className="w-full border rounded-lg p-2 flex flex-col gap-1 overflow-y-auto max-h-52">
                        {responses.map((x, i) => {
                            const bgColor = x.includes("already") ? "bg-orange-600" : x.includes("error") ? "bg-red-600" : "bg-green-500"
                            return (<div
                                className={`${bgColor} px-2 py-1 text-gray-100 rounded-lg`}>{(i + 1) + ". " + x}</div>)
                        })}
                    </div>
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Documents For Help</div>
                    <hr/>
                    These documents can be used to generate your own csv file for quick upload
                   <a target="_blank" href={getBaseUrl()+"/admin/content/file/sample-products.xlsx"}> <div className="rounded bg-blue-600 text-white py-2 flex justify-center">
                        Download Sample XLSX
                    </div></a>
                    <a target="_blank" href={getBaseUrl()+"/admin/content/file/template-products.xlsx"}> <div className="rounded bg-green-600 text-white py-2 flex justify-center">
                        Download Template XLSX
                    </div></a>

                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Category Id Finder</div>
                    <hr/>
                    You can use this to find the id of categories for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...categories.map(x => ({
                                value: x.name+"  id="+x.id, label: x.name+"  id="+x.id, name:x.name, id:x.id
                            }))]}
                            placeholder={'Categories'}
                            components={{
                                Option,
                            }}  />
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Collection Id Finder</div>
                    <hr/>
                    You can use this to find the id of collections for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...collections.map(x => ({
                                value: x.name+"  id="+x.id, label: x.name+"  id="+x.id, name:x.name, id:x.id
                            }))]}
                            placeholder={'Collections'}
                            components={{
                                Option,
                            }}  />
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Variation Types Id Finder</div>
                    <hr/>
                    You can use this to find the id of variation types for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...variationTypes.map(x => ({
                                value: x.variation_type+"  id="+x.id, label: x.variation_type+"  id="+x.id, name:x.variation_type, id:x.id
                            }))]}
                            placeholder={'Variation Types'}
                            components={{
                                Option,
                            }}  />
                </div>
                <div className="m-3 bg-white rounded px-3 py-3 flex flex-col gap-2">
                    <div>Variation Id Finder</div>
                    <hr/>
                    You can use this to find the id of variations for use in csv file
                    <hr/>
                    <div>Search</div>
                    <Select className="mt-1 w-full border border-gray-500"
                            isSearchable={true}
                            options={[...variations.map(x => ({
                                value: x.variation+"  id="+x.id, label: x.variation+"  id="+x.id, name:x.variation, id:x.id
                            }))]}
                            placeholder={'Variations'}
                            components={{
                                Option,
                            }}  />
                </div>
            </div>
        </>
    )
}
export default UploadBulkProductPage