import React, {useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchCategoryList, postCategory} from "../../../redux/actions/CategoryActions";
import ImageUploadComponent from "../../../Components/ImageUploadComponent";
import useAlert from "../../../CustomHooks/useAlert";

const AddCategoryPage = () => {
    const [showAlert, AlertComponent] = useAlert();
    const dispatch = useDispatch()
    const {success, categories} = useSelector((state) => state.categoryList);
    const addCategoryStatus = useSelector((state) => state.addCategory);
    const {error, loading, message} = addCategoryStatus
    const [image, setImage] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        keywords: "",
        description: "",
        parent_id: 0,
        visibility: true,
        category_order: 0
    })
    useEffect(() => {
        dispatch(fetchCategoryList())
    }, []);

    const submit = async (e) => {
        e.preventDefault()
        if (Object.keys(image).length !== 0) {
            formData.image = image
            dispatch(postCategory(formData))
            dispatch(fetchCategoryList())
        } else {
            showAlert("Please upload an image")
        }
    }
    useEffect(() => {
        message && showAlert("success", "Category Added Successfully!")
        error && showAlert("error", error.message)
    }, [message, error])
    return (
        success && <React.Fragment>
            {AlertComponent}
            <div className="flex flex-col bg-gray-100 gap-4">
                <form onSubmit={submit}>
                    <div className="flex flex-col rounded-lg my-3 mx-2 p-4 bg-white gap-3">
                        <span className="text-lg font-public-sans font-bold">Category Information</span>
                        <hr/>

                        <div className="w-full">
                            <label htmlFor="name">Category Name</label>
                            <input required={true} type="text" id="full_name" value={formData.name}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="short_description">Short Description</label>
                            <textarea required={true} rows="5" id="short_description" value={formData.description}
                                      onChange={(e) => setFormData({...formData, description: e.target.value})}
                                      className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="category_order">Category Order</label>
                            <input required={true} type="number" id="category_order" value={formData.category_order}
                                   onChange={(e) => setFormData({...formData, category_order: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <div className="w-full">
                            <label htmlFor="category">Parent Category</label>
                            <select className="py-2 px-3 mt-1 rounded-md w-full border border-gray-500"
                                    id="category" value={formData.parent_id}
                                    onChange={(e) => setFormData({...formData, parent_id: e.target.value})}>
                                <option value={0}>None</option>
                                {categories.map(value => (
                                    <option key={value.id} value={value.id}>{value.name}</option>
                                ))}
                            </select>
                        </div>
                        <ImageUploadComponent setImage={setImage} title={"Set Category Image"} required={true}/>
                        <span>
                            Category Image should be an icon with small size and dimensions
                    </span>

                        <div className="w-full">
                            <label htmlFor="keywords">Key Words</label>
                            <input type="text" id="keywords" value={formData.keywords}
                                   onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                                   className="py-2 px-3 mt-0.5 rounded-md w-full border border-gray-500"/>
                        </div>
                        <button
                            className="w-full hover:bg-fuchsia-800 hover:shadow-2xl px-7 py-3 font-bold text-white bg-blue-700 rounded cursor-pointer transition-all shadow-md"
                            type="submit">{!loading ? "Add Category" : "Loading..."}</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
export default AddCategoryPage